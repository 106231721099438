import React from "react";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


const STLedgerData = (props) => {
    console.log(props.stLedger, "aaaaaaaaaaaaaaaaaaaaaaa")
    return (
        <>
            {props.isFetchingSTLedger ? (
                <div className="loader-div">
                    <Spinner
                        animation="border"
                        size="xl"
                        role="status"
                        aria-hidden="true"
                        variant="info"
                    />
                </div>
            ) : (
                <Container fluid>
                    <div className="main">
                        <div style={{ textAlign: 'center', marginBottom: '35px' }}>
                            <Link to="/hr/shortTerm/STLedger">
                                <Button variant="contained" endIcon={<ArrowBackIcon />} size="large" > BACK TO SEARCH </Button>
                            </Link>
                        </div>

                        <div className="main">
                            <h5>Employee Name: {props.stLedger.employeeDetails.employeeName}</h5>
                            <h5>Total Payment Amount: {props.stLedger.debitSum}</h5>
                            <h5>Total Received Amount: {props.stLedger.creditSum}</h5>
                            {/* <h5>Closing Balance: {props.searchInventoryLedgerList.closing_balance}</h5> */}
                            <MaterialTable
                                title="Short Term Ledger"
                                columns={[
                                    { title: "Date", field: "date" },
                                    { title: "Payment Type", field: "accType" },
                                    { title: "Description", field: "description" },
                                    // { title: "Opening Balance", field: "openingBalance" },
                                    { title: "Payment Amount", field: "debitValue" },
                                    { title: "Received Amount", field: "creditValue" },
                                    { title: "Closing Balance", field: "closingBalance" },
                                ]}
                                data={props.stLedger.data}
                                options={{
                                    actionsColumnIndex: -1,
                                    exportButton: true,
                                    exportAllData: true,
                                    paging: true,
                                    pageSize: 50,
                                    emptyRowsWhenPaging: false,
                                    pageSizeOptions: [50, 100, 150, 200],
                                    headerStyle: {
                                        position: "sticky",
                                        top: 0,
                                        color: "#00BBBB",
                                        fontWeight: "550",
                                        onRowAdd: "none",
                                    },
                                }}
                            />
                        </div>
                    </div>
                </Container>
            )}
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {

    };
};
const mapStateToProps = (state, ownProps) => ({
    stLedger: state.STReducer.stLedger,
    isFetchingSTLedger: state.STReducer.isFetchingSTLedger,
});

export default connect(mapStateToProps, mapDispatchToProps)(STLedgerData);