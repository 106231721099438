import * as React from 'react';
import { NavLink } from "react-router-dom";
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import CategoryIcon from '@mui/icons-material/Category';
import InventoryIcon from '@mui/icons-material/Inventory';
import DoorSlidingIcon from '@mui/icons-material/DoorSliding';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import ListAltIcon from '@mui/icons-material/ListAlt';
import LineWeightIcon from '@mui/icons-material/LineWeight';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import AddBoxIcon from '@mui/icons-material/AddBox';
import SummarizeIcon from '@mui/icons-material/Summarize';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PaymentsIcon from '@mui/icons-material/Payments';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import PaymentIcon from '@mui/icons-material/Payment';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import SearchIcon from '@mui/icons-material/Search';
import DomainVerificationIcon from '@mui/icons-material/DomainVerification';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import Filter4Icon from '@mui/icons-material/Filter4';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import BadgeIcon from '@mui/icons-material/Badge';
import PollIcon from '@mui/icons-material/Poll';
import TableChartIcon from '@mui/icons-material/TableChart';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import UploadIcon from '@mui/icons-material/Upload';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DataThresholdingIcon from '@mui/icons-material/DataThresholding';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import DiscountIcon from '@mui/icons-material/Discount';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import RamenDiningIcon from '@mui/icons-material/RamenDining';
import StraightenIcon from '@mui/icons-material/Straighten';
import LocalPizzaIcon from '@mui/icons-material/LocalPizza';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import LabelIcon from '@mui/icons-material/Label';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import BusinessIcon from '@mui/icons-material/Business';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ScaleIcon from '@mui/icons-material/Scale';
import BackupIcon from '@mui/icons-material/Backup';
import BarChartIcon from '@mui/icons-material/BarChart';
import AlignVerticalBottomIcon from '@mui/icons-material/AlignVerticalBottom';
import ApartmentIcon from '@mui/icons-material/Apartment';
import RepartitionIcon from '@mui/icons-material/Repartition';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import PaidIcon from '@mui/icons-material/Paid';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ArticleIcon from '@mui/icons-material/Article';
import SendIcon from '@mui/icons-material/Send';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import AssignmentReturnedIcon from '@mui/icons-material/AssignmentReturned';
import AddCommentIcon from '@mui/icons-material/AddComment';


export default function Menu(props) {
  const [openCategory, setOpenCategory] = React.useState(false);
  const [openSubCategory, setOpenSubCategory] = React.useState(false);
  const [openWarehouse, setOpenWarehouse] = React.useState(false);
  const [openUser, setOpenUser] = React.useState(false);
  const [openCoupon, setOpenCoupon] = React.useState(false);
  const [openVendor, setOpenVendor] = React.useState(false);
  const [openSize, setOpenSize] = React.useState(false);
  const [openProduct, setOpenProduct] = React.useState(false);
  const [openReciving, setOpenReciving] = React.useState(false);
  const [openUom, setOpenUom] = React.useState(false);
  const [openRecipe, setOpenRecipe] = React.useState(false);
  const [openDeal, setOpenDeal] = React.useState(false);
  const [openRestaurant, setOpenRestaurant] = React.useState(false);
  const [openMasterControl, setOpenMasterControl] = React.useState(false);
  // const [openhall, setOpenhall] = React.useState(false);
  const [openIssuance, setOpenissuance] = React.useState(false);
  const [openDemand, setOpenDemand] = React.useState(false);
  const [openPurchase, setOpenPurchase] = React.useState(false);
  const [openBrand, setOpenBrand] = React.useState(false);
  const [openFlavour, setOpenFlavour] = React.useState(false);
  const [generateBarcode, setGenerateBarCode] = React.useState(false);
  const [openCustomer, setOpenCustomer] = React.useState(false);
  const [igp, setIgp] = React.useState(false);
  const [openVoucher, setOpenVoucher] = React.useState(false);
  const [level4, setLevel4] = React.useState(false);
  const [level5, setLevel5] = React.useState(false);
  const [orderCreation, setOrderCreation] = React.useState(false);
  const [openExpenseType, setOpenExpenseType] = React.useState(false);
  const [division, setDivision] = React.useState(false);
  const [department, setDepartment] = React.useState(false);
  const [designation, setDesignation] = React.useState(false);
  const [shiftControl, setShiftControl] = React.useState(false);
  const [employeeType, setEmployeeType] = React.useState(false);
  const [employee, setEmployee] = React.useState(false);
  const [addSalary, setAddSalary] = React.useState(false);
  const [addIncrement, setAddIncrement] = React.useState(false);
  const [STAdvance, setSTAdvance] = React.useState(false);
  const [LTAdvance, setLTAdvance] = React.useState(false);
  const [fine, setFine] = React.useState(false);
  const [article, setArticle] = React.useState(false);
  const [costControl, setCostControl] = React.useState(false);
  const [productionIssuance, setProductionIssuance] = React.useState(false);
  const [productionReceiving, setProductionReceiving] = React.useState(false);


  const Checkpermissions = (permissionsType, permissionsName) => {
    return props.permissions.some(p => p[`${permissionsType}`] === permissionsName) ? true : false
  }

  // { STORE LIST }
  let storeList = <List>
    {/* WAREHOUSE */}
    {Checkpermissions("permissionsCatName", "Warehouse") &&
      <ListItemButton onClick={() => setOpenWarehouse(!openWarehouse)}>
        <ListItemIcon>
          <WarehouseIcon />
        </ListItemIcon>
        <ListItemText primary="Businnes Location" />
        {openWarehouse ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>}
    <Collapse in={openWarehouse} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/inventory/warehouse/warehousAdd" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Warehouse Add") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText primary="Add Businnes Location" />
            </ListItemButton>}
        </NavLink>
        <NavLink to="/inventory/warehouse/warehouseList" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Warehouse List") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary="Businnes Location List" />
            </ListItemButton>}
        </NavLink>
      </List>
    </Collapse>

    {/* CATEGORY */}
    {Checkpermissions("permissionsCatName", "Category") &&
      <ListItemButton onClick={() => setOpenCategory(!openCategory)}>
        <ListItemIcon>
          <CategoryIcon />
        </ListItemIcon>
        <ListItemText primary="Category" />
        {openCategory ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>}
    <Collapse in={openCategory} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/inventory/catagory/addcatagory" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Category Add") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText primary="Add Category" />
            </ListItemButton>}
        </NavLink>
        <NavLink to="/inventory/catagory/listcatagory" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Category List") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary="Category List" />
            </ListItemButton>}
        </NavLink>
      </List>
    </Collapse>

    {/*SUB CATEGORY */}
    {Checkpermissions("permissionsCatName", "Sub Category") &&
      <ListItemButton onClick={() => setOpenSubCategory(!openSubCategory)}>
        <ListItemIcon>
          <CategoryIcon />
        </ListItemIcon>
        <ListItemText primary="Sub Category" />
        {openSubCategory ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>}
    <Collapse in={openSubCategory} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/inventory/subCategory/subCategoryAdd" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Sub Category Add") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText primary="Add Sub Category" />
            </ListItemButton>}
        </NavLink>
        <NavLink to="/inventory/subCategory/subCategoryList" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Sub Category List") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary="Sub Category List" />
            </ListItemButton>}
        </NavLink>
      </List>
    </Collapse>

    {/* BRAND */}
    {Checkpermissions("permissionsCatName", "Brand") &&
      <ListItemButton onClick={() => setOpenBrand(!openBrand)}>
        <ListItemIcon>
          <LabelIcon />
        </ListItemIcon>
        <ListItemText primary="Brand" />
        {openBrand ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>}
    <Collapse in={openBrand} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/inventory/brand/addBrand" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Brand Add") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText primary="New Brand" />
            </ListItemButton>}
        </NavLink>
        <NavLink to="/inventory/brand/brandList" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Brand List") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary="Brand List" />
            </ListItemButton>}
        </NavLink>
      </List>
    </Collapse>

    {/* PRODUCT */}
    {Checkpermissions("permissionsCatName", "Product") &&
      <ListItemButton onClick={() => setOpenProduct(!openProduct)}>
        <ListItemIcon>
          <InventoryIcon />
        </ListItemIcon>
        <ListItemText primary="Product" />
        {openProduct ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>}
    <Collapse in={openProduct} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/inventory/Product/addProduct" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Product Add") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText primary="New Product" />
            </ListItemButton>}
        </NavLink>
        <NavLink to="/inventory/Product/listProduct" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Product List") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary="Product List" />
            </ListItemButton>}
        </NavLink>
        {/* <NavLink to="/inventory/Product/storeProductList">
          {Checkpermissions("permissionsSubCatName", "Store Product List") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary="Store Product List" />
            </ListItemButton>}
        </NavLink> */}
        {/* <NavLink to="/inventory/product/ProductBalance">
          {Checkpermissions("permissionsSubCatName", "Store Product Difference Report") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary="Product Difference Report" />
            </ListItemButton>}
        </NavLink> */}
        <NavLink to="/inventory/product/uploadProduct" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Product Upload") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <UploadFileIcon />
              </ListItemIcon>
              <ListItemText primary="Upload Product" />
            </ListItemButton>
          }
        </NavLink>
      </List>
    </Collapse>

    {/* Recipe */}
    {Checkpermissions("permissionsCatName", "Recipe") &&
      <ListItemButton onClick={() => setOpenRecipe(!openRecipe)}>
        <ListItemIcon>
          <RamenDiningIcon />
        </ListItemIcon>
        <ListItemText primary="Recipe" />
        {openRecipe ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>}
    <Collapse in={openRecipe} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/inventory/Recipe/addRecipe" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Recipe Add") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText primary="New Recipe" />
            </ListItemButton>}
        </NavLink>
        {/* "/inventory/catagory/listcatagory" */}
        <NavLink to="/inventory/Recipe/listRecipe" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Recipe List") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <LineWeightIcon />
              </ListItemIcon>
              <ListItemText primary="Recipe List" />
            </ListItemButton>}
        </NavLink>
      </List>
    </Collapse>

    {/* PURCHASE ORDER */}
    {Checkpermissions("permissionsCatName", "Purchase Order") &&
      <ListItemButton onClick={() => setOpenPurchase(!openPurchase)}>
        <ListItemIcon>
          <ShoppingBasketIcon />
        </ListItemIcon>
        <ListItemText primary="Purchase Order" />
        {openPurchase ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
    }
    <Collapse in={openPurchase} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/inventory/purchase/PurchaseOrderAdd" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Purchase Order Add") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText primary="New Purchase Order" />
            </ListItemButton>
          }
        </NavLink>
        <NavLink to="/inventory/purchase/PurchaseOrderList" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Purchase Order List") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary="Purchase Order List" />
            </ListItemButton>
          }
        </NavLink>
      </List>
    </Collapse>

    {/* IGP */}
    {Checkpermissions("permissionsCatName", "IGP") &&
      <ListItemButton onClick={() => setIgp(!igp)}>
        <ListItemIcon>
          <TransferWithinAStationIcon />
        </ListItemIcon>
        <ListItemText primary="IGP" />
        {igp ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
    }
    <Collapse in={igp} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/inventory/igp/IgpAdd" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "IGP Add") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText primary="ADD IGP" />
            </ListItemButton>
          }
        </NavLink>
        <NavLink to="/inventory/igp/IgpList" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "IGP List") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary="IGP List" />
            </ListItemButton>
          }
        </NavLink>
      </List>
    </Collapse>

    {/* DEMAND */}
    {Checkpermissions("permissionsCatName", "Demand") &&
      <ListItemButton onClick={() => setOpenDemand(!openDemand)}>
        <ListItemIcon>
          <VolunteerActivismIcon />
        </ListItemIcon>
        <ListItemText primary="Demand" />
        {openDemand ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>}
    <Collapse in={openDemand} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/inventory/demand/DemandAdd" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Demand Add") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText primary="New Demand" />
            </ListItemButton>
          }
        </NavLink>
        <NavLink to="/inventory/demand/DemandList" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Demand List") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary="Demand List" />
            </ListItemButton>
          }
        </NavLink>
      </List>
    </Collapse>

    {/* ISSUANCE */}
    {Checkpermissions("permissionsCatName", "Issuance") &&
      <ListItemButton onClick={() => setOpenissuance(!openIssuance)}>
        <ListItemIcon>
          <ArrowCircleUpIcon />
        </ListItemIcon>
        <ListItemText primary="Issuance" />
        {openIssuance ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>}
    <Collapse in={openIssuance} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/inventory/issuance/addIssuance" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Issuance Add") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText primary="New Issuance" />
            </ListItemButton>
          }
        </NavLink>
        <NavLink to="/inventory/Issuance/IssuanceDetail" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Issuance List") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary="Issuance List" />
            </ListItemButton>
          }
        </NavLink>
        <NavLink to="/inventory/Issuance/IssuanceById" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Issuance Report With Id") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary="Issuance Report By Id" />
            </ListItemButton>
          }
        </NavLink>
        <NavLink to="/inventory/Issuance/IssuanceWithDate" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Issuance Report With Dates") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <PictureAsPdfIcon />
              </ListItemIcon>
              <ListItemText primary="Issuance Report By Date" />
            </ListItemButton>
          }
        </NavLink>
      </List>
    </Collapse>

    {/* RECEVING */}
    {Checkpermissions("permissionsCatName", "Receiving") &&
      <ListItemButton onClick={() => setOpenReciving(!openReciving)}>
        <ListItemIcon>
          <ArrowCircleDownIcon />
        </ListItemIcon>
        <ListItemText primary="Receiving" />
        {openReciving ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>}
    <Collapse in={openReciving} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/inventory/Product/addReciving" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Receiving Add") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText primary="New Receiving" />
            </ListItemButton>}
        </NavLink>
        <NavLink to="/inventory/Product/listReciving" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Receiving List") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary="Receiving List" />
            </ListItemButton>}
        </NavLink>
        <NavLink to="/inventory/Product/RecivingReport" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Receiving Report With Id") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary="Receiving Report By Id" />
            </ListItemButton>}
        </NavLink>
        <NavLink to="/inventory/Product/RecivingReportDate" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Receiving Report With Dates") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <PictureAsPdfIcon />
              </ListItemIcon>
              <ListItemText primary="Receiving Report By Date" />
            </ListItemButton>}
        </NavLink>
      </List>
    </Collapse>

    {/* INVENTORY LEDGER */}
    {Checkpermissions("permissionsCatName", "Inventory Ledger") &&
      <NavLink to="/inventory/ledger" style={{ textDecoration: 'none', color: 'black' }}>
        <ListItemButton>
          <ListItemIcon>
            <SummarizeIcon />
          </ListItemIcon>
          <ListItemText primary="Inventory Ledger" />
        </ListItemButton>
      </NavLink>}

  </List>

  // { MASTERCONTROL LIST }
  let masterControlList = <List>
    {/* Flavour */}
    {Checkpermissions("permissionsCatName", "Colour") &&
      <ListItemButton onClick={() => setOpenFlavour(!openFlavour)}>
        <ListItemIcon>
          <LocalPizzaIcon />
        </ListItemIcon>
        <ListItemText primary="Colour" />
        {openFlavour ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>}
    <Collapse in={openFlavour} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/inventory/Colour/addColour" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Colour Add") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText primary="New Colour" />
            </ListItemButton>}
        </NavLink>
        <NavLink to="/inventory/Colour/listColour" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Colour List") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <LineWeightIcon />
              </ListItemIcon>
              <ListItemText primary="Colour List" />
            </ListItemButton>}
        </NavLink>
      </List>
    </Collapse>

    {/* Size */}
    {Checkpermissions("permissionsCatName", "Size") &&
      <ListItemButton onClick={() => setOpenSize(!openSize)}>
        <ListItemIcon>
          <StraightenIcon />
        </ListItemIcon>
        <ListItemText primary="Size" />
        {openSize ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>}
    <Collapse in={openSize} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/inventory/Size/addSize" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Size Add") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText primary="New Size" />
            </ListItemButton>}
        </NavLink>
        <NavLink to="/inventory/Size/listSize" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Size List") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <LineWeightIcon />
              </ListItemIcon>
              <ListItemText primary="Size List" />
            </ListItemButton>}
        </NavLink>
      </List>
    </Collapse>

    {/* Generate Barcode */}
    {Checkpermissions("permissionsCatName", "Barcode") &&
      <ListItemButton onClick={() => setGenerateBarCode(!generateBarcode)}>
        <ListItemIcon>
          <StraightenIcon />
        </ListItemIcon>
        <ListItemText primary="Barcode" />
        {generateBarcode ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>}
    <Collapse in={generateBarcode} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/inventory/barcode/generateBarcode" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Generate Barcode") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText primary="Generate Barcode" />
            </ListItemButton>}
        </NavLink>
      </List>
    </Collapse>

    {/* Deal */}
    {Checkpermissions("permissionsCatName", "Deal") &&
      <ListItemButton onClick={() => setOpenDeal(!openDeal)}>
        <ListItemIcon>
          <RamenDiningIcon />
        </ListItemIcon>
        <ListItemText primary="Deal" />
        {openDeal ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
    }
    <Collapse in={openDeal} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/inventory/deal/addDeal" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Deal Add") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText primary="New Deal" />
            </ListItemButton>
          }
        </NavLink>
        {/* "/inventory/catagory/listcatagory" */}
        <NavLink to="/inventory/deal/dealList" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Deal List") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <LineWeightIcon />
              </ListItemIcon>
              <ListItemText primary="Deal List" />
            </ListItemButton>
          }
        </NavLink>
      </List>
    </Collapse>

    {/* Deal */}
    {Checkpermissions("permissionsCatName", "Expense Type") &&
      <ListItemButton onClick={() => setOpenExpenseType(!openExpenseType)}>
        <ListItemIcon>
          <BarChartIcon />
        </ListItemIcon>
        <ListItemText primary="Expense Type" />
        {openExpenseType ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
    }
    <Collapse in={openExpenseType} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/inventory/expense/expenseType" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Expense Type Add") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText primary="Expense Type Add" />
            </ListItemButton>
          }
        </NavLink>
        <NavLink to="/inventory/expense/expenseTypeList" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Expense Type List") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <LineWeightIcon />
              </ListItemIcon>
              <ListItemText primary="Expense Type List" />
            </ListItemButton>
          }
        </NavLink>
      </List>
    </Collapse>


    {/* Hall */}
    {/* {Checkpermissions("permissionsCatName", "Hall") &&
      <ListItemButton onClick={() => setOpenhall(!openhall)}>
        <ListItemIcon>
          <AddBusinessIcon />
        </ListItemIcon>
        <ListItemText primary="Hall" />
        {openhall ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>}
    <Collapse in={openhall} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/inventory/Hall/addHall">
          {Checkpermissions("permissionsSubCatName", "Hall Add") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText primary="New Hall" />
            </ListItemButton>}
        </NavLink>
        <NavLink to="/inventory/Hall/listHall">
          {Checkpermissions("permissionsSubCatName", "Hall List") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <LineWeightIcon />
              </ListItemIcon>
              <ListItemText primary="Hall List" />
            </ListItemButton>}
        </NavLink>
      </List>
    </Collapse> */}

    {/* Table */}
    {/* {Checkpermissions("permissionsCatName", "Table") &&
      <ListItemButton onClick={() => setOpenTable(!openTable)}>
        <ListItemIcon>
          <TableRestaurantIcon />
        </ListItemIcon>
        <ListItemText primary="Rack" />
        {openTable ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>}
    <Collapse in={openTable} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/inventory/Table/addTable">
          {Checkpermissions("permissionsSubCatName", "Table Add") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText primary="New Rack" />
            </ListItemButton>}
        </NavLink>
        <NavLink to="/inventory/Table/uploadTable">
          {Checkpermissions("permissionsSubCatName", "Table Upload") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText primary="Upload Rack" />
            </ListItemButton>}
        </NavLink>
        <NavLink to="/inventory/Table/listTable">
          {Checkpermissions("permissionsSubCatName", "Table List") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <LineWeightIcon />
              </ListItemIcon>
              <ListItemText primary="Rack List" />
            </ListItemButton>}
        </NavLink>
      </List>
    </Collapse> */}

    {/*Coupon*/}
    {Checkpermissions("permissionsCatName", "Coupon") &&
      <ListItemButton onClick={() => setOpenCoupon(!openCoupon)}>
        <ListItemIcon>
          <DiscountIcon />
        </ListItemIcon>
        <ListItemText primary="Coupon" />
        {openCoupon ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>}
    <Collapse in={openCoupon} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/inventory/Coupon/addCoupon" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Coupon Add") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText primary="New Coupon" />
            </ListItemButton>}
        </NavLink>
        <NavLink to="/inventory/Coupon/listCoupon" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Coupon List") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <LineWeightIcon />
              </ListItemIcon>
              <ListItemText primary="Coupon List" />
            </ListItemButton>}
        </NavLink>
      </List>
    </Collapse>

    {/* CUSTOMER */}
    {Checkpermissions("permissionsCatName", "Customer") &&
      <ListItemButton onClick={() => setOpenCustomer(!openCustomer)}>
        <ListItemIcon>
          <AccountBoxIcon />
        </ListItemIcon>
        <ListItemText primary="Customer" />
        {openCustomer ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>}
    <Collapse in={openCustomer} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/inventory/Customer/addCustomer" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Customer Add") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText primary="New Customer" />
            </ListItemButton>}
        </NavLink>
        <NavLink to="/inventory/Customer/listCustomer" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Customer List") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary="Customer List" />
            </ListItemButton>}
        </NavLink>
      </List>
    </Collapse>

    {/* VENDOR */}
    {Checkpermissions("permissionsCatName", "Vendor") &&
      <ListItemButton onClick={() => setOpenVendor(!openVendor)}>
        <ListItemIcon>
          <AccountBoxIcon />
        </ListItemIcon>
        <ListItemText primary="Vendor" />
        {openVendor ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>}
    <Collapse in={openVendor} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/inventory/Vendor/addVendor" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Vendor Add") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText primary="New Vendor" />
            </ListItemButton>}
        </NavLink>
        <NavLink to="/inventory/Vendor/listVendor" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Vendor List") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary="Vendor List" />
            </ListItemButton>}</NavLink>
      </List>
    </Collapse>

    {/* UOM */}
    {Checkpermissions("permissionsCatName", "UOM") &&
      <ListItemButton onClick={() => setOpenUom(!openUom)}>
        <ListItemIcon>
          <ScaleIcon />
        </ListItemIcon>
        <ListItemText primary="Uom" />
        {openUom ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>}
    <Collapse in={openUom} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/inventory/uom/adduom" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "UOM Add") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText primary="New Uom" />
            </ListItemButton>}
        </NavLink>
        <NavLink to="/inventory/uom/listUom" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "UOM List") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary="Uom List" />
            </ListItemButton>}
        </NavLink>
      </List>
    </Collapse>

    {/* {Checkpermissions("permissionsCatName", "UOM") &&
      <NavLink to="/inventory/uomlist" style={{ textDecoration: 'none', color: 'black' }}>
        <ListItemButton>
          <ListItemIcon>
            <AutoStoriesIcon />
          </ListItemIcon>
          <ListItemText primary="UOM List" />
        </ListItemButton>
      </NavLink>} */}



  </List>

  // { ACCOUNTS LIST }
  let accountsList = <List>
    <ListItemButton>
      <ListItemIcon>
        <ReceiptIcon />
      </ListItemIcon>
      <ListItemText primary="Invoice" />
    </ListItemButton>

    {/* level 3 front */}
    {Checkpermissions("permissionsCatName", "Level Three") &&
      <ListItemButton onClick={() => setLevel4(!level4)}>
        <ListItemIcon>
          <Filter4Icon />
        </ListItemIcon>
        <ListItemText primary="Level Three" />
        {level4 ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>}

    <Collapse in={level4} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/accounts/level4/add">
          {Checkpermissions("permissionsSubCatName", "Add Level Three") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddCircleIcon />
              </ListItemIcon>
              <ListItemText primary="Add Level Three" />
            </ListItemButton>}
        </NavLink>

        <NavLink to="/accounts/level4/details">
          {Checkpermissions("permissionsSubCatName", "Level Three Detail") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <TableChartIcon />
              </ListItemIcon>
              <ListItemText primary="Level Three Details" />
            </ListItemButton>}
        </NavLink>


        <NavLink to="/accounts/level4/upload">
          {Checkpermissions("permissionsSubCatName", "Level Three Upload") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <UploadFileIcon />
              </ListItemIcon>
              <ListItemText primary="Level Three Upload" />
            </ListItemButton>}
        </NavLink>

        <NavLink to="/accounts/level4/report">
          {Checkpermissions("permissionsSubCatName", "Level Three Report") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <PollIcon />
              </ListItemIcon>
              <ListItemText primary="Level Three Report" />
            </ListItemButton>}
        </NavLink>
      </List>
    </Collapse>
    {/* Add Account */}
    {Checkpermissions("permissionsCatName", "Level Four") &&
      <ListItemButton onClick={() => setLevel5(!level5)}>
        <ListItemIcon>
          <Filter4Icon />
        </ListItemIcon>
        <ListItemText primary="Add Account" />
        {level5 ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>}

    <Collapse in={level5} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/accounts/level5/add">
          {Checkpermissions("permissionsSubCatName", "Add Level Four") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText primary="Add Account" />
            </ListItemButton>}
        </NavLink>

        <NavLink to="/accounts/level5/details">
          {Checkpermissions("permissionsSubCatName", "Level Four Detail") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <PermMediaIcon />
              </ListItemIcon>
              <ListItemText primary="Level Four Details" />
            </ListItemButton>}
        </NavLink>


        <NavLink to="/accounts/level5/upload">
          {Checkpermissions("permissionsSubCatName", "Level Four Upload") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <UploadIcon />
              </ListItemIcon>
              <ListItemText primary="Level Four Upload" />
            </ListItemButton>}
        </NavLink>

        <NavLink to="/accounts/level5/report">
          {Checkpermissions("permissionsSubCatName", "Level Four Report") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <DataThresholdingIcon />
              </ListItemIcon>
              <ListItemText primary="Level Four Report" />
            </ListItemButton>}
        </NavLink>

      </List>
    </Collapse>
    {/* voucher */}
    {Checkpermissions("permissionsCatName", "Voucher") &&
      <ListItemButton onClick={() => setOpenVoucher(!openVoucher)}>
        <ListItemIcon>
          <ReceiptIcon />
        </ListItemIcon>
        <ListItemText primary="Voucher" />
        {openVoucher ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>}

    <Collapse in={openVoucher} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/accounts/vouchers/cashpayment">
          {Checkpermissions("permissionsSubCatName", "CPV") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <PaymentsIcon />
              </ListItemIcon>
              <ListItemText primary="Cash Payment Voucher" />
            </ListItemButton>}
        </NavLink>

        <NavLink to="/accounts/vouchers/cashreceipt">
          {Checkpermissions("permissionsSubCatName", "CRV") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <RequestQuoteIcon />
              </ListItemIcon>
              <ListItemText primary="Cash Receipt Voucher" />
            </ListItemButton>}
        </NavLink>


        <NavLink to="/accounts/vouchers/bankpayment">
          {Checkpermissions("permissionsSubCatName", "BPV") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <PaymentIcon />
              </ListItemIcon>
              <ListItemText primary="Bank Payment Voucher" />
            </ListItemButton>}
        </NavLink>

        <NavLink to="/accounts/vouchers/bankreceipt">
          {Checkpermissions("permissionsSubCatName", "BRV") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <ReceiptLongIcon />
              </ListItemIcon>
              <ListItemText primary="Bank Receipt Voucher" />
            </ListItemButton>}
        </NavLink>

        <NavLink to="/accounts/vouchers/journal">
          {Checkpermissions("permissionsSubCatName", "JV") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <StickyNote2Icon />
              </ListItemIcon>
              <ListItemText primary="Journal Voucher" />
            </ListItemButton>}
        </NavLink>
      </List>
    </Collapse>
    {/* voucher verification */}
    {Checkpermissions("permissionsCatName", "Voucher Verification") &&
      <NavLink to="/accounts/voucherverification" style={{ textDecoration: 'none', color: 'black' }}>
        <ListItemButton>
          <ListItemIcon>
            <DomainVerificationIcon />
          </ListItemIcon>
          <ListItemText primary="Voucher Verification" />
        </ListItemButton>
      </NavLink>}
    {/* voucher search */}
    {Checkpermissions("permissionsCatName", "Voucher Search") &&
      <NavLink to="/accounts/vouchersearch" style={{ textDecoration: 'none', color: 'black' }} >
        <ListItemButton>
          <ListItemIcon>
            <SearchIcon />
          </ListItemIcon>
          <ListItemText primary="Voucher Search" />
        </ListItemButton>
      </NavLink>}
    {/* account Ledger */}
    {Checkpermissions("permissionsCatName", "Accounts Ledger") &&
      <NavLink to="/accounts/ledger" style={{ textDecoration: 'none', color: 'black' }}>
        <ListItemButton>
          <ListItemIcon>
            <AutoStoriesIcon />
          </ListItemIcon>
          <ListItemText primary="Ledger" />
        </ListItemButton>
      </NavLink>}
    {/* trial balance */}
    {Checkpermissions("permissionsCatName", "Trail Balance Report") &&
      <NavLink to="/accounts/trailbalance" style={{ textDecoration: 'none', color: 'black' }}>
        <ListItemButton>
          <ListItemIcon>
            <AccountBalanceWalletIcon />
          </ListItemIcon>
          <ListItemText primary="Trail Balance" />
        </ListItemButton>
      </NavLink>}
    {Checkpermissions("permissionsCatName", "Trail Balance Report") &&
      <NavLink to="/accounts/chartofAccount" style={{ textDecoration: 'none', color: 'black' }}>
        <ListItemButton>
          <ListItemIcon>
            <AccountBalanceWalletIcon />
          </ListItemIcon>
          <ListItemText primary="Chart Of Account" />
        </ListItemButton>
      </NavLink>}

    {Checkpermissions("permissionsCatName", "Aging Report") &&
      <NavLink to="/accounts/addaging" style={{ textDecoration: 'none', color: 'black' }}>
        <ListItemButton>
          <ListItemIcon>
            <AutoStoriesIcon />
          </ListItemIcon>
          <ListItemText primary="Aging Report" />
        </ListItemButton>
      </NavLink>}
    {Checkpermissions("permissionsCatName", "Cash Book Report") &&
      <NavLink to="/accounts/cash-book" style={{ textDecoration: 'none', color: 'black' }}>
        <ListItemButton>
          <ListItemIcon>
            <AutoStoriesIcon />
          </ListItemIcon>
          <ListItemText primary="Cash Book" />
        </ListItemButton>
      </NavLink>}
    {Checkpermissions("permissionsCatName", "Profit Loss Report") &&
      <NavLink to="/accounts/profit-loss" style={{ textDecoration: 'none', color: 'black' }}>
        <ListItemButton>
          <ListItemIcon>
            <AutoStoriesIcon />
          </ListItemIcon>
          <ListItemText primary="Profit Loss Statement" />
        </ListItemButton>
      </NavLink>}
    {Checkpermissions("permissionsCatName", "Transaction Summary") &&
      <NavLink to="/accounts/transaction-summary" style={{ textDecoration: 'none', color: 'black' }}>
        <ListItemButton>
          <ListItemIcon>
            <AutoStoriesIcon />
          </ListItemIcon>
          <ListItemText primary="Transaction Summary" />
        </ListItemButton>
      </NavLink>}
  </List>

  // { SETTING LIST }
  let settingList = <List>
    {/* COMPANY */}
    {Checkpermissions("permissionsCatName", "Company") &&
      <ListItemButton onClick={() => setOpenRestaurant(!openRestaurant)}>
        <ListItemIcon>
          <BusinessIcon />
        </ListItemIcon>
        <ListItemText primary="Open New Company" />
        {openRestaurant ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>}
    <Collapse in={openRestaurant} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/setting/Company/addCompany" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Company Add") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText primary="Add Company Details" />
            </ListItemButton>}
        </NavLink>
        <NavLink to="/setting/Company/listCompany" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Company List") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary="Company List" />
            </ListItemButton>}
        </NavLink>
        {/* <NavLink to = "/setting/Company/editCompany"> 
   
       <ListItemButton sx={{ pl: 4 }}>
         <ListItemIcon>
           <AddBoxIcon />
         </ListItemIcon>
         <ListItemText primary="Edit Company" />
       </ListItemButton>
      
       </NavLink> */}
      </List>
    </Collapse>
    {/* Master Control */}
    {Checkpermissions("permissionsCatName", "Accounts Integration") &&
      <ListItemButton onClick={() => setOpenMasterControl(!openMasterControl)}>
        <ListItemIcon>
          <AssessmentIcon />
        </ListItemIcon>
        <ListItemText primary="Accounts Integration" />
        {openMasterControl ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>}
    <Collapse in={openMasterControl} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/setting/MasterControl/addMasterControl" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Accounts Integration Add") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText primary="New Master Control" />
            </ListItemButton>}
        </NavLink>
        <NavLink to="/setting/MasterControl/listMasterControl" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Accounts Integration List") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary="MasterControl List" />
            </ListItemButton>}
        </NavLink>
      </List>
    </Collapse>
    {/* user */}
    {Checkpermissions("permissionsCatName", "User") &&
      <ListItemButton onClick={() => setOpenUser(!openUser)}>
        <ListItemIcon>
          <PersonAddAlt1Icon />
        </ListItemIcon>
        <ListItemText primary="User" />
        {openUser ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>}
    {/* } */}

    <Collapse in={openUser} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>

        <NavLink to="/setting/addUser" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "User Add") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText primary="New User" />
            </ListItemButton>
          }
        </NavLink>
        {/* "/inventory/catagory/listcatagory" */}
        {Checkpermissions("permissionsSubCatName", "User List") &&
          <NavLink to="/setting/listUser" style={{ textDecoration: 'none' }}>
            {/* {Checkpermissions("permissionsSubCatName","User List")&& */}
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary="User List" />
            </ListItemButton>
            {/* } */}
          </NavLink>}
      </List>
    </Collapse>
    {/* day ope */}
    {Checkpermissions("permissionsCatName", "Day Open") &&
      <NavLink to="/setting/opening" style={{ textDecoration: 'none', color: 'black' }}>
        <ListItemButton>
          <ListItemIcon>
            <MeetingRoomIcon />
          </ListItemIcon>
          <ListItemText primary="Day Open" />
        </ListItemButton>
      </NavLink>}
    {/* day close */}
    {Checkpermissions("permissionsCatName", "Day Close") &&
      <NavLink to="/setting/closing" style={{ textDecoration: 'none', color: 'black' }}>
        <ListItemButton>
          <ListItemIcon>
            <DoorSlidingIcon />
          </ListItemIcon>
          <ListItemText primary="Day close" />
        </ListItemButton>
      </NavLink>}
    {/* day report */}
    {Checkpermissions("permissionsCatName", "DayOpen Record") &&
      <NavLink to="/setting/dayOpen_record" style={{ textDecoration: 'none', color: 'black' }}>
        <ListItemButton>
          <ListItemIcon>
            <ListAltIcon />
          </ListItemIcon>
          <ListItemText primary="Day Open Record" />
        </ListItemButton>
      </NavLink>}
    {/* permissio */}
    {Checkpermissions("permissionsCatName", "Permission") &&
      <NavLink to="/setting/permission" style={{ textDecoration: 'none', color: 'black' }}>
        <ListItemButton>
          <ListItemIcon>
            <VerifiedUserIcon />
          </ListItemIcon>
          <ListItemText primary="Permissions" />
        </ListItemButton>
      </NavLink>
    }
    {Checkpermissions("permissionsCatName", "BackUp") &&
      <NavLink to="/setting/BackUp" style={{ textDecoration: 'none', color: 'black' }}>
        <ListItemButton>
          <ListItemIcon>
            <BackupIcon />
          </ListItemIcon>
          <ListItemText primary="Backup" />
        </ListItemButton>
      </NavLink>
    }

    {Checkpermissions("permissionsCatName", "FBR Ingegration") &&
      <NavLink to="/setting/FBR" style={{ textDecoration: 'none', color: 'black' }}>
        <ListItemButton>
          <ListItemIcon>
            <AccountBalanceIcon />
          </ListItemIcon>
          <ListItemText primary="FBR Ingegration" />
        </ListItemButton>
      </NavLink>
    }

  </List>

  // { PRODUCTION LIST }
  let productionList = <List>

    {/* MASTER SETTING */}
    {Checkpermissions("permissionsCatName", "Category") &&
      <ListItemButton onClick={() => setOpenCategory(!openCategory)}>
        <ListItemIcon>
          <SettingsSuggestIcon />
        </ListItemIcon>
        <ListItemText primary="Master Setting" />
        {openCategory ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>}
    <Collapse in={openCategory} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/inventory/catagory/addcatagory" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Category Add") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText primary="New Category" />
            </ListItemButton>}
        </NavLink>
        <NavLink to="/inventory/catagory/listcatagory" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Category List") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary="Category List" />
            </ListItemButton>}
        </NavLink>
        <NavLink to="/inventory/subCategory/subCategoryAdd" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Sub Category Add") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText primary="Add Sub Category" />
            </ListItemButton>}
        </NavLink>
        <NavLink to="/inventory/subCategory/subCategoryList" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Sub Category List") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary="Sub Category List" />
            </ListItemButton>}
        </NavLink>
        <NavLink to="/inventory/Size/addSize" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Size Add") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText primary="New Size" />
            </ListItemButton>}
        </NavLink>
        <NavLink to="/inventory/Size/listSize" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Size List") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary="Sizes List" />
            </ListItemButton>}
        </NavLink>
        <NavLink to="/inventory/uom/adduom" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "UOM Add") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText primary="New Uom" />
            </ListItemButton>}
        </NavLink>
        <NavLink to="/inventory/uom/listUom" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "UOM List") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary="Uom's List" />
            </ListItemButton>}
        </NavLink>
        <NavLink to="/inventory/Colour/addColour" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Colour Add") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText primary="New Colour" />
            </ListItemButton>}
        </NavLink>
        <NavLink to="/inventory/Colour/listColour" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Colour List") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary="Colours List" />
            </ListItemButton>}
        </NavLink>
        <NavLink to="/inventory/Customer/addCustomer" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Customer Add") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText primary="New Customer" />
            </ListItemButton>}
        </NavLink>
        <NavLink to="/inventory/Customer/listCustomer" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Customer List") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary="Customers List" />
            </ListItemButton>}
        </NavLink>
        <NavLink to="/inventory/brand/addBrand" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Brand Add") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText primary="New Brand" />
            </ListItemButton>}
        </NavLink>
        <NavLink to="/inventory/brand/brandList" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Brand List") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary="Brands List" />
            </ListItemButton>}
        </NavLink>
        <NavLink to="/inventory/Vendor/addVendor" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Vendor Add") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText primary="New Vendor" />
            </ListItemButton>}
        </NavLink>
        <NavLink to="/inventory/Vendor/listVendor" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Vendor List") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary="Vendors List" />
            </ListItemButton>}
        </NavLink>
        <NavLink to="/production/Employee/AddProductionEmployee" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Vendor Add") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText primary="New Employee" />
            </ListItemButton>}
        </NavLink>
        <NavLink to="/production/Employee/ViewProductionEmployee" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Vendor List") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary="Employees List" />
            </ListItemButton>}
        </NavLink>
        <NavLink to="/production/process/AddProcess" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Vendor Add") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText primary="New Process" />
            </ListItemButton>}
        </NavLink>
        <NavLink to="/production/process/ViewProcess" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Vendor List") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary="Process List" />
            </ListItemButton>}
        </NavLink>
        <NavLink to="/inventory/Hall/addHall" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Hall Add") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText primary="New Hall" />
            </ListItemButton>}
        </NavLink>
        <NavLink to="/inventory/Hall/listHall" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Hall List") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary="Halls List" />
            </ListItemButton>}
        </NavLink>
        <NavLink to="/production/Machine/AddMachine" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Hall Add") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText primary="New Machine" />
            </ListItemButton>}
        </NavLink>
        <NavLink to="/production/Machine/ViewMachine" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Hall List") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary="Machines List" />
            </ListItemButton>}
        </NavLink>
      </List>
    </Collapse>

    {/* ARTICLE */}
    {Checkpermissions("permissionsCatName", "Vendor") &&
      <ListItemButton onClick={() => setArticle(!article)}>
        <ListItemIcon>
          <ArticleIcon />
        </ListItemIcon>
        <ListItemText primary="Article" />
        {article ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>}
    <Collapse in={article} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/production/Article/AddArticle" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Vendor Add") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText primary="New Article" />
            </ListItemButton>}
        </NavLink>
        {/* <NavLink to="/production/Article/AddArticleVariation" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Vendor Add") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText primary="Add Article Variation" />
            </ListItemButton>}
        </NavLink> */}
        <NavLink to="/production/Article/AddArticleRecipe" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Vendor Add") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <AddBoxIcon />
              </ListItemIcon>
              <ListItemText primary="Add Article Recipe" />
            </ListItemButton>}
        </NavLink>
        <NavLink to="/production/Article/ViewArticle" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Vendor List") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary="Articles List" />
            </ListItemButton>}
        </NavLink>
        <NavLink to="/production/Article/ArticleFilter" style={{ textDecoration: 'none' }}>
          {Checkpermissions("permissionsSubCatName", "Vendor List") &&
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemIcon>
                <ListAltIcon />
              </ListItemIcon>
              <ListItemText primary="Articles Filter" />
            </ListItemButton>}
        </NavLink>
      </List>
    </Collapse>

    {/* ORDER CREATION */}
    <ListItemButton onClick={() => setOrderCreation(!orderCreation)}>
      <ListItemIcon>
        <BusinessIcon />
      </ListItemIcon>
      <ListItemText primary="Orders" />
      {orderCreation ? <ExpandLess /> : <ExpandMore />}
    </ListItemButton>
    <Collapse in={orderCreation} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/production/OrderCreation/AddOrderCreation" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <AddBoxIcon />
            </ListItemIcon>
            <ListItemText primary="New Order" />
          </ListItemButton>
        </NavLink>
        <NavLink to="/production/OrderCreation/ListOrderCreation" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <ListAltIcon />
            </ListItemIcon>
            <ListItemText primary="Orders List" />
          </ListItemButton>
        </NavLink>
      </List>
    </Collapse>

    {/* COST CONTROL */}
    <ListItemButton onClick={() => setCostControl(!costControl)}>
      <ListItemIcon>
        <RequestQuoteIcon />
      </ListItemIcon>
      <ListItemText primary="Order Planing" />
      {costControl ? <ExpandLess /> : <ExpandMore />}
    </ListItemButton>
    <Collapse in={costControl} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/production/CostControl/MaterialRequired" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <AddBoxIcon />
            </ListItemIcon>
            <ListItemText primary="Material Requirment" />
          </ListItemButton>
        </NavLink>
        <NavLink to="/production/CostControl/ProcessRequired" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <AddBoxIcon />
            </ListItemIcon>
            <ListItemText primary="Labour Cost" />
          </ListItemButton>
        </NavLink>
        {/* <NavLink to="/production/CostControl/AdminCostRequired" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <AddBoxIcon />
            </ListItemIcon>
            <ListItemText primary="Over Heads Cost" />
          </ListItemButton>
        </NavLink> */}
      </List>
    </Collapse>

    {/* PRODUCTION ISSUANCE */}
    <ListItemButton onClick={() => setProductionIssuance(!productionIssuance)}>
      <ListItemIcon>
        <SendIcon />
      </ListItemIcon>
      <ListItemText primary="Issuance" />
      {productionIssuance ? <ExpandLess /> : <ExpandMore />}
    </ListItemButton>
    <Collapse in={productionIssuance} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/production/ProductionIssuance/AddProductionIssuance" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <AddBoxIcon />
            </ListItemIcon>
            <ListItemText primary="Add Issuance" />
          </ListItemButton>
        </NavLink>
        <NavLink to="/production/ProductionIssuance/ViewProductionIssuance" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <ListAltIcon />
            </ListItemIcon>
            <ListItemText primary="Issuance List" />
          </ListItemButton>
        </NavLink>
      </List>
    </Collapse>

    {/* PRODUCTION RECEIVING */}
    <ListItemButton onClick={() => setProductionReceiving(!productionReceiving)}>
      <ListItemIcon>
        <AssignmentReturnedIcon />
      </ListItemIcon>
      <ListItemText primary="Receiving" />
      {productionReceiving ? <ExpandLess /> : <ExpandMore />}
    </ListItemButton>
    <Collapse in={productionReceiving} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/production/ProductionReceiving/AddProductionReceiving" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <AddBoxIcon />
            </ListItemIcon>
            <ListItemText primary="New Receiving" />
          </ListItemButton>
        </NavLink>
        <NavLink to="/production/ProductionReceiving/ViewProductionReceiving" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <ListAltIcon />
            </ListItemIcon>
            <ListItemText primary="Receiving List" />
          </ListItemButton>
        </NavLink>
      </List>
    </Collapse>





  </List>

  // { HR LIST }
  let HRList = <List>
    {/* DIVISION  */}
    <ListItemButton onClick={() => setDivision(!division)}>
      <ListItemIcon>
        <AlignVerticalBottomIcon />
      </ListItemIcon>
      <ListItemText primary="Division" />
      {division ? <ExpandLess /> : <ExpandMore />}
    </ListItemButton>
    <Collapse in={division} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/hr/division/AddDivision" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <AddBoxIcon />
            </ListItemIcon>
            <ListItemText primary="Add Division" />
          </ListItemButton>
        </NavLink>
        <NavLink to="/hr/division/ViewDivision" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <ListAltIcon />
            </ListItemIcon>
            <ListItemText primary="Division List" />
          </ListItemButton>
        </NavLink>
      </List>
    </Collapse>

    {/* DEPARTMENT  */}
    <ListItemButton onClick={() => setDepartment(!department)}>
      <ListItemIcon>
        <ApartmentIcon />
      </ListItemIcon>
      <ListItemText primary="Department" />
      {department ? <ExpandLess /> : <ExpandMore />}
    </ListItemButton>
    <Collapse in={department} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/hr/department/AddDepartment" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <AddBoxIcon />
            </ListItemIcon>
            <ListItemText primary="Add Department" />
          </ListItemButton>
        </NavLink>
        <NavLink to="/hr/department/ViewDepartment" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <ListAltIcon />
            </ListItemIcon>
            <ListItemText primary="Department List" />
          </ListItemButton>
        </NavLink>
      </List>
    </Collapse>

    {/* DESIGNATION  */}
    <ListItemButton onClick={() => setDesignation(!designation)}>
      <ListItemIcon>
        <CompareArrowsIcon />
      </ListItemIcon>
      <ListItemText primary="Designation" />
      {designation ? <ExpandLess /> : <ExpandMore />}
    </ListItemButton>
    <Collapse in={designation} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/hr/designation/AddDesignation" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <AddBoxIcon />
            </ListItemIcon>
            <ListItemText primary="Add Designation" />
          </ListItemButton>
        </NavLink>
        <NavLink to="/hr/designation/ViewDesignation" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <ListAltIcon />
            </ListItemIcon>
            <ListItemText primary="Designation List" />
          </ListItemButton>
        </NavLink>
      </List>
    </Collapse>

    {/* SHIFT-CONTROL */}
    <ListItemButton onClick={() => setShiftControl(!shiftControl)}>
      <ListItemIcon>
        <RepartitionIcon />
      </ListItemIcon>
      <ListItemText primary="ShiftControl" />
      {shiftControl ? <ExpandLess /> : <ExpandMore />}
    </ListItemButton>
    <Collapse in={shiftControl} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/hr/shiftControl/AddShiftControl" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <AddBoxIcon />
            </ListItemIcon>
            <ListItemText primary="Add ShiftControl" />
          </ListItemButton>
        </NavLink>
        <NavLink to="/hr/shiftControl/ViewShiftControl" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <ListAltIcon />
            </ListItemIcon>
            <ListItemText primary="ShiftControl List" />
          </ListItemButton>
        </NavLink>
      </List>
    </Collapse>

    {/* EMPLOYEE-TYPE */}
    <ListItemButton onClick={() => setEmployeeType(!employeeType)}>
      <ListItemIcon>
        <BadgeIcon />
      </ListItemIcon>
      <ListItemText primary="EmployeeType" />
      {employeeType ? <ExpandLess /> : <ExpandMore />}
    </ListItemButton>
    <Collapse in={employeeType} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/hr/employeeType/AddEmployeeType" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <AddBoxIcon />
            </ListItemIcon>
            <ListItemText primary="Add EmployeeType" />
          </ListItemButton>
        </NavLink>
        <NavLink to="/hr/employeeType/ViewEmployeeType" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <ListAltIcon />
            </ListItemIcon>
            <ListItemText primary="EmployeeType List" />
          </ListItemButton>
        </NavLink>
      </List>
    </Collapse>

    {/* EMPLOYEE */}
    <ListItemButton onClick={() => setEmployee(!employee)}>
      <ListItemIcon>
        <PersonAddAltIcon />
      </ListItemIcon>
      <ListItemText primary="Employee" />
      {employee ? <ExpandLess /> : <ExpandMore />}
    </ListItemButton>
    <Collapse in={employee} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/hr/employee/AddEmployee" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <AddBoxIcon />
            </ListItemIcon>
            <ListItemText primary="Add Employee" />
          </ListItemButton>
        </NavLink>
        <NavLink to="/hr/employee/ViewEmployee" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <ListAltIcon />
            </ListItemIcon>
            <ListItemText primary="Employee List" />
          </ListItemButton>
        </NavLink>
      </List>
    </Collapse>

    {/* SALARY */}
    <ListItemButton onClick={() => setAddSalary(!addSalary)}>
      <ListItemIcon>
        <PaidIcon />
      </ListItemIcon>
      <ListItemText primary="Salary" />
      {addSalary ? <ExpandLess /> : <ExpandMore />}
    </ListItemButton>
    <Collapse in={addSalary} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/hr/salary/AddSalary" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <AddBoxIcon />
            </ListItemIcon>
            <ListItemText primary="Add Salary" />
          </ListItemButton>
        </NavLink>
        <NavLink to="/hr/salary/EditSalaryWithEmpID" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <ListAltIcon />
            </ListItemIcon>
            <ListItemText primary="Edit Salary" />
          </ListItemButton>
        </NavLink>
      </List>
    </Collapse>


    {/* INCREMENT */}
    <ListItemButton onClick={() => setAddIncrement(!addIncrement)}>
      <ListItemIcon>
        <ImportExportIcon />
      </ListItemIcon>
      <ListItemText primary="Increment" />
      {addIncrement ? <ExpandLess /> : <ExpandMore />}
    </ListItemButton>
    <Collapse in={addIncrement} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/hr/increment/AddIncrement" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <AddBoxIcon />
            </ListItemIcon>
            <ListItemText primary="Add Increment" />
          </ListItemButton>
        </NavLink>
        <NavLink to="/hr/increment/IncrementList" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <ListAltIcon />
            </ListItemIcon>
            <ListItemText primary="Increment List" />
          </ListItemButton>
        </NavLink>
      </List>
    </Collapse>


    {/* MASTERCONTROL HR */}
    <NavLink to="/hr/masterControl/AddMasterControlHR" style={{ textDecoration: 'none', color: 'black' }}>
      <ListItemButton>
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary="Master Control" />
      </ListItemButton>
    </NavLink>


    {/* SHORT-TERM */}
    <ListItemButton onClick={() => setSTAdvance(!STAdvance)}>
      <ListItemIcon>
        <LogoutIcon />
      </ListItemIcon>
      <ListItemText primary="Short-Term Advance" />
      {STAdvance ? <ExpandLess /> : <ExpandMore />}
    </ListItemButton>
    <Collapse in={STAdvance} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/hr/shortTerm/STPayment" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <AddBoxIcon />
            </ListItemIcon>
            <ListItemText primary="Pay Advance" />
          </ListItemButton>
        </NavLink>
        <NavLink to="/hr/shortTerm/STReciving" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <AddBoxIcon />
            </ListItemIcon>
            <ListItemText primary="Receive Advance" />
          </ListItemButton>
        </NavLink>
        <NavLink to="/hr/shortTerm/STList" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <ListAltIcon />
            </ListItemIcon>
            <ListItemText primary="Advance List" />
          </ListItemButton>
        </NavLink>
        <NavLink to="/hr/shortTerm/STLedger" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <ListAltIcon />
            </ListItemIcon>
            <ListItemText primary="Ledger" />
          </ListItemButton>
        </NavLink>
      </List>
    </Collapse>


    {/* LONG-TERM */}
    <ListItemButton onClick={() => setLTAdvance(!LTAdvance)}>
      <ListItemIcon>
        <LogoutIcon />
      </ListItemIcon>
      <ListItemText primary="Long-Term Advance" />
      {LTAdvance ? <ExpandLess /> : <ExpandMore />}
    </ListItemButton>
    <Collapse in={LTAdvance} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/hr/longTerm/LTPayment" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <AddBoxIcon />
            </ListItemIcon>
            <ListItemText primary="Pay Advance" />
          </ListItemButton>
        </NavLink>
        <NavLink to="/hr/longTerm/LTReciving" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <AddBoxIcon />
            </ListItemIcon>
            <ListItemText primary="Receive Advance" />
          </ListItemButton>
        </NavLink>
        <NavLink to="/hr/longTerm/LTList" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <ListAltIcon />
            </ListItemIcon>
            <ListItemText primary="Advance List" />
          </ListItemButton>
        </NavLink>
        <NavLink to="/hr/longTerm/LTLedger" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <ListAltIcon />
            </ListItemIcon>
            <ListItemText primary="Ledger" />
          </ListItemButton>
        </NavLink>
      </List>
    </Collapse>


    {/* FINE & PENALTIES */}
    <ListItemButton onClick={() => setFine(!fine)}>
      <ListItemIcon>
        <RemoveCircleOutlineIcon />
      </ListItemIcon>
      <ListItemText primary="Fine & Penalties" />
      {fine ? <ExpandLess /> : <ExpandMore />}
    </ListItemButton>
    <Collapse in={fine} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/hr/fine/AddFineAndPenalty" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <AddBoxIcon />
            </ListItemIcon>
            <ListItemText primary="Add Fine & Penalties" />
          </ListItemButton>
        </NavLink>
        <NavLink to="/hr/fine/FineAndPenaltyList" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <ListAltIcon />
            </ListItemIcon>
            <ListItemText primary="Fine & Penalties List" />
          </ListItemButton>
        </NavLink>
      </List>
    </Collapse>


  </List>


  // { CRM LIST }
  let CRMList = <List>

    {/* PRODUCT  */}
    <ListItemButton onClick={() => setOpenProduct(!openProduct)}>
      <ListItemIcon>
        <InventoryIcon />
      </ListItemIcon>
      <ListItemText primary="Product" />
      {openProduct ? <ExpandLess /> : <ExpandMore />}
    </ListItemButton>
    <Collapse in={openProduct} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/crm/product/AddProduct" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <AddBoxIcon />
            </ListItemIcon>
            <ListItemText primary="Add Product" />
          </ListItemButton>
        </NavLink>
        <NavLink to="/crm/product/ViewProduct" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <ListAltIcon />
            </ListItemIcon>
            <ListItemText primary="Product List" />
          </ListItemButton>
        </NavLink>
      </List>
    </Collapse>


    {/* CUSTOMER  */}
    <ListItemButton onClick={() => setOpenCustomer(!openCustomer)}>
      <ListItemIcon>
        <AccountBoxIcon />
      </ListItemIcon>
      <ListItemText primary="Customer" />
      {openCustomer ? <ExpandLess /> : <ExpandMore />}
    </ListItemButton>
    <Collapse in={openCustomer} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <NavLink to="/crm/customer/AddCustomer" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <AddBoxIcon />
            </ListItemIcon>
            <ListItemText primary="Add Customer" />
          </ListItemButton>
        </NavLink>
        <NavLink to="/crm/customer/ViewCustomer" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <ListAltIcon />
            </ListItemIcon>
            <ListItemText primary="Customer List" />
          </ListItemButton>
        </NavLink>
        <NavLink to="/crm/customer/UpdateCustomer" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <AddCommentIcon />
            </ListItemIcon>
            <ListItemText primary="Add Comment" />
          </ListItemButton>
        </NavLink>
        <NavLink to="/crm/customer/UploadCustomer" style={{ textDecoration: 'none' }}>
          <ListItemButton sx={{ pl: 4 }}>
            <ListItemIcon>
              <UploadFileIcon />
            </ListItemIcon>
            <ListItemText primary="Upload Customer" />
          </ListItemButton>
        </NavLink>
      </List>
    </Collapse>



  </List>



  if (props.moduleKey === 'UserInventory' || window.localStorage.getItem('module_key') === 'user_inventory') return masterControlList;
  else if (props.moduleKey === 'UserAccounts' || window.localStorage.getItem('module_key') === 'user_accounts') return accountsList;
  else if (props.moduleKey === 'storeList' || window.localStorage.getItem('module_key') === 'storeList') return storeList;
  else if (props.moduleKey === 'setting' || window.localStorage.getItem('module_key') === 'setting') return settingList;
  else if (props.moduleKey === 'production' || window.localStorage.getItem('module_key') === 'production') return productionList;
  else if (props.moduleKey === 'hr' || window.localStorage.getItem('module_key') === 'hr') return HRList;
  else if (props.moduleKey === 'crm' || window.localStorage.getItem('module_key') === 'crm') return CRMList;
  else return masterControlList;
}; 
