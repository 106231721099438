
import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { setHeading } from "../../../accounts/account.actions";
import { AddProductionReceivingApi } from "./ProductionReceivingAction";
import { getVendorData } from "../../AddDetails/Vendor/Vendor.action";
import { GetOrderCreationApi, GetOrderCreationWithIDApi } from "../orderCreation/OrderCreationAction";
import { GetProductWithIDApi } from "../../AddDetails/AddProduct/Product.action";
import _ from "lodash";
import Spinner from "react-bootstrap/Spinner";
import Swal from "sweetalert";
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import Select from "react-select";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';


const AddProductionReceiving = (props) => {
    const [orderId, setOrderId] = useState();
    const [orderVariationId, setOrderVariationId] = useState();
    const [orderName, setOrderName] = useState();
    const [vendorId, setVendorId] = useState();
    const [vendorName, setVendorName] = useState();
    const [orderArticleId, setOrderArticleId] = useState();
    const [orderArticleProcessId, setOrderArticleProcessId] = useState();
    const [orderArticleProcessName, setOrderArticleProcessName] = useState();
    const [orderArticleProcessRate, setOrderArticleProcessRate] = useState();
    const [issuanceDate, setReceivingDate] = useState();
    const [issuanceType, setIssuanceType] = useState();
    const [description, setDescription] = useState();
    const [articleProcesses, setArticleProcesses] = useState([]);
    const [visibleAlert, setAlertVisible] = useState(false);
    const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
    const [inputField, setInputField] = useState([{
        articleId: "",
        articleName: "",
        orderQuantity: 0,
        issueQuantity: 0,
        recevingQuantity: 0
    }]);


    function SaveProductionReceiving() {
        props.AddProductionReceivingApi({
            orderId: orderId,
            orderName: orderName,
            orderVariationId: orderVariationId,
            orderArticleId: orderArticleId,
            issuanceDate: issuanceDate,
            issuanceType: issuanceType,
            description: description,
            vendorDetails: {
                _id: vendorId,
                vendorName: vendorName,
            },
            orderDetails: {
                orderId: orderId,
                articleIssuanceDetails: inputField,
            }
        },
            handleVisible,
            handleDangerVisible
        )
        setOrderId("")
        setOrderVariationId("")
        setOrderName("")
        setVendorId("")
        setOrderArticleId("")
        setOrderArticleProcessId("")
        setReceivingDate("")
        setIssuanceType("")
        setDescription("")
        setInputField([{
            articleId: "",
            articleName: "",
            orderQuantity: 0,
            issueQuantity: 0,
            recevingQuantity: 0
        }]);
    }
    const handleVisible = (msg) => {
        setAlertVisible(true);
        setTimeout(() => {
            setAlertVisible(false);
        }, 9000);
        Swal({
            title: "Success",
            text: msg,
            icon: "success",
            button: "Ok",
        });
    };
    const handleDangerVisible = (err) => {
        setDangerVisibleAlert(true);
        setTimeout(() => {
            setDangerVisibleAlert(false);
        }, 9000);
        Swal({
            title: "Error",
            text: err,
            icon: "error",
            button: "Ok",
        });
    };

    const handleChangeVendor = (selectedOption) => {
        setVendorId(selectedOption.value);
        setVendorName(selectedOption.label);
    };
    const GetVendorList = !_.isEmpty(props.vendorDataList) && props.vendorDataList.map((x) => {
        let data = { value: x._id, label: x.vendorName };
        return data;
    });

    let processTypeData = [{
        id: "External Receiving",
        type: "External Receiving",
    },
    {
        id: "Internal Receiving",
        type: "Internal Receiving",
    }];
    const handleChangeReceivingType = (selectedOption) => {
        setIssuanceType(selectedOption.label);
    };
    const GetReceivingTypeList = processTypeData.map((x) => {
        let data = { value: x.id, label: x.type };
        return data;
    });

    const handleChangeOrder = (selectedOption) => {
        setOrderId(selectedOption.value);
        setOrderName(selectedOption.label);
        props.GetOrderCreationWithIDApi(selectedOption.value)
    };
    const GetOrderList = !_.isEmpty(props.orderCreationList) && props.orderCreationList.map((x) => {
        let data = { value: x._id, label: `${x.sequenceNumber} / ${x.orderNumber}` };
        return data;
    });

    const GetOrderWithIdList = !_.isEmpty(props.orderCreationWitIdData) && props.orderCreationWitIdData[0].articleDetails.map((x) => {
        let data = {
            value: x._id,
            label: `${x.articleName} / ${x.articleVariationName}`,
            articleId: x.articleId,
            articleName: x.articleName,
            articleVariationId: x.articleVariationId,
            articleVariationName: x.articleVariationName
        };
        return data;
    });


    const handleChange = async (e, index, type) => {
        const list = [...inputField];
        if (type === "articleId") {
            const selectedArticle = _.find(props.orderCreationWitIdData[0].articleDetails, (x) => x._id === e.value);

            list[index]["articleId"] = e.value;
            list[index]["articleName"] = e.label;
            list[index]["orderQuantity"] = selectedArticle.quantity;
            list[index]["issueQuantity"] = Number(selectedArticle.quantity) - Number(selectedArticle.remainingQuantity);
            list[index]["orderVariationId"] = selectedArticle._id;
            list[index]["receivedQuantity"] = selectedArticle.receivedQuantity;

            setInputField(list);

            // Fetch processes for the selected article
            try {
                const processes = await selectedArticle.processData;
                setArticleProcesses(processes || []);
            } catch (error) {
                console.error("Failed to fetch processes:", error);
            }
        }

        if (type === "recevingQuantity") {
            const enteredQuantity = Number(e.target.value);
            if (enteredQuantity > list[index]["issueQuantity"]) {
                Swal({
                    title: "Error",
                    text: "Issuance Quantity cannot be greater than Order Issue Quantity",
                    icon: "warning",
                    button: "Ok",
                });
                return;
            }
            list[index]["recevingQuantity"] = enteredQuantity;
        }
        setInputField(list);
    };
    const handleAddInput = () => {
        setInputField([
            ...inputField,
            { articleId: "", articleName: "", orderQuantity: 0, issueQuantity: 0, recevingQuantity: 0 },
        ]);
    };
    const handleRemoveInput = (index) => {
        const list = [...inputField];
        list.splice(index, 1);
        setInputField(list);
    };

    useEffect(() => {
        props.setHeading("Add Production Receiving");
        props.GetOrderCreationApi()
        props.getVendorData()
    }, []);

    return (
        <>
            {props.isAddingProductionReceiving || props.isFetchingOrderCreation ? (
                <div className="loader-div">
                    <Spinner
                        variant="primary"
                        animation="border"
                        size="xl"
                        role="status"
                        aria-hidden="true"
                    />
                </div>
            ) : (
                <Container fluid>
                    <div className="main">
                        <Form>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="garana">
                                        <Form.Label><b>Select Order *</b></Form.Label>
                                        <Select
                                            isDisabled={_.isEmpty(GetOrderList)}
                                            placeholder="Select Order"
                                            onChange={handleChangeOrder}
                                            options={GetOrderList}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="garana">
                                        <Form.Label><b>Select Vendor *</b></Form.Label>
                                        <Select
                                            isDisabled={_.isEmpty(GetVendorList)}
                                            placeholder="Select Vendor"
                                            onChange={handleChangeVendor}
                                            options={GetVendorList}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="garana">
                                        <Form.Label><b>Select Receiving Type *</b></Form.Label>
                                        <Select
                                            placeholder="Select Receiving Type"
                                            onChange={handleChangeReceivingType}
                                            options={GetReceivingTypeList}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label><b>Receiving Date *</b></Form.Label>
                                        <Form.Control
                                            type="date"
                                            placeholder="Receiving Date"
                                            onChange={(e) => setReceivingDate(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="garana">
                                        <Form.Label><b>Description </b></Form.Label>
                                        <Form.Control
                                            type="text"
                                            as="textarea"
                                            rows={3}
                                            placeholder="Description"
                                            onChange={(e) => setDescription(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <div hidden={_.isEmpty(orderId)}>
                                <Row>
                                    <Col>
                                        <Form.Group>
                                            {inputField.map((item, i) => {
                                                console.log(item, "iiiiiiiiiiiiiiiiiiiiiiiiii")
                                                return (
                                                    <React.Fragment key={i}>
                                                        <Row>
                                                            <Col>
                                                                <Form.Group>
                                                                    <Form.Label><b>Select Article *</b> </Form.Label>
                                                                    <Select
                                                                        style={{ zIndex: 1 }}
                                                                        value={{ label: item.articleName }}
                                                                        placeholder="Select Article & Variation"
                                                                        name="articleId"
                                                                        onChange={(e) => handleChange(e, i, "articleId")}
                                                                        options={GetOrderWithIdList}
                                                                        // isOptionDisabled={(option) => (!_.isEmpty(_.find(inputField, (x) => x.articleId === option.value)))}
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col>
                                                                <Form.Group className="mb-3">
                                                                    <Form.Label><b>Order Quantity *</b></Form.Label>
                                                                    <Form.Control
                                                                        placeholder="Order Quantity"
                                                                        name="orderQuantity"
                                                                        disabled
                                                                        value={item.orderQuantity}
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col>
                                                                <Form.Group className="mb-3">
                                                                    <Form.Label><b>Issue Quantity *</b></Form.Label>
                                                                    <Form.Control
                                                                        placeholder="Issue Quantity"
                                                                        name="issueQuantity"
                                                                        disabled
                                                                        value={item.issueQuantity}
                                                                    />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col>
                                                                <Form.Group className="mb-3">
                                                                    <Form.Label><b>Receiving Quantity *</b> </Form.Label>
                                                                    <Form.Control
                                                                        min={0}
                                                                        type="number"
                                                                        placeholder="recevingQuantity"
                                                                        value={item.recevingQuantity}
                                                                        onChange={(e) => handleChange(e, i, "recevingQuantity")}
                                                                    />
                                                                </Form.Group>
                                                            </Col>

                                                            <Col style={{ marginTop: "2.5%" }}>
                                                                <Form.Group>
                                                                    {inputField.length - 1 === i && (
                                                                        <Button
                                                                            title="Click to Add Product"
                                                                            onClick={handleAddInput}
                                                                            style={{ float: "right", backgroundColor: '#81abd3', color: 'white' }}
                                                                        >
                                                                            <AddIcon />
                                                                        </Button>
                                                                    )}

                                                                    {inputField.length !== 1 && (
                                                                        <Button
                                                                            title="Click to Remove Product"
                                                                            onClick={() => handleRemoveInput(i)}
                                                                            style={{ float: "right", backgroundColor: '#81abd3', color: 'white', marginRight: '20px' }}
                                                                        >
                                                                            <RemoveIcon />
                                                                        </Button>
                                                                    )}
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                    </React.Fragment>
                                                );
                                            })}

                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>

                            <div className="sendDiv">
                                {_.isEmpty(orderId) || _.isEmpty(vendorId) || _.isEmpty(issuanceType) || _.isEmpty(issuanceDate) || inputField.some((field) => _.isEmpty(field.articleId) || field.recevingQuantity <= 0) ? (
                                    <Button variant="contained" endIcon={<SendIcon />} size="large" disabled> SAVE </Button>
                                ) : (
                                    <Button variant="contained" endIcon={<SendIcon />} size="large" onClick={SaveProductionReceiving}> SAVE </Button>
                                )}
                            </div>

                        </Form>
                    </div>
                </Container>
            )}
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        AddProductionReceivingApi: (data, handleVisible, handleDangerVisible) => dispatch(AddProductionReceivingApi(data, handleVisible, handleDangerVisible)),
        setHeading: (data) => dispatch(setHeading(data)),
        getVendorData: () => dispatch(getVendorData()),
        GetOrderCreationApi: () => dispatch(GetOrderCreationApi()),
        GetProductWithIDApi: (id) => dispatch(GetProductWithIDApi(id)),
        GetOrderCreationWithIDApi: (orderId) => dispatch(GetOrderCreationWithIDApi(orderId)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    isAddingProductionReceiving: state.ProductionReceivingReducer.isAddingProductionReceiving,
    isFetchingOrderCreation: state.OrderCreationReducer.isFetchingOrderCreation,
    orderCreationList: state.OrderCreationReducer.orderCreationList,
    isFetchingVendor: state.VendorReducer.isFetchingVendor,
    vendorDataList: state.VendorReducer.vendorDataList,
    isFetchingProductWithID: state.ProductReducer.isFetchingProductWithID,
    productDataWithIDList: state.ProductReducer.productDataWithIDList,
    orderCreationWitIdData: state.OrderCreationReducer.orderCreationWitIdData,
});


export default connect(mapStateToProps, mapDispatchToProps)(AddProductionReceiving);