const initialState = {
    processList: [],
    isFetchingProcess: false,
    isAddingProcess: false,
};

const ProcessReducer = (state = initialState, action) => {
    switch (action.type) {
        case "REQUEST_ADD_PROCESS":
            return { ...state, isAddingProcess: true };
        case "SUCCESS_ADD_PROCESS":
            return { ...state, processList: action.payload.data, isAddingProcess: false };
        case "ERROR_ADD_PROCESS":
            return { ...state, };


        case "REQUEST_GET_PROCESS":
            return { ...state, isFetchingProcess: true };
        case "SUCCESS_GET_PROCESS":
            return { ...state, processList: action.payload, isFetchingProcess: false };
        case "ERROR_GET_PROCESS":
            return { ...state, isFetchingProcess: false };


        default:
            return state;
    }
};
export default ProcessReducer;