import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { GetEmployeeApi } from "../employee/employeeAction";
import { Col, Container, Form, Row } from "react-bootstrap";
import { setHeading } from "../../../accounts/account.actions";
import { AddIncrementApi } from "./incrementAction";
import Swal from "sweetalert";
import Spinner from "react-bootstrap/Spinner";
import _ from "lodash";
import Select from "react-select";
// import moment from "moment/moment";
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';


const AddIncrement = (props) => {
    const [employeeId, setEmployeeId] = useState(0);
    const [employeeName, setEmployeeName] = useState("");
    const [incrementDecrement, setIncrementDecrement] = useState("");
    const [value, setValue] = useState(0);
    const [date, setDate] = useState(0);
    const [percentage, setPercentage] = useState(0);
    const [thisDisables1Field, setThisDisables1Field] = useState("");
    const [visibleAlert, setAlertVisible] = useState(false);
    const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);


    const SaveIncrement = () => {
        props.AddIncrementApi({
            employeeId: employeeId,
            type: incrementDecrement,
            value: Number(value),
            date: date,
            percentage: percentage,
        },
            handleVisible,
            handleDangerVisible
        );
    };
    const handleVisible = () => {
        setAlertVisible(true);
        setEmployeeId(0);
        setEmployeeName();
        setIncrementDecrement();
        setValue(0);
        setPercentage(0);
        setThisDisables1Field("");
        setTimeout(() => {
            setAlertVisible(false);
        }, 9000);
        Swal({
            title: "Success",
            text: "Salary Modificatiion successfully...",
            icon: "success",
            button: "Ok",
        });
    };
    const handleDangerVisible = () => {
        setDangerVisibleAlert(true);
        setTimeout(() => {
            setDangerVisibleAlert(false);
        }, 9000);
        Swal({
            title: "Error",
            text: "Salary Modificatiion Failed...",
            icon: "error",
            button: "Ok",
        });
    };

    const getEmployeeList = !_.isEmpty(props.employeeList) && props.employeeList.map((x) => {
        let data = {
            value: x._id,
            label: `${x.employeeName} / ${x.fatherName} / ${x.cnic}`,
        };
        return data;
    });
    const handleChangeEmployee = (selectedOption) => {
        setEmployeeId(selectedOption.value);
        setEmployeeName(selectedOption.label);
    };

    const getincrementDecrementList = [
        { value: 1, label: "Increment" },
        { value: 0, label: "Decrement" },
    ];
    const handleChangeIncDec = (selectedOption) => {
        setIncrementDecrement(selectedOption.label);
    };
    const getTotalSalary = !_.isEmpty(employeeId) && _.find(props.employeeList, (x) => x._id === employeeId);


    useEffect(() => {
        props.GetEmployeeApi();
        props.setHeading("Salary Increment")
    }, []);

    return (
        <Container fluid>
            {props.isFetchingEmployeeData || props.isAddingIncrement ? (
                <div className="loader-div">
                    <Spinner
                        animation="border"
                        size="xl"
                        role="status"
                        aria-hidden="true"
                        variant="info"
                    />
                </div>
            ) : (
                <div>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label><b>Select Employee *</b></Form.Label>
                                <Select
                                    isDisabled={_.isEmpty(getEmployeeList)}
                                    placeholder="Select Employee"
                                    onChange={handleChangeEmployee}
                                    options={getEmployeeList}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label><b>Total Salary (Inc. Allowance) *</b></Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Total Salary"
                                    value={getTotalSalary === false ? 0 : getTotalSalary.salary.totalSalary}
                                    disabled={true}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label><b>Date *</b></Form.Label>
                                <Form.Control
                                    type="date"
                                    // defaultValue={currentDate}
                                    placeholder="Date"
                                    onChange={(e) => setDate(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label><b>Select Increment / Decrement *</b></Form.Label>
                                <Select
                                    placeholder="Select Increment / Decrement"
                                    onChange={handleChangeIncDec}
                                    options={getincrementDecrementList}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label><b>Value (In Amount) *</b></Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="Value"
                                    value={value}
                                    onChange={(e) => {
                                        setValue(e.target.value);
                                        // setThisDisables1Field("IncrementValue");
                                        // setPercentage((e.target.value / getTotalSalary === false ? 0 : getTotalSalary.salary.totalSalary) * 100);
                                    }}
                                />
                            </Form.Group>
                        </Col>
                        <Col></Col>
                        {/* <Col>
                            <Form.Group className="mb-3" controlId="garana">
                                <Form.Label><b>Percentage *</b></Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="Percentage"
                                    step=".01"
                                    value={percentage}
                                    onChange={(e) => {
                                        setPercentage(e.target.value);
                                        setThisDisables1Field("IncrementPercentage");
                                        setValue((e.target.value * totalSalary) / 100);
                                    }}
                                    disabled
                                />
                            </Form.Group>
                        </Col> */}
                    </Row>

                    <div className="sendDiv">
                        {_.isEmpty(employeeName) || _.isEmpty(incrementDecrement) || _.isEmpty(date) || _.isEmpty(value) ? (
                            <Button variant="contained" endIcon={<SendIcon />} size="large" disabled> SAVE </Button>
                        ) : (
                            <Button variant="contained" endIcon={<SendIcon />} size="large" onClick={() => { SaveIncrement(); }}> SAVE </Button>
                        )}
                    </div>

                </div>
            )}
        </Container>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        AddIncrementApi: (data, handleVisible, handleDangerVisible) => dispatch(AddIncrementApi(data, handleVisible, handleDangerVisible)),
        GetEmployeeApi: () => dispatch(GetEmployeeApi()),
        setHeading: (data) => dispatch(setHeading(data)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    isAddingIncrement: state.IncrementReducer.isAddingIncrement,
    employeeList: state.EmployeeReducer.employeeList,
    isFetchingEmployeeData: state.EmployeeReducer.isFetchingEmployeeData
});

export default connect(mapStateToProps, mapDispatchToProps)(AddIncrement);