import React, { useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { connect } from "react-redux";
import { setHeading } from "../../../accounts/account.actions";
import { GetLevelFourApi, AddMasterControlHRApi, GetSelectedMasterControlHRAccountsApi } from "./masterControlHRAction";
import _ from "lodash";
import Spinner from 'react-bootstrap/Spinner';
import Select from 'react-select';
import Swal from 'sweetalert';
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';


const MasterControlHR = (props) => {
    const [LTAccountId, setLTAccountId] = useState('');
    const [LTAccountName, setLTAccountName] = useState('');
    const [STAccountId, setSTAccountId] = useState('');
    const [STAccountName, setSTAccountName] = useState('');
    const [STAccounts, setsTAccounts] = useState({
        bankId: '',
        bankName: '',
        cashId: '',
        cashName: '',
        adjustId: '',
        adjustName: '',
    });
    const [LTAccounts, setlTAccounts] = useState({
        bankId: '',
        bankName: '',
        cashId: '',
        cashName: '',
        adjustId: '',
        adjustName: '',
    });
    const [miscAccounts, setMiscAccounts] = useState({
        sExpenseId: '',
        sExpenseName: '',
        ovetimeId: '',
        ovetimeName: '',
        allowancesId: '',
        allowancesName: '',
        deductionsId: '',
        deductionsName: '',
        salaryId: '',
        salaryName: '',
    });
    const [visibleAlert, setAlertVisible] = useState(false);
    const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);


    function saveAccounts() {
        props.AddMasterControlHRApi({
            longTermAdv: LTAccountId,
            longTermBank: LTAccounts.bankId,
            longTermCash: LTAccounts.cashId,
            longTermAdjustment: LTAccounts.adjustId,
            shortTermAdv: STAccountId,
            shortTermBank: STAccounts.bankId,
            shortTermCash: STAccounts.cashId,
            shortTermAdjustment: STAccounts.adjustId,
            salaryExpense: miscAccounts.sExpenseId,
            overtimeExpense: miscAccounts.ovetimeId,
            allowances: miscAccounts.allowancesId,
            deductions: miscAccounts.deductionsId,
            salaryPayable: miscAccounts.salaryId
        },
            handleVisible,
            handleDangerVisible,
        );
    }
    const handleVisible = (message) => {
        setAlertVisible(true)
        setTimeout(() => {
            setAlertVisible(false)
        }, 9000);
        Swal({
            title: "Success",
            text: message,
            icon: "success",
            button: "Ok",
        });
    }
    const handleDangerVisible = (error) => {
        setDangerVisibleAlert(true)
        setTimeout(() => {
            setDangerVisibleAlert(false)
        }, 9000);
        Swal({
            title: "Error",
            text: error,
            icon: "error",
            button: "Ok",
        });
    }

    const getAccountsList = !_.isEmpty(props.levelFourList) && props.levelFourList.map((x) => {
        let data = { value: x.levelFourData._id, label: x.levelFourData.levelFourName };
        return data
    })
    const handleChangeLTAccount = (selectedOption) => {
        setLTAccountId(selectedOption.value);
        setLTAccountName(selectedOption.label);
    };
    const handleChangeSTAccount = (selectedOption) => {
        setSTAccountId(selectedOption.value);
        setSTAccountName(selectedOption.label);
    };

    useEffect(() => {
        props.setHeading("Master Control HR")
        props.GetLevelFourApi();
        props.GetSelectedMasterControlHRAccountsApi();
    }, []);

    return (
        <>
            {(props.isFetchingLevelFourAccounts) ? (
                <div className="loader-div">
                    <Spinner
                        animation="grow"
                        size="xl"
                        role="status"
                        aria-hidden="true"
                        variant="info"
                    />
                </div>
            ) : (
                <div>
                    <Form>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Select LT Account *</b></Form.Label>
                                    <Select
                                        // isDisabled={_.isEmpty(getDepartmentNameList)}
                                        placeholder="Select LT Account"
                                        onChange={handleChangeLTAccount}
                                        options={getAccountsList}
                                        value={{
                                            value: (LTAccountId === "" && !_.isEmpty(props.selectedMasterData)) ? props.selectedMasterData[0].longTermAdv._id : LTAccountId,
                                            label: (LTAccountId === "" && !_.isEmpty(props.selectedMasterData)) ? props.selectedMasterData[0].longTermAdv.levelFourName : LTAccountName
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Select ST Account *</b></Form.Label>
                                    <Select
                                        // isDisabled={_.isEmpty(getDepartmentNameList)}
                                        placeholder="Select ST Account"
                                        onChange={handleChangeSTAccount}
                                        options={getAccountsList}
                                        value={{
                                            value: (STAccountId === "" && !_.isEmpty(props.selectedMasterData)) ? props.selectedMasterData[0].shortTermAdv._id : STAccountId,
                                            label: (STAccountId === "" && !_.isEmpty(props.selectedMasterData)) ? props.selectedMasterData[0].shortTermAdv.levelFourName : STAccountName
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Select LT Bank Account *</b></Form.Label>
                                    <Select
                                        // isDisabled={_.isEmpty(getDepartmentNameList)}
                                        placeholder="Select LT Bank Account"
                                        onChange={(selectedOption) => { setlTAccounts((prev) => ({ ...prev, bankId: selectedOption.value, bankName: selectedOption.label })); }}
                                        options={getAccountsList}
                                        value={{
                                            value: (LTAccounts.bankId === "" && !_.isEmpty(props.selectedMasterData)) ? props.selectedMasterData[0].longTermBank._id : LTAccounts.bankId,
                                            label: (LTAccounts.bankId === "" && !_.isEmpty(props.selectedMasterData)) ? props.selectedMasterData[0].longTermBank.levelFourName : LTAccounts.bankName
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Select ST Bank Account *</b></Form.Label>
                                    <Select
                                        // isDisabled={_.isEmpty(getDepartmentNameList)}
                                        placeholder="Select ST Bank Account"
                                        onChange={(selectedOption) => { setsTAccounts((prev) => ({ ...prev, bankId: selectedOption.value, bankName: selectedOption.label })); }}
                                        options={getAccountsList}
                                        value={{
                                            value: (STAccounts.bankId === "" && !_.isEmpty(props.selectedMasterData)) ? props.selectedMasterData[0].shortTermBank._id : STAccounts.bankId,
                                            label: (STAccounts.bankId === "" && !_.isEmpty(props.selectedMasterData)) ? props.selectedMasterData[0].shortTermBank.levelFourName : STAccounts.bankName
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Select LT Cash Account *</b></Form.Label>
                                    <Select
                                        // isDisabled={_.isEmpty(getDepartmentNameList)}
                                        placeholder="Select LT Cash Account"
                                        onChange={(selectedOption) => { setlTAccounts((prev) => ({ ...prev, cashId: selectedOption.value, cashName: selectedOption.label })); }}
                                        options={getAccountsList}
                                        value={{
                                            value: (LTAccounts.cashId === "" && !_.isEmpty(props.selectedMasterData)) ? props.selectedMasterData[0].longTermCash._id : LTAccounts.cashId,
                                            label: (LTAccounts.cashId === "" && !_.isEmpty(props.selectedMasterData)) ? props.selectedMasterData[0].longTermCash.levelFourName : LTAccounts.cashName
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Select ST Cash Account *</b></Form.Label>
                                    <Select
                                        // isDisabled={_.isEmpty(getDepartmentNameList)}
                                        placeholder="Select ST Cash Account"
                                        onChange={(selectedOption) => { setsTAccounts((prev) => ({ ...prev, cashId: selectedOption.value, cashName: selectedOption.label })); }}
                                        options={getAccountsList}
                                        value={{
                                            value: (STAccounts.cashId === "" && !_.isEmpty(props.selectedMasterData)) ? props.selectedMasterData[0].shortTermCash._id : STAccounts.cashId,
                                            label: (STAccounts.cashId === "" && !_.isEmpty(props.selectedMasterData)) ? props.selectedMasterData[0].shortTermCash.levelFourName : STAccounts.cashName
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Select LT Adjustment Account *</b></Form.Label>
                                    <Select
                                        // isDisabled={_.isEmpty(getDepartmentNameList)}
                                        placeholder="Select LT Adjustment Account"
                                        onChange={(selectedOption) => { setlTAccounts((prev) => ({ ...prev, adjustId: selectedOption.value, adjustName: selectedOption.label })); }}
                                        options={getAccountsList}
                                        value={{
                                            value: (LTAccounts.adjustId === "" && !_.isEmpty(props.selectedMasterData)) ? props.selectedMasterData[0].longTermAdjustment._id : LTAccounts.adjustId,
                                            label: (LTAccounts.adjustId === "" && !_.isEmpty(props.selectedMasterData)) ? props.selectedMasterData[0].longTermAdjustment.levelFourName : LTAccounts.adjustName
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Select ST Adjustment Account *</b></Form.Label>
                                    <Select
                                        // isDisabled={_.isEmpty(getDepartmentNameList)}
                                        placeholder="Select ST Adjustment Account"
                                        onChange={(selectedOption) => { setsTAccounts((prev) => ({ ...prev, adjustId: selectedOption.value, adjustName: selectedOption.label })); }}
                                        options={getAccountsList}
                                        value={{
                                            value: (STAccounts.adjustId === "" && !_.isEmpty(props.selectedMasterData)) ? props.selectedMasterData[0].shortTermAdjustment._id : STAccounts.adjustId,
                                            label: (STAccounts.adjustId === "" && !_.isEmpty(props.selectedMasterData)) ? props.selectedMasterData[0].shortTermAdjustment.levelFourName : STAccounts.adjustName
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Select Expense Account *</b></Form.Label>
                                    <Select
                                        // isDisabled={_.isEmpty(getDepartmentNameList)}
                                        placeholder="Select Expense Account"
                                        onChange={(selectedOption) => { setMiscAccounts((prev) => ({ ...prev, sExpenseId: selectedOption.value, sExpenseName: selectedOption.label })); }}
                                        options={getAccountsList}
                                        value={{
                                            value: (miscAccounts.sExpenseId === "" && !_.isEmpty(props.selectedMasterData)) ? props.selectedMasterData[0].salaryExpense._id : miscAccounts.sExpenseId,
                                            label: (miscAccounts.sExpenseId === "" && !_.isEmpty(props.selectedMasterData)) ? props.selectedMasterData[0].salaryExpense.levelFourName : miscAccounts.sExpenseName
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Select Overtime Account *</b></Form.Label>
                                    <Select
                                        // isDisabled={_.isEmpty(getDepartmentNameList)}
                                        placeholder="Select Overtime Account"
                                        onChange={(selectedOption) => { setMiscAccounts((prev) => ({ ...prev, ovetimeId: selectedOption.value, ovetimeName: selectedOption.label })); }}
                                        options={getAccountsList}
                                        value={{
                                            value: (miscAccounts.ovetimeId === "" && !_.isEmpty(props.selectedMasterData)) ? props.selectedMasterData[0].overtimeExpense._id : miscAccounts.ovetimeId,
                                            label: (miscAccounts.ovetimeId === "" && !_.isEmpty(props.selectedMasterData)) ? props.selectedMasterData[0].overtimeExpense.levelFourName : miscAccounts.ovetimeName
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Select Allowances Account *</b></Form.Label>
                                    <Select
                                        // isDisabled={_.isEmpty(getDepartmentNameList)}
                                        placeholder="Select Allowances Account"
                                        onChange={(selectedOption) => { setMiscAccounts((prev) => ({ ...prev, allowancesId: selectedOption.value, allowancesName: selectedOption.label })); }}
                                        options={getAccountsList}
                                        value={{
                                            value: (miscAccounts.allowancesId === "" && !_.isEmpty(props.selectedMasterData)) ? props.selectedMasterData[0].allowances._id : miscAccounts.allowancesId,
                                            label: (miscAccounts.allowancesId === "" && !_.isEmpty(props.selectedMasterData)) ? props.selectedMasterData[0].allowances.levelFourName : miscAccounts.allowancesName
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Select Deductions Account *</b></Form.Label>
                                    <Select
                                        // isDisabled={_.isEmpty(getDepartmentNameList)}
                                        placeholder="Select Deductions Account"
                                        onChange={(selectedOption) => { setMiscAccounts((prev) => ({ ...prev, deductionsId: selectedOption.value, deductionsName: selectedOption.label })); }}
                                        options={getAccountsList}
                                        value={{
                                            value: (miscAccounts.deductionsId === "" && !_.isEmpty(props.selectedMasterData)) ? props.selectedMasterData[0].deductions._id : miscAccounts.deductionsId,
                                            label: (miscAccounts.deductionsId === "" && !_.isEmpty(props.selectedMasterData)) ? props.selectedMasterData[0].deductions.levelFourName : miscAccounts.deductionsName
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Select Salary Payable Account *</b></Form.Label>
                                    <Select
                                        // isDisabled={_.isEmpty(getDepartmentNameList)}
                                        placeholder="Select Salary Payable Account"
                                        onChange={(selectedOption) => { setMiscAccounts((prev) => ({ ...prev, salaryId: selectedOption.value, salaryName: selectedOption.label })); }}
                                        options={getAccountsList}
                                        value={{
                                            value: (miscAccounts.salaryId === "" && !_.isEmpty(props.selectedMasterData)) ? props.selectedMasterData[0].salaryPayable._id : miscAccounts.salaryId,
                                            label: (miscAccounts.salaryId === "" && !_.isEmpty(props.selectedMasterData)) ? props.selectedMasterData[0].salaryPayable.levelFourName : miscAccounts.salaryName
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col></Col>
                        </Row>

                        <div className="sendDiv">
                            <Button variant="contained" endIcon={<SendIcon />} size="large" onClick={() => { saveAccounts(); }}> SAVE </Button>
                        </div>

                    </Form>
                </div>
            )}
        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
        AddMasterControlHRApi: (data, handleVisible, handleDangerVisible) => dispatch(AddMasterControlHRApi(data, handleVisible, handleDangerVisible)),
        GetLevelFourApi: () => dispatch(GetLevelFourApi()),
        GetSelectedMasterControlHRAccountsApi: () => dispatch(GetSelectedMasterControlHRAccountsApi()),
        setHeading: (data) => dispatch(setHeading(data)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    isFetchingLevelFourAccounts: state.MasterControlHRReducer.isFetchingLevelFourAccounts,
    levelFourList: state.MasterControlHRReducer.levelFourList,
    selectedMasterData: state.MasterControlHRReducer.selectedMasterData,
    isFetchingSelectedMasterAccounts: state.MasterControlHRReducer.isFetchingSelectedMasterAccounts,
});

export default connect(mapStateToProps, mapDispatchToProps)(MasterControlHR);