import API from "../../../../../global/api";


export const requestAddProductionEmployee = () => {
    return {
        type: "REQUEST_ADD_PRODUCTION_EMPLOYEE",
    };
};
export const successAddProductionEmployee = (data) => {
    return {
        type: "SUCCESS_ADD_PRODUCTION_EMPLOYEE",
        payload: data,
    };
};
export const errorAddProductionEmployee = () => {
    return {
        type: "ERROR_ADD_PRODUCTION_EMPLOYEE",
    };
};
export const AddProductionEmployeeApi = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddProductionEmployee());
        API.post(`/productionEmployee`, data).then((res) => {
            dispatch(successAddProductionEmployee(data));
            handleVisible(res.data.message);
        }).catch((error) => {
            dispatch(errorAddProductionEmployee());
            handleDangerVisible(error.response.data.message);
        })
    };
};


export const requestGetProductionEmployee = () => {
    return {
        type: "REQUEST_GET_PRODUCTION_EMPLOYEE",
    };
};
export const successGetProductionEmployee = (data) => {
    return {
        type: "SUCCESS_GET_PRODUCTION_EMPLOYEE",
        payload: data,
    };
};
export const errorGetProductionEmployee = () => {
    return {
        type: "ERROR_GET_PRODUCTION_EMPLOYEE",
    };
};
export const GetProductionEmployeeApi = () => {
    return (dispatch) => {
        dispatch(requestGetProductionEmployee());
        API.get(`/productionEmployee`).then((res) => {
            let getData = res.data.data;
            dispatch(successGetProductionEmployee(getData));
        }).catch((error) => {
            dispatch(errorGetProductionEmployee());
        })
    };
};


export const requestDeleteProductionEmployee = () => {
    return {
        type: "REQUEST_DELETE_PRODUCTION_EMPLOYEE",
    };
};
export const successDeleteProductionEmployee = (user_id) => {
    return {
        type: "SUCCESS_DELETE_PRODUCTION_EMPLOYEE",
        user_id: user_id,
    };
};
export const errorDeleteProductionEmployee = () => {
    return {
        type: "ERROR_DELETE_PRODUCTION_EMPLOYEE",
    };
};
export const DeleteProductionEmployeeApi = (id) => {
    return dispatch => {
        dispatch(requestDeleteProductionEmployee());
        API.delete(`/productionEmployee/${id}`).then(res => {
            dispatch(successDeleteProductionEmployee(id))
        }).catch((error) => {
            dispatch(errorDeleteProductionEmployee())
        });
    }
};


export const requestUpdateProductionEmployee = () => {
    return {
        type: "REQUEST_UPDATE_PRODUCTION_EMPLOYEE",
    };
};
export const successUpdateProductionEmployee = (data) => {
    return {
        type: "SUCCESS_UPDATE_PRODUCTION_EMPLOYEE",
        payload: data,
    };
};
export const errorUpdateProductionEmployee = () => {
    return {
        type: "ERROR_UPDATE_PRODUCTION_EMPLOYEE",
    };
};
export const UpdateProductionEmployeeApi = (data, oldData) => {
    return dispatch => {
        dispatch(requestUpdateProductionEmployee());
        API.put(`/productionEmployee/${oldData._id}`, {
            employeeName: data.employeeName,
            fatherName: data.fatherName,
            phone: data.phone,
            cnic: data.cnic,
            gender: data.gender,
            machineId: data.machineId,
            department: data.department,
            designation: data.designation,
            incharge: data.incharge,
            hall: data.hall,
        }, {
        }).then(res => {
            dispatch(successUpdateProductionEmployee([data]))
        }).catch((error) => {
            dispatch(errorUpdateProductionEmployee());
        })
    }
};