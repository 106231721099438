import API from "../../../../../global/api";
import jsPDF from "jspdf";
import _ from "lodash";
import logo from "../../../../../Images/logo.png";
import "jspdf-autotable";


export const requestAddOrderCreation = () => {
  return {
    type: "REQUEST_ADD_ORDER_CREATION",
  };
};
export const successAddOrderCreation = (data) => {
  return {
    type: "SUCCESS_ADD_ORDER_CREATION",
    payload: data,
  };
};
export const errorAddOrderCreation = () => {
  return {
    type: "ERROR_ADD_ORDER_CREATION",
  };
};
export const AddOrderCreationApi = (data, handleVisible, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestAddOrderCreation());
    API.post(`/orderCreation/`, data).then((res) => {
      dispatch(successAddOrderCreation(data));
      handleVisible(res.data.message);
    }).catch((error) => {
      dispatch(errorAddOrderCreation());
      handleDangerVisible(error.response.data.message);
    });
  };
};


export const requestGetOrderCreation = () => {
  return {
    type: "REQUEST_GET_ORDER_CREATION",
  };
};
export const successGetOrderCreation = (data) => {
  return {
    type: "SUCCESS_GET_ORDER_CREATION",
    payload: data,
  };
};
export const errorGetOrderCreation = () => {
  return {
    type: "ERROR_GET_ORDER_CREATION",
  };
};
export const GetOrderCreationApi = () => {
  return (dispatch) => {
    dispatch(requestGetOrderCreation());
    API.get(`/orderCreation/`).then((res) => {
      let getData = res.data;
      dispatch(successGetOrderCreation(getData));
    }).catch((error) => {
      dispatch(errorGetOrderCreation());
    });
  };
};


export const requestGetOrderCreationWithID = () => {
  return {
    type: "REQUEST_GET_ORDER_CREATION_WITH_ID",
  };
};
export const successGetOrderCreationWithID = (data) => {
  return {
    type: "SUCCESS_GET_ORDER_CREATION_WITH_ID",
    payload: data,
  };
};
export const errorGetOrderCreationWithID = () => {
  return {
    type: "ERROR_GET_ORDER_CREATION_WITH_ID",
  };
};
export const GetOrderCreationWithIDApi = (orderId) => {
  return (dispatch) => {
    dispatch(requestGetOrderCreationWithID());
    API.get(`/orderCreation/get_order_with_id/${orderId}`).then((res) => {
      let getData = res.data;
      dispatch(successGetOrderCreationWithID(getData));
    }).catch((error) => {
      dispatch(errorGetOrderCreationWithID());
    });
  };
};


export const PrintSingleOrder = (data) => {
  return (dispatch) => {
    const unit = "pt";
    const size = "A4";
    const orientation = "potrait";
    const doc = new jsPDF(orientation, unit, size);
    const title = "Order Details";
    const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
    const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
    const companyNtn = `${process.env.REACT_APP_COMPANY_NTN}`;
    const sequenceNumber = "Order No: " + data.sequenceNumber;
    const orderDate = "Order Date: " + data.orderDate;
    const customerName = "Customer Name: " + data.customerDetails.name;
    const brandName = "Brand Name: " + data.brandDetails.brandName;
    const orderDeliveryDate = "Order Delivery Date: " + data.orderDeliveryDate;
    const orderShippingDate = "Order Shipping Date: " + data.orderShippingDate;
    const orderDeadLineDate = "Order DeadLine Date: " + data.orderDeadLineDate;
    const manualOrderNumber = "Manual Order Number: " + data.orderNumber;

    const headers = [[
      "Sr",
      "Article Name",
      "Article Variation",
      "Quantity",
    ]];
    const prodactData = _.isEmpty(data.articleDetails) ? '' : data.articleDetails.map((elt, i) => [
      i + 1,
      elt.articleName,
      elt.articleVariationName,
      elt.quantity,
    ]);
    if (!_.isEmpty(prodactData)) {
      prodactData.push(["", "", "", Math.round(_.sumBy(prodactData, '3') * 100) / 100])
    }
    let ProductContent = {
      startY: 230,
      startX: 50,
      head: headers,
      styles: { fontSize: 11 },
      body: prodactData,
      theme: "grid",
      headStyles: { fillColor: "#2980ba" },
    };
    doc.autoTable(ProductContent);


    doc.addImage(logo, "PNG", 40, 15, 80, 80);
    doc.text(companyName, 150, 40, 0, 20);
    doc.setFontSize(12);
    doc.setFont("times", "normal");
    doc.text(companyAddress, 150, 58, 0, 20);
    doc.text(companyNtn, 150, 80, 0, 20);
    doc.setFontSize(12);
    doc.text(title, 240, 120);
    doc.text(sequenceNumber, 50, 150, 0, 20);
    doc.text(orderDate, 330, 150, 0, 20);
    doc.text(brandName, 50, 170, 0, 20);
    doc.text(orderDeliveryDate, 330, 170, 0, 20);
    doc.text(orderShippingDate, 50, 190, 0, 20);
    doc.text(orderDeadLineDate, 330, 190, 0, 20);
    doc.text(customerName, 50, 210, 0, 20);
    doc.text(manualOrderNumber, 330, 210, 0, 20);


    doc.setProperties({ title: `Order PDF` });
    var blob = doc.output("blob");
    window.open(URL.createObjectURL(blob));
  };
};