import API from "../../../../../global/api";


export const requestSTPayment = () => {
    return {
        type: "REQUEST_ST_PAYMENT",
    };
};
export const successSTPayment = (data) => {
    return {
        type: "SUCCESS_ST_PAYMENT",
        payload: data,
    };
};
export const errorSTPayment = () => {
    return {
        type: "ERROR_ST_PAYMENT",
    };
};
export const AddSTPaymentApi = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestSTPayment());
        API.post(`/shortTermAdv`, data).then((res) => {
            dispatch(successSTPayment(data));
            handleVisible(res.data.message);
        }).catch((error) => {
            dispatch(errorSTPayment());
            handleDangerVisible(error.response.data.message);
        });
    };
};


export const requestAddSTRecivings = () => {
    return {
        type: "REQUEST_ADD_ST_RECIVING",
    };
};
export const successAddSTRecivings = (data) => {
    return {
        type: "SUCCESS_ADD_ST_RECIVING",
        payload: data,
    };
};
export const errorAddSTRecivings = () => {
    return {
        type: "ERROR_ADD_ST_RECIVING",
    };
};
export const AddSTRecivingApi = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddSTRecivings());
        API.post(`/shortTermAdv/short_term_receiving`, data).then((res) => {
            dispatch(successAddSTRecivings(data));
            handleVisible(res.data.message);
        }).catch((error) => {
            dispatch(errorAddSTRecivings());
            handleDangerVisible(error.response.data.message);
        });
    };
};


export const requestGetSTLedger = () => {
    return {
        type: "REQUEST_GET_ST_LEDGER",
    };
};
export const successGetSTLedger = (data) => {
    return {
        type: "SUCCESS_GET_ST_LEDGER",
        payload: data,
    };
};
export const errorGetSTLedger = () => {
    return {
        type: "ERROR_GET_ST_LEDGER",
    };
};
export const GetSTLedgerApi = (data, startDate, endDate) => {
    return (dispatch) => {
        dispatch(requestGetSTLedger());
        API.post(`/shortTermAdv/ledger`, data).then((res) => {
            let getData = res.data;
            dispatch(successGetSTLedger(getData));
        }).catch((error) => {
            dispatch(errorGetSTLedger());
        })
    };
};