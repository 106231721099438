const initialState = {
    machineList: [],
    isFetchingMachine: false,
    isAddingMachine: false,
};

const MachineReducer = (state = initialState, action) => {
    switch (action.type) {
        case "REQUEST_ADD_MACHINE":
            return { ...state, isAddingMachine: true };
        case "SUCCESS_ADD_MACHINE":
            return { ...state, machineList: action.payload.data, isAddingMachine: false };
        case "ERROR_ADD_MACHINE":
            return { ...state };


        case "REQUEST_GET_MACHINE":
            return { ...state, isFetchingMachine: true };
        case "SUCCESS_GET_MACHINE":
            return { ...state, machineList: action.payload, isFetchingMachine: false };
        case "ERROR_GET_MACHINE":
            return { ...state, isFetchingMachine: false };

        default:
            return state;
    }
};
export default MachineReducer;