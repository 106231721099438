import API from "../../../../../global/api";
// import _ from "lodash";


export const requestAddDesignation = () => {
    return {
        type: "REQUEST_ADD_DESIGNATION",
    };
};
export const successAddDesignation = (data) => {
    return {
        type: "SUCCESS_ADD_DESIGNATION",
        payload: data,
    };
};
export const errorAddDesignation = () => {
    return {
        type: "ERROR_ADD_DESIGNATION",
    };
};
export const AddDesignationApi = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddDesignation());
        API.post(`/designation`, data).then((res) => {
            let getDesignationData = res.data;
            dispatch(successAddDesignation(getDesignationData));
            handleVisible(res.data.message);
        }).catch((error) => {
            dispatch(errorAddDesignation());
            handleDangerVisible(error.response.data.message);
        })
    };
};


export const requestGetDesignation = () => {
    return {
        type: "REQUEST_GET_DESIGNATION",
    };
};
export const successGetDesignation = (data) => {
    return {
        type: "SUCCESS_GET_DESIGNATION",
        payload: data,
    };
};
export const errorGetDesignation = () => {
    return {
        type: "ERROR_GET_DESIGNATION",
    };
};
export const GetDesignationApi = () => {
    return (dispatch) => {
        dispatch(requestGetDesignation());
        API.get(`/designation`).then((res) => {
            let getData = res.data;
            dispatch(successGetDesignation(getData));
        }).catch((error) => {
            dispatch(errorGetDesignation());
        })
    };
};


export const requestUpdateDesignation = () => {
    return {
        type: "REQUEST_UPDATE_DESIGNATION",
    };
};
export const successUpdateDesignation = (data) => {
    return {
        type: "SUCCESS_UPDATE_DESIGNATION",
        payload: data,
    };
};
export const errorUpdateDesignation = () => {
    return {
        type: "ERROR_UPDATE_DESIGNATION",
    };
};
export const UpdateDesignationApi = (newData, oldData, updateTableQuery) => {
    return (dispatch) => {
        dispatch(requestUpdateDesignation());
        API.put(`/designation/${oldData._id}`, newData).then((res) => {
            dispatch(successUpdateDesignation([newData]));
            updateTableQuery()
        }).catch((error) => {
            dispatch(errorUpdateDesignation());
        })
    };
};


export const requestDeleteDesignation = () => {
    return {
        type: "REQUEST_DELETE_DESIGNATION",
    };
};
export const successDeleteDesignation = (designationId) => {
    return {
        type: "SUCCESS_DELETE_DESIGNATION",
        designation_id: designationId,
    };
};
export const errorDeleteDesignation = () => {
    return {
        type: "ERROR_DELETE_DESIGNATION",
    };
};
export const DeleteDesignationApi = (designation_id, updateTableQuery) => {
    return dispatch => {
        dispatch(requestDeleteDesignation());
        API.delete(`/designation/${designation_id}`).then(res => {
            updateTableQuery()
            dispatch(successDeleteDesignation(designation_id))
        }).catch((error) =>
            dispatch(errorDeleteDesignation()));
    }
};


export const requestGetDesignationByDepartment = () => {
    return {
        type: "REQUEST_GET_DESIGNATION_BY_DEPARTMENT",
    };
};
export const successGetDesignationByDepartment = (data) => {
    return {
        type: "SUCCESS_GET_DESIGNATION_BY_DEPARTMENT",
        payload: data,
    };
};
export const errorGetDesignationByDepartment = () => {
    return {
        type: "ERROR_GET_DESIGNATION_BY_DEPARTMENT",
    };
};

export const GetDesignationByDepartmentApi = (id) => {
    return (dispatch) => {
        dispatch(requestGetDesignationByDepartment());
        API.get(`/designation/get_designation_with_departmentID/${id}`).then((res) => {
            let getData = res.data;
            dispatch(successGetDesignationByDepartment(getData));
        }).catch((error) => {
            dispatch(errorGetDesignationByDepartment());
        })
    };
};