import API from "../../../../../global/api";


export const requestAddEmployeeType = () => {
    return {
        type: "REQUEST_ADD_EMPLOYEE_TYPE",
    };
};
export const successAddEmployeeType = (data) => {
    return {
        type: "SUCCESS_ADD_EMPLOYEE_TYPE",
        payload: data,
    };
};
export const errorAddEmployeeType = () => {
    return {
        type: "ERROR_ADD_EMPLOYEE_TYPE",
    };
};
export const AddEmployeeTypeApi = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddEmployeeType());
        API.post(`/employeeType`, data).then((res) => {
            handleVisible(res.data.message);
            let getData = res.data;
            dispatch(successAddEmployeeType(getData));
        }).catch((error) => {
            handleDangerVisible(error.response.data.message);
            dispatch(errorAddEmployeeType());
        })
    };
};


export const requestGetEmployeeType = () => {
    return {
        type: "REQUEST_GET_EMPLOYEE_TYPE",
    };
};
export const successGetEmployeeType = (data) => {
    return {
        type: "SUCCESS_GET_EMPLOYEE_TYPE",
        payload: data,
    };
};
export const errorGetEmployeeType = () => {
    return {
        type: "ERROR_GET_EMPLOYEE_TYPE",
    };
};
export const GetEmployeeTypeApi = () => {
    return (dispatch) => {
        dispatch(requestGetEmployeeType());
        API.get(`/employeeType`).then((res) => {
            let getData = res.data;
            dispatch(successGetEmployeeType(getData));
        }).catch((error) => {
            dispatch(errorGetEmployeeType());
        })
    };
};


export const requestUpdateEmployeeType = () => {
    return {
        type: "REQUEST_UPDATE_EMPLOYEE_TYPE",
    };
};
export const successUpdateEmployeeType = (data) => {
    return {
        type: "SUCCESS_UPDATE_EMPLOYEE_TYPE",
        payload: data,
    };
};
export const errorUpdateEmployeeType = () => {
    return {
        type: "ERROR_UPDATE_EMPLOYEE_TYPE",
    };
};
export const UpdateEmployeeTypeApi = (newData, oldData, updateTableQuery) => {
    return (dispatch) => {
        dispatch(requestUpdateEmployeeType());
        API.put(`/employeeType/${oldData._id}`, newData).then((res) => {
            dispatch(successUpdateEmployeeType([newData]));
            updateTableQuery()
        }).catch((error) => {
            dispatch(errorUpdateEmployeeType());
        })
    };
};


export const requestDeleteEmployeeType = () => {
    return {
        type: "REQUEST_DELETE_EMPLOYEE_TYPE",
    };
};
export const successDeleteEmployeeType = (data) => {
    return {
        type: "SUCCESS_DELETE_EMPLOYEE_TYPE",
        payload: data,
    };
};
export const errorDeleteEmployeeType = () => {
    return {
        type: "ERROR_DELETE_EMPLOYEE_TYPE",
    };
};
export const DeleteEmployeeTypeApi = (employee_type_id, updateTableQuery) => {
    return (dispatch) => {
        dispatch(requestDeleteEmployeeType());
        API.delete(`/employeeType/${employee_type_id}`).then((res) => {
            dispatch(successDeleteEmployeeType());
            updateTableQuery()
        }).catch((error) => {
            dispatch(errorDeleteEmployeeType());
        })
    };
};
