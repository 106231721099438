// import _ from "lodash";

const initialState = {
    isAddingDivisionData: false,
    addDivision: [],
    divisionList: [],
    isFetchingDivisionData: false,
    isUpdatingDivisionData: false,
    isDeletingDivisionData: false,
};

const DivisionReducer = (state = initialState, action) => {
    switch (action.type) {

        case "REQUEST_ADD_DIVISION":
            return { ...state, isAddingDivisionData: true };
        case "SUCCESS_ADD_DIVISION":
            return { ...state, addDivision: action.payload, isAddingDivisionData: false };
        case "ERROR_ADD_DIVISION":
            return { ...state, isAddingDivisionData: false };


        case "REQUEST_GET_DIVISION":
            return { ...state, isFetchingDivisionData: true };
        case "SUCCESS_GET_DIVISION":
            return { ...state, divisionList: action.payload.data, isFetchingDivisionData: false, };
        case "ERROR_GET_DIVISION":
            return { ...state };


        case "REQUEST_UPDATE_DIVISION":
            return { ...state, isUpdatingDivisionData: true };
        case "SUCCESS_UPDATE_DIVISION":
            return { ...state, isUpdatingDivisionData: false };
        case "ERROR_UPDATE_DIVISION":
            return { ...state, isUpdatingDivisionData: false };


        case "REQUEST_DELETE_DIVISION":
            return { ...state, isDeletingDivisionData: false };
        case "SUCCESS_DELETE_DIVISION":
            return { ...state, isDeletingDivisionData: false };
        case "ERROR_DELETE_DIVISION":
            return { ...state, isDeletingDivisionData: false };

        default:
            return state;
    }
}

export default DivisionReducer;