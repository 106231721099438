import React, { useEffect } from "react";
import { Container, } from "react-bootstrap";
import { connect } from "react-redux";
import { PrintSingleOrder } from "./OrderCreationAction";
import { baseURL, headers } from "../../../../../global/api";
import { setHeading } from "../../../accounts/account.actions";
import MaterialSize from "material-table";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import PrintIcon from '@mui/icons-material/Print';


const ViewOrderCreation = (props) => {

    useEffect(() => {
        props.setHeading("Order Creation List");
    }, []);

    return (
        <>
            {props.isFetchingOrderCreation ? (
                <div className="loader-div">
                    <Spinner
                        variant="primary"
                        animation="border"
                        size="xl"
                        role="status"
                        aria-hidden="true"
                    />
                </div>
            ) : (
                <Container fluid>
                    <div className="main">
                        <MaterialSize
                            title="Order Creation Details"
                            columns={[
                                { title: 'Sequence Number', field: 'sequenceNumber', editable: 'never' },
                                { title: "Customer Name", field: "customerDetails.name", editable: 'never' },
                                { title: "Brand Name", field: "brandDetails.brandName", editable: 'never' },
                                { title: "Order Number", field: "orderNumber" },
                                { title: "Order Date", field: "orderDate" },
                                { title: "Order Delivery Date", field: "orderDeliveryDate" },
                                { title: "Order Shipping Date", field: "orderShippingDate" },
                                { title: "Order DeadLine Date", field: "orderDeadLineDate" },
                                { title: "Created By", field: "userDetails.userName", editable: 'never' },
                            ]}
                            data={(query) =>
                                new Promise((resolve, reject) => {
                                    let url = `${baseURL}/orderCreation?`;
                                    url += "limit=" + query.pageSize;
                                    url += "&page=" + (query.page);
                                    url += "&search=" + query.search;
                                    fetch(url, {
                                        method: "GET",
                                        headers: headers,
                                    }).then((response) => response.json()).then((result) => {
                                        resolve({
                                            data: result.data,
                                            page: result.page,
                                            totalCount: result.total_results,
                                        });
                                    });
                                })
                            }
                            detailPanel={rowData => {
                                return (
                                    <MaterialTable
                                        columns={[
                                            { title: 'Article Name', field: 'articleName' },
                                            { title: 'Variation', field: 'articleVariationName' },
                                            { title: 'Order Quantity', field: 'quantity' },
                                            { title: 'Issue Quantity', render: rowData => Number(rowData.quantity) - Number(rowData.remainingQuantity) },
                                            { title: 'Remaining Quantity', field: 'remainingQuantity' },
                                            { title: 'Received Quantity', field: 'receivedQuantity' },
                                        ]}
                                        data={rowData.articleDetails}
                                        options={{
                                            actionsColumnIndex: -1,
                                            toolbar: false,
                                            exportButton: false,
                                            sorting: false,
                                            paging: false,
                                            pageSize: 50,
                                            emptyRowsWhenPaging: false,
                                            pageSizeOptions: [50, 100, 150, 200],
                                            headerStyle: {
                                                position: 'sticky',
                                                top: 0,
                                                color: '#00BBBB',
                                                fontWeight: '550',
                                                onRowAdd: 'none',
                                            }
                                        }}
                                    />
                                )
                            }}
                            options={{
                                actionsColumnIndex: -1,
                                exportButton: true,
                                exportAllData: true,
                                paging: true,
                                pageSize: 50,
                                emptyRowsWhenPaging: false,
                                pageSizeOptions: [50, 100, 150, 200],
                                headerStyle: {
                                    position: "sticky",
                                    top: 0,
                                    color: "#00BBBB",
                                    fontWeight: "550",
                                    onRowAdd: "none",
                                },
                            }}
                            editable={{
                                // isEditHidden: (rowData) => Checkpermissions("permissionsSubCatName", "Colour Edit"),
                                // isDeleteHidden: (rowData) => Checkpermissions("permissionsSubCatName", "Colour Delete"),
                                isDeletable: rowData => false,
                                isEditable: rowData => false,
                                onRowUpdate: (newData, oldData) =>
                                    new Promise((resolve) => {
                                        setTimeout(() => {
                                            resolve();
                                            // props.UpdateProcessApi(newData, oldData);
                                        }, 600);
                                    }),
                                onRowDelete: (oldData) =>
                                    new Promise((resolve) => {
                                        setTimeout(() => {
                                            resolve();
                                            // props.DeleteProcessApi(oldData._id);
                                        }, 600);
                                    }),
                            }}
                            actions={[
                                (rowData) => ({
                                    icon: () => <PrintIcon />,
                                    tooltip: "Print Order",
                                    onClick: (event, rowData) => {
                                        props.PrintSingleOrder(rowData)
                                    },
                                }),
                            ]}
                        />
                    </div>
                </Container>
            )}
        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
        PrintSingleOrder: (data) => dispatch(PrintSingleOrder(data)),
        // UpdateProcessApi: (newData, oldData) => dispatch(UpdateProcessApi(newData, oldData)),
        setHeading: (data) => dispatch(setHeading(data)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    orderCreationList: state.OrderCreationReducer.orderCreationList,
    isFetchingOrderCreation: state.OrderCreationReducer.isFetchingOrderCreation,
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewOrderCreation);