import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { setHeading } from "../../../accounts/account.actions";
import { getCustomerData } from "../../AddDetails/AddCustomer/Customer.action";
import { GetBrandWithCustomerIDApi } from "../../AddDetails/Brand/Brand.action";
import { AddOrderCreationApi } from "./OrderCreationAction";
import { GetArticleApi, GetArticleWithIDApi } from "../article/ArticleAction";
import _ from "lodash";
import Select from 'react-select';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import Spinner from "react-bootstrap/Spinner";
import Swal from "sweetalert";


const AddOrderCreation = (props) => {
    // const [articleId, setArticleId] = useState();
    const [customerId, setCustomerId] = useState();
    const [customerName, setCustomerName] = useState();
    const [brandId, setBrandId] = useState();
    const [brandName, setBrandName] = useState();
    const [orderNumber, setOrderNumber] = useState();
    const [orderDate, setOrderDate] = useState();
    const [orderDeliveryDate, setOrderDeliveryDate] = useState();
    const [orderShippingDate, setOrderShippingDate] = useState();
    const [orderDeadLineDate, setOrderDeadLineDate] = useState();
    const [description, setDescription] = useState("");
    const [visibleAlert, setAlertVisible] = useState(false);
    const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
    const [inputField, setInputField] = useState([{
        articleId: "",
        articleName: "",
        articleVariationId: "",
        quantity: 0
    }]);


    const SaveOrderCreation = () => {
        props.AddOrderCreationApi({
            customerId: customerId,
            customerName: customerName,
            brandId: brandId,
            brandName: brandName,
            orderNumber: orderNumber,
            orderDate: orderDate,
            orderDeliveryDate: orderDeliveryDate,
            orderShippingDate: orderShippingDate,
            orderDeadLineDate: orderDeadLineDate,
            description: description,
            articleDetails: inputField,
        },
            handleVisible,
            handleDangerVisible
        )
        setCustomerId("")
        setBrandId("")
        setOrderNumber("")
        setOrderDate("")
        setOrderDeliveryDate("")
        setOrderShippingDate("")
        setOrderDeadLineDate("")
        setInputField([{
            articleId: "",
            articleName: "",
            articleVariationId: "",
            quantity: 0,
        }]);
    }
    const handleVisible = (msg) => {
        setAlertVisible(true);
        setTimeout(() => {
            setAlertVisible(false);
        }, 9000);
        Swal({
            title: "Success",
            text: msg,
            icon: "success",
            button: "Ok",
        });
    };
    const handleDangerVisible = (err) => {
        setDangerVisibleAlert(true);
        setTimeout(() => {
            setDangerVisibleAlert(false);
        }, 9000);
        Swal({
            title: "Error",
            text: err,
            icon: "error",
            button: "Ok",
        });
    };


    const handleChangeCustomer = (selectedOption) => {
        setCustomerId(selectedOption.value);
        setCustomerName(selectedOption.label);
        props.GetBrandWithCustomerIDApi(selectedOption.value)
    };
    const getCustomerList = !_.isEmpty(props.customerDataList) && props.customerDataList.map((x) => {
        let data = { value: x._id, label: x.name };
        return data;
    });

    const handleChangeBrand = (selectedOption) => {
        setBrandId(selectedOption.value);
        setBrandName(selectedOption.label);
    };
    const getBrandList = !_.isEmpty(props.brandWithCustomerID) && props.brandWithCustomerID.map((x) => {
        let data = { value: x._id, label: x.brandName };
        return data;
    });

    const getArticleList = !_.isEmpty(props.articleList) && props.articleList.map((x) => {
        let data = { value: x._id, label: `${x.articleName}` };
        return data;
    });

    const GetArticleVariationList = !_.isEmpty(props.articleListWithID) && props.articleListWithID[0].variation.filter((x) => x.isRecipe).map((x) => {
        let data = { value: x._id, label: `${x.colourLabel} / ${x.sizeLabel}`, };
        return data;
    });


    const handleChange = (e, index, type) => {
        const list = [...inputField];
        if (type === "article") { list[index]["articleId"] = e.value; props.GetArticleWithIDApi(e.value) };
        if (type === "article") list[index]["articleName"] = e.label;
        if (type === "variation") list[index]["articleVariationId"] = e.value;
        if (type === "variation") list[index]["articleVariationName"] = e.label;
        if (type === "quantity") list[index]["quantity"] = Number(e.target.value);
        if (type === "quantity") list[index]["remainingQuantity"] = Number(e.target.value);

        setInputField(list);
    };
    const handleAddInput = () => {
        setInputField([
            ...inputField,
            { articleId: "", articleName: "", articleVariationId: "", quantity: 0 },
        ]);
    };
    const handleRemoveInput = (index) => {
        const list = [...inputField];
        list.splice(index, 1);
        setInputField(list);
    };

    useEffect(() => {
        props.setHeading("New Order");
        props.getCustomerData()
        props.GetArticleApi()
    }, []);


    return (
        <>
            {props.isFetchingCustomer || props.isAddingOrderCreation ? (
                <div className="loader-div">
                    <Spinner
                        variant="primary"
                        animation="border"
                        size="xl"
                        role="status"
                        aria-hidden="true"
                    />
                </div>
            ) : (
                <Container fluid>
                    <div className="main">
                        <Form>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label><b>Select Customer *</b></Form.Label>
                                        <Select
                                            placeholder="Select Customer"
                                            onChange={handleChangeCustomer}
                                            options={getCustomerList}
                                            isDisabled={_.isEmpty(getCustomerList)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label><b>Select Brand </b></Form.Label>
                                        <Select
                                            placeholder="Select Brand"
                                            onChange={handleChangeBrand}
                                            options={getBrandList}
                                            isDisabled={_.isEmpty(customerId) || _.isEmpty(getBrandList)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label><b>Manual Order Number </b></Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Order Number"
                                            onChange={(e) => setOrderNumber(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label><b>Order Date *</b></Form.Label>
                                        <Form.Control
                                            type="date"
                                            placeholder="Order Date"
                                            onChange={(e) => setOrderDate(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label><b>Order Delivery Date </b></Form.Label>
                                        <Form.Control
                                            type="date"
                                            placeholder="Order Delivery Date"
                                            onChange={(e) => setOrderDeliveryDate(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label><b>Order Shipping Date </b></Form.Label>
                                        <Form.Control
                                            type="date"
                                            placeholder="Order Shipping Date"
                                            onChange={(e) => setOrderShippingDate(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label><b>DeadLine Date </b></Form.Label>
                                        <Form.Control
                                            type="date"
                                            placeholder="DeadLine Date"
                                            onChange={(e) => setOrderDeadLineDate(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label><b>Description</b></Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Description"
                                            onChange={(e) => setDescription(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col></Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Form.Group>
                                        {inputField.map((item, i) => {
                                            return (
                                                <React.Fragment key={i}>
                                                    <Row>
                                                        <Col>
                                                            <Form.Group>
                                                                <Form.Label><b>Select Article *</b> </Form.Label>
                                                                <Select
                                                                    style={{ zIndex: 1 }}
                                                                    value={{ label: item.articleName }}
                                                                    placeholder="Select Article"
                                                                    name="article"
                                                                    onChange={(e) => handleChange(e, i, "article")}
                                                                    options={getArticleList}
                                                                // isOptionDisabled={(option) => (!isEmpty(find(inputField, (x) => x.productId == option.value)))}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col>
                                                            <Form.Group>
                                                                <Form.Label><b>Select Variation *</b> </Form.Label>
                                                                <Select
                                                                    style={{ zIndex: 1 }}
                                                                    // value={{ label: item.productVariationId, }}
                                                                    isDisabled={_.isEmpty(GetArticleVariationList)}
                                                                    placeholder="Select Variation"
                                                                    name="variation"
                                                                    onChange={(e) => handleChange(e, i, "variation", item.articleId)}
                                                                    options={GetArticleVariationList}
                                                                    isOptionDisabled={(option) => !_.isEmpty(_.find(inputField, (x) => x.articleVariationId === option.value))}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label><b>Quantity *</b> </Form.Label>
                                                                <Form.Control
                                                                    min={0}
                                                                    type="number"
                                                                    placeholder="Quantity"
                                                                    value={item.quantity}
                                                                    onChange={(e) => handleChange(e, i, "quantity", item.productId)}
                                                                />
                                                            </Form.Group>
                                                        </Col>


                                                        <Col style={{ marginTop: "2.5%" }}>
                                                            <Form.Group>
                                                                {inputField.length - 1 === i && (
                                                                    <Button
                                                                        title="Click to Add Article"
                                                                        onClick={handleAddInput}
                                                                        style={{ float: "right", backgroundColor: '#81abd3', color: 'white' }}
                                                                    >
                                                                        <AddIcon />
                                                                    </Button>
                                                                )}

                                                                {inputField.length !== 1 && (
                                                                    <Button
                                                                        title="Click to Remove Article"
                                                                        onClick={() => handleRemoveInput(i)}
                                                                        style={{ float: "right", backgroundColor: '#81abd3', color: 'white', marginRight: '20px' }}
                                                                    >
                                                                        <RemoveIcon />
                                                                    </Button>
                                                                )}
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                </React.Fragment>
                                            );
                                        })}

                                    </Form.Group>
                                </Col>
                            </Row>

                            <div className="sendDiv">
                                {_.isEmpty(customerId) || _.isEmpty(brandId) || _.isEmpty(orderNumber) || _.isEmpty(orderDate) ||
                                    inputField.some((field) => _.isEmpty(field.articleId) || _.isEmpty(field.articleVariationId) || field.quantity <= 0) ? (
                                    <Button variant="contained" endIcon={<SendIcon />} size="large" disabled>
                                        SAVE
                                    </Button>
                                ) : (
                                    <Button variant="contained" endIcon={<SendIcon />} size="large" onClick={SaveOrderCreation}>
                                        SAVE
                                    </Button>
                                )}
                            </div>


                        </Form>
                    </div>
                </Container>
            )}
        </>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        AddOrderCreationApi: (data, handleVisible, handleDangerVisible) => dispatch(AddOrderCreationApi(data, handleVisible, handleDangerVisible)),
        getCustomerData: () => dispatch(getCustomerData()),
        GetBrandWithCustomerIDApi: (id) => dispatch(GetBrandWithCustomerIDApi(id)),
        GetArticleApi: () => dispatch(GetArticleApi()),
        GetArticleWithIDApi: (id) => dispatch(GetArticleWithIDApi(id)),
        setHeading: (data) => dispatch(setHeading(data)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    isAddingOrderCreation: state.OrderCreationReducer.isAddingOrderCreation,
    customerDataList: state.CustomerReducer.customerDataList,
    isFetchingCustomer: state.CustomerReducer.isFetchingCustomer,
    brandWithCustomerID: state.brandReducer.brandWithCustomerID,
    isFetchingArticle: state.ArticleReducer.isFetchingArticle,
    articleList: state.ArticleReducer.articleList,
    isFetchingArticleWithID: state.ArticleReducer.isFetchingArticleWithID,
    articleListWithID: state.ArticleReducer.articleListWithID,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddOrderCreation);