import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, } from "react-bootstrap";
import Navbar from 'react-bootstrap/Navbar';
import "../add-details.css";
import Modal from "react-bootstrap/Modal";
import { connect } from "react-redux";
import { isEmpty, find, sumBy } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import useScanDetection from 'use-scan-detection';
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import { IconButton } from "@material-ui/core";
import PrintIcon from '@mui/icons-material/Print';
import Select from "react-select";
import Swal from "sweetalert";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea, CardActions, Chip, TableRow } from "@mui/material";
import InputGroup from "react-bootstrap/InputGroup";
import { baseURL } from "../../../../../global/api";
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { AddOrders, deleteOrders, GetOrderById, getOrders, getOrderTypes, getTables, updateOrders, } from "./POSView.action";
import { getCoupons } from "../AddCoupons/Coupons.action";
import { getCustomerData } from "../AddCustomer/Customer.action";
import { filterItem, getProductData } from "../AddProduct/Product.action";
import { getCategoryData } from "../categories/Catagory.action";
import { filterItemByMenu } from "../Recipe/Recipe.action";
import { getProductTypeById } from "../Recipe/Recipe.action";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import { getPendingOrder, updateStatus } from "../KDS/KDSView.action";
import IconMenu from "../../../drawer/IconMenu";
import { getRestaurantData } from "../AddResturant/Resturant.action";
import { getUserRes } from "../../AddUser/user.actions";



const AddBillingPos = (props) => {
  const { state } = useLocation();
  const [issuanceType, setIssuanceType] = useState("Full");
  const [issuanceTypeLabel, setIssuanceTypeLabel] = useState("Full");
  const [inputField, setInputField] = useState([]);
  const [inputFielddumy, setInputFieldDumy] = useState([]);
  const [categoryId, setcategoryId] = useState();
  const [discountId, setDiscountId] = useState();
  const [discountname, setDiscountname] = useState();
  const [maximumQuantity, setMaximumQuantity] = useState(0);
  const [requistion, setRequistion] = useState("Manual");
  const [customer, setCustomer] = useState("");
  const [customerLabel, setCustomerLabel] = useState("");
  const [coupon, setCoupon] = useState("");
  const [couponType, setCouponType] = useState("");
  const [couponDiscount, setCouponDiscount] = useState(0);
  const [couponLabel, setCouponLabel] = useState("");
  const [table, setTable] = useState("");
  const [tableLabel, setTableLabel] = useState("");
  const [orderType, setOrderType] = useState("1");
  const [orderTypeLabel, setOrderTypeLabel] = useState("DINEIN");
  const [totalAmount, setTotalAmount] = useState(0);
  const [SubTotalAmount, setSubTotalAmount] = useState(totalAmount);
  const [taxAmount, setTaxAmount] = useState(0);
  const [afterTaxTotalAmount, setAfterTaxTotalAmount] = useState(SubTotalAmount);
  const [amount, setAmount] = useState("");
  const [productId, setProductId] = useState("");
  const [productLabel, setProductLabel] = useState("");
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [advanceAmount, setAdvanceAmount] = useState(0);
  const [remainingAmount, setRemainingAmount] = useState(0);
  const [requistionType, setRequistionType] = useState();
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  const [dangerVisibleAlertCoupon, setDangerVisibleAlertCoupon] = useState(false);
  const [dangerVisibleAlertQuantity, setDangerVisibleAlertQuantity] = useState(false);
  const [dangerVisibleAlertProduct, setDangerVisibleAlertProduct] = useState(false);
  const [categoryId1, setcategoryId1] = useState();
  const [availableQuantity, setAvailableQuantity] = useState("");
  const [waiterId, setWaiterId] = useState("");
  const [waiterName, setWaiterName] = useState("");
  const [People, setPeople] = useState(isEmpty(state) ? 0 : state.numberOfPeople);
  const [disableProduct, setDisableProduct] = useState(false);
  const [orderTypeId, setOrderTypeId] = useState(isEmpty(props.seatSelected._id) ? 2 : 1);
  const [orderTypeName, setOrderTypeName] = useState(isEmpty(state) ? "Take Away" : "Dine In");
  const [show, setShow] = useState(false);
  const [cash, setCash] = useState(0);
  const [card, setCard] = useState(0);
  const [taxLabel, setTax] = useState(0);
  const [dumyitem, setDumyItem] = useState("")
  const [show1, setShow1] = useState(false);
  const [couponModal, setCouponModal] = useState(false);
  const [customerModal, setCustomerModal] = useState(false);
  const [CommentModal, setCommentModalModal] = useState(false);
  const [orderComment, setOrderComment] = useState("");
  const [orderCommentModal, setOrderCommentModal] = useState(false);
  const [barcode, setBarocode] = useState("");
  const [cardId, setCardId] = useState("");
  const [cardName, setCardName] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const [customerAddress, setCustomerAddress] = useState("");
  const [customerNTN, setCustomerNTN] = useState("");
  const [otherAmount, setOtherAmount] = useState("");
  const [otherDesc, setOtherDesc] = useState();


  const permissions = JSON.parse(window.localStorage.getItem("permissions"))
  const Checkpermissions = (permissionsType, permissionsName) => {
    return permissions.some(p => p[`${permissionsType}`] === permissionsName) ? true : false
  }

  function uploadOrder(isPrint) {
    // console.log(inputFielddumy,"testtttttttttt")
    // let field=inputField.concat(inputFielddumy)
    // // let p=field[1].product
    // field.map((x) => {
    //   inputFielddumy.map((z)=>{
    //     console.log(x,z,"lllllllllllllllllllllll")
    //   if(x.product==z.product){
    //     console.log(x,"filedddddddddddddddddd")
    //   }})
    //     })
    props.addOrders(
      {
        isPrint,
        items: inputField,
        grandTotal: parseInt(Number(SubTotalAmount) + Number(otherAmount)),
        tax: parseInt(taxAmount),
        otherAmount: Number(otherAmount),
        otherDesc: otherDesc,
        // (Math.round(afterTaxTotalAmount*100)/100) - (Math.round(SubTotalAmount*100)/100),
        coupon: coupon,
        customer: customer,
        // orderType: orderTypeName,
        // tableId: isEmpty(state) ? null : state.id,
        // barcodeId: barcode,
        discount: couponLabel == "" ? 0 : (totalAmount - SubTotalAmount).toFixed(2),
        subTotal: totalAmount,
        // waiterId: waiterId,
        // numberOfPeople: People,
        orderComment: orderComment,
        customerDetail: {
          customerName: customerName,
          customerPhone: customerPhone,
          customerAddress: customerAddress,
          customerNTN: customerNTN,
        },
      },
      handleVisible,
      handleDangerVisible,
      // getdata,
    );
    isEmpty(state) ? navigate("/pos") : navigate("/table");
    setCard(0);
    setCash(0);
    setOrderTypeName("");
    setAdvanceAmount(0);
    setRemainingAmount(0);
    setDiscountPercentage(0);
    setTotalAmount(0);
    setTaxAmount(0);
    setTable("");
    setTableLabel("");
    setCouponLabel("");
    setCustomerLabel("");
    setCustomer("");
    setSubTotalAmount(0);
    setInputField([]);
    setCoupon("");
    setCouponType("");
    setCouponDiscount(0);
    setAfterTaxTotalAmount(0);
    //  state=0;
    handleClose1();
    setCustomerName("");
    setCustomerPhone("");
    setCustomerAddress("");
    setCustomerNTN("");
    setOtherAmount("");
    setOtherDesc("");
  }

  const handleVisible = (msg) => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      // button: "Ok",
      buttons: {
        roll: {
          text: "Go To Order View",
          value: "OV",
        },
        roll2: {
          text: "Ok",
          value: "ok",
        },
      },
    }).then(function (x) {
      if (x == "OV") {
        navigate("/order");
      } else if (x == "ok") {
        isEmpty(state) ? navigate("/pos") : navigate("/table");
      }
    });
  };
  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };
  const handleDangerVisibleCopon = () => {
    setDangerVisibleAlertCoupon(true);
    setTimeout(() => {
      setDangerVisibleAlertCoupon(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Total Order Amount Is Less Than Paid Amount, Please Change The Coupon",
      icon: "error",
      button: "Ok",
    });
  };
  const handleDangerVisibleQuantity = () => {
    setDangerVisibleAlertQuantity(true);
    setTimeout(() => {
      setDangerVisibleAlertQuantity(false);
    }, 9000);
    Swal({
      title: "Warning",
      text: "low Quantity",
      icon: "info",
      button: "Ok",
    });
  };
  const handleDangerVisibleProduct = () => {
    setDangerVisibleAlertProduct(true);
    setTimeout(() => {
      setDangerVisibleAlertProduct(false);
    }, 9000);
    Swal({
      title: "Warning",
      text: "No Product Found",
      icon: "error",
      button: "Ok",
    });
  };


  const getDiscountList = !isEmpty(props.couponsDataList) ? props.couponsDataList.map((x) => {
    let data = {
      value: x._id,
      label: x.name,
      type: x.type,
      amount: x.amount,
    };
    return data;
  }) : [];

  const getCustomersList = !isEmpty(props.customerDataList) ? props.customerDataList.map((x) => {
    let data = {
      value: x._id,
      label: `${x.name}`,
    };
    return data;
  }) : [];

  const getOrderTypesList = !isEmpty(props.orderTypesList) ? props.orderTypesList.map((x) => {
    let data = {
      value: x.order_type_id,
      label: x.order_type_name
    };
    return data;
  }) : [];

  const getTables_List = !isEmpty(props.tablesList) ? props.tablesList.map((x) => {
    let data = {
      value: x.restaurant_table_id,
      label: x.restaurant_table_name,
    };
    return data;
  }) : [];

  const getVariationList = !isEmpty(props.productTypeListById) ? props.productTypeListById[0].variation.map((x) => {
    let data = {
      value: x._id,
      label: `${x.colourLabel}-${x.sizeLabel}`,
      price: x.sellingPrice,
      isRecipe: x.isRecipe,
      productBalance: x.productBalance,
    };
    return data;
  }) : [];

  let getcategoryList = !isEmpty(props.categoryList.data) && props.categoryList.data.map((x) => {
    let data = { value: x._id, label: x.name };
    return data;
  });
  const handleChangeCategory = (selectedOption) => {
    setcategoryId1(selectedOption.value);
    setcategoryId(selectedOption.label);
    selectedOption.value == "All" ? props.getProduct() : props.serchByMenu(selectedOption.value);
  };
  const handleClickCategory = (item) => {
    setCardId(item.value)
    setCardName(item.label)
    // setcategoryId1(selectedOption.value);
    // setcategoryId(selectedOption.label);
    item.value == "All" ? props.getProduct() : props.serchByMenu(item.value);
  };

  const ProductTypeList = !isEmpty(ProductTypeData) && ProductTypeData.map((x) => {
    let data = { value: x.orderTypeId, label: x.orderTypeName };
    return data;
  });
  const handleChangeOrderType = (selectedOption) => {
    setOrderTypeName(selectedOption.label);
    setOrderTypeId(selectedOption.value);
  };
  !isEmpty(props.categoryList.data) && getcategoryList.unshift({ value: "All", label: "ALL" });
  let ProductTypeData = [
    {
      orderTypeId: 2,
      orderTypeName: "Take Away",
    },
    {
      orderTypeId: 3,
      orderTypeName: "Delivery",
    },
    {
      orderTypeId: 4,
      orderTypeName: "Drive Throw",
    },
  ];

  const WaiterList = !isEmpty(filterdUserList) && filterdUserList.map((x) => {
    let data = { value: x._id, label: x.userName };
    return data;
  });
  const handleChangeWaiter = (selectedOption) => {
    setWaiterName(selectedOption.label);
    setWaiterId(selectedOption.value);
  };

  const filterdUserList = !isEmpty(props.userList) && props.userList.filter(e => e.role == "waiter")

  const barcod = (id) => {
    let i = find(props.productSearchList, { barcodeId: id });
    isEmpty(i) ? handleDangerVisibleProduct() : variationData(i)
  }
  useScanDetection(Checkpermissions("permissionsCatName", "Enable Barcode") && { onComplete: barcod });


  const handleShow1 = () => setShow1(true);
  const handleClose1 = () => {
    setShow1(false)
    setCard(0);
    setCash(0);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCloseComment = () => setCommentModalModal(false);
  const handleShowComment = (item) => {
    setDumyItem(item)
    setCommentModalModal(true)
  };

  const handleShowCoupon = () => setCouponModal(true);
  const handleCloseCoupon = () => setCouponModal(false);
  const handleClickCoupon = (selectedOption) => {
    setSubTotalAmount(totalAmount);
    setCoupon(selectedOption.value);
    setCouponLabel(selectedOption.label);
    setAmount(selectedOption.amount);
    setCouponType(selectedOption.type);
    let dis = selectedOption.type == "fixed" ? selectedOption.amount : totalAmount * selectedOption.amount * 0.01;
    setCouponDiscount(selectedOption.type == "fixed" ? selectedOption.amount : totalAmount * selectedOption.amount * 0.01);
    setSubTotalAmount(totalAmount - dis);
    handleCloseCoupon()
  };
  const handleDeleteCoupon = () => {
    setCoupon("");
    setCouponLabel("");
    setCouponDiscount(0)
    setSubTotalAmount(totalAmount);
  };

  const handleShowCustomer = () => setCustomerModal(true);
  const handleCloseCustomer = () => setCustomerModal(false);
  const handleDelete = () => {
    setCustomer("");
    setCustomerLabel("");
  };

  const handleCloseOrderComment = () => setOrderCommentModal(false);
  const handleShowOrderComment = (item) => {
    setOrderCommentModal(true)
  };

  const navigate = useNavigate();
  const getdata = () => {
    props.getPendingOrder();
  };
  function openPaymentMethodModal() {
    handleShow1();
  }

  const handleClick = (selectedOption) => {
    setCustomer(selectedOption.value);
    setCustomerLabel(selectedOption.label);
    // setAmount(selectedOption.amount);
    handleCloseCustomer()
  };
  // const variationData = (item) => {
  //   const list = [...inputField];
  //   setAvailableQuantity(item.productBalance);
  //   setProductId(item._id);
  //   setProductLabel(item.name);
  //   let damy = { price: item.salePrice, label: item.name, value: item._id, Pquantity: item.productBalance };
  //   insertItem(damy);
  // };
  const variationData = (item) => {
    const list = [...inputField];
    setAvailableQuantity(item.productBalance);
    setProductId(item._id);
    setProductLabel(item.name);
    if (item.productTypeName == 'Simple Product') {
      let damy = { price: item.salePrice, label: item.name, value: item._id, Pquantity: item.productBalance };
      insertItem(damy);
    } else {
      handleShow();
      setProductId(item._id);
      setProductLabel(item.name);
      props.getProductVariation(item._id);
    }
  };
  const handleRemoveInput = (id) => {
    const list = [...inputField];
    let index = list.findIndex((item) => item.productVariationId === id);
    list.splice(index, 1);
    setTotalAmount(sumBy(list, "productTotalAmount"));
    if (couponDiscount == 0) {
      setSubTotalAmount(sumBy(list, "productTotalAmount"))
    }
    else {
      let dis = couponType == "fixed" ? amount : sumBy(list, "productTotalAmount") * amount * 0.01;
      setCouponDiscount(dis)
      setSubTotalAmount(sumBy(list, "productTotalAmount") - dis)
    }
    setInputField(list);
  };
  const changeItemQuanity = (item, symbol) => {
    // setAvailableQuantity(item.productBalance);
    const list = [...inputField];
    let index = list.findIndex(
      (itemInv) => itemInv.productVariationId === item.productVariationId
    );
    if (symbol == 1) {
      if (checkQuantity(item) == true) {
        list[index]["quantity"] = Number(list[index]["quantity"]) + 1;
        list[index]["productTotalAmount"] = Number(list[index]["productSellingPrice"]) * Number(list[index]["quantity"]);
      } else {
        setInputField(list)
      }
    } else {
      list[index]["quantity"] = Number(list[index]["quantity"]) == 1 ? Number(list[index]["quantity"]) : Number(list[index]["quantity"]) - 1;
      list[index]["productTotalAmount"] = Number(list[index]["quantity"]) == 1 ? Number(list[index]["productSellingPrice"]) * 1 : Number(list[index]["productSellingPrice"]) * Number(list[index]["quantity"]);
    }
    setTotalAmount(sumBy(list, "productTotalAmount"));
    if (couponDiscount == 0) {
      setSubTotalAmount(sumBy(list, "productTotalAmount"))
    } else {
      let dis = couponType == "fixed" ? amount : sumBy(list, "productTotalAmount") * amount * 0.01;
      setCouponDiscount(dis)
      setSubTotalAmount(sumBy(list, "productTotalAmount") - dis)
    }
    setInputField(list);
  };
  const changeComment = (e) => {
    const list = [...inputField];
    let i = find(list, { product: dumyitem.product })
    i["comment"] = e
    setInputField(list);
  };
  const insertItem = (item, index) => {
    console.log(item, "yyyyyyyyyyyyyyyyy")
    const list = [...inputField];
    console.log(list, "lllllllllllllllllllll")
    if (isEmpty(find(list, { productVariationId: item.value }))) {
      let dummyItem = {};
      dummyItem["comment"] = ""
      dummyItem["product"] = productId == "" ? item.value : productId;
      dummyItem["productLabel"] = productLabel == "" ? item.label : productLabel;
      dummyItem["productVariationId"] = item.value;
      dummyItem["productVariationLabel"] = item.label;
      dummyItem["quantity"] = 1;
      dummyItem["Pquantity"] = item.Pquantity;
      dummyItem["productSellingPrice"] = Number(item.price);
      dummyItem["productTotalAmount"] = 1 * Number(item.price);
      let listData = [...list, dummyItem];
      setTotalAmount(sumBy(listData, "productTotalAmount"));

      if (couponDiscount == 0) {
        setSubTotalAmount(sumBy(listData, "productTotalAmount"))
      } else {
        let dis = couponType == "fixed" ? amount : sumBy(listData, "productTotalAmount") * amount * 0.01;
        setCouponDiscount(dis)
        setSubTotalAmount(sumBy(listData, "productTotalAmount") - dis)
      }
      setInputField(listData)
      handleClose();
      setProductId("");
      setProductLabel("");
    } else {
      let index = list.findIndex((itemInv) => itemInv.productVariationId === item.value);
      let index1 = list.length;
      if (checkQuantity(list[index]) == true) {
        list[index]["comment"] = ""
        list[index]["quantity"] = Number(list[index]["quantity"]) + 1;
        list[index]["productTotalAmount"] = Number(list[index]["productSellingPrice"]) * Number(list[index]["quantity"]);
        setTotalAmount(sumBy(list, "productTotalAmount"));
        if (couponDiscount == 0) {
          setSubTotalAmount(sumBy(list, "productTotalAmount"))
        } else {
          let dis = couponType == "fixed" ? amount : sumBy(list, "productTotalAmount") * amount * 0.01;
          setCouponDiscount(dis)
          setSubTotalAmount(sumBy(list, "productTotalAmount") - dis)
        }
        handleClose();
        setProductId("");
        setProductLabel("");
        setInputField(list);
      } else {
        setInputField(list)
      }
    }
  };
  const handlePaymentType = (type) => {
    if (props.restaurantList[0].taxType == "FBRTax") {
      if (type == "cash") {
        setCash(1);
        setCard(0);
        setTax(props.restaurantList[0].fbrTaxCash);
        let taxCash = (Number(SubTotalAmount) * Number(props.restaurantList[0].fbrTaxCash)) / 100;
        setTaxAmount(taxCash);
        setAfterTaxTotalAmount(Number(taxCash) + Number(SubTotalAmount));
      } else {
        setCard(1);
        setCash(0);
        setTax(props.restaurantList[0].fbrTaxCard);
        let taxCard = (Number(SubTotalAmount) * Number(props.restaurantList[0].fbrTaxCard)) / 100;
        setTaxAmount(taxCard);
        setAfterTaxTotalAmount(Number(taxCard) + Number(SubTotalAmount));
      }
    } else {
      if (type == "cash") {
        setCash(1);
        setCard(0);
        setTax(props.restaurantList[0].praTaxCash);
        let taxCash = (Number(SubTotalAmount) * Number(props.restaurantList[0].praTaxCash)) / 100;
        setTaxAmount(taxCash);
        setAfterTaxTotalAmount(taxCash + SubTotalAmount);
      } else {
        setCard(1);
        setCash(0);
        setTax(props.restaurantList[0].praTaxCard);
        let taxCard = (Number(SubTotalAmount) * Number(props.restaurantList[0].praTaxCard)) / 100;
        setTaxAmount(taxCard);
        setAfterTaxTotalAmount(taxCard + SubTotalAmount);
      }
    }
  };
  const checkQuantity = (item) => {
    let dumyAvailableQuantity = item.Pquantity == 0 ? 2 : Number(item.Pquantity)
    if (item.product == item.productVariationId && Number(item.quantity) >= dumyAvailableQuantity) {
      handleDangerVisibleQuantity()
      return false;
    } else {
      return true;
    }
  }


  useEffect(() => {
    props.getUser()
    props.getResturant();
    props.getCoupons();
    props.getCustomer();
    props.getProduct();
    // props.getCatagoery();
  }, []);

  return (
    <>
      <div >
        <Navbar style={{ backgroundColor: '#81abd3' }}  >
          <Container>
            <Navbar.Brand style={{ color: 'white' }}><b>Sales Order</b></Navbar.Brand>
            <IconMenu />
          </Container>
        </Navbar>
      </div>
      {/* for loading */}
      {props.isFetchingServiceAndHybridProductList || props.isFetchingUser || props.isFetchingCouponsData || props.isFetchingCustomer || props.isAddingOrders ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {props.isAddingOrders ? "Saving..." : "Loading..."}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div style={{ padding: '4px', width: '100%', overflow: 'auto', whiteSpace: 'nowrap', }}>
            {!isEmpty(getcategoryList) ? getcategoryList.map((item, i) => {
              return (
                <React.Fragment key={i}>
                  <button style={{
                    display: 'inline-block',
                    backgroundColor: '#81abd3',
                    textAlign: 'center',
                    padding: '14px',
                    marginRight: '10px',
                    textDecoration: 'none',
                    cursor: 'pointer',
                    borderStyle: 'none'
                  }}
                    onClick={(e) => handleClickCategory(item)}
                  ><b>{item.label}</b></button>
                </React.Fragment>
              );
            })
              : []}
          </div>

          {/* mian Code */}
          <Row style={{ overflowX: 'hidden', marginTop: '10px' }}>

            {/* PRODUCTS DISPLAY SIDE */}
            <Col xl="7" lg="7" md="6" sm="6" style={{ borderRightStyle: "solid", borderRightColor: "#e9ecef" }} >
              <Row style={{ marginBottom: '10px' }}>
                <Col>
                  <Form.Group>
                    <Form.Label>
                      <b>Search Items</b>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Search Items"
                      name="receivedBy"
                      onChange={(e) => props.searchProduct(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>
                      <b>Customer Name</b>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Customer Name"
                      name="receivedBy"
                      onChange={(e) => setCustomerName(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row style={{ marginBottom: '10px' }}>
                <Col >
                  <Form.Group>
                    <Form.Label>
                      <b>Customer NTN</b>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Customer NTN"
                      name="receivedBy"
                      onChange={(e) => setCustomerNTN(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>
                      <b>Customer Address</b>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Customer Address"
                      name="receivedBy"
                      onChange={(e) => setCustomerAddress(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>
                      <b>Customer Phone</b>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Customer Phone"
                      name="receivedBy"
                      onChange={(e) => setCustomerPhone(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              {cardName != "" &&
                <Row>
                  <Col>
                    <Card style={{ height: '50px', backgroundColor: '#81abd3', marginBottom: '5px' }}>
                      <CardActionArea >
                        <CardContent>
                          <Typography gutterBottom variant="body3" component="div" style={{ fontSize: '12px' }} >
                            <p style={{ textAlign: 'center', fontSize: '16px' }}> <b>{cardName}</b></p>
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Col>
                </Row>}

              <Row style={{ height: props.productSearchList.length > 23 ? '80vh' : 'auto', overflow: "auto" }} className="overflow-auto" >
                {/* <p style={{textAlign:'center'}}><b>{cardName}</b></p> */}
                {props.productSearchList.map((item, i) => {
                  return (
                    <React.Fragment key={i}>
                      <Col xl="3" lg="3" md="6" sm="6" style={{ marginBottom: 20 }} >
                        <Card style={{ backgroundColor: '#c1c1c1' }} sx={{ opacity: item.productBalance <= 0 && item.productTypeName == 'Simple Product' ? 0.5 : 1, pointerEvents: item.productBalance <= 0 && item.productTypeName == 'Simple Product' ? "none" : "auto" }} onClick={(e) => variationData(item)} >
                          <CardActionArea >
                            {/* <CardMedia component="img" height="120px" width="100px" image={`${baseURL}/assets/${item.images}`} alt="Product" /> */}
                            <CardContent>
                              {!isEmpty(props.productSearchList) && (
                                <Typography gutterBottom variant="body3" component="div" style={{ fontSize: '14px', marginBottom: '3px' }}>
                                  <b> {item.name} ({item.salePrice})</b>
                                </Typography>
                              )}
                            </CardContent>
                          </CardActionArea>
                        </Card>
                      </Col>
                    </React.Fragment>
                  )
                })}
              </Row>
            </Col>


            {/* ORDER DETAIL COLOUMN */}
            <Col xl="5" lg="5" md="6" sm="6">
              <div>
                {/* coupon */}
                {Checkpermissions("permissionsCatName", "Assign Coupan") &&
                  <Row>
                    <Col xl="4" lg="4" md="4" sm="6">
                      <Form.Group className="mb-1 pt-2">
                        <Form.Label>
                          <b>Coupons</b>
                        </Form.Label>
                      </Form.Group>
                    </Col>
                    <Col xl="1" lg="2" md="2" sm="4">
                      <AddCircleOutlineIcon
                        style={{ marginTop: "9px", cursor: "pointer" }}
                        onClick={handleShowCoupon}
                      ></AddCircleOutlineIcon>
                    </Col>
                    {couponLabel == "" ? ("") : (
                      <Col xl="4" lg="6" md="6" sm="12">
                        <Chip
                          color="primary"
                          label={couponLabel}
                          onDelete={handleDeleteCoupon}
                          style={{ marginTop: "5px", marginLeft: "-10px" }}
                        ></Chip>
                      </Col>
                    )}
                  </Row>}
                {/* customer */}
                <Row>
                  <Col xl="4" lg="4" md="4" sm="6">
                    <Form.Group className="mb-1 pt-2">
                      <Form.Label>
                        <b>Customer</b>
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col xl="1" lg="2" md="2" sm="4">
                    <AddCircleOutlineIcon
                      style={{ marginTop: "9px", cursor: "pointer" }}
                      onClick={handleShowCustomer}
                    ></AddCircleOutlineIcon>
                  </Col>
                  {customerLabel == "" ? ("") : (
                    <Col xl="4" lg="6" md="6" sm="12">
                      <Chip
                        color="primary"
                        label={customerLabel}
                        onDelete={handleDelete}
                      ></Chip>
                    </Col>
                  )}
                </Row>
              </div>

              <Row>
                <Col xl="1" lg="1" md="2" sm="6">
                  <h6><b>Sr </b> </h6>
                </Col>
                <Col xl="5" lg="5" md="5" sm="6">
                  <h6> <b>Product</b></h6>
                </Col>
                <Col xl="3" lg="3" md="3" sm="6" style={{ marginLeft: '3%' }}>
                  <h6><b>Qty</b> </h6>
                </Col>
                <Col xl="3" lg="3" md="2" sm="6" style={{ marginLeft: '-5%' }}>
                  <h6><b>Total</b></h6>
                </Col>
              </Row>
              {/* ITEM ADD TO CART AREA */}
              <Row style={{ height: '355px', maxHeight: '355px', overflowY: "auto", overflowX: "hidden", marginRight: '-30px' }}>
                <Col xl="12" lg="12" md="12" sm="12" >
                  {!isEmpty(inputField) && inputField.map((item, i) => {
                    return (
                      <Row>
                        <Col xl="1" lg="1" md="1" sm="3">
                          <Form.Group className="mb-1 pt-2">
                            <Form.Control
                              type="text"
                              disabled
                              style={{ fontSize: 11, padding: '3px', backgroundColor: '#81abd3', fontWeight: 'bold' }}
                              placeholder={`${i + 1}`}
                              name="receivedBy"
                              onChange={(e) => props.filterItem(e.target.value)}
                            />
                          </Form.Group>
                        </Col>
                        <Col xl="5" lg="5" md="5" sm="9" >
                          <Form.Group className="mb-1 pt-2">
                            <Form.Control
                              type="text"
                              disabled
                              style={{ fontSize: 11, padding: '3px' }}
                              placeholder={`${item.productLabel}--(${item.productVariationLabel})`}
                              name="receivedBy"
                              onChange={(e) => props.filterItem(e.target.value)}
                            />
                          </Form.Group>
                        </Col>
                        <Col xl="3" lg="3" md="3" sm="6" >
                          <InputGroup className="mb-1 pt-2 pt-2">
                            <RemoveCircleOutlineOutlinedIcon
                              style={{ marginTop: "8px", cursor: "pointer" }}
                              onClick={() => changeItemQuanity(item, 0)}
                            ></RemoveCircleOutlineOutlinedIcon>
                            <Form.Control
                              value={item.quantity}
                              aria-label="Product Name"
                              disabled
                              style={{ fontSize: 12, padding: '3px' }}
                            />
                            <AddCircleOutlineIcon
                              style={{ marginTop: "8px", cursor: "pointer" }}
                              onClick={() => changeItemQuanity(item, 1)}
                            ></AddCircleOutlineIcon>
                          </InputGroup>
                        </Col>
                        <Col xl="3" lg="3" md="3" sm="6" style={{ marginLeft: '-10px' }}>
                          <InputGroup className="mb-1 pt-2">
                            <Form.Control
                              placeholder={item.productTotalAmount}
                              aria-label="Total Amount"
                              disabled
                              style={{ fontSize: 11, padding: '1px', }}
                            />
                            <RemoveCircleOutlineOutlinedIcon
                              style={{ cursor: "pointer" }}
                              onClick={() => handleRemoveInput(item.productVariationId)}
                            />
                            {/* <ChatBubbleOutlineIcon
                              style={{ cursor: "pointer" }}
                              onClick={() => handleShowComment(item)}
                            /> */}
                          </InputGroup>
                        </Col>
                      </Row>
                    );
                  })}
                </Col>
              </Row>

              <Row style={{ marginTop: '10px', paddingBottom: '10px' }}>
                {/* <Col xl="4" lg="4" md="4" sm="4">
                  <Form.Group>
                    <Form.Control
                      type="text"
                      placeholder='Description'
                      name="receivedBy"
                      onChange={(e) => setOtherDesc(e.target.value)}
                    />
                  </Form.Group>
                </Col> */}
                <Col xl="4" lg="4" md="4" sm="4">
                  <Form.Group>
                    <Form.Control
                      type="Number"
                      placeholder="Other Amount"
                      name="receivedBy"
                      min={0}
                      onChange={(e) => setOtherAmount(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>


              {/* BILL TOTAL AREA */}
              <Row style={{ marginTop: '10px', paddingBottom: '10px', backgroundColor: '#81abd3' }}>
                <Col xl="4" lg="4" md="4" sm="12">
                  <Form.Group>
                    <Form.Label>
                      <b>Sub Total</b>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={totalAmount}
                      disabled
                      name="receivedBy"
                    // onChange={(e) => props.filterItem(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col xl="4" lg="4" md="4" sm="12">
                  <Form.Group>
                    <Form.Label>
                      <b>Discount</b>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={couponLabel == "" ? 0 : (totalAmount - SubTotalAmount).toFixed(2)}
                      disabled
                      name="receivedBy"
                    // onChange={(e) => props.filterItem(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col xl="4" lg="4" md="4" sm="12">
                  <Form.Group>
                    <Form.Label>
                      <b>Grand Total</b>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      // placeholder={couponLabel == "" ? totalAmount : SubTotalAmount}
                      placeholder={couponLabel == "" ? Number(totalAmount) + Number(otherAmount) : Number(SubTotalAmount) + Number(otherAmount)}
                      disabled
                      name="receivedBy"
                    // onChange={(e) => props.filterItem(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>


              {/* SAVE AND PRINT BUTTONS */}
              <Row>
                {/* <Col>
                  <Button
                    style={{ marginTop: '20px' }}
                    variant="contained"
                    endIcon={<ChatBubbleOutlineIcon />}
                    size="large"
                    onClick={() => handleShowOrderComment()}
                  >
                  </Button>
                </Col> */}
                <Col>
                  <Button
                    style={{ marginTop: '20px' }}
                    variant="contained"
                    endIcon={<SendIcon />}
                    size="large"
                    onClick={() => SubTotalAmount < 0 ? handleDangerVisibleCopon() : uploadOrder(0)}
                    disabled={isEmpty(inputField) ? true : false}
                  >
                    SAVE
                  </Button>
                </Col>
                <Col>
                  <Button
                    style={{ marginTop: '20px' }}
                    variant="contained"
                    endIcon={<PrintIcon />}
                    size="large"
                    disabled={isEmpty(inputField) ? true : false}
                    onClick={() => SubTotalAmount < 0 ? handleDangerVisibleCopon() : uploadOrder(1)}
                  >
                    SAVE & PRINT
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>


          {/* PRODUCT VARIATON MODAL */}
          <Modal show={show} onHide={handleClose} animation={false}>
            <Modal.Header closeButton>
              <Modal.Title>Select Variation</Modal.Title>
            </Modal.Header>
            {props.isFetchingProductTypeById ? (
              <div className="loader-div " style={{ margin: "10%", marginLeft: "40%" }} >
                <Button variant="info" disabled>
                  <Spinner
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    variant="info"
                  />
                  Loading
                  {/* {props.isAddingOrders ? "Saving..." : "Loading..."} */}
                </Button>
              </div>
            ) : (
              <Modal.Body>
                {" "}
                {getVariationList.map((x, i) => {
                  return (
                    <React.Fragment key={i}>
                      <Card sx={{ opacity: x.productBalance <= 0 ? 0.5 : 1, pointerEvents: x.productBalance <= 0 ? "none" : "auto" }}
                        style={{
                          marginLeft: "1%",
                          marginRight: "1%",
                          marginBottom: "2%",
                          backgroundColor: "#1976d2",
                        }}
                        onClick={(e) => insertItem(x, i)}
                      >
                        <CardActionArea>
                          <CardContent >
                            {!isEmpty(getVariationList) && (
                              <Typography gutterBottom variant="body3" mb="5%" component="div" >
                                <b style={{ color: "white" }}>
                                  <span style={{ float: "left" }} >{x.label}</span>
                                  <span style={{ float: "right" }} >{x.price}</span>
                                </b>
                              </Typography>
                            )}
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    </React.Fragment>
                  )
                })}
              </Modal.Body>
            )}
          </Modal>
          {/* modal for cash or card payment */}
          <Modal show={show1} onHide={handleClose1} animation={false}>
            <Modal.Header closeButton>
              <Modal.Title>Select Payment Method</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col xl="4" lg="4" md="4" sm="4">
                  {" "}
                </Col>
                <Col xl="2" lg="2" md="2" sm="2">
                  {" "}
                  <Chip
                    color="primary"
                    label={"Cash"}
                    onClick={(e) => handlePaymentType("cash")}
                  ></Chip>
                </Col>
                <Col xl="2" lg="2" md="2" sm="2">
                  {" "}
                  <Chip
                    color="primary"
                    label={"Card"}
                    onClick={(e) => handlePaymentType("card")}
                  ></Chip>
                </Col>
                <Col xl="4" lg="4" md="4" sm="4">
                  {" "}
                </Col>
              </Row>
              <Row>
                <Form.Label>
                  <span style={{ float: "left" }}>Total Amount</span>
                  <span style={{ float: "right" }}>{totalAmount}</span>
                </Form.Label>
              </Row>
              <Row>
                <Form.Label>
                  <span style={{ float: "left" }}>Coupon:({couponLabel})</span>
                  <span style={{ float: "right" }}>{couponDiscount}</span>
                </Form.Label>
              </Row>
              <Row>
                ----------------------------------------------------------------------------
              </Row>
              <Row>
                <Form.Label>
                  {/* <span style={{float:'left'}}>D</span> */}
                  <span style={{ float: "right" }}>{SubTotalAmount}</span>
                </Form.Label>
              </Row>
              <Row>
                <Form.Label>
                  <span style={{ float: "left" }}> Tax({taxLabel}%) on{" "}{cash == 0 && card == 0 ? "" : cash == 1 ? "Cash" : card == 1 ? "Card" : ""}</span>
                  <span style={{ float: "right" }}>{taxAmount}</span>
                </Form.Label>
              </Row>
              <Row>
                ----------------------------------------------------------------------------
              </Row>
              <Row>
                <Form.Label>
                  <span style={{ float: "left" }}> Total Amount After Tax </span>
                  <span style={{ float: "right" }}> {afterTaxTotalAmount}</span>
                </Form.Label>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                onClick={() => uploadOrder(0)}
                disabled={cash == 1 || card == 1 ? false : true}
              >
                Save
              </Button>
              <Button
                variant="primary"
                onClick={() => uploadOrder(1)}
                disabled={cash == 1 || card == 1 ? false : true}
              >
                Save & Print
              </Button>
            </Modal.Footer>
          </Modal>
          {/* coupon modal */}
          <Modal show={couponModal} onHide={handleCloseCoupon} animation={false}>
            <Modal.Header closeButton>
              <Modal.Title>Select Coupon</Modal.Title>
            </Modal.Header>
            {props.isFetchingCouponsData ? (
              <div
                className="loader-div "
                style={{ margin: "10%", marginLeft: "40%" }}
              >
                <Button variant="info" disabled>
                  <Spinner
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    variant="info"
                  />
                  Loading
                  {/* {props.isAddingOrders ? "Saving..." : "Loading..."} */}
                </Button>
              </div>
            ) : (
              <Modal.Body>
                {/* <Col xl="4" lg="4" md="4" sm="12">
                  <Form.Group>
                    <Form.Control
                      type="number"
                      placeholder='Amount'
                      name="receivedBy"
                      // value={setOtherAmount}
                      onChange={(e) => handleClickCoupon({
                        value: "6664a1f36239779ee2f688bb",
                        label: "TEST",
                        type: "fixed",
                        amount: e.target.value
                      })}
                    />
                  </Form.Group>
                </Col> */}

                {" "}
                {getDiscountList.map((x, i) => {
                  return (
                    <React.Fragment key={i}>
                      <Chip
                        style={{ margin: "3px" }}
                        label={x.label}
                        color="primary"
                        onClick={(e) => handleClickCoupon(x)}
                      // onDelete={handleDelete}
                      />
                    </React.Fragment>
                  );
                })}
              </Modal.Body>
            )}
          </Modal>
          {/* customer modal */}
          <Modal show={customerModal} onHide={handleCloseCustomer} animation={false}>
            <Modal.Header closeButton>
              <Modal.Title>Select Customer</Modal.Title>
            </Modal.Header>
            {props.isFetchingCustomer ? (
              <div
                className="loader-div "
                style={{ margin: "10%", marginLeft: "40%" }}
              >
                <Button variant="info" disabled>
                  <Spinner
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    variant="info"
                  />
                  Loading
                  {/* {props.isAddingOrders ? "Saving..." : "Loading..."} */}
                </Button>
              </div>
            ) : (
              <Modal.Body>
                {" "}
                {getCustomersList.map((x, i) => {
                  //     console.log(x, i, "llllllllll");
                  return (
                    <React.Fragment key={i}>
                      <Chip
                        style={{ margin: "3px" }}
                        color="primary"
                        label={x.label}
                        onClick={(e) => handleClick(x)} //(e) => handleChangeDiscount(e)
                      // onDelete={handleDelete}
                      />
                    </React.Fragment>
                  );
                })}
              </Modal.Body>
            )}
          </Modal>
          {/* Comment Modal */}
          <Modal show={CommentModal} onHide={handleCloseComment} animation={false}>
            <Modal.Header closeButton>
              <Modal.Title>Add Comment</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <Row>
                <Col lg="12" md="12" xs="12">
                  <Form.Group className="mb-3" controlId="garana">
                    <Form.Label>
                      <b>Comment </b>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      // value={inputField}
                      placeholder="Comment"
                      onChange={(e) => changeComment(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg="9" md="9" xs="9"></Col>
                <Col lg="3" md="3" xs="2">
                  <Button
                    //onClick={uploadCompleteProduct}>
                    // disabled={reason == "" ? true : false}
                    onClick={() => {
                      // props.orderCancel(idEdit,{
                      //   orderCancelReason:reason
                      // });
                      handleCloseComment()
                    }}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Modal.Body>
          </Modal>
          {/* order commet modal */}
          <Modal show={orderCommentModal} onHide={handleCloseOrderComment} animation={false}>
            <Modal.Header closeButton>
              <Modal.Title>Add Order Comment</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <Row>
                <Col lg="12" md="12" xs="12">
                  <Form.Group className="mb-3" controlId="garana">
                    <Form.Label>
                      <b>Comment </b>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={orderComment}
                      placeholder="Comment"
                      onChange={(e) => setOrderComment(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg="9" md="9" xs="9"></Col>
                <Col lg="3" md="3" xs="2">
                  <Button
                    //onClick={uploadCompleteProduct}>
                    // disabled={reason == "" ? true : false}
                    onClick={() => {
                      // props.orderCancel(idEdit,{
                      //   orderCancelReason:reason
                      // });
                      handleCloseOrderComment()
                    }}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Modal.Body>
          </Modal>
          {/* </div> */}
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getUser: () => dispatch(getUserRes()),
    getResturant: () => dispatch(getRestaurantData()),
    getProduct: () => dispatch(getProductData()),
    getCatagoery: () => dispatch(getCategoryData()),
    getCoupons: () => dispatch(getCoupons()),
    getPendingOrder: () => dispatch(getPendingOrder()),
    addOrders: (data, handleVisible, handleDangerVisible) => dispatch(AddOrders(data, handleVisible, handleDangerVisible)),
    deleteOrder: (oldData) => dispatch(deleteOrders(oldData)),
    getOrder: () => dispatch(getOrders()),
    getOrderById: (orderId) => dispatch(GetOrderById(orderId)),
    updateOrder: (oldData, newData) => dispatch(updateOrders(oldData, newData)),
    getCustomer: () => dispatch(getCustomerData()),
    getProductVariation: (id) => dispatch(getProductTypeById(id)),
    getOrderTypes: () => dispatch(getOrderTypes()),
    getTables: () => dispatch(getTables()),
    updateStatus: (data, status) => dispatch(updateStatus(data, status)),
    searchProduct: (data) => dispatch(filterItem(data)),
    serchByMenu: (id) => dispatch(filterItemByMenu(id)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  productDataList: state.ProductReducer.productDataList,
  isFetchingUser: state.UserReducer.isFetchingUser,
  userList: state.UserReducer.userList,
  customersList: state.POSViewReducer.customersList,
  customerDataList: state.CustomerReducer.customerDataList,
  isFetchingCustomer: state.CustomerReducer.isFetchingCustomer,
  isFetchingOrderTypes: state.POSViewReducer.isFetchingOrderTypes,
  orderTypesList: state.POSViewReducer.orderTypesList,
  isFetchingTables: state.POSViewReducer.isFetchingTables,
  OrderDataByID: state.POSViewReducer.OrderDataByID,
  tablesList: state.POSViewReducer.tablesList,
  categoryList: state.CategoryReducer.categoryList,
  couponsDataList: state.CouponReducer.couponsDataList,
  isFetchingCouponsData: state.CouponReducer.isFetchingCouponsData,
  serviceAndHybridProductList: state.RecipeReducer.serviceAndHybridProductList,
  isFetchingServiceAndHybridProductList: state.ProductReducer.isFetchingServiceAndHybridProductList,
  productTypeListById: state.RecipeReducer.productTypeListById,
  isFetchingProductTypeById: state.RecipeReducer.isFetchingProductTypeById,
  seatSelected: state.POSViewReducer.seatSelected,
  isAddingOrders: state.POSViewReducer.isAddingOrders,
  productSearchList: state.ProductReducer.productSearchList,
  restaurantList: state.ResturantReducer.restaurantDataList,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddBillingPos);
