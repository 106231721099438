import React, { useEffect, useRef } from "react";
import { Container, Button } from "react-bootstrap";
import { connect } from "react-redux";
// import _ from "lodash";
import Spinner from 'react-bootstrap/Spinner';
import MaterialTable from 'material-table';
import { UpdateshiftControlApi, DeleteshiftControlApi } from "./shiftControlAction";
import { setHeading } from "../../../accounts/account.actions";
import { baseURL, headers } from "../../../../../global/api";


const ViewShiftControl = (props) => {
    const tableRef = useRef();
    function updateTableQuery() {
        tableRef.current.onQueryChange()
    };

    useEffect(() => {
        props.setHeading("Shift Control List")
    }, []);
    return (
        <>
            {(props.isFetchingShiftControlData ) ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />
                    </Button>
                </div>
            ) : (
                <Container fluid>
                    <MaterialTable
                        title="Shift Details"
                        columns={[
                            { title: 'Shift Name', field: 'shiftName' },
                            { title: 'Shift Mangement', field: 'shiftMangement', render: rowData => (rowData.shiftMangement === 1 ? "Two Time" : rowData.shiftMangement === 2 ? "Four Time" : "") },
                            { title: 'Arrival In', field: 'arrivalIn' },
                            { title: 'Break Out', field: 'breakOut' },
                            { title: 'Break In', field: 'breakIn' },
                            { title: 'Departure', field: 'departure' },
                            { title: 'Allowed Late Arrival In', field: 'allowLateArrivalIn' },
                            { title: 'Allowed Early Break Out', field: 'allowEarlyBreakOut' },
                            { title: 'Allowed Early Break In', field: 'allowEarlyBreakIn' },
                            { title: 'Allowed Late Departure', field: 'allowLateDeparture' },
                            { title: 'Jummah Arrival In', field: 'jummahArrivelIn' },
                            { title: 'Jummah Break Out', field: 'breakJummahOut' },
                            { title: 'Jummah Break In', field: 'breakJummahIn' },
                            { title: 'Jummah Departure', field: 'jummahDeparture' },
                            { title: 'Jummah Allowed Late Arrival In', field: 'jummahAllowLateArrivalIn' },
                            { title: 'Jummah Allowed Early Break Out', field: 'jummahAllowEarlyBreakOut' },
                            { title: 'Jummah Allowed Early Break In', field: 'jummahAllowEarlyBreakIn' },
                            { title: 'Jummah Allowed Late Departure', field: 'jummahAllowLateDeparture' },
                        ]}
                        data={(query) =>
                            new Promise((resolve, reject) => {
                                let url = `${baseURL}/shiftControl?`;
                                url += "limit=" + query.pageSize;
                                url += "&page=" + query.page;
                                url += "&search=" + query.search;
                                fetch(url, {
                                    method: "GET",
                                    headers: headers,
                                }).then((response) => response.json()).then((result) => {
                                    resolve({
                                        data: result.data,
                                        page: result.page,
                                        totalCount: result.total_results,
                                    });
                                });
                            })
                        }
                        options={{
                            actionsColumnIndex: -1,
                            filtering: false,
                            exportButton: true,
                            exportAllData: true,
                            sorting: true,
                            paging: true,
                            pageSize: 50,
                            emptyRowsWhenPaging: false,
                            pageSizeOptions: [50, 100, 150, 200, 500],
                            headerStyle: {
                                position: 'sticky', top: 0,
                                color: '#00BBBB',
                                fontWeight: '550',
                                onRowAdd: 'none',
                            },
                        }}
                        editable={{
                            // isEditable: rowData => false,
                            // isEditHidden: rowData => true,
                            onRowUpdate: (newData, oldData) =>
                                new Promise((resolve) => {
                                    setTimeout(() => {
                                        resolve();
                                        props.UpdateshiftControlApi(newData, oldData, updateTableQuery)
                                    }, 600);
                                }),

                            isDeletable: rowData => false,
                            // isDeleteHidden: rowData => true,
                            onRowDelete: (oldData) =>
                                new Promise((resolve) => {
                                    setTimeout(() => {
                                        resolve();
                                        props.DeleteshiftControlApi(oldData._id, updateTableQuery);
                                    }, 600);
                                }),
                        }}
                    />
                </Container>

            )}
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        DeleteshiftControlApi: (shift_control_id, updateTableQuery) => dispatch(DeleteshiftControlApi(shift_control_id, updateTableQuery)),
        UpdateshiftControlApi: (newData, oldData, updateTableQuery) => dispatch(UpdateshiftControlApi(newData, oldData, updateTableQuery)),
        setHeading: (data) => dispatch(setHeading(data)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    isFetchingShiftControlData: state.ShiftControlReducer.isFetchingShiftControlData,
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewShiftControl);