import React, { useEffect, useRef } from "react";
import { Container, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { uniqBy, } from "lodash";
import Spinner from 'react-bootstrap/Spinner';
import MaterialTable from 'material-table';
import { GetEmployeeApi } from "./employeeAction";
import { setHeading } from "../../../accounts/account.actions";
import { baseURL, headers } from "../../../../../global/api";


const ViewEmployee = (props) => {
    const tableRef = useRef();
    function updateTableQuery() {
        tableRef.current.onQueryChange()
    };

    useEffect(() => {
        props.setHeading("Employee List")
    }, []);

    return (
        <>
            {props.isFetchingEmployeeData ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />
                    </Button>
                </div>
            ) : (
                <Container fluid>
                    <div className="main">
                        <MaterialTable
                            tableRef={tableRef}
                            title="Employees List"
                            columns={uniqBy([
                                { title: 'Image', field: 'uploadImage', editable: 'never', render: rowData => <img src={`${baseURL}/assets/${rowData.uploadImage}`} alt='employeeImg' style={{ width: 50, borderRadius: '50%' }} /> },
                                { title: 'Department', field: 'departmentDetails.department', editable: 'never' },
                                { title: 'Designation', field: 'designationDetails.designation', editable: 'never' },
                                { title: 'Name', field: 'employeeName' },
                                { title: 'Father Name', field: 'fatherName' },
                                { title: 'CNIC', field: 'cnic' },
                                { title: 'Date Of Birth', field: 'dob' },
                                { title: 'Joining Date', field: 'joiningDate' },
                                { title: 'Employee Type', field: 'employeeTypeDetails.employeeType', editable: 'never' },
                                { title: 'Active Status', field: 'active_status', render: rowData => (rowData.active_status === true) ? 'Active' : 'InActive' },
                            ], 'title')}
                            data={(query) =>
                                new Promise((resolve, reject) => {
                                    let url = `${baseURL}/employee?`;
                                    url += "limit=" + query.pageSize;
                                    url += "&page=" + query.page;
                                    url += "&search=" + query.search;
                                    fetch(url, {
                                        method: "GET",
                                        headers: headers,
                                    }).then((response) => response.json()).then((result) => {
                                        resolve({
                                            data: result.data,
                                            page: result.page,
                                            totalCount: result.total_results,
                                        });
                                    });
                                })
                            }
                            detailPanel={rowData => {
                                return (
                                    <MaterialTable
                                        columns={[
                                            { title: 'Machine Id', field: 'machineId' },
                                            { title: 'Basic Salary', field: 'basicSalary' },
                                            { title: 'Medicial Allowance', field: 'medicialAllowance' },
                                            { title: 'Petrol Allowance', field: 'petrolAllowance' },
                                            { title: 'Accomodation Allowance', field: 'accomodationAllowance' },
                                            { title: 'Special Allowance', field: 'specialAllowance' },
                                            { title: 'Total Allowances', field: 'totalAllowances' },
                                            { title: 'Total Salary', field: 'totalSalary' },
                                        ]}
                                        data={[rowData.salary]}
                                        options={{
                                            actionsColumnIndex: -1,
                                            toolbar: false,
                                            exportButton: true,
                                            sorting: true,
                                            paging: false,
                                            pageSize: 30,
                                            emptyRowsWhenPaging: false,
                                            pageSizeOptions: [50, 100, 150, 200],
                                            headerStyle: {
                                                position: 'sticky', top: 0,
                                                color: '#00BBBB',
                                                fontWeight: '550',
                                                onRowAdd: 'none',
                                            }
                                        }}
                                    />
                                )
                            }}
                            options={{
                                actionsColumnIndex: -1,
                                filtering: false,
                                exportButton: true,
                                exportAllData: true,
                                sorting: true,
                                paging: true,
                                pageSize: 50,
                                emptyRowsWhenPaging: false,
                                pageSizeOptions: [50, 100, 150, 200, 500],
                                headerStyle: {
                                    position: 'sticky', top: 0,
                                    color: '#00BBBB',
                                    fontWeight: '550',
                                    onRowAdd: 'none',
                                },
                            }}
                            editable={{
                                isEditable: rowData => false,
                                onRowUpdate: (newData, oldData) =>
                                    new Promise((resolve) => {
                                        setTimeout(() => {
                                            resolve();
                                            if (oldData) {
                                                props.HRUpdateEmployee(newData, oldData)

                                            }
                                        }, 600);
                                    }),
                                // isDeletable: rowData => false,
                                onRowDelete: (oldData) =>
                                    new Promise((resolve) => {
                                        setTimeout(() => {
                                            resolve();
                                            props.deleteHR(oldData._id, updateTableQuery);
                                        }, 600);
                                    }),
                            }}
                        />
                    </div>
                </Container>
            )}
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        GetEmployeeApi: () => dispatch(GetEmployeeApi()),
        setHeading: (data) => dispatch(setHeading(data)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    employeeList: state.EmployeeReducer.employeeList,
    isFetchingEmployeeData: state.EmployeeReducer.isFetchingEmployeeData
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewEmployee);