import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import "jspdf-autotable";
import Swal from "sweetalert";
import { AddFBRIntegration } from "./FBRIntegration.action";
import { setHeading } from "../../../accounts/account.actions";
import { getRestaurantData } from "../AddResturant/Resturant.action";
import _ from "lodash";
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import Select from "react-select";


const AddResturant = (props) => {
    const [retaurantId, setRestaurantId] = useState();
    const [restaurantName, setRestaurantName] = useState();
    const [FBRPOSID, setPOSID] = useState(0);
    const [FBRCode, setCode] = useState(0);
    const [FBRToken, setToken] = useState(0);
    const [FBRIntegrationId, setFBRIntegrationId] = useState();
    const [FBRIntegrationName, setFBRIntegrationName] = useState();
    const [FBRIntegrationTypeId, setFBRIntegrationTypeId] = useState();
    const [FBRIntegrationTypeName, setFBRIntegrationTypeName] = useState();
    const [visibleAlert, setAlertVisible] = useState(false);
    const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);


    const handleVisible = (msg) => {
        setAlertVisible(true)
        setTimeout(() => {
            setAlertVisible(false)
        }, 9000);
        Swal({
            title: "Success",
            text: msg,
            icon: "success",
            button: "Ok",
        });
    }
    const handleDangerVisible = (err) => {
        setDangerVisibleAlert(true)
        setTimeout(() => {
            setDangerVisibleAlert(false)
        }, 9000);
        Swal({
            title: "Error",
            text: err,
            icon: "error",
            button: "Ok",
        });
    }

    function uploadFBRIntegration() {
        props.AddFBRIntegration({
            retaurantId: retaurantId,
            FBRIntegration: FBRIntegrationId,
            FBRLocalOrLive: FBRIntegrationTypeName,
            FBRPOSID: FBRPOSID,
            FBRCode: FBRCode,
            FBRToken: FBRToken,
        },
            handleVisible,
            handleDangerVisible
        );
        setRestaurantName("")
        setFBRIntegrationName("")
        setFBRIntegrationTypeName("")
        setPOSID("")
        setCode("")
        setToken("")
    }

    let FBRIntegrationSelection = [
        {
            FBRIntegrationId: 1,
            FBRIntegrationName: "Yes",
        },
        {
            FBRIntegrationId: 0,
            FBRIntegrationName: "No",
        }
    ];
    const FBRIntegrationSelectionList = !isEmpty(FBRIntegrationSelection) && FBRIntegrationSelection.map((x) => {
        let data = { value: x.FBRIntegrationId, label: x.FBRIntegrationName };
        return data;
    });
    const handleChangeFBRIntegrationSelection = (selectedOption) => {
        setFBRIntegrationName(selectedOption.label);
        setFBRIntegrationId(selectedOption.value);
    };

    let FBRIntegrationType = [
        {
            FBRIntegrationTypeId: 1,
            FBRIntegrationTypeName: "Web Base",
        },
        {
            FBRIntegrationTypeId: 0,
            FBRIntegrationTypeName: "Desktop Base",
        }
    ];
    const FBRIntegrationTypeList = !isEmpty(FBRIntegrationType) && FBRIntegrationType.map((x) => {
        let data = { value: x.FBRIntegrationTypeId, label: x.FBRIntegrationTypeName };
        return data;
    });
    const handleChangeFBRIntegrationType = (selectedOption) => {
        setFBRIntegrationTypeName(selectedOption.label);
        setFBRIntegrationTypeId(selectedOption.value);
    };

    const handleChangeRestaurant = (selectedOption) => {
        setRestaurantId(selectedOption.value);
        setRestaurantName(selectedOption.label);
    };
    const getRetaurantList = !_.isEmpty(props.restaurantDataList) && props.restaurantDataList.map((x) => {
        let data = { value: x._id, label: x.restaurantName };
        return data;
    });

    useEffect(() => {
        props.getRestaurantData();
        props.setHeading("FBR Integration");
    }, []);

    return (
        <>
            {props.isAddingFBRCredential ? (
                <div className="loader-div">
                    <Spinner
                        variant="primary"
                        animation="grow"
                        size="xl"
                        role="status"
                        aria-hidden="true"
                    />
                </div>
            ) : (
                <Container fluid>
                    <div className="main">
                        <div>
                            <Form>
                                <Row>

                                    <Col>
                                        <Form.Group className="mb-1 mt-1" >
                                            <Form.Label>
                                                <b>Select Company *</b>
                                            </Form.Label>
                                            <Select
                                                value={{ label: restaurantName }}
                                                placeholder="Select Company"
                                                onChange={handleChangeRestaurant}
                                                options={getRetaurantList}
                                            />
                                        </Form.Group>
                                    </Col>

                                    <div hidden={_.isEmpty(restaurantName) ? true : false}>
                                        <Col>
                                            <Form.Group className="mb-1 mt-1">
                                                <Form.Label>
                                                    <b>FBR Integration *</b>
                                                </Form.Label>
                                                <Select
                                                    placeholder="FBR Integration"
                                                    onChange={handleChangeFBRIntegrationSelection}
                                                    options={FBRIntegrationSelectionList}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <div hidden={FBRIntegrationId == 1 ? false : true}>
                                            <Col>
                                                <Form.Group className="mb-1 mt-1">
                                                    <Form.Label>
                                                        <b>FBR Integration Type *</b>
                                                    </Form.Label>
                                                    <Select
                                                        placeholder="FBR Integration Type"
                                                        onChange={handleChangeFBRIntegrationType}
                                                        options={FBRIntegrationTypeList}
                                                    />
                                                </Form.Group>
                                            </Col>

                                            <div hidden={_.isEmpty(FBRIntegrationTypeName) ? true : false}>
                                                <Col>
                                                    <Form.Group className="mb-3" controlId="garana" >
                                                        <Form.Label><b>POS ID * </b></Form.Label>
                                                        <Form.Control
                                                            type="Number"
                                                            placeholder="POS ID"
                                                            onChange={(e) => setPOSID(e.target.value)}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group className="mb-3" controlId="garana" >
                                                        <Form.Label><b>Code * </b></Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Code"
                                                            onChange={(e) => setCode(e.target.value)}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group className="mb-3" controlId="garana" >
                                                        <Form.Label><b>Token * </b></Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Token"
                                                            onChange={(e) => setToken(e.target.value)}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </div>
                                        </div>

                                    </div>
                                </Row>

                                <div className="sendDiv">
                                    {_.isEmpty(restaurantName) || _.isEmpty(FBRIntegrationName) || _.isEmpty(FBRIntegrationTypeName) || _.isEmpty(FBRPOSID) || _.isEmpty(FBRCode) || _.isEmpty(FBRToken) ? (
                                        <Button variant="contained" endIcon={<SendIcon />} size="large" disabled> SAVE </Button>
                                    ) : (
                                        <Button variant="contained" endIcon={<SendIcon />} size="large" onClick={uploadFBRIntegration}> SAVE </Button>
                                    )}
                                </div>

                            </Form>
                        </div>
                    </div>
                </Container>
            )}
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        AddFBRIntegration: (data, handleVisible, handleDangerVisible) => dispatch(AddFBRIntegration(data, handleVisible, handleDangerVisible)),
        setHeading: (data) => dispatch(setHeading(data)),
        getRestaurantData: () => dispatch(getRestaurantData()),
    };
};
const mapStateToProps = (state, ownProps) => ({
    restaurantDataList: state.ResturantReducer.restaurantDataList,
    isAddingFBRCredential: state.FBRIntegrationReducer.isAddingFBRCredential,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddResturant);
