import React, { useEffect } from "react";
import { Container, } from "react-bootstrap";
import { connect } from "react-redux";
import { } from "./ProductionIssuanceAction";
import { baseURL, headers } from "../../../../../global/api";
import { setHeading } from "../../../accounts/account.actions";
import MaterialSize from "material-table";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";


const ViewProductionIssuance = (props) => {

    useEffect(() => {
        props.setHeading("Issuance List");
    }, []);

    return (
        <>
            {props.isFetchingProcess ? (
                <div className="loader-div">
                    <Spinner
                        variant="primary"
                        animation="border"
                        size="xl"
                        role="status"
                        aria-hidden="true"
                    />
                </div>
            ) : (
                <Container fluid>
                    <div className="main">
                        <MaterialSize
                            title="Issuance Details"
                            columns={[
                                { title: 'Sequence Number', field: 'sequenceNumber', editable: 'never' },
                                { title: "Order Manual No", field: "orderDetails.orderNumber", editable: 'never' },
                                { title: "Vendor Name", field: "vendorDetails.vendorName", editable: 'never' },
                                { title: "Issuance Date", field: "issuanceDate" },
                                { title: "Issuance Type", field: "issuanceType" },
                                { title: "Description", field: "description" },
                                { title: "Created By", field: "userDetails.userName", editable: 'never' },
                            ]}
                            data={(query) =>
                                new Promise((resolve, reject) => {
                                    let url = `${baseURL}/productionIssuance?`;
                                    url += "limit=" + query.pageSize;
                                    url += "&page=" + (query.page);
                                    url += "&search=" + query.search;
                                    fetch(url, {
                                        method: "GET",
                                        headers: headers,
                                    }).then((response) => response.json()).then((result) => {
                                        resolve({
                                            data: result.data,
                                            page: result.page,
                                            totalCount: result.total_results,
                                        });
                                    });
                                })
                            }
                            detailPanel={rowData => {
                                return (
                                    <MaterialTable
                                        columns={[
                                            { title: 'Article Name', field: 'articleName' },
                                            // { title: 'Order Quantity', field: 'orderQuantity' },
                                            { title: 'Issuance Quantity', field: 'issuanceQuantity' },
                                            { title: 'Process Name', field: 'processDetails.processName' },
                                            { title: 'Process Rate', field: 'processDetails.processRate' },
                                        ]}
                                        data={rowData.orderDetails.articleIssuanceDetails}
                                        options={{
                                            actionsColumnIndex: -1,
                                            toolbar: false,
                                            exportButton: false,
                                            sorting: false,
                                            paging: false,
                                            pageSize: 50,
                                            emptyRowsWhenPaging: false,
                                            pageSizeOptions: [50, 100, 150, 200],
                                            headerStyle: {
                                                position: 'sticky',
                                                top: 0,
                                                color: '#00BBBB',
                                                fontWeight: '550',
                                                onRowAdd: 'none',
                                            }
                                        }}
                                    />
                                )
                            }}
                            options={{
                                actionsColumnIndex: -1,
                                exportButton: true,
                                exportAllData: true,
                                paging: true,
                                pageSize: 50,
                                emptyRowsWhenPaging: false,
                                pageSizeOptions: [50, 100, 150, 200],
                                headerStyle: {
                                    position: "sticky",
                                    top: 0,
                                    color: "#00BBBB",
                                    fontWeight: "550",
                                    onRowAdd: "none",
                                },
                            }}
                            editable={{
                                // isEditHidden: (rowData) => Checkpermissions("permissionsSubCatName", "Colour Edit"),
                                // isDeleteHidden: (rowData) => Checkpermissions("permissionsSubCatName", "Colour Delete"),
                                isDeletable: rowData => false,
                                isEditable: rowData => false,
                                onRowUpdate: (newData, oldData) =>
                                    new Promise((resolve) => {
                                        setTimeout(() => {
                                            resolve();
                                            props.UpdateProcessApi(newData, oldData);
                                        }, 600);
                                    }),
                                onRowDelete: (oldData) =>
                                    new Promise((resolve) => {
                                        setTimeout(() => {
                                            resolve();
                                            props.DeleteProcessApi(oldData._id);
                                        }, 600);
                                    }),
                            }}
                        />
                    </div>
                </Container>
            )}
        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
        // DeleteProcessApi: (id) => dispatch(DeleteProcessApi(id)),
        // UpdateProcessApi: (newData, oldData) => dispatch(UpdateProcessApi(newData, oldData)),
        setHeading: (data) => dispatch(setHeading(data)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    productionIssuanceList: state.ProductionIssuanceReducer.productionIssuanceList,
    isFetchingProductionIssuance: state.ProductionIssuanceReducer.isFetchingProductionIssuance,
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewProductionIssuance);