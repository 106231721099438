import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { setHeading } from "../../../accounts/account.actions";
import { addUom } from "./Uom.action";
import Spinner from "react-bootstrap/Spinner";
import Swal from "sweetalert";
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';


const AddUom = (props) => {
  const [uomName, setUomName] = useState("");
  const [uomUnit, setUomUnit] = useState("");
  const [uomDesc, setUomDesc] = useState("");
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  const handleVisible = (msg) => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  };
  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };

  function uploadUom() {
    props.addUom({
      unitName: uomName,
      unitCode: uomName,
      desc: uomDesc,
    },
      handleVisible,
      handleDangerVisible
    );
    setUomDesc("");
    setUomUnit("");
    setUomName("");
  }

  useEffect(() => {
    props.setHeading("Add UOM");;
  }, []);

  return (
    <>
      {props.isAddingUom ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <Form>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label><b>UOM Name *</b></Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Uom Name"
                    onChange={(e) => setUomName(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label><b>UOM Description </b></Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="UOM Description"
                    onChange={(e) => setUomDesc(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>

            <div className="sendDiv">
              {isEmpty(uomName) ? (
                <Button variant="contained" endIcon={<SendIcon />} size="large" disabled> SAVE </Button>
              ) : (
                <Button variant="contained" endIcon={<SendIcon />} size="large" onClick={uploadUom}> SAVE</Button>
              )}
            </div>

          </Form>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setHeading: (data) => dispatch(setHeading(data)),
    addUom: (data, handleVisible, handleDangerVisible) => dispatch(addUom(data, handleVisible, handleDangerVisible)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  isAddingUom: state.uomReducer.isAddingUom
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUom);
