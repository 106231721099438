import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, } from "react-bootstrap";
import { addHall } from "./Hall.action";
import { setHeading } from "../../../accounts/account.actions";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import Swal from "sweetalert";
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';


const AddHall = (props) => {
  const [hall, setHall] = useState("");
  const [hallDesc, setHallDesc] = useState("");
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  
  function uploadHall() {
    props.addHall({
      hallName: hall,
      hallDesc: hallDesc,
    },
      handleVisible,
      handleDangerVisible
    );
    setHallDesc("");
    setHall("");
  }
  const handleVisible = (msg) => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  };
  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };


  useEffect(() => {
    props.setHeading("Add Hall");
  }, []);

  return (
    <>
      {props.isAddingHall ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="border"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Form>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="garana">
                    <Form.Label> <b>Department / Hall *</b></Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Department / Hall"
                      onChange={(e) => setHall(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="garana">
                    <Form.Label> <b>Description </b></Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Description"
                      onChange={(e) => setHallDesc(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <div className="sendDiv">
                {isEmpty(hall) ? (
                  <Button variant="contained" endIcon={<SendIcon />} size="large" disabled> SAVE </Button>
                ) : (
                  <Button variant="contained" endIcon={<SendIcon />} size="large" onClick={uploadHall}>SAVE </Button>
                )}
              </div>

            </Form>
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addHall: (data, handleVisible, handleDangerVisible) => dispatch(addHall(data, handleVisible, handleDangerVisible)),
    setHeading: (data) => dispatch(setHeading(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  hallList: state.hallReducer.hallList,
  isAddingHall: state.hallReducer.isAddingHall,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddHall);
