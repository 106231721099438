import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { AddProductionEmployeeApi } from "./ProductionEmployeeAction";
import { setHeading } from "../../../accounts/account.actions";
import _ from "lodash";
import Spinner from "react-bootstrap/Spinner";
import Swal from "sweetalert";
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import Select from "react-select";


const AddProductionEmployee = (props) => {
    const [employeeName, setEmployeeName] = useState();
    const [fatherName, setFatherName] = useState();
    const [phone, setPhone] = useState();
    const [cnic, setCNIC] = useState();
    const [gender, setGender] = useState();
    const [machineId, setMachineId] = useState();
    const [department, setDepartment] = useState();
    const [designation, setDesignation] = useState();
    const [incharge, setIncharge] = useState();
    const [hall, setHall] = useState();
    const [visibleAlert, setAlertVisible] = useState(false);
    const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);


    function SaveProductionEmployee() {
        props.AddProductionEmployeeApi({
            employeeName: employeeName,
            fatherName: fatherName,
            phone: phone,
            cnic: cnic,
            gender: gender,
            machineId: machineId,
            department: department,
            designation: designation,
            incharge: incharge,
            hall: hall,
        },
            handleVisible,
            handleDangerVisible
        );
        setEmployeeName("");
        setFatherName("");
        setPhone("");
        setCNIC("");
    }
    const handleVisible = (msg) => {
        setAlertVisible(true);
        setTimeout(() => {
            setAlertVisible(false);
        }, 9000);
        Swal({
            title: "Success",
            text: msg,
            icon: "success",
            button: "Ok",
        });
    };
    const handleDangerVisible = (err) => {
        setDangerVisibleAlert(true);
        setTimeout(() => {
            setDangerVisibleAlert(false);
        }, 9000);
        Swal({
            title: "Error",
            text: err,
            icon: "error",
            button: "Ok",
        });
    };

    let genderData = [{
        id: "Male",
        type: "Male",
    },
    {
        id: "Female",
        type: "Female",
    },
    {
        id: "Other",
        type: "Other",
    }];
    const handleChangeGender = (selectedOption) => {
        setGender(selectedOption.label);
    };
    const GetGenderList = genderData.map((x) => {
        let data = { value: x.id, label: x.type };
        return data;
    });

    useEffect(() => {
        props.setHeading("New Employee");
    }, []);

    return (
        <>
            {props.isAddingProductionEmployee ? (
                <div className="loader-div">
                    <Spinner
                        variant="primary"
                        animation="border"
                        size="xl"
                        role="status"
                        aria-hidden="true"
                    />
                </div>
            ) : (
                <Container fluid>
                    <div className="main">
                        <Form>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="garana">
                                        <Form.Label><b>Employee Name *</b></Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Employee Name"
                                            onChange={(e) => setEmployeeName(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="garana">
                                        <Form.Label><b>Phone</b></Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Phone"
                                            onChange={(e) => setPhone(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="garana">
                                        <Form.Label><b>Father Name </b></Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Father Name"
                                            onChange={(e) => setFatherName(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="garana">
                                        <Form.Label><b>CNIC </b></Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="CNIC"
                                            onChange={(e) => setCNIC(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="garana">
                                        <Form.Label><b>Gender </b></Form.Label>
                                        <Select
                                            placeholder="Select Process Type"
                                            onChange={handleChangeGender}
                                            options={GetGenderList}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="garana">
                                        <Form.Label><b>Machine ID </b></Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Machine ID"
                                            onChange={(e) => setMachineId(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="garana">
                                        <Form.Label><b>Department *</b></Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Department"
                                            onChange={(e) => setDepartment(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="garana">
                                        <Form.Label><b>Designation *</b></Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Designation"
                                            onChange={(e) => setDesignation(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="garana">
                                        <Form.Label><b>Incharge </b></Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Incharge"
                                            onChange={(e) => setIncharge(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="garana">
                                        <Form.Label><b>Hall </b></Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Hall"
                                            onChange={(e) => setHall(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col></Col>
                                <Col></Col>
                            </Row>

                            <div className="sendDiv">
                                {_.isEmpty(employeeName) || _.isEmpty(gender) || _.isEmpty(department) || _.isEmpty(designation) ? (
                                    <Button variant="contained" endIcon={<SendIcon />} size="large" disabled> SAVE </Button>
                                ) : (
                                    <Button variant="contained" endIcon={<SendIcon />} size="large" onClick={SaveProductionEmployee}> SAVE </Button>
                                )}
                            </div>

                        </Form>
                    </div>
                </Container>
            )}
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        AddProductionEmployeeApi: (data, handleVisible, handleDangerVisible) => dispatch(AddProductionEmployeeApi(data, handleVisible, handleDangerVisible)),
        setHeading: (data) => dispatch(setHeading(data)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    isAddingProductionEmployee: state.ProductionEmployeeReducer.isAddingProductionEmployee,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddProductionEmployee);
