import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { setHeading } from "../../../accounts/account.actions";
import { GetArticleApi, GetArticleWithIDApi, AddArticleRecipeApi } from "./ArticleAction";
import { getProductData } from "../../AddDetails/AddProduct/Product.action";
import { GetProcessApi } from "../process/ProcessAction";
import _ from "lodash";
import Spinner from "react-bootstrap/Spinner";
import Select from "react-select";
import Swal from "sweetalert";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import SendIcon from '@mui/icons-material/Send';
import SaveButton from '@mui/material/Button';



const AddArticleRecipe = (props) => {
    const [articleId, setArticleId] = useState();
    const [articleName, setArticleName] = useState();
    const [articleVariationId, setArticleVariationId] = useState();
    const [visibleAlert, setAlertVisible] = useState(false);
    const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
    const [inputFieldProduct, setInputFieldProduct] = useState([{
        productId: 0,
        productName: '',
        quantity: 0,
        purchasePrice: 0,
        totalCost: 0,
        uomLabel: '',
    }]);
    const [inputFieldProcess, setinputFieldProcess] = useState([{
        processId: 0,
        processName: '',
        // quantity: '',
        processRate: 0,
        totalRate: 0,
    }]);
    const [inputFieldExtraService, setInputFieldExtraService] = useState([{
        description: '',
        quantity: 0,
        price: 0,
        totalPrice: 0,
    }]);

    const filteredInputFieldProcess = inputFieldProcess.filter(obj => {
        return !Object.values(obj).some(value => value === 0 || value === "");
    });
    const filteredInputFieldProduct = inputFieldProduct.filter(obj => {
        return !Object.values(obj).some(value => value === 0 || value === "");
    });
    // const filteredInputFieldAdminCost = inputFieldExtraService.filter(obj => {
    //     return !Object.values(obj).some(value => value === 0 || value === "");
    // });


    function SaveExpense() {
        props.AddArticleRecipeApi({
            articleId: articleId,
            articleName: articleName,
            articleVariationId: articleVariationId,
            articleTotalCost: Number(_.sumBy(inputFieldProduct, 'totalCost')) + Number(_.sumBy(inputFieldProcess, 'totalRate')),
            productData: filteredInputFieldProduct,
            processData: filteredInputFieldProcess,
            // adminCostData: filteredInputFieldAdminCost,
        },
            handleVisible,
            handleDangerVisible
        );
        setArticleId("")
        setArticleName("")
        setArticleVariationId("")
        setInputFieldProduct([{
            productId: 0,
            productName: '',
            quantity: 0,
            purchasePrice: 0,
            totalCost: 0,
            uomLabel: '',
        }]);
        setinputFieldProcess([{
            processId: 0,
            processName: '',
            quantity: '',
            processRate: 0,
            totalRate: 0,
        }]);
        // setInputFieldExtraService([{
        //     description: '',
        //     quantity: 0,
        //     price: 0,
        //     totalPrice: 0,
        // }]);
    }
    const handleVisible = (msg) => {
        setAlertVisible(true);
        setTimeout(() => {
            setAlertVisible(false);
        }, 9000);
        Swal({
            title: "Success",
            text: msg,
            icon: "success",
            button: "Ok",
        });
    };
    const handleDangerVisible = (err) => {
        setDangerVisibleAlert(true);
        setTimeout(() => {
            setDangerVisibleAlert(false);
        }, 9000);
        Swal({
            title: "Error",
            text: err,
            icon: "error",
            button: "Ok",
        });
    };

    const handleChangeArticle = (selectedOption) => {
        setArticleId(selectedOption.value);
        setArticleName(selectedOption.label);
        props.GetArticleWithIDApi(selectedOption.value)
    };
    const getArticleList = !_.isEmpty(props.articleList) && props.articleList.map((x) => {
        let data = { value: x._id, label: `${x.sequenceNumber} / ${x.articleName}` };
        return data;
    });

    const handleChangeArticleVariation = (selectedOption) => {
        setArticleVariationId(selectedOption.value);
    };
    const GetArticleVariationList = !_.isEmpty(props.articleListWithID) && props.articleListWithID[0].variation.map((x) => {
        let data = { value: x._id, label: `${x.colourLabel} / ${x.sizeLabel}` };
        return data;
    });


    const handleChangeProduct = (e, index, type) => {
        let z = _.find(props.productDataList, (x) => x._id == e.value);
        const list = [...inputFieldProduct];
        if (type === "intname") {
            list[index]["productId"] = e.value;
            list[index]["productName"] = e.label;
            list[index]["purchasePrice"] = Number(z.purchasePrice);
            list[index]["uomLabel"] = z.uomLabel;
        }
        if (type === "intquantity") {
            list[index]["quantity"] = Number(e.target.value);
            list[index]['totalCost'] = Number(Math.round((list[index]['purchasePrice'] * list[index]['quantity']) * 100) / 100);
        }

        setInputFieldProduct(list);
    };
    console.log(inputFieldProduct, "ppppppppppppppppppppppp")
    const getProductList = !_.isEmpty(props.productDataList) && props.productDataList.map((x) => {
        let data = { value: x._id, label: `${x.name}` };
        return data;
    });
    const handleAddInputProduct = () => {
        setInputFieldProduct([...inputFieldProduct, {
            productId: 0,
            productName: '',
            quantity: '',
            purchasePrice: 0,
            totalCost: 0,
            uomLabel: '',
        }]);
    };
    const handleRemoveInputProduct = (index) => {
        const list = [...inputFieldProduct];
        list.splice(index, 1);
        setInputFieldProduct(list);
    };


    const handleChangeService = (e, index, type) => {
        const list = [...inputFieldProcess];
        if (type === "intname") {
            let selectedProcess = _.find(props.processList.data, (x) => x._id === e.value);
            list[index]["processId"] = e.value;
            list[index]["processName"] = e.label;
            list[index]["processRate"] = selectedProcess ? Number(selectedProcess.rate) : 0; // Set default rate
            list[index]["totalRate"] = Number(selectedProcess ? selectedProcess.rate : 0); // Default total rate
        }
        if (type === "processRate") {
            list[index]["processRate"] = Number(e.target.value); // Allow user to modify rate
            list[index]["totalRate"] = Number(e.target.value); // Update total rate based on modified rate
        }
        setinputFieldProcess(list);
    };
    console.log(inputFieldProcess, "ssssssssssssssssssssssssss")
    const getProcessList = !_.isEmpty(props.processList) && props.processList.data.map((x) => {
        let data = { value: x._id, label: `${x.processName}` };
        return data;
    });
    const handleAddInputService = () => {
        setinputFieldProcess([...inputFieldProcess, {
            processId: 0,
            processName: '',
            // quantity: '',
            totalRate: 0,
        }]);
    };
    const handleRemoveInputService = (index) => {
        const list = [...inputFieldProcess];
        list.splice(index, 1);
        setinputFieldProcess(list);
    };


    const handleChangeExtraService = (e, index, type) => {
        const list = [...inputFieldExtraService];
        if (type === "description") list[index]["description"] = e.target.value;
        if (type === "quantity") list[index]["quantity"] = Number(e.target.value);
        if (type === "price") list[index]["price"] = Number(e.target.value);
        if (type === 'price' || type === 'quantity') list[index]['totalPrice'] = Number(Math.round((list[index]['price'] * list[index]['quantity']) * 100) / 100);

        setInputFieldExtraService(list);
    };
    const handleAddInputExtraService = () => {
        setInputFieldExtraService([...inputFieldExtraService, {
            description: '',
            quantity: 0,
            price: 0,
            totalPrice: 0,
        }]);
    };
    const handleRemoveInputExtraService = (index) => {
        const list = [...inputFieldExtraService];
        list.splice(index, 1);
        setInputFieldExtraService(list);
    };


    useEffect(() => {
        props.setHeading("Article Recipe");
        props.getProductData()
        props.GetArticleApi()
        props.GetProcessApi()
    }, []);

    return (<>
        {props.isFetchingArticle || props.isAddingArticleRecipe ? (
            <div className="loader-div">
                <Spinner
                    variant="primary"
                    animation="border"
                    size="xl"
                    role="status"
                    aria-hidden="true"
                />
            </div>
        ) : (
            <Container fluid>
                <div style={{ marginTop: '-2%' }}>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="garana">
                                <Form.Label><b>Select Article *</b> </Form.Label>
                                <Select
                                    isDisabled={_.isEmpty(getArticleList)}
                                    placeholder="Select Article"
                                    onChange={handleChangeArticle}
                                    options={getArticleList}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="garana">
                                <Form.Label><b>Select Variation *</b> </Form.Label>
                                <Select
                                    isDisabled={_.isEmpty(articleId)}
                                    placeholder="Select Variation"
                                    onChange={handleChangeArticleVariation}
                                    options={GetArticleVariationList}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    {props.isFetchingArticleWithID ? (
                        <div className="loader-div">
                            <Spinner
                                variant="primary"
                                animation="border"
                                size="xl"
                                role="status"
                                aria-hidden="true"
                            />
                        </div>
                    ) : (
                        <div hidden={_.isEmpty(articleVariationId)}>
                            {props.isFetchingArticleWithID ? (
                                <div className="loader-div">
                                    <Spinner
                                        variant="primary"
                                        animation="border"
                                        size="xl"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </div>
                            ) : (<>
                                <div style={{ width: '100%', backgroundColor: '#aed6ae', padding: '15px', borderRadius: '25px', marginBottom: '25px' }}>
                                    <Row>
                                        <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '16px', fontWeight: 'bold' }}>Article Number: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{_.isUndefined(props.articleListWithID[0]) ? '' : props.articleListWithID[0].sequenceNumber}</span> </b></Col>
                                        <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '16px', fontWeight: 'bold' }}>Article Name: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{_.isUndefined(props.articleListWithID[0]) ? '' : props.articleListWithID[0].articleName}</span> </b></Col>
                                        <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Composition: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{_.isUndefined(props.articleListWithID[0]) ? '' : props.articleListWithID[0].composition}</span> </b></Col>
                                    </Row>
                                    <Row>
                                        <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '16px', fontWeight: 'bold' }}>Currency: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{_.isUndefined(props.articleListWithID[0]) ? '' : props.articleListWithID[0].currency}</span> </b></Col>
                                        <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '16px', fontWeight: 'bold' }}>Description: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{_.isUndefined(props.articleListWithID[0]) ? '' : props.articleListWithID[0].description}</span> </b></Col>
                                        <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '16px', fontWeight: 'bold' }}>Export Cost: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{_.isUndefined(props.articleListWithID[0]) ? '' : props.articleListWithID[0].exportCost}</span> </b></Col>
                                    </Row>
                                    <Row>
                                        <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '16px', fontWeight: 'bold' }}>HS Code: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{_.isUndefined(props.articleListWithID[0]) ? '' : props.articleListWithID[0].hSCode}</span> </b></Col>
                                        <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '16px', fontWeight: 'bold' }}>Instruction: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{_.isUndefined(props.articleListWithID[0]) ? '' : props.articleListWithID[0].instruction}</span> </b></Col>
                                        <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '16px', fontWeight: 'bold' }}>Manual Code: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{_.isUndefined(props.articleListWithID[0]) ? '' : props.articleListWithID[0].manualCode}</span> </b></Col>
                                    </Row>
                                </div>

                                <div>
                                    <Row>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label><b>Material Total *</b> </Form.Label>
                                                <Form.Control
                                                    disabled
                                                    type="number"
                                                    placeholder="Material Total"
                                                    value={_.sumBy(inputFieldProduct, 'totalCost')}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label><b>Process Total *</b> </Form.Label>
                                                <Form.Control
                                                    disabled
                                                    type="number"
                                                    placeholder="Process Total"
                                                    value={_.sumBy(inputFieldProcess, 'totalRate')}
                                                />
                                            </Form.Group>
                                        </Col>
                                        {/* <Col>
                                            <Form.Group>
                                                <Form.Label><b>Admin Total *</b> </Form.Label>
                                                <Form.Control
                                                    disabled
                                                    type="number"
                                                    placeholder="Admin Total"
                                                    value={_.sumBy(inputFieldExtraService, 'totalPrice')}
                                                />
                                            </Form.Group>
                                        </Col> */}
                                        <Col>
                                            <Form.Group>
                                                <Form.Label><b>Total Article Cost *</b> </Form.Label>
                                                <Form.Control
                                                    disabled
                                                    type="number"
                                                    placeholder="Admin Total"
                                                    value={Number(_.sumBy(inputFieldProduct, 'totalCost')) + Number(_.sumBy(inputFieldProcess, 'totalRate'))}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </div>

                                <Row style={{ marginTop: '15px' }}>

                                    <Col xl="8" lg="8" md="8" sm="8" style={{ borderRightStyle: "solid" }} >
                                        <Col style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: '15px' }}>Add Material </Col>
                                        {inputFieldProduct.map((item, i) => {
                                            return (
                                                <React.Fragment key={i}>
                                                    <Row>
                                                        <Col style={{ padding: '0px 2px 0px 2px' }}>
                                                            <Form.Group className="mb-3" controlId="garana">
                                                                <Form.Label><b>Select Material *</b> </Form.Label>
                                                                <Select
                                                                    placeholder="Product"
                                                                    name="productId"
                                                                    onChange={(e) => handleChangeProduct(e, i, "intname")}
                                                                    options={getProductList}
                                                                    isOptionDisabled={(option) => !_.isEmpty(_.find(inputFieldProduct, (x) => x.productId == option.value))}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col style={{ padding: '0px 2px 0px 2px' }}>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label><b>Quantity *</b> </Form.Label>
                                                                <Form.Control
                                                                    // min={0}
                                                                    type="number"
                                                                    placeholder="Add Quantity"
                                                                    name="quantity"
                                                                    value={item.quantity}
                                                                    onChange={(e) => handleChangeProduct(e, i, "intquantity")}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col style={{ padding: '0px 2px 0px 2px' }}>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label><b>UOM *</b> </Form.Label>
                                                                <Form.Control
                                                                    disabled
                                                                    type="text"
                                                                    placeholder="UOM"
                                                                    name="uomLabel"
                                                                    value={item.uomLabel}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col style={{ padding: '0px 2px 0px 2px' }}>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label><b>Purchase Price *</b> </Form.Label>
                                                                <Form.Control
                                                                    disabled
                                                                    type="number"
                                                                    placeholder="Purchase Price"
                                                                    name="purchasePrice"
                                                                    value={item.purchasePrice}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col style={{ padding: '0px 2px 0px 2px' }}>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label><b>Total Price *</b> </Form.Label>
                                                                <Form.Control
                                                                    disabled
                                                                    type="number"
                                                                    placeholder="Total Price"
                                                                    name="totalCost"
                                                                    value={item.totalCost}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col style={{ marginTop: '4.5%', padding: '0px 2px 0px 2px' }} >
                                                            {inputFieldProduct.length - 1 == i && (
                                                                <Button title="Add Product" onClick={handleAddInputProduct} style={{ float: "right" }} >
                                                                    <AddIcon />
                                                                </Button>
                                                            )}
                                                            {inputFieldProduct.length !== 1 && (
                                                                <Button title="Remove Product" onClick={() => handleRemoveInputProduct(i)} style={{ float: "right", marginRight: "1%" }}>
                                                                    <RemoveIcon />
                                                                </Button>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                    <Row>

                                                    </Row>
                                                </React.Fragment>)
                                        })}
                                    </Col>


                                    <Col xl="4" lg="4" md="4" sm="4" >
                                        <Col style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: '15px' }}> Add Process </Col>
                                        {inputFieldProcess.map((item, i) => {
                                            return (
                                                <React.Fragment key={i}>
                                                    <Row>
                                                        <Col style={{ padding: '0px 2px 0px 10px' }}>
                                                            <Form.Group className="mb-3" controlId="garana">
                                                                <Form.Label><b>Select Process *</b> </Form.Label>
                                                                <Select
                                                                    placeholder="Process"
                                                                    name="productId"
                                                                    onChange={(e) => handleChangeService(e, i, "intname")}
                                                                    options={getProcessList}
                                                                    isOptionDisabled={(option) => !_.isEmpty(_.find(inputFieldProcess, (x) => x.processId == option.value))}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        {/* <Col>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label><b>Add Quantity *</b> </Form.Label>
                                                                <Form.Control
                                                                    type="number"
                                                                    placeholder="Add Quantity"
                                                                    name="quantity"
                                                                    value={item.quantity}
                                                                    onChange={(e) => handleChangeService(e, i, "intquantity")}
                                                                />
                                                            </Form.Group>
                                                        </Col> */}
                                                        <Col style={{ padding: '0px 2px 0px 2px' }}>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label><b>Rate *</b> </Form.Label>
                                                                <Form.Control
                                                                    type="number"
                                                                    placeholder="Rate"
                                                                    name="processRate"
                                                                    value={item.processRate}
                                                                    onChange={(e) => handleChangeService(e, i, "processRate")}
                                                                />

                                                            </Form.Group>
                                                        </Col>
                                                        <Col style={{ marginTop: '4.5%', padding: '0px 2px 0px 2px' }}>
                                                            {inputFieldProcess.length - 1 === i && (
                                                                <Button title="Add Process" onClick={handleAddInputService} style={{ float: "right" }} >
                                                                    <AddIcon />
                                                                </Button>
                                                            )}
                                                            {inputFieldProcess.length !== 1 && (
                                                                <Button title="Remove Process" onClick={() => handleRemoveInputService(i)} style={{ float: "right", marginRight: "1%" }}>
                                                                    <RemoveIcon />
                                                                </Button>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                </React.Fragment>)
                                        })}
                                    </Col>


                                    {/* <Col style={{ marginTop: '20px' }}>
                                        <Col style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: '15px' }}> Admin Cost </Col>
                                        {inputFieldExtraService.map((item, i) => {
                                            return (
                                                <React.Fragment key={i}>
                                                    <Row>
                                                        <Col>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label><b>Description *</b> </Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Description"
                                                                    name="description"
                                                                    value={item.description}
                                                                    onChange={(e) => handleChangeExtraService(e, i, "description")}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label><b>Quantity *</b> </Form.Label>
                                                                <Form.Control
                                                                    type="number"
                                                                    placeholder="Quantity"
                                                                    name="quantity"
                                                                    value={item.quantity}
                                                                    onChange={(e) => handleChangeExtraService(e, i, "quantity")}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label><b>Price *</b> </Form.Label>
                                                                <Form.Control
                                                                    type="number"
                                                                    placeholder="Price"
                                                                    name="price"
                                                                    value={item.price}
                                                                    onChange={(e) => handleChangeExtraService(e, i, "price")}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col>
                                                            <Form.Group className="mb-3">
                                                                <Form.Label><b>Total Price</b> </Form.Label>
                                                                <Form.Control
                                                                    disabled
                                                                    type="number"
                                                                    placeholder="Total Price"
                                                                    name="totalPrice"
                                                                    value={item.totalPrice}
                                                                    onChange={(e) => handleChangeExtraService(e, i, "totalPrice")}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col style={{ marginTop: '23px' }}>
                                                            {inputFieldExtraService.length - 1 === i && (
                                                                <Button title="Add Extra Service" onClick={handleAddInputExtraService} style={{ float: "right" }} >
                                                                    <AddIcon />
                                                                </Button>
                                                            )}
                                                            {inputFieldExtraService.length !== 1 && (
                                                                <Button title="Remove Extra Service" onClick={() => handleRemoveInputExtraService(i)} style={{ float: "right", marginRight: "1%" }}>
                                                                    <RemoveIcon />
                                                                </Button>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                </React.Fragment>)
                                        })}
                                    </Col> */}

                                    <div style={{ marginTop: '20px', textAlign: 'center' }}>
                                        {_.isEmpty(articleId) || _.isEmpty(articleVariationId) || inputFieldProcess.some((fields) => _.isEmpty(fields.processId) || fields.processRate <= 0) || inputFieldProduct.some((fields) => _.isEmpty(fields.productId) || fields.quantity <= 0) ? (
                                            <SaveButton variant="contained" endIcon={<SendIcon />} size="large" disabled> SAVE </SaveButton>
                                        ) : (
                                            <SaveButton variant="contained" endIcon={<SendIcon />} size="large" onClick={SaveExpense}> SAVE </SaveButton>
                                        )}
                                    </div>

                                </Row>
                            </>)}
                        </div>
                    )}
                </div>
            </Container>
        )}
    </>)
}

const mapDispatchToProps = (dispatch) => {
    return {
        AddArticleRecipeApi: (data, handleVisible, handleDangerVisible) => dispatch(AddArticleRecipeApi(data, handleVisible, handleDangerVisible)),
        GetArticleApi: () => dispatch(GetArticleApi()),
        GetArticleWithIDApi: (id) => dispatch(GetArticleWithIDApi(id)),
        getProductData: () => dispatch(getProductData()),
        GetProcessApi: () => dispatch(GetProcessApi()),
        setHeading: (data) => dispatch(setHeading(data)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    isAddingArticleRecipe: state.ArticleReducer.isAddingArticleRecipe,
    isFetchingArticle: state.ArticleReducer.isFetchingArticle,
    articleList: state.ArticleReducer.articleList,
    isFetchingArticleWithID: state.ArticleReducer.isFetchingArticleWithID,
    articleListWithID: state.ArticleReducer.articleListWithID,
    productDataList: state.ProductReducer.productDataList,
    isFetchingProduct: state.ProductReducer.isFetchingProduct,
    processList: state.ProcessReducer.processList,
    isFetchingProcess: state.ProcessReducer.isFetchingProcess,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddArticleRecipe);