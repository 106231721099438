import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { GetOrderCreationApi } from "../orderCreation/OrderCreationAction";
import { GetProcessRequiredApi, ProcessCostRequirementPDF } from "./CostControlAction";
import { setHeading } from "../../../accounts/account.actions";
import _ from "lodash";
import Spinner from "react-bootstrap/Spinner";
import Button from '@mui/material/Button';
import Select from "react-select";
import PrintIcon from '@mui/icons-material/Print';


const ProcessCost = (props) => {
    const [orderId, setOrderId] = useState();
    const [orderName, setOrderName] = useState();

    const handleChangeOrder = (selectedOption) => {
        setOrderId(selectedOption.value);
        setOrderName(selectedOption.label);
        props.GetProcessRequiredApi({ orderId: selectedOption.value })
    };
    const GetOrderCreationList = !_.isEmpty(props.orderCreationList) && props.orderCreationList.map((x) => {
        let data = { value: x._id, label: `${x.sequenceNumber} / ${x.orderNumber}` };
        return data;
    });

    useEffect(() => {
        props.setHeading("Process Requirement");
        props.GetOrderCreationApi()
    }, []);

    const tableStyle = {
        borderCollapse: "collapse",
        width: "90%",
        margin: '0 auto',
    };
    const cellStyle = {
        border: "1px solid black",
        padding: "8px",
        textAlign: "left",
    };

    return (<>
        {props.isFetchingOrderCreation ? (
            <div className="loader-div">
                <Spinner
                    variant="primary"
                    animation="border"
                    size="xl"
                    role="status"
                    aria-hidden="true"
                />
            </div>
        ) : (
            <Container fluid>
                <div>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="garana">
                                <Form.Label><b>Select Order *</b></Form.Label>
                                <Select
                                    isDisabled={_.isEmpty(GetOrderCreationList)}
                                    placeholder="Select Order"
                                    onChange={handleChangeOrder}
                                    options={GetOrderCreationList}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    {props.isFetchingProcessRequirement ? (
                        <div className="loader-div">
                            <Spinner
                                variant="primary"
                                animation="border"
                                size="xl"
                                role="status"
                                aria-hidden="true"
                            />
                        </div>
                    ) : (
                        <div hidden={_.isEmpty(orderId)}>
                            {props.isFetchingProcessRequirement ? (
                                <div className="loader-div">
                                    <Spinner
                                        variant="primary"
                                        animation="border"
                                        size="xl"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </div>
                            ) : (<>

                                <div style={{ width: '100%', backgroundColor: '#aed6ae', padding: '15px', borderRadius: '25px', marginBottom: '25px' }}>
                                    <Row>
                                        <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '16px', fontWeight: 'bold' }}>Order Number: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{_.isUndefined(props.processRequiredList.data) ? '' : props.processRequiredList.data[0].sequenceNumber}</span> </b></Col>
                                        <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '16px', fontWeight: 'bold' }}>Order Date: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{_.isUndefined(props.processRequiredList.data) ? '' : props.processRequiredList.data[0].orderDate}</span> </b></Col>
                                        <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Customer Name: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{_.isUndefined(props.processRequiredList.data) ? '' : props.processRequiredList.data[0].customerDetails.name}</span> </b></Col>
                                    </Row>
                                    <Row>
                                        <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '16px', fontWeight: 'bold' }}>Brand Details: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{_.isUndefined(props.processRequiredList.data) ? '' : props.processRequiredList.data[0].brandDetails.brandName}</span> </b></Col>
                                        <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '16px', fontWeight: 'bold' }}>Order Delivery Date: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{_.isUndefined(props.processRequiredList.data) ? '' : props.processRequiredList.data[0].orderDeliveryDate}</span> </b></Col>
                                        <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '16px', fontWeight: 'bold' }}>Order Shipping Date: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{_.isUndefined(props.processRequiredList.data) ? '' : props.processRequiredList.data[0].orderShippingDate}</span> </b></Col>
                                    </Row>
                                    <Row>
                                        <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '16px', fontWeight: 'bold' }}>Order DeadLine Date: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{_.isUndefined(props.processRequiredList.data) ? '' : props.processRequiredList.data[0].orderDeadLineDate}</span> </b></Col>
                                        <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '16px', fontWeight: 'bold' }}>Description: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{_.isUndefined(props.processRequiredList.data) ? '' : props.processRequiredList.data[0].description}</span> </b></Col>
                                    </Row>
                                </div>

                                <div style={{ marginBottom: '35px' }}>
                                    <Col style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '20px', marginBottom: '15px' }}> Article List </Col>
                                    <table style={tableStyle}>
                                        <thead>
                                            <tr style={{ backgroundColor: '#81abd3' }}>
                                                <th style={cellStyle}>Sr No</th>
                                                <th style={cellStyle}>Article Name</th>
                                                <th style={cellStyle}>Article Variation Name</th>
                                                <th style={cellStyle}>Quantity</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {_.isUndefined(props.processRequiredList.data) ? '' : props.processRequiredList.data[0].articleDetails.map((item, index) => (
                                                <tr key={index}>
                                                    <td style={cellStyle}>{index + 1}</td>
                                                    <td style={cellStyle}>{item.articleName}</td>
                                                    <td style={cellStyle}>{item.articleVariationName}</td>
                                                    <td style={cellStyle}>{item.quantity}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>


                                <div style={{ marginBottom: '35px' }}>
                                    <Col style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '20px', marginBottom: '15px' }}> Process Required List </Col>
                                    <table style={tableStyle}>
                                        <thead>
                                            <tr style={{ backgroundColor: '#81abd3' }}>
                                                <th style={cellStyle}>Sr No</th>
                                                <th style={cellStyle}>Process Name</th>
                                                <th style={cellStyle}>Total Process Cost</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {_.isEmpty(props.processRequiredList.requiredProcessList) ? '' : props.processRequiredList.requiredProcessList.map((item, index) => (
                                                <tr key={index}>
                                                    <td style={cellStyle}>{index + 1}</td>
                                                    <td style={cellStyle}>{item.processName}</td>
                                                    <td style={cellStyle}>{item.processRate}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>

                                <div className="sendDiv">
                                    <Button variant="contained" endIcon={<PrintIcon />} size="large" onClick={ProcessCostRequirementPDF(props.processRequiredList)}> PRINT REQUIRMENT </Button>
                                </div>

                            </>)}
                        </div>
                    )}
                </div>
            </Container>
        )}
    </>)
};

const mapDispatchToProps = (dispatch) => {
    return {
        GetOrderCreationApi: () => dispatch(GetOrderCreationApi()),
        GetProcessRequiredApi: (id) => dispatch(GetProcessRequiredApi(id)),
        setHeading: (data) => dispatch(setHeading(data)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    orderCreationList: state.OrderCreationReducer.orderCreationList,
    isFetchingOrderCreation: state.OrderCreationReducer.isFetchingOrderCreation,
    processRequiredList: state.CostControlReducer.processRequiredList,
    isFetchingProcessRequirement: state.CostControlReducer.isFetchingProcessRequirement,
});

export default connect(mapStateToProps, mapDispatchToProps)(ProcessCost);