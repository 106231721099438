import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, FormGroup } from "react-bootstrap";
import "../add-details.css";
import { connect } from "react-redux";
import { isEmpty, find } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import Select from "react-select";
import Swal from "sweetalert";
import moment from "moment";
import { getRestaurantData } from "../AddResturant/Resturant.action";
import { getProductTypeById, } from "../Recipe/Recipe.action";
import { addReceiving } from "./Receiving.action";
import { getHybridAndCompleteData } from "../InventoryLedger/InventoryLedger.action";
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';


const AddReceving = (props) => {
  const [inputField1, setInputField1] = useState([
    {
      productId: 0,
      ProductName: "",
      availableProductQuantity: 0,
      requiredProductQuantity: 0,
      productPrice: 0,
      marketPrice: 0,
      productTypeName: "",
      ProductVariationId: 0,
      ProductVariationLabel: "",
    },
  ]);
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [wareHouseId, setwareHouseId] = useState("");
  const [wareHouselabel, setwareHouseLabel] = useState("");
  const [maximumQuantity, setMaximumQuantity] = useState(0);
  const [requistion, setRequistion] = useState();
  const [requistionLabel, setRequistionLabel] = useState();
  const [department, setdepartment] = useState();
  const [issuedBy, setIssuedBy] = useState();
  const [receivedBy, setReceivedBy] = useState("");
  const [requistionType, setRequistionType] = useState();
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  const [restaurantId, setRestaurantId] = useState("");
  const [restaurantname, setRestaurantName] = useState("");
  const [dangerVisibleAlertProduct, setDangerVisibleAlertProduct] = useState(false);
  const [barcode, setBarocode] = useState("");


  const handleVisible = (msg) => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  };
  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };

  function uploadReciving() {
    props.addReceiving(
      {
        receivingDate: date,
        // requsitionType: "abc",//requistion,
        // department: "abc",//department,
        //issuedBy:"abc", //issuedBy,
        receivedBy: receivedBy,
        recivingDescription: requistionType,
        receivingData: inputField1,
        restaurantId: restaurantId,
        // barcodeId:barcode,
      },
      handleVisible,
      handleDangerVisible
    );
    setRequistionType("");
    setReceivedBy("");
    setIssuedBy("");
    setRequistionLabel("");
    setdepartment("");
    setRequistion("-1");
    setInputField1([
      {
        productId: 0,
        ProductName: "",
        availableProductQuantity: 0,
        requiredProductQuantity: 0,
        productPrice: 0,
        // uom: "",
        // uomName: "",
        // uomPrice:0,
        // quantity: 0,
        marketPrice: 0,
      },
    ]);
  }

  const handleAddInput = () => {
    setInputField1([
      ...inputField1,
      {
        productId: 0,
        ProductName: "",
        availableProductQuantity: 0,
        requiredProductQuantity: 0,
        productPrice: 0,
        marketPrice: 0,
        ProductVariationId: 0,
        ProductVariationLabel: "",
      },
    ]);
  };
  //remove for add fuction
  const handleRemoveInput = (index) => {
    const list = [...inputField1];
    list.splice(index, 1);
    setInputField1(list);
  };
  const handleChange1 = (e, index, type, itemId) => {
    let z = find(props.hybridAndCompleteData, (x) => x._id == e.value);
    const { name, value } = e;
    const list = [...inputField1];
    if (type == "master") { list[index]["productId"] = e.value; props.getProductTypeById(e.value) };
    if (type == "master") list[index]["ProductName"] = e.label;
    if (type == "master") list[index]["availableProductQuantity"] = e.productQuantityCount;
    if (type == "master") list[index]["productPrice"] = e.productPrice;
    if (type == "master") setMaximumQuantity(Number(z.productBalance));
    if (type == "intquantity") list[index]["requiredProductQuantity"] = e.target.value < 0 ? alert("cannot added less than 0") : Number(e.target.value);
    if (type == "intPrice") list[index]["marketPrice"] = e.target.value;
    // list[index]["productTypeName"] = e.productTypeName;
    if (type == "master") list[index]["productTypeName"] = e.productTypeName;
    if (type == "variation") list[index]["productVariationId"] = e.value;
    if (type == "variation") list[index]["productVariationLabel"] = e.label;
    // if (type == "variation") list[index]["requiredProductQuantity"] = e.label;


    setInputField1(list);
  };

  const handleChangeRestaurant = (selectedOption) => {
    setRestaurantId(selectedOption.value);
    setRestaurantName(selectedOption.label);
  };
  const getRestaurantList = !isEmpty(props.restaurantDataList) && props.restaurantDataList.map((x) => {
    let data = { value: x._id, label: x.restaurantName };
    return data;
  });

  const getMList = !isEmpty(props.hybridAndCompleteData) && props.hybridAndCompleteData.map((x) => {
    let data = {
      value: x._id,
      label: x.name,
      productQuantityCount: x.productBalance,
      productPrice: x.purchasePrice,
      productTypeName: x.productTypeName,
    };
    return data;
  });

  const getVariationList = !isEmpty(props.productTypeListById) && props.productTypeListById[0].variation.map((x) => {
    let data = { value: x._id, label: `${x.colourLabel} / ${x.sizeLabel}` };
    return data;
  });

  // const barcod = (id) => {
  //   let i = find(props.hybridAndCompleteData, { barcodeId: id });
  //   let b;
  //   isEmpty(i)
  //     ? handleDangerVisibleProduct()
  //     : (b = find(getMList, { value: i._id }));
  //   handleChange1(b, inputField1.length - 1, "master");
  // };
  // useScanDetection({
  //   onComplete: barcod,
  //   //  minLength: 13 // EAN13
  // });
  useEffect(() => {
    props.getMatrial();
    props.getRestaurantData();
  }, []);

  return (
    <>
      {props.isFetchingHybridAndCompleteData || props.isAddingReceiving ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {props.isAddingReceiving ? "Saving..." : "Loading..."}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Row>
              <Col>
                <Form.Group className="mb-3" >
                  <Form.Label>
                    <b>Select Company *</b>
                  </Form.Label>
                  <Select
                    isDisabled={isEmpty(props.restaurantDataList)}
                    placeholder="Select Company"
                    onChange={handleChangeRestaurant}
                    options={getRestaurantList}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Date </b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="Date"
                    name="Date"
                    value={date}
                    onChange={(e) => setDate(moment(e.target.value).format("YYYY-MM-DD"))}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Received By *</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Received By"
                    name="receivedBy"
                    value={receivedBy}
                    onChange={(e) => setReceivedBy(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Description</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Description"
                    name="requistionType"
                    value={requistionType}
                    onChange={(e) => setRequistionType(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>

            {inputField1.map((item, i) => {
              console.log(item, "ttttttttttttttttttttttt")
              return (
                <React.Fragment key={i}>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label> <b> Select Product *</b> </Form.Label>
                        <Select
                          style={{ zIndex: 1 }}
                          value={{ label: item.ProductName, }}
                          placeholder="Select Product"
                          name="master"
                          onChange={(e) => handleChange1(e, i, "master")}
                          options={getMList}
                          isOptionDisabled={(option) => (!isEmpty(find(inputField1, (x) => x.productId == option.value)))}
                        />
                      </Form.Group>
                    </Col>

                    {item.productTypeName == "Variation Product" ? <>
                      <Col>
                        <Form.Group>
                          <Form.Label> <b> Select Variation *</b> </Form.Label>
                          <Select
                            // style={{ zIndex: 1 }}
                            // value={{ label: item.productVariationId, }}
                            isDisabled={isEmpty(getVariationList)}
                            placeholder="Select Variation"
                            name="variation"
                            onChange={(e) => handleChange1(e, i, "variation", item.productId)}
                            options={getVariationList}
                          />
                        </Form.Group>
                      </Col>
                      {/* <Col>
                        <Form.Group className="mb-3">
                          <Form.Label> <b>Available Quantity</b> </Form.Label>
                          <Form.Control
                            placeholder="Available Quantity"
                            name="productQuantityCount"
                            disabled
                          // value={getVariationList.productBalance}
                          />
                        </Form.Group>
                      </Col> */}
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Label><b>Add Quantity *</b> </Form.Label>
                          <Form.Control
                            type="number"
                            placeholder="Add Quantity"
                            // name="productQuantity"
                            value={item.requiredProductQuantity}
                            onChange={(e) => handleChange1(e, i, "intquantity", item.productId)}
                          />
                        </Form.Group>
                      </Col>
                    </> :
                      <>
                        <Col>
                          <Form.Group className="mb-3">
                            <Form.Label> <b>Available Quantity *</b> </Form.Label>
                            <Form.Control
                              placeholder="Available Quantity"
                              name="productQuantityCount"
                              disabled
                              value={item.availableProductQuantity}
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group className="mb-3">
                            <Form.Label><b>Add Quantity *</b> </Form.Label>
                            <Form.Control
                              type="number"
                              placeholder="Add Quantity"
                              name="productQuantity"
                              value={item.requiredProductQuantity}
                              onChange={(e) => handleChange1(e, i, "intquantity", item.ProductId)}
                            />
                          </Form.Group>
                        </Col>
                        {/* <Col>
                          <Form.Group className="mb-3">
                            <Form.Label> <b>Price *</b></Form.Label>
                            <Form.Control
                              type="number"
                              placeholder="Price"
                              name="Price"
                              value={item.marketPrice}
                              onChange={(e) => handleChange1(e, i, "intPrice", item.ProductId)}
                            />
                          </Form.Group>
                        </Col> */}
                      </>
                    }

                  </Row>
                  <FormGroup>
                    <Row>
                      <Col lg="12">
                        {inputField1.length - 1 == i && (
                          <Button
                            title="Click to Add Receving"
                            onClick={handleAddInput}
                            style={{
                              float: "right",
                            }}
                          >
                            <AddIcon />
                          </Button>
                        )}

                        {inputField1.length !== 1 && (
                          <Button
                            title="Click to Remove Receving"
                            onClick={() => handleRemoveInput(i)}
                            style={{
                              float: "right",
                              marginRight: "1%",
                            }}
                          >
                            <RemoveIcon />
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </FormGroup>
                </React.Fragment>
              );
            })}

            <div className="sendDiv">
              {isEmpty(restaurantId) || isEmpty(receivedBy) || inputField1[0].productId == "" ? (
                <Button variant="contained" endIcon={<SendIcon />} size="large" disabled> SAVE </Button>
              ) : (
                <Button variant="contained" endIcon={<SendIcon />} size="large" onClick={uploadReciving} > SAVE </Button>
              )}
            </div>

          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    addReceiving: (data, handleVisible, handleDangerVisible) => dispatch(addReceiving(data, handleVisible, handleDangerVisible)),
    getMatrial: () => dispatch(getHybridAndCompleteData()),
    getRestaurantData: () => dispatch(getRestaurantData()),
    getProductTypeById: (data) => dispatch(getProductTypeById(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  hybridAndCompleteData: state.InventoryLegderReducer.hybridAndCompleteData,
  isFetchingHybridAndCompleteData: state.InventoryLegderReducer.isFetchingHybridAndCompleteData,
  restaurantDataList: state.ResturantReducer.restaurantDataList,
  isAddingReceiving: state.RecivingReducer.isAddingReceiving,
  productTypeListById: state.RecipeReducer.productTypeListById,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddReceving);
