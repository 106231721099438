import React, { useEffect, useState } from "react";
import { Container, } from "react-bootstrap";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import { baseURL, headers } from "../../../../../global/api";
import { deleteRestaurant, getRestaurantData, updateRestaurant } from "./Resturant.action";
import { setHeading } from "../../../accounts/account.actions";


const ViewResturant = (props) => {
  const permissions = JSON.parse(window.localStorage.getItem("permissions"))
  const Checkpermissions = (permissionsType, permissionsName) => {
    return permissions.some(p => p[`${permissionsType}`] === permissionsName) ? false : true
  }
  useEffect(() => {
    props.setHeading("Company List");
  }, []);
  return (
    <>
      {props.isFetchingRestaurantData ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <MaterialTable
              title="Company Details"
              columns={[
                // { title: 'Restaurant Id', field: 'restaurant_id', editable: 'never' },
                { title: 'Image', field: 'image', editable: 'never', render: rowData => <img src={rowData.image} alt="resImg" style={{ width: 50, borderRadius: '50%' }} /> },
                { title: 'Company Name', field: 'restaurantName' },
                { title: 'Company Location', field: 'restaurantLocation' },
                { title: 'Description', field: 'restaurantDesc' },
                { title: 'tax', field: 'tax' },
                { title: 'NTN Number', field: 'ntn' },
                { title: 'Tax Type', field: 'taxType', },
                { title: 'PRA On Cash', field: 'praTaxCash' },
                { title: 'PRA On Card', field: 'praTaxCard' },
                { title: 'FBR On Cash', field: 'fbrTaxCash' },
                { title: 'FBR On Card', field: 'fbrTaxCard' },
                { title: 'Email', field: 'email' },
                { title: 'Phone', field: 'phone' },
                { title: 'STRN', field: 'strn' },
              ]}
              options={{
                actionsColumnIndex: -1,
                exportButton: true,
                exportAllData: true,
                paging: true,
                pageSize: 10,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [10, 20, 50],
                headerStyle: {
                  position: 'sticky', top: 0,
                  color: '#00BBBB',
                  fontWeight: '550',
                  onRowAdd: 'none',
                },
              }}
              data={(query) =>
                new Promise((resolve, reject) => {
                  let url = `${baseURL}/restaurant?`;
                  url += "limit=" + query.pageSize;
                  url += "&page=" + (query.page);
                  url += "&search=" + query.search;
                  fetch(url, {
                    method: 'GET',
                    headers: headers,
                  }).then((response) => response.json()).then((result) => {
                    resolve({
                      data: result.data,
                      page: result.page,
                      totalCount: result.total_results,
                    });
                  });
                })
              }
              editable={{
                isEditHidden: rowData => Checkpermissions("permissionsSubCatName", "Company Edit"),
                isDeleteHidden: rowData => Checkpermissions("permissionsSubCatName", "Company Delete"),
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      {
                        props.updateRestaurant(newData, oldData)
                      }
                    }, 600);
                  }),
                // onRowDelete: (oldData) =>
                //   new Promise((resolve) => {
                //     setTimeout(() => {
                //       resolve();
                //       props.deleteRestaurant(oldData._id);
                //     }, 600);
                //   }),
              }}
            />
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getRestaurantData: () => dispatch(getRestaurantData()),
    deleteRestaurant: (restaurantId) => dispatch(deleteRestaurant(restaurantId)),
    updateRestaurant: (newData, oldData) => dispatch(updateRestaurant(newData, oldData)),
    setHeading: (data) => dispatch(setHeading(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  restaurantDataList: state.ResturantReducer.restaurantDataList,
  isFetchingRestaurant: state.ResturantReducer.isFetchingRestaurant,
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewResturant);
