const initialState = {
    UploadFilesURL: [],
    isAddingUploadImage: false,
};

const UploadFilesReducer = (state = initialState, action) => {
    switch (action.type) {

        case "REQUEST_ADD_FILES":
            return { ...state, isAddingUploadImage: true };
        case "SUCCESS_ADD_FILES":
            return { ...state, UploadFilesURL: state.UploadFilesURL.concat(action.payload), isAddingUploadImage: false, };
        case "ERROR_ADD_FILES":
            return { ...state, isAddingUploadImage: false };
            
        case "RESET_URL":
            return { ...state, UploadFilesURL: [] }

        default:
            return state;
    }
};
export default UploadFilesReducer;