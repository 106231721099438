import React, { useEffect, useRef } from "react";
import { Container, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { UpdateDepartmentApi, DeleteDepartmentApi } from "./departmentAction";
import { setHeading } from "../../../accounts/account.actions";
import { baseURL, headers } from "../../../../../global/api";
// import _ from "lodash";
import Spinner from 'react-bootstrap/Spinner';
import MaterialTable from 'material-table';


const DepartmentList = (props) => {
    const tableRef = useRef();
    function updateTableQuery() {
        tableRef.current.onQueryChange()
    };
    useEffect(() => {
        props.setHeading("Department List")
    });
    return (
        <>
            {(props.isFetchingDepartmentData) ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />
                    </Button>
                </div>
            ) : (
                <Container fluid>
                    <MaterialTable
                        title="Department"
                        tableRef={tableRef}
                        columns={[
                            { title: 'Division Name', field: 'divisionDetails.name', editable: 'never' },
                            { title: 'Department Name', field: 'department' },
                            { title: 'Department Description', field: 'description' },
                        ]}
                        data={(query) =>
                            new Promise((resolve, reject) => {
                                let url = `${baseURL}/department?`;
                                url += "limit=" + query.pageSize;
                                url += "&page=" + query.page;
                                url += "&search=" + query.search;
                                fetch(url, {
                                    method: "GET",
                                    headers: headers,
                                }).then((response) => response.json()).then((result) => {
                                    resolve({
                                        data: result.data,
                                        page: result.page,
                                        totalCount: result.total_results,
                                    });
                                });
                            })
                        }
                        options={{
                            actionsColumnIndex: -1,
                            filtering: false,
                            exportButton: true,
                            exportAllData: true,
                            sorting: true,
                            paging: true,
                            pageSize: 50,
                            emptyRowsWhenPaging: false,
                            pageSizeOptions: [50, 100, 150, 200, 500],
                            headerStyle: {
                                position: 'sticky', top: 0,
                                color: '#00BBBB',
                                fontWeight: '550',
                                onRowAdd: 'none',
                            },
                        }}
                        editable={{
                            onRowUpdate: (newData, oldData) =>
                                new Promise((resolve) => {
                                    setTimeout(() => {
                                        resolve();
                                        props.UpdateDepartmentApi(newData, oldData, updateTableQuery)
                                    }, 600);
                                }),
                            onRowDelete: (oldData) =>
                                new Promise((resolve) => {
                                    setTimeout(() => {
                                        resolve();
                                        props.DeleteDepartmentApi(oldData._id, updateTableQuery);
                                    }, 600);
                                }),
                        }}
                    />
                </Container>
            )}
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        UpdateDepartmentApi: (newData, oldData, updateTableQuery) => dispatch(UpdateDepartmentApi(newData, oldData, updateTableQuery)),
        DeleteDepartmentApi: (departmentId, updateTableQuery) => dispatch(DeleteDepartmentApi(departmentId, updateTableQuery)),
        setHeading: (data) => dispatch(setHeading(data)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    departmentList: state.DepartmentReducer.departmentList,
    isFetchingDepartmentData: state.DepartmentReducer.isFetchingDepartmentData,
    isFetchingDivisionData: state.DepartmentReducer.isFetchingDivisionData,
    isDeletingDepartmentData: state.DepartmentReducer.isDeletingDepartmentData,
});

export default connect(mapStateToProps, mapDispatchToProps)(DepartmentList);