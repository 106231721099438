import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { GetEmployeeApi } from "../employee/employeeAction";
import { setHeading } from "../../../accounts/account.actions";
import { AddFineApi } from "./FineAndPenaltyAction";
import _ from "lodash";
import Spinner from 'react-bootstrap/Spinner';
import Select from 'react-select';
import Swal from 'sweetalert';
import moment from "moment";
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';


const AddFineAndPenalty = (props) => {
    const [employeeId, setEmployeeId] = useState("");
    const [employeeName, setEmployeeName] = useState("");
    const [reason, setReason] = useState('');
    const [amountDeduct, setAmountDeduct] = useState('');
    const [date, setDate] = useState('');
    const [visibleAlert, setAlertVisible] = useState(false);
    const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

    function SaveFine() {
        props.AddFineApi({
            employeeId: employeeId,
            employeeName: employeeName,
            amountDeduct: Number(amountDeduct),
            date: date,
            reasonFine: reason,
        },
            handleVisible,
            handleDangerVisible
        );
    };
    const handleVisible = (message) => {
        setAlertVisible(true)
        clearStates();
        setTimeout(() => {
            setAlertVisible(false)
        }, 9000);
        Swal({
            title: "Success",
            text: message,
            icon: "success",
            button: "Ok",
        });
    }
    const handleDangerVisible = (error) => {
        setDangerVisibleAlert(true)
        setTimeout(() => {
            setDangerVisibleAlert(false)
        }, 9000);
        Swal({
            title: "Error",
            text: error,
            icon: "error",
            button: "Ok",
        });
    }
    const clearStates = () => {
        setEmployeeId("");
        setReason("");
        setAmountDeduct("");
        setDate('');
    }

    const getEmployeeList = !_.isEmpty(props.employeeList) && props.employeeList.map((x) => {
        let data = {
            value: x._id,
            label: `${x.employeeName} / ${x.fatherName} / ${x.cnic}`,
        };
        return data;
    });
    const handleChangeEmployee = (selectedOption) => {
        setEmployeeId(selectedOption.value);
        setEmployeeName(selectedOption.label);
    };

    useEffect(() => {
        props.GetEmployeeApi();
        props.setHeading("Fine & Penalties");
    }, []);

    return (
        <Container fluid>
            {props.isFetchingEmployeeData || props.isAddingFine ? (
                <div className="loader-div">
                    <Spinner
                        animation="border"
                        size="xl"
                        role="status"
                        aria-hidden="true"
                        variant="info"
                    />
                </div>
            ) : (
                <div className="main">
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" >
                                <Form.Label><b>Select Employee *</b></Form.Label>
                                <Select
                                    placeholder="Select Employee"
                                    onChange={handleChangeEmployee}
                                    options={getEmployeeList}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" >
                                <Form.Label><b>Fine Amount *</b></Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="Fine Amount"
                                    onChange={(e) => setAmountDeduct(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" controlId="garana" >
                                <Form.Label><b>Date *</b></Form.Label>
                                <Form.Control
                                    type="date"
                                    min={moment().subtract(1, 'months').format('YYYY-MM-DD')}
                                    max={moment().subtract(0, 'months').format('YYYY-MM-DD')}
                                    placeholder="Date"
                                    onChange={(e) => setDate(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" controlId="garana" >
                                <Form.Label><b>Reason *</b></Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Reason"
                                    onChange={(e) => setReason(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <div className="sendDiv">
                        {_.isEmpty(employeeId) || _.isEmpty(reason) || _.isEmpty(date) || _.isEmpty(amountDeduct) ? (
                            <Button variant="contained" endIcon={<SendIcon />} size="large" disabled> SAVE </Button>
                        ) : (
                            <Button variant="contained" endIcon={<SendIcon />} size="large" onClick={() => { SaveFine(); }}> SAVE </Button>
                        )}
                    </div>

                </div>
            )}
        </Container>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        AddFineApi: (data, handleVisible, handleDangerVisible) => dispatch(AddFineApi(data, handleVisible, handleDangerVisible)),
        GetEmployeeApi: () => dispatch(GetEmployeeApi()),
        setHeading: (data) => dispatch(setHeading(data)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    isAddingFine: state.FineReducer.isAddingFine,
    employeeList: state.EmployeeReducer.employeeList,
    isFetchingEmployeeData: state.EmployeeReducer.isFetchingEmployeeData
});

export default connect(mapStateToProps, mapDispatchToProps)(AddFineAndPenalty);