import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import { connect } from "react-redux";
import _ from "lodash";
import { GetCRMCustomerApi, AddCRMCustomerCommentApi } from "./CustomerActionCRM";
import { setHeading } from "../../../accounts/account.actions";
import Spinner from "react-bootstrap/Spinner";
import Swal from "sweetalert";
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import Select from "react-select";


const UpdateCustomerCRM = (props) => {
    const [customerId, setCustomerId] = useState("");
    const [status, setStatus] = useState("");
    const [description, setDescription] = useState("");
    const [visibleAlert, setAlertVisible] = useState(false);
    const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);


    function UpdateCRMCustomer() {
        props.AddCRMCustomerCommentApi({
            customerId: customerId,
            status: status,
            description: description,
        },
            handleVisible,
            handleDangerVisible
        );
        setCustomerId("")
        setStatus("")
        setDescription("")
    }
    const handleVisible = (msg) => {
        setAlertVisible(true);
        setTimeout(() => {
            setAlertVisible(false);
        }, 9000);
        Swal({
            title: "Success",
            text: msg,
            icon: "success",
            button: "Ok",
        });
    };
    const handleDangerVisible = (err) => {
        setDangerVisibleAlert(true);
        setTimeout(() => {
            setDangerVisibleAlert(false);
        }, 9000);
        Swal({
            title: "Error",
            text: err,
            icon: "error",
            button: "Ok",
        });
    };

    const handleChangeCustomer = (selectedOption) => {
        setCustomerId(selectedOption.value);
    };
    const getCustomerList = !_.isEmpty(props.CRMCustomerList) && props.CRMCustomerList.map((x) => {
        let data = { value: x._id, label: `${x.sequenceNumber} / ${x.companyName}` };
        return data;
    });

    let statusData = [{
        id: "Meeting",
        type: "Meeting",
    },
    {
        id: "Positive Response",
        type: "Positive Response",
    },
    {
        id: "Call Again",
        type: "Call Again",
    },
    {
        id: "No Response",
        type: "No Response",
    },
    {
        id: "Dead",
        type: "Dead",
    },]
    const handleChangeStatus = (selectedOption) => {
        setStatus(selectedOption.label);
    };
    const GetStatusList = statusData.map((x) => {
        let data = { value: x.id, label: x.type };
        return data;
    });

    useEffect(() => {
        props.setHeading("Add Comment");
        props.GetCRMCustomerApi()
    }, []);

    return (
        <>
            {props.isAddingCRMCustomerComment || props.isFetchingCRMCustomer ? (
                <div className="loader-div">
                    <Spinner
                        variant="primary"
                        animation="border"
                        size="xl"
                        role="status"
                        aria-hidden="true"
                    />
                </div>
            ) : (
                <Container fluid>
                    <Form>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="garana">
                                    <Form.Label><b>Select Customer *</b></Form.Label>
                                    <Select
                                        isDisabled={_.isEmpty(getCustomerList)}
                                        placeholder="Select Customer"
                                        onChange={handleChangeCustomer}
                                        options={getCustomerList}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="garana">
                                    <Form.Label><b>Status *</b></Form.Label>
                                    <Select
                                        placeholder="Status"
                                        onChange={handleChangeStatus}
                                        options={GetStatusList}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="garana">
                                    <Form.Label><b>Comment </b></Form.Label>
                                    <Form.Control
                                        type="text"
                                        as="textarea"
                                        rows={3}
                                        placeholder="Comment"
                                        onChange={(e) => setDescription(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <div className="sendDiv">
                            {_.isEmpty(customerId) || _.isEmpty(status) ? (
                                <Button variant="contained" endIcon={<SendIcon />} size="large" disabled> SAVE </Button>
                            ) : (
                                <Button variant="contained" endIcon={<SendIcon />} size="large" onClick={UpdateCRMCustomer}> SAVE</Button>
                            )}
                        </div>

                    </Form>
                </Container>
            )}
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        AddCRMCustomerCommentApi: (data, handleVisible, handleDangerVisible) => dispatch(AddCRMCustomerCommentApi(data, handleVisible, handleDangerVisible)),
        GetCRMCustomerApi: () => dispatch(GetCRMCustomerApi()),
        setHeading: (data) => dispatch(setHeading(data)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    isAddingCRMCustomerComment: state.CustomerReducerCRM.isAddingCRMCustomerComment,
    isFetchingCRMCustomer: state.CustomerReducerCRM.isFetchingCRMCustomer,
    CRMCustomerList: state.CustomerReducerCRM.CRMCustomerList,
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateCustomerCRM);