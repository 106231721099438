import API from "../../../../../global/api";


export const requestAddProductionIssuance = () => {
    return {
        type: "REQUEST_ADD_PRODUCTION_ISSUANCE",
    };
};
export const successAddProductionIssuance = (data) => {
    return {
        type: "SUCCESS_ADD_PRODUCTION_ISSUANCE",
        payload: data,
    };
};
export const errorAddProductionIssuance = () => {
    return {
        type: "ERROR_ADD_PRODUCTION_ISSUANCE",
    };
};
export const AddProductionIssuanceApi = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddProductionIssuance());
        API.post(`/productionIssuance`, data).then((res) => {
            dispatch(successAddProductionIssuance(data));
            handleVisible(res.data.message);
        }).catch((error) => {
            dispatch(errorAddProductionIssuance());
            handleDangerVisible(error.response.data.message);
        })
    };
};


export const requestGetProductionIssuance = () => {
    return {
        type: "REQUEST_GET_PRODUCTION_ISSUANCE",
    };
};
export const successGetProductionIssuance = (data) => {
    return {
        type: "SUCCESS_GET_PRODUCTION_ISSUANCE",
        payload: data,
    };
};
export const errorGetProductionIssuance = () => {
    return {
        type: "ERROR_GET_PRODUCTION_ISSUANCE",
    };
};
export const GetProductionIssuanceApi = () => {
    return (dispatch) => {
        dispatch(requestGetProductionIssuance());
        API.get(`/productionIssuance`).then((res) => {
            let getData = res.data;
            dispatch(successGetProductionIssuance(getData));
        }).catch((error) => {
            dispatch(errorGetProductionIssuance());
        })
    };
};