const initialState = {
  vendorList: [],
  vendorDataList: [],
  isFetchingVendor: false,
  isAddingVendor: false,
};

const VendorReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REQUEST_VENDOR_GET_DATA":
      return { ...state, isFetchingVendor: true, };
    case "SUCCESS_VENDOR_GET_DATA":
      return { ...state, vendorDataList: action.payload.data, isFetchingVendor: false };
    case "ERROR_VENDOR_GET_DATA":
      return { ...state, };


    case "REQUEST_ADD_VENDOR":
      return { ...state, isAddingVendor: true };
    case "SUCCESS_ADD_VENDOR":
      return { ...state, vendorList: action.payload, isAddingVendor: false, };
    case "ERROR_ADD_VENDOR":
      return { ...state, isAddingVendor: false };

      
    default:
      return state;
  }
};
export default VendorReducer;