import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { AddDepartmentApi } from "./departmentAction";
import { GetDivisionApi } from "../division/divisionAction";
import { setHeading } from "../../../accounts/account.actions";
import _ from "lodash";
import Spinner from 'react-bootstrap/Spinner';
import Select from 'react-select';
import Swal from 'sweetalert';
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';


const AddDepartment = (props) => {
    const [divisionId, setDivisionId] = useState(0);
    const [divisionName, setDivisionName] = useState();
    const [departmentName, setDepartmentName] = useState();
    const [departmentDescription, setDepartmentDescription] = useState();
    const [visibleAlert, setAlertVisible] = useState(false);
    const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);


    function uploadDepartment() {
        props.AddDepartmentApi({
            divisionId: divisionId,
            department: _.upperCase(departmentName),
            description: _.upperCase(departmentDescription),
        }, handleVisible, handleDangerVisible);
    }
    const clearStates = () => {
        setDivisionId(0);
        setDivisionName();
        setDepartmentName();
        setDepartmentDescription();
    }
    const handleVisible = (message) => {
        setAlertVisible(true)
        clearStates()
        setTimeout(() => {
            setAlertVisible(false)
        }, 9000);
        Swal({
            title: "Success",
            text: message,
            icon: "success",
            button: "Ok",
        });
    }
    const handleDangerVisible = (error) => {
        setDangerVisibleAlert(true)
        setTimeout(() => {
            setDangerVisibleAlert(false)
        }, 9000);
        Swal({
            title: "Error",
            text: error,
            icon: "error",
            button: "Ok",
        });
    }

    const handleChangeDivision = (selectedOption) => {
        setDivisionId(selectedOption.value);
        setDivisionName(selectedOption.label);
    }
    const getDivisionList = !_.isEmpty(props.divisionList) && (props.divisionList.map((x) => {
        let data = { value: x._id, label: x.name };
        return data;
    }));

    useEffect(() => {
        props.setHeading("Department Add")
        props.GetDivisionApi();
    },[]);
    return (
        <>
            {(props.isFetchingDivisionData || props.isAddingDepartmentData) ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />
                    </Button>
                </div>
            ) : (
                <Container fluid>
                    <Form>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Select Division *</b></Form.Label>
                                    <Select
                                        placeholder="Select Division"
                                        onChange={handleChangeDivision}
                                        options={getDivisionList}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Department Name *</b></Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Department Name"
                                        onChange={(e) => setDepartmentName(e.target.value)}
                                        disabled={_.isEmpty(divisionName)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>
                                        <b>Department Description</b>
                                    </Form.Label>
                                    <Form.Control as="textarea"
                                        rows={3}
                                        placeholder="Department Description"
                                        onMouseLeave={(e) => setDepartmentDescription(e.target.value)}
                                        disabled={_.isEmpty(divisionName)} />
                                </Form.Group>
                            </Col>
                        </Row>

                        <div className="sendDiv">
                            {_.isEmpty(divisionName) || _.isEmpty(departmentName) ? (
                                <Button variant="contained" endIcon={<SendIcon />} size="large" disabled> SAVE </Button>
                            ) : (
                                <Button variant="contained" endIcon={<SendIcon />} size="large" onClick={() => { uploadDepartment(); }}> SAVE </Button>
                            )}
                        </div>

                    </Form>
                </Container>
            )}
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        AddDepartmentApi: (data, handleVisible, handleDangerVisible) => dispatch(AddDepartmentApi(data, handleVisible, handleDangerVisible)),
        GetDivisionApi: () => dispatch(GetDivisionApi()),
        setHeading: (data) => dispatch(setHeading(data)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    isFetchingDivisionData: state.DivisionReducer.isFetchingDivisionData,
    divisionList: state.DivisionReducer.divisionList,
    isAddingDepartmentData: state.DepartmentReducer.isAddingDepartmentData,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddDepartment);