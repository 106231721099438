import React, { useState } from 'react'
import { Container, Form, Row, Col } from 'react-bootstrap';
import { AddDivisionApi } from './divisionAction';
import { connect } from "react-redux";
import Spinner from 'react-bootstrap/Spinner';
import { useEffect } from 'react';
import Swal from 'sweetalert';
import _ from "lodash";
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import { setHeading } from '../../../accounts/account.actions';


const AddDivision = (props) => {
    const [divisionName, setDivisionName] = useState();
    const [divisionDescription, setDivisionDescription] = useState();
    const [submit, setSubmit] = useState(false);
    const [visibleAlert, setAlertVisible] = useState(false);
    const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);


    function uploadDivision() {
        props.AddDivisionApi({
            name: _.upperCase(divisionName),
            description: _.upperCase(divisionDescription),
        }, handleVisible, handleDangerVisible);
    }
    const clearStates = () => {
        setDivisionName();
        setDivisionDescription();
        setSubmit(false);
    }
    const handleVisible = (message) => {
        setAlertVisible(true)
        clearStates();
        setTimeout(() => {
            setAlertVisible(false)
        }, 9000);
        Swal({
            title: "Success",
            text: message,
            icon: "success",
            button: "Ok",
        });
    }
    const handleDangerVisible = (error) => {
        setDangerVisibleAlert(true)
        setTimeout(() => {
            setDangerVisibleAlert(false)
        }, 9000);
        Swal({
            title: "Error",
            text: error,
            icon: "error",
            button: "Ok",
        });
    }

    useEffect(() => {
        props.setHeading("Division Add");
    });
    return (
        <>
            {(props.isFetchingDivisionData || props.isAddingDivisionData) ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />
                    </Button>
                </div>
            ) : (
                <Container fluid>
                    <Form>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Division Name *</b></Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Division Name"
                                        onChange={(e) => setDivisionName(e.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>
                                        <b>Division Description</b>
                                    </Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        placeholder="Division Description"
                                        onBlur={(e) => setDivisionDescription(e.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row>

                        <div className="sendDiv">
                            {_.isEmpty(divisionName) ? (
                                <Button variant="contained" endIcon={<SendIcon />} size="large" disabled> SAVE </Button>
                            ) : (
                                <Button variant="contained" endIcon={<SendIcon />} size="large" onClick={() => { uploadDivision(); setSubmit(true); }}>SAVE </Button>
                            )}
                        </div>
                    </Form>
                </Container>
            )}
        </>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        AddDivisionApi: (data, handleVisible, handleDangerVisible) => dispatch(AddDivisionApi(data, handleVisible, handleDangerVisible)),
        setHeading: (data) => dispatch(setHeading(data)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    isAddingDivisionData: state.DivisionReducer.isAddingDivisionData,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddDivision);