import React, { useEffect, useRef } from "react";
import { Form, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { DeleteIncrementApi, UpdateIncrementApi } from "./incrementAction";
import { baseURL, headers } from "../../../../../global/api";
import { setHeading } from "../../../accounts/account.actions";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";


const IncrementList = (props) => {
    const tableRef = useRef();
    function updateTableQuery() {
        tableRef.current.onQueryChange()
    };

    useEffect(() => {
        props.setHeading("Increment List")
    }, []);

    return (
        <>
            {props.isFetchingIncrement ? (
                <div className="loader-div">
                    <Spinner
                        animation="border"
                        size="xl"
                        role="status"
                        aria-hidden="true"
                        variant="info"
                    />
                </div>
            ) : (
                <Container fluid>
                    <div className="main">
                        <Form>
                            <MaterialTable
                                title="Increment List"
                                columns={[
                                    { title: "Employee Name", field: "employeeDetails.employeeName", editable: 'never' },
                                    { title: "Type", field: "type", editable: 'never' },
                                    { title: "Date", field: "date", editable: 'never' },
                                    { title: "Value", field: "value" },
                                    { title: "Merge Status", field: "isMerge", render: (rowData) => rowData.isMerge ? "Merged" : "Unmerged", editable: 'never' },
                                ]}
                                data={(query) =>
                                    new Promise((resolve, reject) => {
                                        let url = `${baseURL}/increment?`;
                                        url += "size=" + query.pageSize;
                                        url += "&page=" + query.page;
                                        url += "&search=" + query.search;
                                        fetch(url, {
                                            method: "GET",
                                            headers: headers,
                                        }).then((response) => response.json()).then((result) => {
                                            resolve({
                                                data: result.data,
                                                page: result.page,
                                                totalCount: result.total_results,
                                            });
                                        });
                                    })
                                }
                                options={{
                                    actionsColumnIndex: -1,
                                    exportButton: true,
                                    exportAllData: true,
                                    paging: true,
                                    pageSize: 50,
                                    emptyRowsWhenPaging: false,
                                    pageSizeOptions: [50, 100, 150, 200],
                                    headerStyle: {
                                        position: "sticky",
                                        top: 0,
                                        color: "#00BBBB",
                                        fontWeight: "550",
                                        onRowAdd: "none",
                                    },
                                }}
                                editable={{
                                    isEditable: (rowData) => rowData.isMerge === true ? false : true,
                                    isDeletable: (rowData) => rowData.isMerge === true ? false : true,
                                    onRowUpdate: (newData, oldData) =>
                                        new Promise((resolve) => {
                                            setTimeout(() => {
                                                resolve();
                                                props.UpdateIncrementApi(newData, oldData)
                                            }, 600);
                                        }),
                                    onRowDelete: (oldData) =>
                                        new Promise((resolve) => {
                                            setTimeout(() => {
                                                resolve();
                                                props.DeleteIncrementApi(oldData, updateTableQuery);
                                            }, 600);
                                        }),
                                }}
                            />
                        </Form>
                    </div>
                </Container>
            )}
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        UpdateIncrementApi: (newData, oldData, updateTableQuery) => dispatch(UpdateIncrementApi(newData, oldData, updateTableQuery)),
        DeleteIncrementApi: (data, updateTableQuery) => dispatch(DeleteIncrementApi(data, updateTableQuery)),
        setHeading: (data) => dispatch(setHeading(data)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    isFetchingIncrement: state.IncrementReducer.isFetchingIncrement,
});

export default connect(mapStateToProps, mapDispatchToProps)(IncrementList);