import { filter, isEmpty, toLower } from "lodash";
const initialState = {
//   warehouseList: [],
//   warehouse: [],
//   warehouseListWithResId: [],
  isAddingFBRCredential: false,
//   dataAddedWarehouse: false,
//   dataNotAddedWarehouse: false,
//   isFetchingWarehouseList: false,
//   isFetchingWarehouseListWithResId: false,
};

const FBRIntegrationReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REQUEST_UPDATE_RESTAURANT":
      return {
        ...state,
        isAddingFBRCredential: true,
        // dataAddedWarehouse: false,
        // dataNotAddedWarehouse: false,
      };
    case "SUCCESS_UPDATE_RESTAURANT":
      return {
        ...state,
        // warehouse: action.payload,
        isAddingFBRCredential: false,
        // dataAddedWarehouse: true,
        // dataNotAddedWarehouse: false,
      };
    case "ERROR_UPDATE_RESTAURANT":
      return {
        ...state,
        isAddingFBRCredential: false,
        // dataAddedWarehouse: false,
        // dataNotAddedWarehouse: true
      };


    default:
      return state;
  }
};
export default FBRIntegrationReducer;