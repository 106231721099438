import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { addFlavors } from "./Colour.action";
import { setHeading } from "../../../accounts/account.actions";
import _ from 'lodash'
import Spinner from "react-bootstrap/Spinner";
import Swal from "sweetalert";
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';


const AddColour = (props) => {
  const [colourName, setColour] = useState("");
  const [colourDesc, setColourDesc] = useState("");
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  function uploadColour() {
    props.addFlavors({
      colourName: colourName,
      colourDesc: colourDesc,
    },
      handleVisible,
      handleDangerVisible
    );
    setColour("")
    setColourDesc("");
  }
  const handleVisible = (msg) => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  };
  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };

  useEffect(() => {
    props.setHeading("Add Colour");
  }, []);

  return (
    <>
      {props.isAddingFlavors ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <div>
              <Form>
                <Row>
                  <Col lg="6" md="6" xs="6">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label><b>Colour Name *</b></Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Colour Name"
                        onChange={(e) => setColour(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="6" md="6" xs="6">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label><b>Colour Description </b></Form.Label>
                      <Form.Control
                        type="text"
                        value={colourDesc}
                        placeholder="Colour Description"
                        onChange={(e) => setColourDesc(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <div className="sendDiv">
                  {_.isEmpty(colourName) ? (
                    <Button variant="contained" endIcon={<SendIcon />} size="large" disabled> SAVE </Button>
                  ) : (
                    <Button variant="contained" endIcon={<SendIcon />} size="large" onClick={uploadColour}> SAVE </Button>
                  )}
                </div>

              </Form>
            </div>
          </div>
        </Container>
      )}
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    addFlavors: (data, handleVisible, handleDangerVisible) => dispatch(addFlavors(data, handleVisible, handleDangerVisible)),
    setHeading: (data) => dispatch(setHeading(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  flavorsList: state.ColourReducer.flavorsList,
  isAddingFlavors: state.ColourReducer.isAddingFlavors,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddColour);