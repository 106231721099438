import API from "../../../../../global/api";
// import _ from "lodash";


export const requestAddShiftControl = () => {
    return {
        type: "REQUEST_ADD_SHIFT_CONTROL",
    };
};
export const successAddShiftControl = (data) => {
    return {
        type: "SUCCESS_ADD_SHIFT_CONTROL",
        payload: data,
    };
};
export const errorAddShiftControl = () => {
    return {
        type: "ERROR_ADD_SHIFT_CONTROL",
    };
};
export const AddshiftControlApi = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddShiftControl());
        API.post(`/shiftControl`, data)
            .then((res) => {
                dispatch(successAddShiftControl(data));
                handleVisible(res.data.message);
            }).catch((error) => {
                dispatch(errorAddShiftControl());
                handleDangerVisible(error.response.data.message);
            });
    };
};


export const requestGetShiftControl = () => {
    return {
        type: "REQUEST_GET_SHIFT_CONTROL",
    };
};
export const successGetShiftControl = (data) => {
    return {
        type: "SUCCESS_GET_SHIFT_CONTROL",
        payload: data,
    };
};
export const errorGetShiftControl = () => {
    return {
        type: "ERROR_GET_SHIFT_CONTROL",
    };
};
export const GetshiftControlApi = () => {
    return (dispatch) => {
        dispatch(requestGetShiftControl());
        API.get(`/shiftControl`).then((res) => {
            let getData = res.data;
            dispatch(successGetShiftControl(getData));
        }).catch((error) => {
            dispatch(errorGetShiftControl());
        })
    };
};


export const requestDeleteShiftControl = () => {
    return {
        type: "REQUEST_DELETE_SHIFT_CONTROL",
    };
};
export const successDeleteShiftControl = (shiftId) => {
    return {
        type: "SUCCESS_DELETE_SHIFT_CONTROL",
        shift_control_id: shiftId,
    };
};
export const errorDeleteShiftControl = () => {
    return {
        type: "ERROR_DELETE_SHIFT CONTROL",
    };
};
export const DeleteshiftControlApi = (shiftId, updateTableQuery) => {
    return dispatch => {
        dispatch(requestDeleteShiftControl());
        API.delete(`/shiftControl/${shiftId}`)
            .then(res => {
                updateTableQuery()
                dispatch(successDeleteShiftControl(shiftId))
            }).catch((error) =>
                dispatch(errorDeleteShiftControl()));
    }
};


export const requestUpdateShiftControl = () => {
    return {
        type: "REQUEST_UPDATE_SHIFT_CONTROL",
    };
};
export const successUpdateShiftControl = (data) => {
    return {
        type: "SUCCESS_UPDATE_SHIFT_CONTROL",
        payload: data,
    };
};
export const errorUpdateShiftControl = () => {
    return {
        type: "ERROR_UPDATE_SHIFT_CONTROL",
    };
};
export const UpdateshiftControlApi = (data, oldData, updateTableQuery) => {
    return (dispatch) => {
        dispatch(requestUpdateShiftControl());
        API.put(`/shiftControl/${oldData._id}`, data)
            .then((res) => {
                updateTableQuery()
                dispatch(successUpdateShiftControl(data));
            }).catch((error) => {
                dispatch(errorUpdateShiftControl());
            });
    };
};