import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { setHeading } from "../../../accounts/account.actions";
import { getSizeData } from "../../AddDetails/AddSize/Size.action";
import { getFlavorsData } from "../../AddDetails/AddColour/Colour.action";
import { AddSubArticleApi, GetArticleApi } from "./ArticleAction";
import _ from "lodash";
import Spinner from "react-bootstrap/Spinner";
import Select from "react-select";
import Swal from "sweetalert";
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";


const AddSubArticle = (props) => {
    const [articleId, setArticleId] = useState();
    const [visibleAlert, setAlertVisible] = useState(false);
    const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
    const [inputField, setInputField] = useState([{
        manualVariationCode: '',
        colourId: 0,
        colourLabel: '',
        sizeId: 0,
        sizeLabel: '',
    }]);

    function SaveSubArticle() {
        props.AddSubArticleApi({
            articleId: articleId,
            subArticle: inputField,
        },
            handleVisible,
            handleDangerVisible
        );
        setArticleId("")
        setInputField([{
            colourId: 0,
            colourLabel: '',
            sizeId: 0,
            sizeLabel: '',
        }]);
    }
    const handleVisible = (msg) => {
        setAlertVisible(true);
        setTimeout(() => {
            setAlertVisible(false);
        }, 9000);
        Swal({
            title: "Success",
            text: msg,
            icon: "success",
            button: "Ok",
        });
    };
    const handleDangerVisible = (err) => {
        setDangerVisibleAlert(true);
        setTimeout(() => {
            setDangerVisibleAlert(false);
        }, 9000);
        Swal({
            title: "Error",
            text: err,
            icon: "error",
            button: "Ok",
        });
    };

    const handleChangeArticle = (selectedOption) => {
        setArticleId(selectedOption.value);
    };
    const getArticleList = !_.isEmpty(props.articleList) && props.articleList.map((x) => {
        let data = { value: x._id, label: `${x.sequenceNumber} / ${x.articleName}` };
        return data;
    });
    const getArticleWithID = !_.isEmpty(props.articleList) && props.articleList.filter(article => {
        return article._id.toString() === articleId;
    });

    const inventorySize = props.sizeDataList.map((x) => {
        let data = { value: x._id, label: x.sizeName };
        return data;
    });
    const inventoryFlavor = props.flavorsDataList.map((x) => {
        let data = { value: x._id, label: x.colourName };
        return data;
    });

    const handleChange = (e, index, type) => {
        const list = [...inputField];
        if (type === "manualVariationCode") list[index]["manualVariationCode"] = e.target.value;
        if (type === "colour") list[index]["colourId"] = e.value;
        if (type === "colour") list[index]["colourLabel"] = e.label;
        if (type === "size") list[index]["sizeId"] = e.value;
        if (type === "size") list[index]["sizeLabel"] = e.label;
        setInputField(list);
    };
    const handleAddInput = () => {
        setInputField([
            ...inputField,
            { manualVariationCode: "", colourLabel: "", sizeLabel: "" },
        ]);
    };
    const handleRemoveInput = (index) => {
        const list = [...inputField];
        list.splice(index, 1);
        setInputField(list);
    };

    useEffect(() => {
        props.getSizeData()
        props.getFlavorsData()
        props.GetArticleApi();
        props.setHeading("Add Article Variation");
    }, []);

    return (
        <>
            {props.isFetchingSize || props.isFetchingFlavors || props.isFetchingArticle || props.isAddingSubArticle ? (
                <div className="loader-div">
                    <Spinner
                        variant="primary"
                        animation="border"
                        size="xl"
                        role="status"
                        aria-hidden="true"
                    />
                </div>
            ) : (
                <Container fluid>
                    <Form>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label><b>Select Article *</b></Form.Label>
                                    <Select
                                        placeholder="Select Article"
                                        onChange={handleChangeArticle}
                                        options={getArticleList}
                                        isDisabled={_.isEmpty(getArticleList)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    {inputField.map((item, i) => {
                                        return (
                                            <React.Fragment key={i}>
                                                <Row >
                                                    <Col>
                                                        <Form.Group>
                                                            <Form.Label><b> Select Color *</b></Form.Label>
                                                            <Select
                                                                isDisabled={_.isEmpty(articleId)}
                                                                style={{ zIndex: 1 }}
                                                                value={{ label: item.colourLabel }}
                                                                placeholder="Select Color"
                                                                name="colour"
                                                                onChange={(e) => handleChange(e, i, "colour")}
                                                                options={inventoryFlavor}
                                                                isOptionDisabled={(option) => !_.isEmpty(_.find(getArticleWithID[0].variation, (x) => x.colourLabel === option.label)) || !_.isEmpty(_.find(inputField, (x) => x.colourLabel === option.label))}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col>
                                                        <Form.Group>
                                                            <Form.Label><b> Select Size *</b></Form.Label>
                                                            <Select
                                                                isDisabled={_.isEmpty(articleId)}
                                                                style={{ zIndex: 1 }}
                                                                value={{ label: item.sizeLabel }}
                                                                placeholder="Select Size"
                                                                name="size"
                                                                onChange={(e) => handleChange(e, i, "size")}
                                                                options={inventorySize}
                                                            // isOptionDisabled={(option) => !_.isEmpty(_.find(getArticleWithID[0].variation, (x) => x.sizeLabel === option.label)) || !_.isEmpty(_.find(inputField, (x) => x.sizeLabel === option.label))}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label><b>Manual Code *</b> </Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Manual Code"
                                                                value={item.manualVariationCode}
                                                                onChange={(e) => handleChange(e, i, "manualVariationCode")}
                                                            />
                                                        </Form.Group>
                                                    </Col>

                                                    <Col style={{ marginTop: "2.5%" }}>
                                                        <Form.Group>
                                                            {inputField.length - 1 === i && (
                                                                <Button
                                                                    disabled
                                                                    title="Click to Add Variation"
                                                                    onClick={handleAddInput}
                                                                    style={{ float: "right", backgroundColor: '#81abd3', color: 'white' }}
                                                                >
                                                                    <AddIcon />
                                                                </Button>
                                                            )}
                                                            {inputField.length !== 1 && (
                                                                <Button
                                                                    disabled
                                                                    title="Click to Remove Variation"
                                                                    onClick={() => handleRemoveInput(i)}
                                                                    style={{ float: "right", backgroundColor: '#81abd3', color: 'white', marginRight: '20px' }}
                                                                >
                                                                    <RemoveIcon />
                                                                </Button>
                                                            )}
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </React.Fragment>
                                        );
                                    })}
                                </Form.Group>
                            </Col>
                        </Row>

                        <div className="sendDiv">
                            {_.isEmpty(articleId) || _.isEmpty(inputField[0].colourLabel) || _.isEmpty(inputField[0].sizeLabel) ? (
                                <Button variant="contained" endIcon={<SendIcon />} size="large" disabled> SAVE </Button>
                            ) : (
                                <Button variant="contained" endIcon={<SendIcon />} size="large" onClick={SaveSubArticle}> SAVE </Button>
                            )}
                        </div>

                    </Form>
                </Container>
            )}
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        AddSubArticleApi: (data, handleVisible, handleDangerVisible) => dispatch(AddSubArticleApi(data, handleVisible, handleDangerVisible)),
        getSizeData: () => dispatch(getSizeData()),
        getFlavorsData: () => dispatch(getFlavorsData()),
        GetArticleApi: () => dispatch(GetArticleApi()),
        setHeading: (data) => dispatch(setHeading(data)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    isAddingSubArticle: state.ArticleReducer.isAddingSubArticle,
    sizeDataList: state.sizeReducer.sizeDataList,
    isFetchingSize: state.sizeReducer.isFetchingSize,
    flavorsDataList: state.ColourReducer.flavorsDataList,
    isFetchingFlavors: state.ColourReducer.isFetchingFlavors,
    isFetchingArticle: state.ArticleReducer.isFetchingArticle,
    articleList: state.ArticleReducer.articleList,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddSubArticle);
