import React, { useEffect, useState } from "react";
import {
  InputGroup,
  Row,
  Col,
  Form,
  Table,
  Container,
  Button,
  FormGroup,
  Modal,
  Card,
  Tabs,
  Tab,
} from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, get, map, find, isUndefined, filter, sumBy } from "lodash";
import moment from "moment";
import Spinner from "react-bootstrap/Spinner";
import "./style.css";
import Logo from "../../../../Images/logo.png";
import MaterialTable from "material-table";
import Select from "react-select";
import Swal from 'sweetalert';
import {
  getAllVoucherType,
  addCashPayment,
  getAllVoucher,
  submitJournal,
  deleteJournal,
  resetFunction4,
} from "./Voucher.action";
import {
  getLevel5Data,
  getcreditdebit,
} from "../accountsLevels/accountsLevel.action";
import { setHeading } from "../../accounts/account.actions";
import { previewVoucher } from "../../../../global/globalFunctions";


const JournalVoucher = (props) => {

  const [voucher, setVoucher] = useState();
  const [datestate, setDatestate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [visibleAlert, setAlertVisible] = useState(false);
  const [level4Id, setLevel4Id] = useState(2);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  const handleVisible = (data) => {
    setAlertVisible(true)
    setTimeout(() => {
      setAlertVisible(false)
    }, 9000);
    Swal({
      title: "Success",
      text: "Added successfully...",
      icon: "success",
      buttons: ["Cancel", "Print Voucher"]
    }).then((willPrint) => {
      if (willPrint) {
        //subPage()
        previewVoucher(data, "Pending");
      } else {
        // setShowDialog(false)
      }
    })
  }

  const handleDangerVisible = () => {
    setDangerVisibleAlert(true)
    setTimeout(() => {
      setDangerVisibleAlert(false)
    }, 9000);
    Swal({
      title: "Error",
      text: "Something went wrong...",
      icon: "error",
      button: "Ok",
    });
  }
  const [values, setValues] = useState({
    voucherDescription: "",
    voucherAmount: "",
    // PONo: "",
    billNumber: "",
    accountTittle: "",
    // payTo: "",
    levelFourId: "",
    voucherDate: "",
    accountType: "-1",
    credit: "",
    debit: "",
    crossAccount: ""
  });

  // const inputEvent = (event) => {
  //   setValues({
  //     ...values,
  //     [event.target.name]: event.target.value,
  //   });
  // };

  const inputEvent = (event) => {
    event.target.name == "voucherDate" &&
      setDatestate(event.target.value);
    if (event.target.name == "voucherAmount") {
      setValues({
        ...values,
        [event.target.name]: event.target.value,
        debit: Number(event.target.value),
        credit: Number(event.target.value)
      })
    }
    else {
      setValues({
        ...values,
        [event.target.name]: event.target.value,
      })
    }
  };

  const cashAccountNo =
    !isEmpty(props.level5List) &&
    props.level5List.map((x) => {
      let data = {
        value: x.levelFourData._id,
        label:
          x.levelFourData.allLevelKey + " /" + x.levelFourData.levelFourName,
      };
      return data;
    });

  const inputEventAccount = (selectedOption, type) => {
    console.log(selectedOption, "level4id")
    setLevel4Id(selectedOption.value);
    setValues({
      levelFourId: selectedOption.value,
    });
  };

  const inputEvent1Account = (selectedOption) => {
    setLevel4Id(selectedOption.value);
    setValues({
      ...values,
      ["levelFourId"]: selectedOption.value,
      ["accountTittle"]: get(
        find(
          props.level5List,
          (x) => x.levelFourData._id == selectedOption.value
        ),
        "levelFourData.levelFourName",
        0
      ) + "(" + get(
        find(
          props.level5List,
          (x) => x.levelFourData._id == selectedOption.value
        ),
        "levelFourData.allLevelKey",
        0
      ) + ")",
    });
  };

  const addPayment = () => {
    let newVal = values;
    if (!isEmpty(datestate)) newVal.voucherDate = datestate;
    props.submitJournal(newVal);
    setValues({
      //   voucherDescription: newVal.voucherDescription,
      //  voucherAmount: newVal.voucherAmount,
      //   PONo: "",
      //   billNumber: newVal.billNumber,
      //   accountTittle: "",
      //   //  payTo: "",
      //   levelFiveId: "",
      //   voucherDate: "",
      //   accountType: "-1",
      //   credit:"",
      //   debit:""
      voucherDescription: newVal.voucherDescription,
      voucherAmount: newVal.voucherAmount,
      PONo: "",
      billNumber: "",
      accountTittle: "",
      payTo: "",
      levelFiveId: "",
      voucherDate: "",
      accountType: "-1",
      credit: "",
      debit: "",
      crossAccount: ""
    });
  };

  const add = () => {
    console.log(cash, 'cashhhhhh')
    props.addCashPayment({
      voucherTypeId: "5",
      vocDate: datestate,
      voucher: voucher,
      levelFourId: cash.map((x) => {
        return x.datajournal.levelFourId;
      }),
      voucherData: cash.map((x) => {
        return x.datajournal;
      }),
      voucherType: "JV",
    }, handleVisible, handleDangerVisible);
    props.resetFunction4();
  };

  useEffect(() => {
    props.getcreditdebit();
    props.getLevel5Data();
    props.setHeading("Journal Voucher");
  }, []);

  const cash = props.journal;
  let creditSum = 0,
    debitSum = 0;

  cash.map((x) => {
    if (x.datajournal.accountType == 1) {
      creditSum = creditSum + Number(x.datajournal.voucherAmount);
    } else if (x.datajournal.accountType == 2) {
      debitSum = debitSum + Number(x.datajournal.voucherAmount);
    }
  });

  const [state, setState] = React.useState({
    columns: [
      { title: "Date", field: "datajournal.voucherDate" },
      { title: "Account Name", field: "datajournal.accountTittle" },
      { title: "Bill No", field: "datajournal.billNumber" },
      { title: "Description", field: "datajournal.voucherDescription" },
      { title: "Amount", field: "datajournal.voucherAmount" },
      {
        title: "DR/CR",
        field: "datajournal.accountType",
        render: (rowData) =>
          rowData.datajournal.accountType == 1
            ? "Credit"
            : rowData.datajournal.accountType == 2
              ? "Debit"
              : "---",
      },
    ],
  });
  return (
    <>
      {props.isFetchinglevel5List ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Card style={{ padding: "3%" }}>
              <Row>
                <Col lg='12'>
                  <div >
                    <Row>
                      <Col lg="3" sm="12">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Date</Form.Label>
                          <Form.Control
                            type="date"
                            name="voucherDate"
                            value={datestate}
                            max={moment(new Date()).format("YYYY-MM-DD")}
                            //value={values.voucherDate}
                            onChange={inputEvent}
                            disabled={!isEmpty(props.journal)}
                          />
                        </Form.Group>
                      </Col>
                      <Col lg="3" sm="12">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>DEBIT</Form.Label>
                          <Form.Control
                            type="text"
                            name="voucherDate"
                            value={sumBy(filter(cash, x => x.datajournal.accountType == '2'), item => Number(item.datajournal.voucherAmount))}
                            disabled={true}
                          />
                        </Form.Group>
                      </Col>
                      <Col lg="3" sm="12">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>CREDIT</Form.Label>
                          <Form.Control
                            type="text"
                            name="voucherDate"
                            value={sumBy(filter(cash, x => x.datajournal.accountType == '1'), item => Number(item.datajournal.voucherAmount))}

                            disabled={true}
                          />
                        </Form.Group>
                      </Col>
                      <Col lg="3" sm="12">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Difference</Form.Label>
                          <Form.Control
                            type="text"
                            name="voucherDate"
                            value={Math.abs(sumBy(filter(cash, x => x.datajournal.accountType == '1'), item => Number(item.datajournal.voucherAmount)) - sumBy(filter(cash, x => x.datajournal.accountType == '2'), item => Number(item.datajournal.voucherAmount)))}
                            isInvalid={!Math.abs(sumBy(filter(cash, x => x.datajournal.accountType == '1'), item => Number(item.datajournal.voucherAmount)) == sumBy(filter(cash, x => x.datajournal.accountType == '2'), item => Number(item.datajournal.voucherAmount)))}
                            disabled={true}
                          />

                        </Form.Group>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
              <div style={{ padding: "1% 0" }}>
                <Row>
                  <Col lg="6" sm="12">
                    <Form.Group className="mb-3" controlId="accountId">
                      <Form.Label>Account *</Form.Label>
                      <Select
                        value={{ label: values.accountTittle }}
                        placeholder="Select Cash Account No..."
                        onChange={(inputEventAccount, inputEvent1Account)}
                        options={cashAccountNo}
                      //  isOptionDisabled = {(option) => isUndefined(cash[0]) ? false : option.value== cash[0].datajournal.levelFiveId}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="6" sm="12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Account Type *</Form.Label>
                      <Form.Control
                        as="select"
                        name="accountType"
                        value={values.accountType}
                        onChange={inputEvent}
                      >
                        <option value="-1" selected>
                          Select an option
                        </option>
                        <option value="2">Debit</option>
                        <option value="1">Credit</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
              </div>
              <div style={{ padding: "1% 0" }}>
                <Row>
                  <Col lg="2" sm="12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Bill</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Bill No"
                        name="billNumber"
                        value={values.billNumber}
                        onChange={inputEvent}

                      //    isOptionDisabled={(option) => (option.levelFourData.levelFourId == 1 || option.levelFourData.levelFourId == 2 )}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="7" sm="12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter description"
                        name="voucherDescription"
                        value={values.voucherDescription}
                        onChange={inputEvent}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="3" sm="12">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Amount *</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Enter Amount"
                        name="voucherAmount"
                        value={values.voucherAmount}
                        onChange={inputEvent}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>

              <Row>
                <Col lg="6" sm="12" className="text-center">
                  {datestate == "" || values.voucherAmount == "" || values.accountType == "-1" || values.levelFourId == "" ?
                    <Button className="sendButtonVoucher" disabled style={{ color: "white" }}>
                      ADD
                    </Button>
                    : <Button onClick={addPayment}>
                      ADD
                    </Button>}
                </Col>
                <Col lg="6" sm="12">
                  {creditSum !== 0 &&
                    debitSum !== 0 &&
                    creditSum == debitSum ? (
                    <Button

                      style={{ marginLeft: "2%" }}
                      onClick={add}
                    >
                      POST
                    </Button>
                  ) : (
                    <Button
                      disabled
                      style={{
                        marginLeft: "2%",
                        color: "white",

                        border: "1px solid",
                      }}
                    >
                      POST
                    </Button>
                  )}
                </Col>
              </Row>

              <div className="table">
                <MaterialTable
                  title="Journal Voucher"
                  columns={state.columns}
                  data={cash}
                  options={{
                    actionsColumnIndex: -1,
                    paging: true,
                    pageSize: 200, // make initial page size
                    emptyRowsWhenPaging: false, // To avoid of having empty rows
                    pageSizeOptions: [50, 100, 150, 200],
                    headerStyle: {
                      position: "sticky",
                      top: 0,
                      color: "#00BBBB",
                      fontWeight: "550",
                      onRowAdd: "none",
                    },
                  }}
                  editable={{
                    onRowDelete: (oldData) =>
                      new Promise((resolve) => {
                        setTimeout(() => {
                          resolve();
                          props.deleteJournal(oldData);
                        }, 600);
                      }),
                  }}
                />
              </div>
            </Card>
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    addCashPayment: (data, handleVisible, handleDangerVisible) => dispatch(addCashPayment(data, handleVisible, handleDangerVisible)),
    submitJournal: (data) => dispatch(submitJournal(data)),
    deleteJournal: (id) => dispatch(deleteJournal(id)),
    getcreditdebit: () => dispatch(getcreditdebit()),
    resetFunction4: () => dispatch(resetFunction4()),
    getLevel5Data: () => dispatch(getLevel5Data()),
    setHeading: (data) => dispatch(setHeading(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  journal: state.VoucherReducer.journal,
  level5List: state.accountsLevelReducer.level5List,
  creditDebitValue: state.accountsLevelReducer.creditDebitValue,
  isFetchinglevel5List: state.accountsLevelReducer.isFetchinglevel5List,
});

export default connect(mapStateToProps, mapDispatchToProps)(JournalVoucher);
