const initialState = {
    productionReceivingList: [],
    isFetchingProductionReceiving: false,
    isAddingProductionReceiving: false,
};

const ProductionReceivingReducer = (state = initialState, action) => {
    switch (action.type) {
        case "REQUEST_ADD_PRODUCTION_RECEIVING":
            return { ...state, isAddingProductionReceiving: true };
        case "SUCCESS_ADD_PRODUCTION_RECEIVING":
            return { ...state, productionReceivingList: action.payload.data, isAddingProductionReceiving: false };
        case "ERROR_ADD_PRODUCTION_RECEIVING":
            return { ...state, };


        case "REQUEST_GET_PRODUCTION_RECEIVING":
            return { ...state, isFetchingProductionReceiving: true };
        case "SUCCESS_GET_PRODUCTION_RECEIVING":
            return { ...state, productionReceivingList: action.payload, isFetchingProductionReceiving: false };
        case "ERROR_GET_PRODUCTION_RECEIVING":
            return { ...state, isFetchingProductionReceiving: false };


        default:
            return state;
    }
};
export default ProductionReceivingReducer;