import API from "../../../../../global/api";
import _ from "lodash";


export const requestAddSalary = () => {
    return {
        type: "REQUEST_ADD_SALARY",
    };
};
export const successAddSalary = (data) => {
    return {
        type: "SUCCESS_ADD_SALARY",
        payload: data,
    };
};
export const errorAddSalary = () => {
    return {
        type: "ERROR_ADD_SALARY",
    };
};
export const AddSalaryApi = (data, employeeId, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddSalary());
        API.post(`/salary/${employeeId}`, data).then((res) => {
            dispatch(successAddSalary(data));
            handleVisible(res.data.message);
        }).catch((error) => {
            dispatch(errorAddSalary());
            handleDangerVisible(error.response.data.message);
        });
    };
};


export const requestGetSalaryById = () => {
    return {
        type: "REQUEST_GET_SALARY_BY_ID",
    };
};
export const successGetSalaryById = (data) => {
    console.log('SUCCESS_GET_SALARY_BY_ID', data)
    return {
        type: "SUCCESS_GET_SALARY_BY_ID",
        payload: data,
    };
};
export const errorGetSalaryById = () => {
    return {
        type: "ERROR_GET_SALARY_BY_ID",
    };
};
export const GetSalaryWithEmpIDApi = (employeeId) => {
    return (dispatch) => {
        dispatch(requestGetSalaryById());
        API.get(`/salary/get_salary_with_employeeID/${employeeId}`).then((res) => {
            let getData = res.data;
            dispatch(successGetSalaryById(getData));
        }).catch((error) => {
            dispatch(errorGetSalaryById());
        })
    };
}


export const setSalaryFields = (data = { key: "", value: "" }) => {
    return {
        type: "SET_SALARY_FIELDS",
        payload: data
    };
}
export const setEditEmployeeFields = (data) => {
    return {
        type: "SET_EDIT_EMPLOYEE_FIELDS",
        payload: data
    };
}


export const requestUpdateSalary = () => {
    return {
        type: "REQUEST_UPDATE_SALARY",
    };
};
export const successUpdateSalary = (data) => {
    return {
        type: "SUCCESS_UPDATE_SALARY",
        payload: data,
    };
};
export const errorUpdateSalary = () => {
    return {
        type: "ERROR_UPDATE_SALARY",
    };
};
export const UpdateSalaryApi = (data, id, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestUpdateSalary());
        API.put(`/salary/${id}`, data).then((res) => {
            dispatch(successUpdateSalary(data));
            handleVisible(res.data.message);
        }).catch((error) => {
            dispatch(errorUpdateSalary());
            handleDangerVisible(error.response.data.message);
        });
    };
};