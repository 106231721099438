import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import accountReducer from "../views/user_views/accounts/account.reducers";
import VendorReducer from "../views/user_views/forms/AddDetails/Vendor/Vendor.reducer";
import UploadFilesReducer from "../views/user_views/forms/UploadFiles/UploadFileReducer";
import VoucherReducer from "../views/user_views/forms/Vouchers/Voucher.reducers";
import POSViewReducer from "../views/user_views/forms/AddDetails/POS/POSView.reducers";
import accountsLevelReducer from "../views/user_views/forms/accountsLevels/accountsLevel.reducer";
import MaterialLegderReducer from "../views/user_views/forms/materialLedger/materialledger.reducers";
import TransactitonSummaryReducer from "../views/user_views/forms/accountsTransactionSummary/TransactionSummaryReducer.reducers";
import userReducer from "../views/user_views/accounts/account.reducers";
import profitLoss from "../views/user_views/accounts/ProfitLoss/ProfitLoss.reducer";
import UserReducer from "../views/user_views/forms/AddUser/user.reducers";
import CouponReducer from "../views/user_views/forms/AddDetails/AddCoupons/Coupon.reducer";
import sizeReducer from "../views/user_views/forms/AddDetails/AddSize/size.reducer";
import ColourReducer from "../views/user_views/forms/AddDetails/AddColour/Colour.reducer";
import CustomerReducer from "../views/user_views/forms/AddDetails/AddCustomer/Customer.reducer";
import hallReducer from "../views/user_views/forms/AddDetails/AddHall/Hall.reducer";
import ResturantReducer from "../views/user_views/forms/AddDetails/AddResturant/Resturant.reducer";
import TableReducer from "../views/user_views/forms/AddDetails/AddTable/Table.reducer";
import DayReducer from "../views/user_views/forms/AddDetails/Day/Day.reducer";
import KDSReducer from "../views/user_views/forms/AddDetails/KDS/KDSView.reducers";
import MasterControlReducer from "../views/user_views/forms/AddDetails/AddMasterControl/MasterControl.reducer";
import ProductReducer from "../views/user_views/forms/AddDetails/AddProduct/Product.reducer";
import WarehouseReducer from "../views/user_views/forms/AddDetails/Warehouse/Warehouse.reducer";
import CategoryReducer from "../views/user_views/forms/AddDetails/categories/Catagory.reducer";
import SubCategoryReducer from "../views/user_views/forms/AddDetails/subCategory/SubCategory.reducer";
import RecipeReducer from "../views/user_views/forms/AddDetails/Recipe/Recipe.reducer";
import ReportReducer from "../views/user_views/forms/AddDetails/Reports/Report.resucer";
import RecivingReducer from "../views/user_views/forms/AddDetails/AddReciving/Receiving.reducer";
import InventoryLegderReducer from "../views/user_views/forms/AddDetails/InventoryLedger/InventoryLedger.reducer";
import BackUpReducer from "../views/user_views/forms/BackUp/BackUp.reducers";
import CreateDealReducer from "../views/user_views/forms/AddDetails/AddDeal/CreateDeal.reducer";
import IssuanceReducer from "../views/user_views/forms/AddDetails/Issuance/Issuance.reducer";
import uomReducer from "../views/user_views/forms/AddDetails/AddUOM/Uom.reducer";
import BarcodeReducer from "../views/user_views/forms/AddDetails/Barcode/Barcode.reducer";
import brandReducer from "../views/user_views/forms/AddDetails/Brand/Brand.reducer";
import PurchaseReducer from "../views/user_views/forms/AddDetails/PurchaseOrder/PurchaseOrder.reducer";
import DemandReducer from "../views/user_views/forms/AddDetails/Demand/Demand.reducer";
import IGPReducer from "../views/user_views/forms/AddDetails/Igp/IGP.reducer";
import OrderCreationReducer from "../views/user_views/forms/Production/orderCreation/OrderCreationReducer";
import ExpenseReducer from "../views/user_views/forms/AddDetails/expense/Expense.reducer";
import FBRIntegrationReducer from "../views/user_views/forms/AddDetails/FBR/FBRIntegration.reducer";
import permissionReducer from "../views/user_views/forms/AddDetails/Permissions/UserPermissions.reducer";
import DivisionReducer from "../views/user_views/forms/HRModule/division/divisionReducer";
import DepartmentReducer from "../views/user_views/forms/HRModule/department/departmentReducer";
import DesignationReducer from "../views/user_views/forms/HRModule/designation/designationReducer";
import ShiftControlReducer from "../views/user_views/forms/HRModule/shiftControl/shiftControlReducer";
import EmployeeTypeReducer from "../views/user_views/forms/HRModule/employeeType/employeeTypeReducer";
import EmployeeReducer from "../views/user_views/forms/HRModule/employee/employeeReducer";
import SalaryReducer from "../views/user_views/forms/HRModule/salary/salaryReducer";
import IncrementReducer from "../views/user_views/forms/HRModule/increment/incrementReducer";
import STReducer from "../views/user_views/forms/HRModule/ST-Advance/STRedcuer";
import LTReducer from "../views/user_views/forms/HRModule/LT-Advance/LTReducer";
import MasterControlHRReducer from "../views/user_views/forms/HRModule/masterControlHR/masterControlHRReducer";
import FineReducer from "../views/user_views/forms/HRModule/fineAndPenalty/FineAndPenaltyReducer";
import ProcessReducer from "../views/user_views/forms/Production/process/ProcessReducer";
import AccountLegderReducer from "../views/user_views/forms/ledger/Ledger.reducer";
import ProductionEmployeeReducer from "../views/user_views/forms/Production/productionEmployee/ProductionEmployeeReducer";
import ArticleReducer from "../views/user_views/forms/Production/article/ArticleReducer";
import MachineReducer from "../views/user_views/forms/Production/machine/MachineReducer";
import CostControlReducer from "../views/user_views/forms/Production/costControl/CostControlReducer";
import ProductionIssuanceReducer from "../views/user_views/forms/Production/productionIssuance/ProductionIssuanceReducer";
import ProductionReceivingReducer from "../views/user_views/forms/Production/productionReceiving/ProductionReceivingReducer";
import CustomerReducerCRM from "../views/user_views/forms/CRMModule/CRMCustomer/CustomerReducerCRM";
import CRMProductReducer from "../views/user_views/forms/CRMModule/CRMProducts/CRMProductReducer";


// import AddAgingReducer from "../views/user_views/forms/accountsAging/Aging.reducers";
// import productionDetailReducer from "../views/user_views/forms/production/ProductionDetails/ProductionDetail.reducer";
// import AddArticleMaterialReducer from "../views/user_views/forms/production/AddArticleMaterialFolder/AddArticleMaterial.reducers"
// import AddLabourReducer from "../views/user_views/forms/production/AddLabourFolder/AddLabour.reducers";
// import COnversionCostReducer from "../views/user_views/forms/production/ConversionCostFolder/Conversion.reducers";
// import OrderCreationReducer from "../views/user_views/forms/production/OrderCreationFolder/OrderCreation.reducer"
// import AddAdminCostReducer from "../views/user_views/forms/production/AdminCost/AddAdminCost.reducers";
// import AddIssuanceReducer from "../views/user_views/forms/production/IssuanceFolder/Issuance.reducers";
// import AddArticleReducer from '../views/user_views/forms/production/AddArticleFolder/AddArticles.reducers';
// import productionReducer from '../views/user_views/forms/production/production.reducers';
// import ReturnReducer from "../views/user_views/forms/returnToVendors/returntovendors.reducer";
// import addLedgerReducer from "../views/user_views/forms/Ledger/level.reducer";


const AllReducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    accountReducer,
    userReducer,
    MaterialLegderReducer,
    UploadFilesReducer,
    // usersReducer,
    // LegderReducer,
    VoucherReducer,
    accountsLevelReducer,
    AccountLegderReducer,
    DemandReducer,
    TransactitonSummaryReducer,
    profitLoss,
    permissionReducer,
    POSViewReducer,
    UserReducer,
    CouponReducer,
    sizeReducer,
    ColourReducer,
    CustomerReducer,
    IGPReducer,
    hallReducer,
    ResturantReducer,
    TableReducer,
    DayReducer,
    ReportReducer,
    MasterControlReducer,
    ProductReducer,
    WarehouseReducer,
    CategoryReducer,
    SubCategoryReducer,
    RecipeReducer,
    KDSReducer,
    RecivingReducer,
    InventoryLegderReducer,
    VendorReducer,
    BackUpReducer,
    CreateDealReducer,
    IssuanceReducer,
    uomReducer,
    BarcodeReducer,
    brandReducer,
    PurchaseReducer,
    OrderCreationReducer,
    ExpenseReducer,
    FBRIntegrationReducer,
    DivisionReducer,
    DepartmentReducer,
    DesignationReducer,
    ShiftControlReducer,
    EmployeeTypeReducer,
    EmployeeReducer,
    SalaryReducer,
    IncrementReducer,
    STReducer,
    MasterControlHRReducer,
    LTReducer,
    FineReducer,
    ProcessReducer,
    ProductionEmployeeReducer,
    ArticleReducer,
    MachineReducer,
    CostControlReducer,
    ProductionIssuanceReducer,
    ProductionReceivingReducer,
    CustomerReducerCRM,
    CRMProductReducer,
    // AddAgingReducer,
    // productionDetailReducer,
    // AddArticleReducer,
    // AddArticleMaterialReducer,
    // AddLabourReducer,
    // COnversionCostReducer,
    // OrderCreationReducer,
    // AddAdminCostReducer
    // AddAdminCostReducer,
    // AddIssuanceReducer,
    // productionReducer,
    // AddDataReducer,
    // ReturnReducer,
    // ProcessedPurchaseOrderReducer,
    //addLedgerReducer,
    // adminAccountReducer,
  });

export default AllReducers
