import API from "../../../../../global/api";


export const requestAddMasterControlHR = () => {
    return {
        type: "REQUEST_ADD_MASTER_ACCOUNTS",
    };
};
export const successAddMasterControlHR = (data) => {
    return {
        type: "SUCCESS_ADD_MASTER_ACCOUNTS",
        payload: data,
    };
};
export const errorAddMasterControlHR = () => {
    return {
        type: "ERROR_ADD_MASTER_ACCOUNTS",
    };
};
export const AddMasterControlHRApi = (data, handleVisible, handleDangerVisible) => {
    const filterData = Object.fromEntries(
        Object.entries(data).filter(([key, value]) => value !== '')
    );
    return (dispatch) => {
        dispatch(requestAddMasterControlHR());
        API.post(`/masterControl_hr`, filterData).then((res) => {
            dispatch(successAddMasterControlHR(filterData));
            handleVisible(res.data.message);
        }).catch((error) => {
            dispatch(errorAddMasterControlHR());
            handleDangerVisible(error.response.data.message);
        });
    };
};


export const requestGetLevelFour = () => {
    return {
        type: "REQUEST_GET_LEVELFOUR",
    };
};
export const successGetLevelFour = (data) => {
    return {
        type: "SUCCESS_GET_LEVELFOUR",
        payload: data,
    };
};
export const errorGetLevelFour = () => {
    return {
        type: "ERROR_GET_LEVELFOUR",
    };
};
export const GetLevelFourApi = () => {
    return (dispatch) => {
        dispatch(requestGetLevelFour());
        API.get(`/levelFour/get_levelFour_details`).then((res) => {
            let getData = res.data;
            dispatch(successGetLevelFour(getData));
        }).catch((error) => {
            dispatch(errorGetLevelFour());
        })
    };
};


export const requestGetSelectedMasterControlHRAccounts = () => {
    return {
        type: "REQUEST_GET_SELECTED_MASTERCONTROL_HR_ACCOUNTS",
    };
};
export const successGetSelectedMasterControlHRAccounts = (data) => {
    return {
        type: "SUCCESS_GET_SELECTED_MASTERCONTROL_HR_ACCOUNTS",
        payload: data,
    };
};
export const errorGetSelectedMasterControlHRAccounts = () => {
    return {
        type: "ERROR_GET_SELECTED_MASTERCONTROL_HR_ACCOUNTS",
    };
};
export const GetSelectedMasterControlHRAccountsApi = () => {
    return (dispatch) => {
        dispatch(requestGetSelectedMasterControlHRAccounts());
        API.get(`/masterControl_hr`).then((res) => {
            let getData = res.data;
            dispatch(successGetSelectedMasterControlHRAccounts(getData));
        }).catch((error) => {
            dispatch(errorGetSelectedMasterControlHRAccounts());
        })
    };
};