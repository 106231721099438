import API from "../../../../../global/api";


export const requestAddIncrement = () => {
    return {
        type: "REQUEST_ADD_INCREMENT",
    };
};
export const successAddIncrement = (data) => {
    return {
        type: "SUCCESS_ADD_INCREMENT",
        payload: data,
    };
};
export const errorAddIncrement = () => {
    return {
        type: "ERROR_ADD_INCREMENT",
    };
};
export const AddIncrementApi = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddIncrement());
        API.post(`/increment`, data).then((res) => {
            let resData = res.data;
            dispatch(successAddIncrement(resData));
            handleVisible(res.data.message);
        }).catch((error) => {
            dispatch(errorAddIncrement());
            handleDangerVisible(error.response.data.message);
        })
    };
};


export const requestGetIncrement = () => {
    return {
        type: "REQUEST_GET_INCREMENT",
    };
};
export const successGetIncrement = (data) => {
    return {
        type: "SUCCESS_GET_INCREMENT",
        payload: data,
    };
};
export const errorGetIncrement = () => {
    return {
        type: "ERROR_GET_INCREMENT",
    };
};
export const GetIncrementApi = () => {
    return (dispatch) => {
        dispatch(requestGetIncrement());
        API.get(`/increment`).then((res) => {
            let getData = res.data;
            dispatch(successGetIncrement(getData));
        }).catch((error) => {
            dispatch(errorGetIncrement());
        })
    };
};


export const requestUpdateIncrement = () => {
    return {
        type: "REQUEST_UPDATE_INCREMENT",
    };
};
export const successUpdateIncrement = (data) => {
    return {
        type: "SUCCESS_UPDATE_INCREMENT",
        payload: data,
    };
};
export const errorUpdateIncrement = () => {
    return {
        type: "ERROR_UPDATE_INCREMENT",
    };
};
export const UpdateIncrementApi = (newData, oldData, updateTableQuery) => {
    return (dispatch) => {
        dispatch(requestUpdateIncrement());
        API.put(`/increment/${oldData._id}`, {
            value: newData.value
        }).then((res) => {
            dispatch(successUpdateIncrement([newData]));
            updateTableQuery()
        }).catch((error) => {
            dispatch(errorUpdateIncrement());
        })
    };
};


export const requestDeleteIncrement = () => {
    return {
        type: "REQUEST_DELETE_INCREMENT",
    };
};
export const successDeleteIncrement = (oldData) => {
    return {
        type: "SUCCESS_DELETE_INCREMENT",
        data: oldData,
    };
};
export const errorDeleteIncrement = () => {
    return {
        type: "ERROR_DELETE_INCREMENT",
    };
};
export const DeleteIncrementApi = (oldData, updateTableQuery) => {
    return dispatch => {
        dispatch(requestDeleteIncrement());
        API.delete(`/increment/${oldData._id}`).then(res => {
            updateTableQuery()
            dispatch(successDeleteIncrement(oldData))
        }).catch((error) =>
            dispatch(errorDeleteIncrement()));
    }
};