import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { getSubCategoryWithCategoryIdApi } from "../../AddDetails/subCategory/SubCategory.action";
import { getCategoryData } from "../../AddDetails/categories/Catagory.action";
import { setHeading } from "../../../accounts/account.actions";
import { GetArticlesWithCategoryApi, PrintFullArticlePDF } from "./ArticleAction";
import Spinner from "react-bootstrap/Spinner";
import Select from "react-select";
import MaterialTable from "material-table";
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';


const ArticleFilter = (props) => {
    const [categoryId, setCategoryId] = useState();
    const [subCategoryId, setSubCategoryId] = useState();
    const [show, setShow] = useState(false);


    const handleChangeCategory = (selectedOption) => {
        setCategoryId(selectedOption.value);
        props.getSubCategoryWithCategoryIdApi(selectedOption.value);
    };
    const getCategoryList = !isEmpty(props.categoryList) && props.categoryList.data.filter(catData => catData.categoryType === 'Production').map((x) => {
        let data = { value: x._id, label: x.name };
        return data;
    });

    const handleChangeSubCategory = (selectedOption) => {
        setSubCategoryId(selectedOption.value);
    };
    const getSubCategoryList = !isEmpty(props.subCategoryListByCategoryId) && props.subCategoryListByCategoryId.map((x) => {
        let data = { value: x._id, label: x.subCategoryName };
        return data;
    });

    useEffect(() => {
        props.getCategoryData();
        props.setHeading("Article Filter");
    }, []);
    console.log(props.articlesWithCategory, "oooooooooooooooooooo")

    function SearchData() {
        props.GetArticlesWithCategoryApi({
            categoryId: categoryId,
            subCategoryId: subCategoryId,
        });
        setShow(true);
    }

    return (
        <>
            {props.isFetchingcategoryList ? (
                <div className="loader-div">
                    <Spinner
                        variant="primary"
                        animation="border"
                        size="xl"
                        role="status"
                        aria-hidden="true"
                    />
                </div>
            ) : (
                <Container fluid>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3" >
                                <Form.Label><b>Select Category *</b></Form.Label>
                                <Select
                                    placeholder="Select Category"
                                    isDisabled={isEmpty(getCategoryList)}
                                    onChange={handleChangeCategory}
                                    options={getCategoryList}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3" >
                                <Form.Label><b>Select Sub Category *</b></Form.Label>
                                <Select
                                    placeholder="Select Sub Category"
                                    isDisabled={isEmpty(props.subCategoryListByCategoryId) || isEmpty(categoryId)}
                                    onChange={handleChangeSubCategory}
                                    options={getSubCategoryList}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <div className="sendDiv">
                        {isEmpty(categoryId) || isEmpty(subCategoryId) ? (
                            <Button variant="contained" endIcon={<SendIcon />} size="large" disabled> SEARCH </Button>
                        ) : (
                            <Button variant="contained" endIcon={<SendIcon />} size="large" onClick={SearchData}> SEARCH </Button>
                        )}
                    </div>


                    <div>
                        {show &&
                            <MaterialTable
                                style={{ zIndex: 0 }}
                                title="Article List"
                                columns={[
                                    { title: 'Article Code', field: 'sequenceNumber', editable: 'never' },
                                    { title: "Category", field: "categoryDetails.categoryName", editable: 'never' },
                                    { title: "Sub Category", field: "subCategoryDetails.subCategoryName", editable: 'never' },
                                    { title: "Customer Name", field: "customerDetails.name", editable: 'never' },
                                    { title: "Brand Name", field: "brandDetails.brandName", editable: 'never' },
                                    { title: "UOM", field: "uomDetails.unitName", editable: 'never' },
                                    { title: "Article Name", field: "articleName" },
                                    // { title: "Carton Size", field: "cartonSize" },
                                    // { title: "Packing", field: "packing" },
                                    // { title: "Weight", field: "weight" },
                                    // { title: "Composition", field: "composition" },
                                    // { title: "HSCode", field: "hSCode" },
                                    // { title: "Description", field: "description" },
                                    // { title: "Catalogue Number", field: "catlogNumber" },
                                    // { title: "Customer Catalogue Number", field: "customerCatalogueNumber" },
                                    { title: "Export Cost", field: "exportCost" },
                                    { title: "Currency", field: "currency", editable: 'never' },
                                    { title: "Conversion To PKR", field: "conversionToPKR" },
                                    { title: "Export Price", field: "exportPrice" },
                                    { title: "Profit", field: "profit" },
                                    { title: "Article Total Cost", field: "articleTotalCost" },
                                    { title: "Created By", field: "userDetails.userName", editable: 'never' },
                                ]}
                                detailPanel={(rowData) => {
                                    return (
                                        <MaterialTable
                                            title="Invoice Detail"
                                            columns={[
                                                { title: 'Code', field: 'variationSequenceNumber' },
                                                { title: 'Manual Code', field: 'manualVariationCode' },
                                                { title: 'Color', field: 'colourLabel' },
                                                { title: 'Size', field: 'sizeLabel' },
                                                { title: 'Article Variation Cost', field: 'articleVariationCost' },
                                            ]}
                                            data={rowData.variation}
                                            options={{
                                                actionsColumnIndex: -1,
                                                toolbar: false,
                                                exportButton: false,
                                                sorting: false,
                                                paging: false,
                                                pageSize: 50,
                                                emptyRowsWhenPaging: false,
                                                pageSizeOptions: [50, 100, 150, 200],
                                                headerStyle: {
                                                    position: 'sticky',
                                                    top: 0,
                                                    color: '#00BBBB',
                                                    fontWeight: '550',
                                                    onRowAdd: 'none',
                                                }
                                            }}
                                        />
                                    )
                                }}
                                data={props.articlesWithCategory.data}
                                actions={[
                                    (rowData) => ({
                                        icon: () => <LocalPrintshopIcon />,
                                        tooltip: "Print Full Article",
                                        onClick: (event, rowData) => {
                                            props.PrintFullArticlePDF(rowData)
                                        },
                                    }),
                                ]}
                                options={{
                                    actionsColumnIndex: -1,
                                    exportButton: true,
                                    exportAllData: true,
                                    paging: true,
                                    pageSize: 50,
                                    emptyRowsWhenPaging: false,
                                    pageSizeOptions: [50, 100, 150, 200],
                                    headerStyle: {
                                        position: "sticky",
                                        top: 0,
                                        color: "#00BBBB",
                                        fontWeight: "550",
                                        onRowAdd: "none",
                                    },
                                }}
                            />
                        }
                    </div>

                </Container>
            )}
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        GetArticlesWithCategoryApi: (data) => dispatch(GetArticlesWithCategoryApi(data)),
        getSubCategoryWithCategoryIdApi: (id) => dispatch(getSubCategoryWithCategoryIdApi(id)),
        getCategoryData: () => dispatch(getCategoryData()),
        PrintFullArticlePDF: (data) => dispatch(PrintFullArticlePDF(data)),
        setHeading: (data) => dispatch(setHeading(data)),
    };
};

const mapStateToProps = (state, ownProps) => ({
    isFetchingcategoryList: state.CategoryReducer.isFetchingcategoryList,
    categoryList: state.CategoryReducer.categoryList,
    subCategoryListByCategoryId: state.SubCategoryReducer.subCategoryListByCategoryId,
    isFetchingArticlesWithCategory: state.ArticleReducer.isFetchingArticlesWithCategory,
    articlesWithCategory: state.ArticleReducer.articlesWithCategory,
});

export default connect(mapStateToProps, mapDispatchToProps)(ArticleFilter);
