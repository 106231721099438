import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert";
import { Row, Col, Form, Container } from "react-bootstrap";
import { getUsers, getPermissions, addPermissions, } from "./UserPermissions.actions";
import Spinner from "react-bootstrap/Spinner";
import Select from "react-select";
import { setHeading } from "../../../accounts/account.actions";
import { isEmpty, find } from "lodash";
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';


const UserPermissions = (props) => {
  const userData = props.usersList;
  const [show, setShow] = useState(false);
  const [users, setUsers] = useState("");
  const [mainCategory1, setMainCategory1] = useState();
  const [mainCategory2, setMainCategory2] = useState();
  const [mainCategory3, setMainCategorySetting] = useState();
  const [mainCategoryMaster, setMainCategoryMaster] = useState();
  const [checkedData, setChecked] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  const [mainCategoryPos, setMainCategoryPos] = useState();
  const [mainCategoryKds, setMainCategoryKds] = useState();
  const [mainCategoryTable, setMainCategoryTable] = useState();
  const [mainCategoryViewOrder, setMainCategoryViewOrder] = useState();
  const [mainCategoryReport, setMainCategoryReport] = useState();
  const [mainCategoryHR, setMainCategoryHR] = useState();
  const [mainCategoryProduction, setMainCategoryProduction] = useState();
  const handleChangeUsers = (selectedOption) => {
    setUsers(selectedOption.value);
  };

  const handleInventory = (event) => {
    setMainCategory1(event.target.value);
    var updatedList = [...checkedData];
    if (event.target.checked) {
      !isEmpty(props.permissionsList) &&
        props.permissionsList[0].permission_cats.map((x) => {
          updatedList.push({
            permissionsCatName: x.permissionsCatName,
            permissionsName: props.permissionsList[0].permissionsName,
          });
          !isEmpty(x.permissionSubCats) &&
            x.permissionSubCats.map((sub) => {
              updatedList.push({
                permissionsCatName: x.permissionsCatName,
                permissionsName: props.permissionsList[0].permissionsName,
                permissionsSubCatName: sub.permissionsSubCatName,
              });
            });
        });
      updatedList.push({
        permissionsCatName: event.target.value,
      });
    } else {
      !isEmpty(props.permissionsList) &&
        props.permissionsList[0].permission_cats.map((x) => {
          !isEmpty(x.permissionSubCats) &&
            x.permissionSubCats.map((sub) => {
              updatedList = updatedList.filter(
                (e) => e.permissionsCatName != x.permissionsCatName
              );
              updatedList = updatedList.filter(
                (e) => e.permissionsSubCatName != sub.permissionsSubCatName
              );
            });
        });
      updatedList = updatedList.filter(
        (e) => e.permissionsCatName != `${event.target.value}`
      );
    }
    setChecked(updatedList);
  };
  const handleCheck = (event) => {
    var updatedList = [...checkedData];
    if (event.target.checked) {
      let data =
        !isEmpty(props.permissionsList) &&
        props.permissionsList[0].permission_cats
          .find((x) => x.permissionsCatName == event.target.value)
          .permissionSubCats.map((y) => {
            return y.permissionsSubCatName;
          });
      isEmpty(data)
        ? updatedList.push({
          permissionsCatName: event.target.value,
          permissionsName: props.permissionsList[0].permissionsName,
        })
        : data.map((z) => {
          updatedList.push({
            permissionsCatName: event.target.value,
            permissionsName: props.permissionsList[0].permissionsName,
            permissionsSubCatName: z,
          });
        });
    } else {
      updatedList = updatedList.filter(
        (e) => e.permissionsCatName != `${event.target.value}`
      );
    }
    setChecked(updatedList);
  };
  const handleCheckSub = (event, pt) => {
    var updatedList = [...checkedData];
    if (event.target.checked) {
      updatedList.push({
        permissionsCatName: pt.permissionsCatName,
        permissionsName: props.permissionsList[0].permissionsName,
        permissionsSubCatName: event.target.value,
      });
    } else {
      updatedList = updatedList.filter(
        (e) => e.permissionsSubCatName != `${event.target.value}`
      );
    }
    setChecked(updatedList);
  };

  const handleMaster = (event) => {
    setMainCategoryMaster(event.target.value);
    var updatedList = [...checkedData];
    if (event.target.checked) {
      !isEmpty(props.permissionsList) &&
        props.permissionsList[1].permission_cats.map((x) => {
          updatedList.push({
            permissionsCatName: x.permissionsCatName,
            permissionsName: props.permissionsList[1].permissionsName,
          });
          !isEmpty(x.permissionSubCats) &&
            x.permissionSubCats.map((sub) => {
              updatedList.push({
                permissionsCatName: x.permissionsCatName,
                permissionsName: props.permissionsList[1].permissionsName,
                permissionsSubCatName: sub.permissionsSubCatName,
              });
            });
        });
      updatedList.push({
        permissionsCatName: event.target.value,
      });
    } else {
      !isEmpty(props.permissionsList) &&
        props.permissionsList[1].permission_cats.map((x) => {
          !isEmpty(x.permissionSubCats) &&
            x.permissionSubCats.map((sub) => {
              updatedList = updatedList.filter(
                (e) => e.permissionsCatName != x.permissionsCatName
              );
              updatedList = updatedList.filter(
                (e) => e.permissionsSubCatName != sub.permissionsSubCatName
              );
            });
        });
      updatedList = updatedList.filter(
        (e) => e.permissionsCatName != `${event.target.value}`
      );
    }
    setChecked(updatedList);
  };
  const handleCheckMaster = (event) => {
    var updatedList = [...checkedData];
    if (event.target.checked) {
      let data =
        !isEmpty(props.permissionsList) &&
        props.permissionsList[1].permission_cats
          .find((x) => x.permissionsCatName == event.target.value)
          .permissionSubCats.map((y) => {
            return y.permissionsSubCatName;
          });
      isEmpty(data)
        ? updatedList.push({
          permissionsCatName: event.target.value,
          permissionsName: props.permissionsList[1].permissionsName,
        })
        : data.map((z) => {
          updatedList.push({
            permissionsCatName: event.target.value,
            permissionsName: props.permissionsList[1].permissionsName,
            permissionsSubCatName: z,
          });
        });
    } else {
      updatedList = updatedList.filter(
        (e) => e.permissionsCatName != `${event.target.value}`
      );
    }
    setChecked(updatedList);
  };
  const handleCheckSubMaster = (event, pt) => {
    var updatedList = [...checkedData];
    if (event.target.checked) {
      updatedList.push({
        permissionsCatName: pt.permissionsCatName,
        permissionsName: props.permissionsList[1].permissionsName,
        permissionsSubCatName: event.target.value,
      });
    } else {
      updatedList = updatedList.filter(
        (e) => e.permissionsSubCatName != `${event.target.value}`
      );
    }
    setChecked(updatedList);
  };


  const handleAccounts = (event) => {
    setMainCategory2(event.target.value);
    var updatedList = [...checkedData];
    if (event.target.checked) {
      !isEmpty(props.permissionsList) &&
        props.permissionsList[2].permission_cats.map((x) => {
          updatedList.push({
            permissionsName: props.permissionsList[2].permissionsName,
            permissionsCatName: x.permissionsCatName,
          });
          !isEmpty(x.permissionSubCats) &&
            x.permissionSubCats.map((sub) => {
              updatedList.push({
                permissionsName: props.permissionsList[2].permissionsName,
                permissionsCatName: x.permissionsCatName,

                permissionsSubCatName: sub.permissionsSubCatName,
              });
            });
        });
      updatedList.push({
        permissionsCatName: event.target.value,
      });
    } else {
      !isEmpty(props.permissionsList) &&
        props.permissionsList[2].permission_cats.map((x) => {
          !isEmpty(x.permissionSubCats) &&
            x.permissionSubCats.map((sub) => {
              updatedList = updatedList.filter(
                (e) => e.permissionsCatName != x.permissionsCatName
              );
              updatedList = updatedList.filter(
                (e) => e.permissionsCatName != sub.permissionsSubCatName
              );
            });
        });
      updatedList = updatedList.filter(
        (e) => e.permissionsCatName != `${event.target.value}`
      );
    }
    setChecked(updatedList);
  };
  const handleCheck1 = (event) => {
    var updatedList = [...checkedData];
    if (event.target.checked) {
      let data =
        !isEmpty(props.permissionsList) &&
        props.permissionsList[2].permission_cats
          .find((x) => x.permissionsCatName == event.target.value)
          .permissionSubCats.map((y) => {
            return y.permissionsSubCatName;
          });
      data.map((z) => {
        updatedList.push({
          permissionsName: props.permissionsList[2].permissionsName,
          permissionsCatName: event.target.value,

          permissionsSubCatName: z,
        });
      });
    } else {
      updatedList = updatedList.filter(
        (e) => e.permissionsCatName != `${event.target.value}`
      );
    }
    setChecked(updatedList);
  };
  const handleCheckSub1 = (event, pt) => {
    var updatedList = [...checkedData];
    if (event.target.checked) {
      updatedList.push({
        permissionsName: props.permissionsList[2].permissionsName,
        permissionsCatName: pt.permissionsCatName,

        permissionsSubCatName: event.target.value,
      });
    } else {
      updatedList = updatedList.filter(
        (e) => e.permissionsSubCatName != `${event.target.value}`
      );
    }
    setChecked(updatedList);
  };

  const handleSetting = (event) => {
    setMainCategorySetting(event.target.value);
    var updatedList = [...checkedData];
    if (event.target.checked) {
      !isEmpty(props.permissionsList) &&
        props.permissionsList[8].permission_cats.map((x) => {
          updatedList.push({
            permissionsName: props.permissionsList[8].permissionsName,
            permissionsCatName: x.permissionsCatName,
          });
          !isEmpty(x.permissionSubCats) &&
            x.permissionSubCats.map((sub) => {
              updatedList.push({
                permissionsName: props.permissionsList[8].permissionsName,
                permissionsCatName: x.permissionsCatName,

                permissionsSubCatName: sub.permissionsSubCatName,
              });
            });
        });
      updatedList.push({
        permissionsCatName: event.target.value,
      });
    } else {
      !isEmpty(props.permissionsList) &&
        props.permissionsList[8].permission_cats.map((x) => {
          !isEmpty(x.permissionSubCats) &&
            x.permissionSubCats.map((sub) => {
              updatedList = updatedList.filter(
                (e) => e.permissionsCatName != x.permissionsCatName
              );
              updatedList = updatedList.filter(
                (e) => e.permissionsCatName != sub.permissionsSubCatName
              );
            });
        });
      updatedList = updatedList.filter(
        (e) => e.permissionsCatName != `${event.target.value}`
      );
    }
    setChecked(updatedList);
  };
  const handleCheckSetting = (event) => {
    var updatedList = [...checkedData];
    if (event.target.checked) {
      let data =
        !isEmpty(props.permissionsList) &&
        props.permissionsList[8].permission_cats
          .find((x) => x.permissionsCatName == event.target.value)
          .permissionSubCats.map((y) => {
            return y.permissionsSubCatName;
          });
      data.map((z) => {
        updatedList.push({
          permissionsName: props.permissionsList[8].permissionsName,
          permissionsCatName: event.target.value,

          permissionsSubCatName: z,
        });
      });
    } else {
      updatedList = updatedList.filter(
        (e) => e.permissionsCatName != `${event.target.value}`
      );
    }
    setChecked(updatedList);
  };
  const handleCheckSubSetting = (event, pt) => {
    var updatedList = [...checkedData];
    if (event.target.checked) {
      updatedList.push({
        permissionsName: props.permissionsList[8].permissionsName,
        permissionsCatName: pt.permissionsCatName,

        permissionsSubCatName: event.target.value,
      });
    } else {
      updatedList = updatedList.filter(
        (e) => e.permissionsSubCatName != `${event.target.value}`
      );
    }
    setChecked(updatedList);
  };

  const handlePos = (event) => {
    setMainCategoryPos(event.target.value);
    var updatedList = [...checkedData];
    if (event.target.checked) {
      updatedList.push({
        permissionsName: event.target.value,
      });
    } else {
      updatedList = updatedList.filter(
        (e) => e.permissionsName != `${event.target.value}`
      );
    }
    setChecked(updatedList);
  };

  const handleViewOrder = (event) => {
    setMainCategoryViewOrder(event.target.value);
    var updatedList = [...checkedData];
    if (event.target.checked) {
      updatedList.push({
        permissionsName: event.target.value,
      });
    } else {
      updatedList = updatedList.filter(
        (e) => e.permissionsName != `${event.target.value}`
      );
    }
    setChecked(updatedList);
  };

  const handleReport = (event) => {
    setMainCategoryReport(event.target.value);
    var updatedList = [...checkedData];
    if (event.target.checked) {
      updatedList.push({
        permissionsName: event.target.value,
      });
    } else {
      updatedList = updatedList.filter(
        (e) => e.permissionsName != `${event.target.value}`
      );
    }
    setChecked(updatedList);
  };

  const handleHR = (event) => {
    setMainCategoryHR(event.target.value);
    var updatedList = [...checkedData];
    if (event.target.checked) {
      updatedList.push({
        permissionsName: event.target.value,
      });
    } else {
      updatedList = updatedList.filter((e) => e.permissionsName != `${event.target.value}`);
    }
    setChecked(updatedList);
  };

  const handleProduction = (event) => {
    setMainCategoryProduction(event.target.value);
    var updatedList = [...checkedData];
    if (event.target.checked) {
      updatedList.push({
        permissionsName: event.target.value,
      });
    } else {
      updatedList = updatedList.filter((e) => e.permissionsName != `${event.target.value}`);
    }
    setChecked(updatedList);
  };


  // const handleKds = (event) => {
  //   setMainCategoryKds(event.target.value);
  //   var updatedList = [...checkedData];
  //   if (event.target.checked) {
  //     updatedList.push({
  //       permissionsName: event.target.value,
  //     });
  //   } else {
  //     updatedList = updatedList.filter(
  //       (e) => e.permissionsName != `${event.target.value}`
  //     );
  //   }
  //   setChecked(updatedList);
  // };

  // const handleTable = (event) => {
  //   setMainCategoryTable(event.target.value);
  //   var updatedList = [...checkedData];
  //   if (event.target.checked) {
  //     updatedList.push({
  //       permissionsName: event.target.value,
  //     });
  //   } else {
  //     updatedList = updatedList.filter(
  //       (e) => e.permissionsName != `${event.target.value}`
  //     );
  //   }
  //   setChecked(updatedList);
  // };

  const handleVisible = () => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: "Added successfully...",
      icon: "success",
      button: "Ok",
    });
  };
  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Something went wrong...",
      icon: "error",
      button: "Ok",
    });
  };

  useEffect(() => {
    props.getUsers();
    props.getPermissions();
    props.setHeading("Permission")
  }, []);

  const sendData = () => {
    props.addPermissions({
      user: users,
      permission: checkedData,
    },
      handleVisible,
      handleDangerVisible
    );
  };

  const getUsersData = !isEmpty(props.usersList) && props.usersList.map((x) => {
    let data = { value: x._id, label: x.userName };
    return data;
  });

  return (
    <>
      {props.isFetchingUsersList || props.isFetchingPermissionsList ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {props.isSavingLevel2 ? "Saving" : "Loading"}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <Form>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>
                    <b> Select User *</b>
                  </Form.Label>
                  <Select
                    placeholder="Select User"
                    onChange={(e) => handleChangeUsers(e)}
                    options={getUsersData}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col lg="3" md="3" sm="12">
                <div style={{ marginLeft: "5%" }}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="flexCheckDefault"
                    value={!isEmpty(props.permissionsList) && props.permissionsList[0].permissionsName}
                    onChange={(event) => handleInventory(event)}
                  />
                  <label className="form-check-label" for="flexCheckDefault">
                    {!isEmpty(props.permissionsList) &&
                      props.permissionsList[0].permissionsName}
                  </label>
                </div>
                {!isEmpty(props.permissionsList) &&
                  props.permissionsList[0].permission_cats.map((inv, index) => {
                    return (
                      <Row>
                        <Col lg="12" md="12" sm="12">
                          <div style={{ marginLeft: "15%" }}>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value={inv.permissionsCatName}
                              checked={!isEmpty(checkedData) ? find(checkedData, (x) => x.permissionsCatName == inv.permissionsCatName) : false}
                              id="flexCheckDefault"
                              onChange={(e) => handleCheck(e)}
                            />
                            <label
                              className="form-check-label"
                              for="flexCheckDefault"
                            >
                              {inv.permissionsCatName}
                            </label>
                            {!isEmpty(inv.permissionSubCats) &&
                              inv.permissionSubCats.map((sub, index) => {
                                return (
                                  <Row>
                                    <Col lg="12" md="12" sm="12">
                                      <div style={{ marginLeft: "15%" }}>
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          value={sub.permissionsSubCatName}
                                          checked={!isEmpty(checkedData) ? find(checkedData, (x) => x.permissionsSubCatName == sub.permissionsSubCatName) : false}
                                          id="flexCheckDefault"
                                          onChange={(e) =>
                                            handleCheckSub(e, inv)
                                          }
                                        />
                                        <label
                                          className="form-check-label"
                                          for="flexCheckDefault"
                                        >
                                          {sub.permissionsSubCatName}
                                        </label>
                                      </div>
                                    </Col>
                                  </Row>
                                );
                              })}
                          </div>
                        </Col>
                      </Row>
                    );
                  })}
              </Col>


              <Col lg="3" md="3" sm="12">
                <div style={{ marginLeft: "5%" }}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={!isEmpty(props.permissionsList) && props.permissionsList[1].permissionsName}
                    permissionsCatName={""}
                    onChange={(event) => handleMaster(event)}
                  />
                  <label className="form-check-label" for="flexCheckDefault">
                    {!isEmpty(props.permissionsList) && props.permissionsList[1].permissionsName}
                  </label>
                </div>
                {!isEmpty(props.permissionsList) &&
                  props.permissionsList[1].permission_cats.map((acc, index) => {
                    return (
                      <Row>
                        <Col lg="12" md="12" sm="12">
                          <div style={{ marginLeft: "15%" }}>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value={acc.permissionsCatName}
                              permissionsCatName="flexCheckDefault"
                              checked={!isEmpty(checkedData) ? find(checkedData, (x) => x.permissionsCatName == acc.permissionsCatName) : false}
                              onChange={(e) => handleCheckMaster(e)}
                            />
                            <label
                              className="form-check-label"
                              for="flexCheckDefault"
                            >
                              {acc.permissionsCatName}
                            </label>
                            {!isEmpty(acc.permissionSubCats) &&
                              acc.permissionSubCats.map((sub, index) => {
                                return (
                                  <Row>
                                    <Col lg="12" md="12" sm="12">
                                      <div style={{ marginLeft: "15%" }}>
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          value={sub.permissionsSubCatName}
                                          checked={!isEmpty(checkedData) ? find(checkedData, (x) => x.permissionsSubCatName == sub.permissionsSubCatName) : false}
                                          permissionsCatName="flexCheckDefault"
                                          onChange={(e) => handleCheckSubMaster(e, acc)}
                                        />
                                        <label
                                          className="form-check-label"
                                          for="flexCheckDefault"
                                        >
                                          {sub.permissionsSubCatName}
                                        </label>
                                      </div>
                                    </Col>
                                  </Row>
                                );
                              })}
                          </div>
                        </Col>
                      </Row>
                    );
                  })}
              </Col>


              <Col lg="3" md="3" sm="12">
                <div style={{ marginLeft: "5%" }}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={!isEmpty(props.permissionsList) && props.permissionsList[2].permissionsName}
                    permissionsCatName={""}
                    onChange={(event) => handleAccounts(event)}
                  />
                  <label className="form-check-label" for="flexCheckDefault">
                    {!isEmpty(props.permissionsList) && props.permissionsList[2].permissionsName}
                  </label>
                </div>
                {!isEmpty(props.permissionsList) &&
                  props.permissionsList[2].permission_cats.map((acc, index) => {
                    return (
                      <Row>
                        <Col lg="12" md="12" sm="12">
                          <div style={{ marginLeft: "15%" }}>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value={acc.permissionsCatName}
                              permissionsCatName="flexCheckDefault"
                              checked={!isEmpty(checkedData) ? find(checkedData, (x) => x.permissionsCatName == acc.permissionsCatName) : false}
                              onChange={(e) => handleCheck1(e)}
                            />
                            <label
                              className="form-check-label"
                              for="flexCheckDefault"
                            >
                              {acc.permissionsCatName}
                            </label>
                            {!isEmpty(acc.permissionSubCats) &&
                              acc.permissionSubCats.map((sub, index) => {
                                return (
                                  <Row>
                                    <Col lg="12" md="12" sm="12">
                                      <div style={{ marginLeft: "15%" }}>
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          value={sub.permissionsSubCatName}
                                          checked={!isEmpty(checkedData) ? find(checkedData, (x) => x.permissionsSubCatName == sub.permissionsSubCatName) : false}
                                          permissionsCatName="flexCheckDefault"
                                          onChange={(e) => handleCheckSub1(e, acc)}
                                        />
                                        <label
                                          className="form-check-label"
                                          for="flexCheckDefault"
                                        >
                                          {sub.permissionsSubCatName}
                                        </label>
                                      </div>
                                    </Col>
                                  </Row>
                                );
                              })}
                          </div>
                        </Col>
                      </Row>
                    );
                  })}
              </Col>


              <Col lg="3" md="3" sm="12">
                <div style={{ marginLeft: "5%" }}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={!isEmpty(props.permissionsList) && props.permissionsList[8].permissionsName}
                    permissionsCatName={""}
                    onChange={(event) => handleSetting(event)}
                  />
                  <label className="form-check-label" for="flexCheckDefault">
                    {!isEmpty(props.permissionsList) && props.permissionsList[8].permissionsName}
                  </label>
                </div>
                {!isEmpty(props.permissionsList) &&
                  props.permissionsList[8].permission_cats.map((acc, index) => {
                    return (
                      <Row>
                        <Col lg="12" md="12" sm="12">
                          <div style={{ marginLeft: "15%" }}>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value={acc.permissionsCatName}
                              permissionsCatName="flexCheckDefault"
                              checked={!isEmpty(checkedData) ? find(checkedData, (x) => x.permissionsCatName == acc.permissionsCatName) : false}
                              onChange={(e) => handleCheckSetting(e)}
                            />
                            <label
                              className="form-check-label"
                              for="flexCheckDefault"
                            >
                              {acc.permissionsCatName}
                            </label>
                            {!isEmpty(acc.permissionSubCats) &&
                              acc.permissionSubCats.map((sub, index) => {
                                return (
                                  <Row>
                                    <Col lg="12" md="12" sm="12">
                                      <div style={{ marginLeft: "15%" }}>
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          value={sub.permissionsSubCatName}
                                          checked={!isEmpty(checkedData) ? find(checkedData, (x) => x.permissionsSubCatName == sub.permissionsSubCatName) : false}
                                          permissionsCatName="flexCheckDefault"
                                          onChange={(e) =>
                                            handleCheckSubSetting(e, acc)
                                          }
                                        />
                                        <label
                                          className="form-check-label"
                                          for="flexCheckDefault"
                                        >
                                          {sub.permissionsSubCatName}
                                        </label>
                                      </div>
                                    </Col>
                                  </Row>
                                );
                              })}
                          </div>
                        </Col>
                      </Row>
                    );
                  })}
              </Col>


              <Col lg="3" md="3" sm="12">
                <div style={{ marginLeft: "5%" }}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={!isEmpty(props.permissionsList) && props.permissionsList[3].permissionsName}
                    id="flexCheckDefault"
                    onChange={(event) => handleViewOrder(event)}
                  />
                  <label className="form-check-label" for="flexCheckDefault">
                    {!isEmpty(props.permissionsList) && props.permissionsList[3].permissionsName}
                  </label>
                </div>
                <div style={{ marginLeft: "5%" }}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={!isEmpty(props.permissionsList) && props.permissionsList[4].permissionsName}
                    id="flexCheckDefault"
                    onChange={(event) => handleReport(event)}
                  />
                  <label className="form-check-label" for="flexCheckDefault">
                    {!isEmpty(props.permissionsList) && props.permissionsList[4].permissionsName}
                  </label>
                </div>
                <div style={{ marginLeft: "5%" }}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={!isEmpty(props.permissionsList) && props.permissionsList[5].permissionsName}
                    id="flexCheckDefault"
                    onChange={(event) => handlePos(event)}
                  />
                  <label className="form-check-label" for="flexCheckDefault">
                    {!isEmpty(props.permissionsList) &&
                      props.permissionsList[5].permissionsName}
                  </label>
                </div>
                <div style={{ marginLeft: "5%" }}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={!isEmpty(props.permissionsList) && props.permissionsList[9].permissionsName}
                    id="flexCheckDefault"
                    onChange={(event) => handleProduction(event)}
                  />
                  <label className="form-check-label" for="flexCheckDefault">
                    {!isEmpty(props.permissionsList) &&
                      props.permissionsList[9].permissionsName}
                  </label>
                </div>
                <div style={{ marginLeft: "5%" }}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={!isEmpty(props.permissionsList) && props.permissionsList[10].permissionsName}
                    id="flexCheckDefault"
                    onChange={(event) => handleHR(event)}
                  />
                  <label className="form-check-label" for="flexCheckDefault">
                    {!isEmpty(props.permissionsList) &&
                      props.permissionsList[10].permissionsName}
                  </label>
                </div>
                {/* <div style={{ marginLeft: "5%" }}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={
                      !isEmpty(props.permissionsList) &&
                      props.permissionsList[6].permissionsName
                    }
                    id="flexCheckDefault"
                    onChange={(event) => handleKds(event)}
                  />
                  <label className="form-check-label" for="flexCheckDefault">
                    {!isEmpty(props.permissionsList) &&
                      props.permissionsList[6].permissionsName}
                  </label>
                </div> */}
                {/* <div style={{ marginLeft: "5%" }}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={
                      !isEmpty(props.permissionsList) &&
                      props.permissionsList[7].permissionsName
                    }
                    id="flexCheckDefault"
                    onChange={(event) => handleTable(event)}
                  />
                  <label className="form-check-label" for="flexCheckDefault">
                    {!isEmpty(props.permissionsList) &&
                      props.permissionsList[7].permissionsName}
                  </label>
                </div> */}
                {/* <div style={{ marginLeft: "5%" }}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={!isEmpty(props.permissionsList) && props.permissionsList[8].permissionsName}
                    id="flexCheckDefault"
                    onChange={(event) => handleBackUp(event)}
                  />
                  <label className="form-check-label" for="flexCheckDefault">
                    {!isEmpty(props.permissionsList) && props.permissionsList[8].permissionsName}
                  </label>
                </div> */}
              </Col>
            </Row>

            <div className="sendDiv">
              {isEmpty(users) ? (
                <Button variant="contained" endIcon={<SendIcon />} size="large" disabled> SAVE </Button>
              ) : (
                <Button variant="contained" endIcon={<SendIcon />} size="large" onClick={sendData}> SAVE </Button>
              )}
            </div>

          </Form>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUsers: () => dispatch(getUsers()),
    getPermissions: () => dispatch(getPermissions()),
    addPermissions: (data, handleVisible, handleDangerVisible) => dispatch(addPermissions(data, handleVisible, handleDangerVisible)),
    setHeading: (data) => dispatch(setHeading(data))
  };
};

const mapStateToProps = (state, ownProps) => ({
  usersList: state.permissionReducer.usersList,
  permissionsList: state.permissionReducer.permissionsList,
  isFetchingUsersList: state.permissionReducer.isFetchingUsersList,
  isFetchingPermissionsList: state.permissionReducer.isFetchingPermissionsList,
});

export default connect(mapStateToProps, mapDispatchToProps)(UserPermissions);
