const initialState = {
  CRMCustomerList: [],
  isFetchingCRMCustomer: false,
  isAddingCRMCustomer: false,
  isAddingCRMCustomerComment: false,
  comment: [],
  isUploadingCustomer: false,
  customerUploadList: [],
};

const CustomerReducerCRM = (state = initialState, action) => {
  switch (action.type) {

    case "REQUEST_ADD_CUSTOMER_CRM":
      return { ...state, isAddingCRMCustomer: true };
    case "SUCCESS_ADD_CUSTOMER_CRM":
      return { ...state, CRMCustomerList: action.payload, isAddingCRMCustomer: false, };
    case "ERROR_ADD_CUSTOMER_CRM":
      return { ...state, isAddingCRMCustomer: false };


    case "REQUEST_GET_CRM_CUSTOMER":
      return { ...state, isFetchingCRMCustomer: true };
    case "SUCCESS_GET_CRM_CUSTOMER":
      return { ...state, CRMCustomerList: action.payload.data, isFetchingCRMCustomer: false, };
    case "ERROR_GET_CRM_CUSTOMER":
      return { ...state };


    case "REQUEST_ADD_CUSTOMER_COMMENT":
      return { ...state, isAddingCRMCustomerComment: true };
    case "SUCCESS_ADD_CUSTOMER_COMMENT":
      return { ...state, comment: action.payload, isAddingCRMCustomerComment: false, };
    case "ERROR_ADD_CUSTOMER_COMMENT":
      return { ...state, isAddingCRMCustomerComment: false };


    case "REQUEST_UPLOAD_CUSTOMER_FILE":
      return { ...state, isUploadingCustomer: true };
    case "SUCCESS_UPLOAD_CUSTOMER_FILE":
      return { ...state, customerUploadList: action.payload, isUploadingCustomer: false, };
    case "ERROR_UPLOAD_CUSTOMER_FILE":
      return { ...state, isUploadingCustomer: false };


    default:
      return state;
  }
};
export default CustomerReducerCRM;
