import React, { useEffect, useRef } from "react";
import { Form, Container, Button } from "react-bootstrap";
import { connect } from "react-redux";
import _ from "lodash";
import Spinner from 'react-bootstrap/Spinner';
import MaterialTable from 'material-table';
import { UpdateEmployeeTypeApi, DeleteEmployeeTypeApi } from "./employeeTypeAction";
import { setHeading } from "../../../accounts/account.actions";
import { baseURL, headers } from "../../../../../global/api";


const EmployeeTypeList = (props) => {
    const tableRef = useRef();
    function updateTableQuery() {
        tableRef.current.onQueryChange()
    };
    useEffect(() => {
        props.setHeading("Employee Type List")
    }, []);
    return (
        <>
            {(props.isFetchingEmployeeTypeData) ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />
                    </Button>
                </div>
            ) : (
                <Container fluid>
                    <Form>
                        <MaterialTable
                            title="Employee Type"
                            columns={[
                                { title: 'EmployeeType', field: 'employeeType' },
                                { title: 'EmployeeType Description', field: 'description' },
                            ]}
                            data={(query) =>
                                new Promise((resolve, reject) => {
                                    let url = `${baseURL}/employeeType?`;
                                    url += "limit=" + query.pageSize;
                                    url += "&page=" + query.page;
                                    url += "&search=" + query.search;
                                    fetch(url, {
                                        method: "GET",
                                        headers: headers,
                                    }).then((response) => response.json()).then((result) => {
                                        resolve({
                                            data: result.data,
                                            page: result.page,
                                            totalCount: result.total_results,
                                        });
                                    });
                                })
                            }
                            options={{
                                actionsColumnIndex: -1,
                                filtering: false,
                                exportButton: true,
                                exportAllData: true,
                                sorting: true,
                                paging: true,
                                pageSize: 50,
                                emptyRowsWhenPaging: false,
                                pageSizeOptions: [50, 100, 150, 200, 500],
                                headerStyle: {
                                    position: 'sticky', top: 0,
                                    color: '#00BBBB',
                                    fontWeight: '550',
                                    onRowAdd: 'none',
                                },
                            }}
                            editable={{
                                onRowUpdate: (newData, oldData) =>
                                    new Promise((resolve) => {
                                        setTimeout(() => {
                                            resolve();
                                            props.UpdateEmployeeTypeApi(newData, oldData, updateTableQuery)
                                        }, 600);
                                    }),
                                onRowDelete: (oldData) =>
                                    new Promise((resolve) => {
                                        setTimeout(() => {
                                            resolve();
                                            props.DeleteEmployeeTypeApi(oldData._id, updateTableQuery);
                                        }, 600);
                                    }),
                            }}
                        />
                    </Form>
                </Container>
            )}
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        UpdateEmployeeTypeApi: (newData, oldData, updateTableQuery) => dispatch(UpdateEmployeeTypeApi(newData, oldData, updateTableQuery)),
        DeleteEmployeeTypeApi: (employee_type_id, updateTableQuery) => dispatch(DeleteEmployeeTypeApi(employee_type_id, updateTableQuery)),
        setHeading: (data) => dispatch(setHeading(data)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    employeeTypeList: state.EmployeeTypeReducer.employeeTypeList,
    isFetchingEmployeeTypeData: state.EmployeeTypeReducer.isFetchingEmployeeTypeData,
});

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeTypeList);