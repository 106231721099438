import React, { useEffect } from "react";
import { Container, } from "react-bootstrap";
import { connect } from "react-redux";
import { getBrandApi, deleteBrandApi, updateBrandApi } from "./Brand.action";
import { baseURL, headers } from "../../../../../global/api";
import { setHeading } from "../../../accounts/account.actions";
import MaterialSize from "material-table";
import Spinner from "react-bootstrap/Spinner";
import jsPDF from "jspdf";
import logo from "../../../../../Images/logo.png";


const ViewBrand = (props) => {

  useEffect(() => {
    props.setHeading("Brand List");
  }, []);

  return (
    <>
      {props.isFetchingBrand ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <MaterialSize
              title="Brand Details"
              columns={[
                { title: 'Sequence Number', field: 'sequenceNumber', editable: 'never' },
                { title: 'Customer', field: 'customerDetails.name', editable: 'never' },
                { title: "Brand Name", field: "brandName" },
                { title: "Brand Description", field: "brandDetails" },
                { title: "Created By", field: "userDetails.userName", editable: 'never' },
              ]}
              data={(query) =>
                new Promise((resolve, reject) => {
                  let url = `${baseURL}/brand?`;
                  url += "limit=" + query.pageSize;
                  url += "&page=" + (query.page);
                  url += "&search=" + query.search;
                  fetch(url, {
                    method: "GET",
                    headers: headers,
                  }).then((response) => response.json()).then((result) => {
                    resolve({
                      data: result.data,
                      page: result.page,
                      totalCount: result.total_results,
                    });
                  });
                })
              }
              options={{
                actionsColumnIndex: -1,
                exportButton: true,
                exportAllData: true,
                paging: true,
                pageSize: 50,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [50, 100, 150, 200],
                headerStyle: {
                  position: "sticky",
                  top: 0,
                  color: "#00BBBB",
                  fontWeight: "550",
                  onRowAdd: "none",
                },
                exportPdf: (columns, data) => {
                  const doc = new jsPDF();
                  const columnTitles = [
                    { title: "Sequence Number", field: "sequenceNumber", render: (rowData) => rowData.sequenceNumber },
                    { title: "Customer Name", field: "customerDetails.name", render: (rowData) => rowData.customerDetails.name },
                    { title: "Brand Name", field: "brandName", render: (rowData) => rowData.brandName },
                    { title: "Description", field: "brandDetails", render: (rowData) => rowData.brandDetails },
                  ].map((columnDef) => columnDef.title);
                  const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
                  const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
                  const companyNtn = `${process.env.REACT_APP_COMPANY_NTN}`;
                  const pdfData = data.map((rowData) => [
                    rowData.sequenceNumber,
                    rowData.customerDetails.name,
                    rowData.brandName,
                    rowData.brandDetails,
                  ]);
                  let content = {
                    startY: 50,
                    startX: 10,
                    head: [columnTitles],
                    body: pdfData,
                    styles: { fontSize: 11 },
                    // theme: "grid",
                    headStyles: { fillColor: "#2980ba" },
                  };
                  doc.addImage(logo, "PNG", 15, 9, 28, 28);
                  doc.setFontSize(12);
                  doc.setFont("times", "normal");
                  doc.setFont(undefined, "bold");
                  doc.text(companyName, 50, 18, 0, 0);
                  doc.setFont("times", "normal");
                  doc.text(companyAddress, 50, 25, 0, 0);
                  doc.text(companyNtn, 50, 32, 0, 0);
                  doc.text(`Brands List`, 90, 45, 0, 0);
                  doc.autoTable(content);
                  doc.setProperties({ title: `Brand PDF` });
                  var blob = doc.output("blob");
                  window.open(URL.createObjectURL(blob));
                },
              }}
              editable={{
                isDeletable: rowData => false,
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      props.updateBrandApi(newData, oldData);
                    }, 600);
                  }),
                onRowDelete: (oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      props.deleteBrandApi(oldData._id);
                    }, 600);
                  }),
              }}
            />
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getBrandApi: () => dispatch(getBrandApi()),
    deleteBrandApi: (id) => dispatch(deleteBrandApi(id)),
    updateBrandApi: (newData, oldData) => dispatch(updateBrandApi(newData, oldData)),
    setHeading: (data) => dispatch(setHeading(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  brandDataList: state.brandReducer.brandDataList,
  isFetchingBrand: state.brandReducer.isFetchingBrand,
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewBrand);
