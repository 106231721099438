import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { addCustomer } from "./Customer.action";
import { setHeading } from "../../../accounts/account.actions";
import Spinner from "react-bootstrap/Spinner";
import Swal from "sweetalert";
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';


const AddCustomer = (props) => {
  const [customer, setCustomer] = useState("");
  const [customerCnic, setCustomerCnic] = useState();
  const [customerPhone, setCustomerPhone] = useState();
  const [customerAddress, setCustomerAddress] = useState("");
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);


  function uploadCustomer() {
    props.addCustomer({
      name: customer,
      phone: customerPhone,
      address: customerAddress,
      email: customerCnic,
    },
      handleVisible,
      handleDangerVisible
    );
    setCustomer("")
    setCustomerCnic("")
    setCustomerPhone("")
    setCustomerAddress("")
  }
  const handleVisible = (msg) => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  };
  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };

  useEffect(() => {
    props.setHeading("Add Customer");
  }, []);

  return (
    <>
      {props.isAddingCustomer ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <Form>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label><b>Customer Name *</b></Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Customer Name"
                    onChange={(e) => setCustomer(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label><b>Customer Phone </b></Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Customer Phone"
                    value={customerPhone}
                    onChange={(e) => setCustomerPhone(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label><b>Customer Email </b></Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Customer Email"
                    onChange={(e) => { setCustomerCnic(e.target.value); }}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label><b>Customer Address </b></Form.Label>
                  <Form.Control
                    type="text"
                    value={customerAddress}
                    placeholder="Customer Address"
                    onChange={(e) => setCustomerAddress(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>

            <div className="sendDiv">
              {isEmpty(customer) ? (
                <Button variant="contained" endIcon={<SendIcon />} size="large" disabled> SAVE </Button>
              ) : (
                <Button variant="contained" endIcon={<SendIcon />} size="large" onClick={uploadCustomer}> SAVE</Button>
              )}
            </div>

          </Form>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addCustomer: (data, handleVisible, handleDangerVisible) => dispatch(addCustomer(data, handleVisible, handleDangerVisible)),
    setHeading: (data) => dispatch(setHeading(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  customerList: state.CustomerReducer.customerList,
  isAddingCustomer: state.CustomerReducer.isAddingCustomer,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddCustomer);