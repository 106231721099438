// import _ from "lodash";

const initialState = {
    shiftControl: [],
    addShiftControl: [],
    isFetchingShiftControlData: false,
    isAddingShiftControl: false,
};

const ShiftControlReducer = (state = initialState, action) => {
    switch (action.type) {

        case "REQUEST_GET_SHIFT_CONTROL":
            return { ...state, isFetchingShiftControlData: true };
        case "SUCCESS_GET_SHIFT_CONTROL":
            return { ...state, shiftControl: action.payload.data, isFetchingShiftControlData: false };
        case "ERROR_GET_SHIFT_CONTROL":
            return { ...state, isFetchingShiftControlData: false };


        case "REQUEST_ADD_SHIFT_CONTROL":
            return { ...state, isAddingShiftControl: true };
        case "SUCCESS_ADD_SHIFT_CONTROL":
            return { ...state, addShiftControl: action.payload, isAddingShiftControl: false };
        case "ERROR_ADD_SHIFT_CONTROL":
            return { ...state, isAddingShiftControl: false };


        default:
            return state;
    }
}

export default ShiftControlReducer;