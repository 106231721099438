import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import { baseURL, headers } from "../../../../../global/api";
import { DeleteCRMCustomer, UpdateCRMCustomerAPi, SendCustomerEmailApi } from "./CustomerActionCRM";
import { setHeading } from "../../../accounts/account.actions";
import MaterialTable from "material-table";
import Spinner from "react-bootstrap/Spinner";
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
const tableRef = React.createRef()


const ViewCustomerCRM = (props) => {

  useEffect(() => {
    props.setHeading("Customer List");
  }, []);

  return (
    <>
      {props.isFetchingCRMCustomer ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="border"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <MaterialTable
              tableRef={tableRef}
              title="Customer Details"
              columns={[
                { title: 'Sequence Number', field: 'sequenceNumber', editable: 'never' },
                { title: 'Product Name', field: 'productDetails.productName', editable: 'never' },
                { title: "Company Name", field: "companyName" },
                { title: "Owner Name", field: "ownerName" },
                { title: "Status", field: "status" },
                { title: "Contact Person", field: "contactPerson" },
                { title: "Designation", field: "designation" },
                { title: "Phone Number 1", field: "phoneNumberOne" },
                { title: "Phone Number 2", field: "phoneNumberTwo" },
                { title: "Office Number", field: "officeNumber" },
                { title: "City", field: "city" },
                { title: "Address", field: "address" },
                { title: "CR", field: "cr" },
                { title: "Date", field: "date" },
                { title: "Email", field: "email" },
                { title: "Website", field: "website" },
                { title: "Description", field: "description" },
                { title: "Created By", field: "userDetails.userName", editable: 'never' },
              ]}
              data={(query) =>
                new Promise((resolve, reject) => {
                  let url = `${baseURL}/CRMCustomer?`;
                  url += "size=" + query.pageSize;
                  url += "&page=" + query.page;
                  url += "&search=" + query.search;
                  fetch(url, {
                    method: "GET",
                    headers: headers,
                  }).then((response) => response.json()).then((result) => {
                    resolve({
                      data: result.data,
                      page: result.page,
                      totalCount: result.total_results,
                    });
                  });
                })
              }
              options={{
                actionsColumnIndex: -1,
                exportButton: true,
                exportAllData: true,
                paging: true,
                pageSize: 50,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [50, 100, 150, 200],
                headerStyle: {
                  position: "sticky",
                  top: 0,
                  color: "#00BBBB",
                  fontWeight: "550",
                  onRowAdd: "none",
                },
              }}
              editable={{
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      props.UpdateCRMCustomerAPi(newData, oldData);
                    }, 600);
                  }),
                onRowDelete: (oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      props.DeleteCRMCustomer(oldData._id);
                    }, 600);
                  }),
              }}
              actions={[
                {
                  icon: 'refresh',
                  tooltip: 'Refresh Data',
                  isFreeAction: true,
                  onClick: () => tableRef.current && tableRef.current.onQueryChange(),
                },
                (rowData) => ({
                  // disabled: true,
                  icon: () => <ForwardToInboxIcon />,
                  tooltip: "Send Email",
                  onClick: (event, rowData) => {
                    props.SendCustomerEmailApi({ email: rowData.email });
                    // tableRef.current && tableRef.current.onQueryChange();
                  },
                }),
              ]}
            />
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    DeleteCRMCustomer: (customerId) => dispatch(DeleteCRMCustomer(customerId)),
    SendCustomerEmailApi: (data) => dispatch(SendCustomerEmailApi(data)),
    UpdateCRMCustomerAPi: (newData, oldData) => dispatch(UpdateCRMCustomerAPi(newData, oldData)),
    setHeading: (data) => dispatch(setHeading(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  isFetchingCRMCustomer: state.CustomerReducerCRM.isFetchingCRMCustomer,
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewCustomerCRM);
