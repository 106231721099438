const initialState = {
    productionIssuanceList: [],
    isFetchingProductionIssuance: false,
    isAddingProductionIssuance: false,
};

const ProductionIssuanceReducer = (state = initialState, action) => {
    switch (action.type) {
        case "REQUEST_ADD_PRODUCTION_ISSUANCE":
            return { ...state, isAddingProductionIssuance: true };
        case "SUCCESS_ADD_PRODUCTION_ISSUANCE":
            return { ...state, productionIssuanceList: action.payload.data, isAddingProductionIssuance: false };
        case "ERROR_ADD_PRODUCTION_ISSUANCE":
            return { ...state, };


        case "REQUEST_GET_PRODUCTION_ISSUANCE":
            return { ...state, isFetchingProductionIssuance: true };
        case "SUCCESS_GET_PRODUCTION_ISSUANCE":
            return { ...state, productionIssuanceList: action.payload, isFetchingProductionIssuance: false };
        case "ERROR_GET_PRODUCTION_ISSUANCE":
            return { ...state, isFetchingProductionIssuance: false };


        default:
            return state;
    }
};
export default ProductionIssuanceReducer;