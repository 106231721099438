import API from "../../../../../global/api";
// import _ from "lodash";


export const requestAddDepartment = () => {
    return {
        type: "REQUEST_ADD_DEPARTMENT",
    };
};
export const successAddDepartment = (data) => {
    return {
        type: "SUCCESS_ADD_DEPARTMENT",
        payload: data,
    };
};
export const errorAddDepartment = () => {
    return {
        type: "ERROR_ADD_DEPARTMENT",
    };
};
export const AddDepartmentApi = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddDepartment());
        API.post(`/department`, data).then((res) => {
            let getData = res.data;
            dispatch(successAddDepartment(getData));
            handleVisible();
        }).catch((error) => {
            dispatch(errorAddDepartment());
            handleDangerVisible()
        })
    };
};


export const requestUpdateDepartment = () => {
    return {
        type: "REQUEST_UPDATE_DEPARTMENT",
    };
};
export const successUpdateDepartment = (data) => {
    return {
        type: "SUCCESS_UPDATE_DEPARTMENT",
        payload: data,
    };
};
export const errorUpdateDepartment = () => {
    return {
        type: "ERROR_UPDATE_DEPARTMENT",
    };
};
export const UpdateDepartmentApi = (newData, oldData, updateTableQuery) => {
    return (dispatch) => {
        dispatch(requestUpdateDepartment());
        API.put(`/department/${oldData._id}`, newData).then((res) => {
            dispatch(successUpdateDepartment([newData]));
            updateTableQuery()
        }).catch((error) => {
            dispatch(errorUpdateDepartment());
        })
    };
};


export const requestDeleteDepartment = () => {
    return {
        type: "REQUEST_DELETE_DEPARTMENT",
    };
};
export const successDeleteDepartment = (data) => {
    return {
        type: "SUCCESS_DELETE_DEPARTMENT",
        payload: data,
    };
};
export const errorDeleteDepartment = () => {
    return {
        type: "ERROR_DELETE_DEPARTMENT",
    };
};
export const DeleteDepartmentApi = (departmentId, updateTableQuery) => {
    return (dispatch) => {
        dispatch(requestDeleteDepartment());
        API.delete(`/department/${departmentId}`).then((res) => {
            dispatch(successDeleteDepartment());
            updateTableQuery();
        }).catch((error) => {
            dispatch(errorDeleteDepartment());
        })
    };
};


export const requestGetDepartment = () => {
    return {
        type: "REQUEST_GET_DEPARTMENT",
    };
};
export const successGetDepartment = (data) => {
    return {
        type: "SUCCESS_GET_DEPARTMENT",
        payload: data,
    };
};
export const errorGetDepartment = () => {
    return {
        type: "ERROR_GET_DEPARTMENT",
    };
};
export const GetDepartmentApi = () => {
    return (dispatch) => {
        dispatch(requestGetDepartment());
        API.get(`/department`).then((res) => {
            let getData = res.data;
            dispatch(successGetDepartment(getData));
        }).catch((error) => {
            dispatch(errorGetDepartment());
        })
    };
};


export const requestGetDepartmentByDivisionId = () => {
    return {
        type: "REQUEST_GET_DEPARTMENT_BY_DIVISIONID",
    };
};
export const successGetDepartmentByDivisionId = (data) => {
    return {
        type: "SUCCESS_GET_DEPARTMENT_BY_DIVISIONID",
        payload: data,
    };
};
export const errorGetDepartmentByDivisionId = () => {
    return {
        type: "ERROR_GET_DEPARTMENT_BY_DIVISIONID",
    };
};
export const GetDepartmentByDivisionIdApi = (divisionId) => {
    return (dispatch) => {
        dispatch(requestGetDepartmentByDivisionId());
        API.get(`/department/get_department_with_division/${divisionId}`).then((res) => {
            let getData = res.data;
            dispatch(successGetDepartmentByDivisionId(getData));
        }).catch((error) => {
            dispatch(errorGetDepartmentByDivisionId());
        })
    };
};