import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { AddProductCRMApi } from "./CRMProductAction";
import { setHeading } from "../../../accounts/account.actions";
import _ from "lodash";
import Spinner from "react-bootstrap/Spinner";
import Swal from "sweetalert";
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';


const AddProductCRM = (props) => {
  const [productName, setProductName] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  function SaveProductCRM() {
    props.AddProductCRMApi({
      productName: productName,
      productDescription: productDescription,
    },
      handleVisible,
      handleDangerVisible
    );
    setProductName("");
    setProductDescription("")
  }
  const handleVisible = (msg) => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  };
  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };

  useEffect(() => {
    props.setHeading("Add Product");
  }, []);

  return (
    <>
      {props.isAddingCRMProduct ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <Form>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label><b>Product Name *</b></Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Product Name"
                    onChange={(e) => setProductName(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label><b>Product Description </b></Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Product Description"
                    onChange={(e) => setProductDescription(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>

            <div className="sendDiv">
              {_.isEmpty(productName) ? (
                <Button variant="contained" endIcon={<SendIcon />} size="large" disabled> SAVE </Button>
              ) : (
                <Button variant="contained" endIcon={<SendIcon />} size="large" onClick={SaveProductCRM}> SAVE </Button>
              )}
            </div>

          </Form>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    AddProductCRMApi: (data, handleVisible, handleDangerVisible) => dispatch(AddProductCRMApi(data, handleVisible, handleDangerVisible)),
    setHeading: (data) => dispatch(setHeading(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  isAddingCRMProduct: state.CRMProductReducer.isAddingCRMProduct,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddProductCRM);
