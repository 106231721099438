import API from "../../../../../global/api";


export const requestVendorData = () => {
    return {
        type: "REQUEST_VENDOR_GET_DATA",
    };
};
export const successVendorGetData = (data) => {
    return {
        type: "SUCCESS_VENDOR_GET_DATA",
        payload: data,
    };
};
export const errorVendorGetData = () => {
    return {
        type: "ERROR_VENDOR_GET_DATA",
    };
};
export const getVendorData = () => {
    return (dispatch) => {
        dispatch(requestVendorData());
        API.get(`/vendor`).then((res) => {
            let getData = res.data;
            dispatch(successVendorGetData(getData));
        }).catch((error) => {
            dispatch(errorVendorGetData());
        })
    };
};


export const requestAddVendor = () => {
    return {
        type: "REQUEST_ADD_VENDOR",
    };
};
export const successAddVendor = (data) => {
    return {
        type: "SUCCESS_ADD_VENDOR",
        payload: data,
    };
};
export const errorAddVendor = () => {
    return {
        type: "ERROR_ADD_VENDOR",
    };
};
export const addVendor = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddVendor());
        API.post(`/vendor`, data).then((res) => {
            dispatch(successAddVendor(data));
            handleVisible(res.data.message);
        }).catch((error) => {
            dispatch(errorAddVendor());
            handleDangerVisible(error.response.data.message);
        })
    };
};


export const DeleteRequestVendor = () => {
    return {
        type: "DELETE_REQUEST_VENDOR",
    };
};
export const DeleteSuccessVendor = (user_id) => {
    return {
        type: "DELETE_SUCCESS_VENDOR",
        user_id: user_id,
    };
};
export const DeleteErrorVendor = () => {
    return {
        type: "DELETE_ERROR_VENDOR",
    };
};
export const deleteVendor = (vendorId) => {
    return dispatch => {
        dispatch(DeleteRequestVendor());
        API.delete(`/vendor/${vendorId}`).then(res => {
            dispatch(DeleteSuccessVendor(vendorId))
        }).catch((error) => {
            dispatch(DeleteErrorVendor())
        });
    }
};


export const UpdateRequestVendor = () => {
    return {
        type: "UPDATE_REQUEST_VENDOR",
    };
};
export const UpdateSuccessVendor = (data) => {
    return {
        type: "UPDATE_SUCCESS_VENDOR",
        payload: data,
    };
};
export const UpdateErrorVendor = () => {
    return {
        type: "UPDATE_ERROR_VENDOR",
    };
};
export const updateVendor = (data, oldData) => {
    return dispatch => {
        dispatch(UpdateRequestVendor());
        API.put(`/vendor/${oldData._id}`,
            {
                vendorName: data.vendorName,
                vendorCnic: data.vendorCnic,
                vendorPhone: data.vendorPhone,
                vendorAddress: data.vendorAddress,
            }, {
        }).then(res => {
            dispatch(UpdateSuccessVendor([data]))
        }).catch((error) =>
            dispatch(UpdateErrorVendor()));
    }
};