const initialState = {
  orderCreation: [],
  isAddingOrderCreation: false,
  isFetchingOrderCreation: false,
  orderCreationList: [],
  isFetchingOrderCreationWitId: false,
  orderCreationWitIdData: [],
};

const OrderCreationReducer = (state = initialState, action) => {
  switch (action.type) {

    case "REQUEST_ADD_ORDER_CREATION":
      return { ...state, isAddingOrderCreation: true };
    case "SUCCESS_ADD_ORDER_CREATION":
      return { ...state, orderCreation: action.payload, isAddingOrderCreation: false };
    case "ERROR_ADD_ORDER_CREATION":
      return { ...state, isAddingOrderCreation: false };


    case "REQUEST_GET_ORDER_CREATION":
      return { ...state, isFetchingOrderCreation: true };
    case "SUCCESS_GET_ORDER_CREATION":
      return { ...state, orderCreationList: action.payload.data, isFetchingOrderCreation: false, };
    case "ERROR_GET_ORDER_CREATION":
      return { ...state, isFetchingOrderCreation: false };


    case "REQUEST_GET_ORDER_CREATION_WITH_ID":
      return { ...state, isFetchingOrderCreationWitId: true };
    case "SUCCESS_GET_ORDER_CREATION_WITH_ID":
      return { ...state, orderCreationWitIdData: action.payload.data, isFetchingOrderCreationWitId: false, };
    case "ERROR_GET_ORDER_CREATION_WITH_ID":
      return { ...state, isFetchingOrderCreationWitId: false };


    default:
      return state;
  }
};

export default OrderCreationReducer;