const initialState = {
    isAddingDesignationData: false,
    addDesignation: [],
    designationList: [],
    isFetchingDesignationData: false,
    designationListByDepartmentId: [],
    isFetchingDesignationDataByDepartmentId: false,
};

const DesignationReducer = (state = initialState, action) => {
    switch (action.type) {

        case "REQUEST_ADD_DESIGNATION":
            return { ...state, isAddingDesignationData: true };
        case "SUCCESS_ADD_DESIGNATION":
            return { ...state, addDesignation: action.payload, isAddingDesignationData: false };
        case "ERROR_ADD_DESIGNATION":
            return { ...state, isAddingDesignationData: false };


        case "REQUEST_GET_DESIGNATION":
            return { ...state, isFetchingDesignationData: true };
        case "SUCCESS_GET_DESIGNATION":
            return { ...state, designationList: action.payload.designation, isFetchingDesignationData: false, };
        case "ERROR_GET_DESIGNATION":
            return { ...state, isFetchingDesignationData: false };


        case "REQUEST_GET_DESIGNATION_BY_DEPARTMENT":
            return { ...state, isFetchingDesignationDataByDepartmentId: true };
        case "SUCCESS_GET_DESIGNATION_BY_DEPARTMENT":
            return { ...state, designationListByDepartmentId: action.payload.data, isFetchingDesignationDataByDepartmentId: false, };
        case "ERROR_GET_DESIGNATION_BY_DEPARTMENT":
            return { ...state, isFetchingDesignationDataByDepartmentId: false };


        default:
            return state;
    }
}

export default DesignationReducer;