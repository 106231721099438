const initialState = {
    addDepartment: [],
    departmentList: [],
    isFetchingDepartmentData: false,
    isDeletingDepartmentData: false,
    isAddingDepartmentData: false,
};

const DepartmentReducer = (state = initialState, action) => {
    switch (action.type) {

        case "REQUEST_ADD_DEPARTMENT":
            return { ...state, isAddingDepartmentData: true };
        case "SUCCESS_ADD_DEPARTMENT":
            return { ...state, addDepartment: action.payload, isAddingDepartmentData: false };
        case "ERROR_ADD_DEPARTMENT":
            return { ...state, isAddingDepartmentData: false };


        case "REQUEST_GET_DEPARTMENT":
            return { ...state, isFetchingDepartmentData: true };
        case "SUCCESS_GET_DEPARTMENT":
            return { ...state, departmentList: action.payload.data, isFetchingDepartmentData: false };
        case "ERROR_GET_DEPARTMENT":
            return { ...state, isFetchingDepartmentData: false };


        case "REQUEST_GET_DEPARTMENT_BY_DIVISIONID":
            return { ...state, isFetchingDepartmentData: true };
        case "SUCCESS_GET_DEPARTMENT_BY_DIVISIONID":
            return { ...state, departmentList: action.payload.data, isFetchingDepartmentData: false };
        case "ERROR_GET_DEPARTMENT_BY_DIVISIONID":
            return { ...state, isFetchingDepartmentData: false };


        case "REQUEST_DELETE_DEPARTMENT":
            return { ...state, isDeletingDepartmentData: true };
        case "SUCCESS_DELETE_DEPARTMENT":
            return { ...state, isDeletingDepartmentData: false };
        case "ERROR_DELETE_DEPARTMENT":
            return { ...state, isDeletingDepartmentData: false };


        default:
            return state;
    }
}

export default DepartmentReducer;