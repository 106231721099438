import React, { useEffect } from "react";
import { Container, } from "react-bootstrap";
import { connect } from "react-redux";
import { DeleteProcessApi, UpdateProcessApi } from "./ProcessAction";
import { baseURL, headers } from "../../../../../global/api";
import { setHeading } from "../../../accounts/account.actions";
import MaterialSize from "material-table";
import Spinner from "react-bootstrap/Spinner";
import jsPDF from "jspdf";
import logo from "../../../../../Images/logo.png";


const ViewProcess = (props) => {

    useEffect(() => {
        props.setHeading("Process List");
    }, []);

    return (
        <>
            {props.isFetchingProcess ? (
                <div className="loader-div">
                    <Spinner
                        variant="primary"
                        animation="border"
                        size="xl"
                        role="status"
                        aria-hidden="true"
                    />
                </div>
            ) : (
                <Container fluid>
                    <div className="main">
                        <MaterialSize
                            title="Process Details"
                            columns={[
                                { title: 'S.No', field: 'sequenceNumber', editable: 'never' },
                                // { title: "Employee Name", field: "employeeDetails.employeeName", editable: 'never' },
                                // { title: "Vendor Name", field: "vendorDetails.vendorName", editable: 'never' },
                                { title: "Process Name", field: "processName" },
                                // { title: "Rate", field: "rate" },
                                { title: "Process Description", field: "description" },
                                { title: "Process Type", field: "processType" },
                                { title: "Created By", field: "userDetails.userName", editable: 'never' },
                            ]}
                            data={(query) =>
                                new Promise((resolve, reject) => {
                                    let url = `${baseURL}/process?`;
                                    url += "limit=" + query.pageSize;
                                    url += "&page=" + (query.page);
                                    url += "&search=" + query.search;
                                    fetch(url, {
                                        method: "GET",
                                        headers: headers,
                                    }).then((response) => response.json()).then((result) => {
                                        resolve({
                                            data: result.data,
                                            page: result.page,
                                            totalCount: result.total_results,
                                        });
                                    });
                                })
                            }
                            options={{
                                actionsColumnIndex: -1,
                                exportButton: true,
                                exportAllData: true,
                                paging: true,
                                pageSize: 50,
                                emptyRowsWhenPaging: false,
                                pageSizeOptions: [50, 100, 150, 200],
                                headerStyle: {
                                    position: "sticky",
                                    top: 0,
                                    color: "#00BBBB",
                                    fontWeight: "550",
                                    onRowAdd: "none",
                                },
                                exportPdf: (columns, data) => {
                                    const doc = new jsPDF();
                                    const columnTitles = [
                                        { title: 'S.No', field: 'sequenceNumber' },
                                        // { title: "Employee Name", field: "employeeDetails.employeeName" },
                                        // { title: "Vendor Name", field: "vendorDetails.vendorName" },
                                        { title: "Process Name", field: "processName" },
                                        // { title: "Rate", field: "rate" },
                                        { title: "Process Description", field: "description" },
                                        { title: "Process Type", field: "processType" },
                                    ].map((columnDef) => columnDef.title);
                                    const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
                                    const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
                                    const companyNtn = `${process.env.REACT_APP_COMPANY_NTN}`;
                                    const pdfData = data.map((rowData) => [
                                        rowData.sequenceNumber,
                                        // rowData.employeeDetails.employeeName,
                                        // rowData.vendorDetails.vendorName,
                                        rowData.processName,
                                        // rowData.rate,
                                        rowData.description,
                                        rowData.processType,
                                    ]);
                                    let content = {
                                        startY: 50,
                                        startX: 10,
                                        head: [columnTitles],
                                        body: pdfData,
                                        styles: { fontSize: 11 },
                                        // theme: "grid",
                                        headStyles: { fillColor: "#2980ba" },
                                    };
                                    doc.addImage(logo, "PNG", 15, 9, 28, 28);
                                    doc.setFontSize(12);
                                    doc.setFont("times", "normal");
                                    doc.setFont(undefined, "bold");
                                    doc.text(companyName, 50, 18, 0, 0);
                                    doc.setFont("times", "normal");
                                    doc.text(companyAddress, 50, 25, 0, 0);
                                    doc.text(companyNtn, 50, 32, 0, 0);
                                    doc.text(`Process List`, 90, 45, 0, 0);
                                    doc.autoTable(content);
                                    doc.setProperties({ title: `Process PDF` });
                                    var blob = doc.output("blob");
                                    window.open(URL.createObjectURL(blob));
                                },
                            }}
                            editable={{
                                // isEditHidden: (rowData) => Checkpermissions("permissionsSubCatName", "Colour Edit"),
                                // isDeleteHidden: (rowData) => Checkpermissions("permissionsSubCatName", "Colour Delete"),
                                isDeletable: rowData => false,
                                isEditable: rowData => true,
                                onRowUpdate: (newData, oldData) =>
                                    new Promise((resolve) => {
                                        setTimeout(() => {
                                            resolve();
                                            props.UpdateProcessApi(newData, oldData);
                                        }, 600);
                                    }),
                                onRowDelete: (oldData) =>
                                    new Promise((resolve) => {
                                        setTimeout(() => {
                                            resolve();
                                            props.DeleteProcessApi(oldData._id);
                                        }, 600);
                                    }),
                            }}
                        />
                    </div>
                </Container>
            )}
        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
        DeleteProcessApi: (id) => dispatch(DeleteProcessApi(id)),
        UpdateProcessApi: (newData, oldData) => dispatch(UpdateProcessApi(newData, oldData)),
        setHeading: (data) => dispatch(setHeading(data)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    processList: state.ProcessReducer.processList,
    isFetchingProcess: state.ProcessReducer.isFetchingProcess,
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewProcess);