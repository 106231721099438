import API from "../../../../../global/api";
import jsPDF from "jspdf";
import _ from "lodash";
import logo from "../../../../../Images/logo.png";
import "jspdf-autotable";


export const requestAddArticle = () => {
    return {
        type: "REQUEST_ADD_ARTICLE",
    };
};
export const successAddArticle = (data) => {
    return {
        type: "SUCCESS_ADD_ARTICLE",
        payload: data,
    };
};
export const errorAddArticle = () => {
    return {
        type: "ERROR_ADD_ARTICLE",
    };
};
export const AddArticleApi = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddArticle());
        API.post(`/article`, data).then((res) => {
            dispatch(successAddArticle(data));
            handleVisible(res.data.message);
        }).catch((error) => {
            dispatch(errorAddArticle());
            handleDangerVisible(error.response.data.message);
        })
    };
};


export const requestAddArticleRecipe = () => {
    return {
        type: "REQUEST_ADD_ARTICLE_RECIPE",
    };
};
export const successAddArticleRecipe = (data) => {
    return {
        type: "SUCCESS_ADD_ARTICLE_RECIPE",
        payload: data,
    };
};
export const errorAddArticleRecipe = () => {
    return {
        type: "ERROR_ADD_ARTICLE_RECIPE",
    };
};
export const AddArticleRecipeApi = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddArticleRecipe());
        API.post(`/article/add_article_recipe`, data).then((res) => {
            dispatch(successAddArticleRecipe(data));
            handleVisible(res.data.message);
        }).catch((error) => {
            dispatch(errorAddArticleRecipe());
            handleDangerVisible(error.response.data.message);
        })
    };
};


export const requestDuplicateArticleVariation = () => {
    return {
        type: "REQUEST_DUPLICATE_ARTICLE_VARIATION",
    };
};
export const successDuplicateArticleVariation = (data) => {
    return {
        type: "SUCCES_DUPLICATE_ARTICLE_VARIATION",
        payload: data,
    };
};
export const errorDuplicateArticleVariation = () => {
    return {
        type: "ERROR_DUPLICATE_ARTICLE_VARIATION",
    };
};
export const DuplicateArticleApi = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestDuplicateArticleVariation());
        API.post(`/article/duplicate_article_variation`, data).then((res) => {
            dispatch(successDuplicateArticleVariation(data));
            handleVisible(res.data.message);
        }).catch((error) => {
            dispatch(errorDuplicateArticleVariation());
            handleDangerVisible(error.response.data.message);
        })
    };
};


export const requestAddSubArticle = () => {
    return {
        type: "REQUEST_ADD_SUB_ARTICLE",
    };
};
export const successAddSubArticle = (data) => {
    return {
        type: "SUCCESS_ADD_SUB_ARTICLE",
        payload: data,
    };
};
export const errorAddSubArticle = () => {
    return {
        type: "ERROR_ADD_SUB_ARTICLE",
    };
};
export const AddSubArticleApi = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddSubArticle());
        API.post(`/article/addSubArticle`, data).then((res) => {
            dispatch(successAddSubArticle(data));
            handleVisible(res.data.message);
        }).catch((error) => {
            dispatch(errorAddSubArticle());
            handleDangerVisible(error.response.data.message);
        })
    };
};


export const requestGetArticle = () => {
    return {
        type: "REQUEST_GET_ARTICLE",
    };
};
export const successGetArticle = (data) => {
    return {
        type: "SUCCESS_GET_ARTICLE",
        payload: data,
    };
};
export const errorGetArticle = () => {
    return {
        type: "ERROR_GET_ARTICLE",
    };
};
export const GetArticleApi = () => {
    return (dispatch) => {
        dispatch(requestGetArticle());
        API.get(`/article`).then((res) => {
            let getData = res.data.data;
            dispatch(successGetArticle(getData));
        }).catch((error) => {
            dispatch(errorGetArticle());
        })
    };
};


export const requestDeleteArticle = () => {
    return {
        type: "REQUEST_DELETE_ARTICLE",
    };
};
export const successDeleteArticle = (user_id) => {
    return {
        type: "SUCCESS_DELETE_ARTICLE",
        user_id: user_id,
    };
};
export const errorDeleteArticle = () => {
    return {
        type: "ERROR_DELETE_ARTICLE",
    };
};
export const DeleteArticleApi = (id) => {
    return dispatch => {
        dispatch(requestDeleteArticle());
        API.delete(`/article/${id}`).then(res => {
            dispatch(successDeleteArticle(id))
        }).catch((error) => {
            dispatch(errorDeleteArticle())
        });
    }
};


export const requestUpdateProcess = () => {
    return {
        type: "REQUEST_UPDATE_ARTICLE",
    };
};
export const successUpdateProcess = (data) => {
    return {
        type: "SUCCESS_UPDATE_ARTICLE",
        payload: data,
    };
};
export const errorUpdateProcess = () => {
    return {
        type: "ERROR_UPDATE_ARTICLE",
    };
};
export const UpdateArticleApi = (data, oldData) => {
    return dispatch => {
        dispatch(requestUpdateProcess());
        API.put(`/article/${oldData._id}`, {
            articleName: data.articleName,
            composition: data.composition,
            currency: data.currency,
            customerCode: data.customerCode,
            description: data.description,
            exportCost: data.exportCost,
            hSCode: data.hSCode,
            instruction: data.instruction,
            manualCode: data.manualCode,
            packing: data.packing,
            productName: data.productName,
            weight: data.weight,
        }, {
        }).then(res => {
            dispatch(successUpdateProcess([data]))
        }).catch((error) => {
            dispatch(errorUpdateProcess());
        })
    }
};


export const requestArticleWithID = () => {
    return {
        type: "REQUEST_ARTICLE_WITH_ID",
    };
};
export const successArticleWithID = (data) => {
    return {
        type: "SUCCESS_ARTICLE_WITH_ID",
        payload: data,
    };
};
export const errorArticleWithID = () => {
    return {
        type: "ERROR_ARTICLE_WITH_ID",
    };
};
export const GetArticleWithIDApi = (id) => {
    return (dispatch) => {
        dispatch(requestArticleWithID());
        API.get(`/article/get_article_with_ID/${id}`).then((res) => {
            let getData = res;
            dispatch(successArticleWithID(getData));
        }).catch((error) => {
            dispatch(errorArticleWithID());
        })
    };
};


export const requestArticleWithCategory = () => {
    return {
        type: "REQUEST_ARTICLES_WITH_CATEGORY",
    };
};
export const successArticleWithCategory = (data) => {
    return {
        type: "SUCCESS_ARTICLES_WITH_CATEGORY",
        payload: data,
    };
};
export const errorArticleWithCategory = () => {
    return {
        type: "ERROR_ARTICLES_WITH_CATEGORY",
    };
};
export const GetArticlesWithCategoryApi = (payload) => {
    return (dispatch) => {
        dispatch(requestArticleWithCategory());
        API.post(`/article/articles_with_category`, payload).then((res) => {
            const data = res.data
            dispatch(successArticleWithCategory(data));
        }).catch((error) => {
            dispatch(errorArticleWithCategory());
        })
    };
};


export const requestArticleWithVariationID = () => {
    return {
        type: "REQUEST_ARTICLES_WITH_VARIATION_ID",
    };
};
export const successArticleWithVariationID = (data) => {
    return {
        type: "SUCCESS_ARTICLES_WITH_VARIATION_ID",
        payload: data,
    };
};
export const errorArticleWithVariationID = () => {
    return {
        type: "ERROR_ARTICLES_WITH_VARIATION_ID",
    };
};
export const GetArticlesWithVariationIdApi = (payload) => {
    return (dispatch) => {
        dispatch(requestArticleWithVariationID());
        API.post(`/article/get_article_variationID`, payload).then((res) => {
            const data = res.data
            dispatch(successArticleWithVariationID(data));
        }).catch((error) => {
            dispatch(errorArticleWithVariationID());
        })
    };
};


export const PrintFullArticlePDF = (data) => {
    return (dispatch) => {
        const unit = "pt";
        const size = "A4";
        const orientation = "potrait";
        const doc = new jsPDF(orientation, unit, size);
        const title = "Article Complete Details";
        const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
        const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
        const companyNtn = `${process.env.REACT_APP_COMPANY_NTN}`;
        const sequenceNumber = "Article No: " + data.sequenceNumber;
        const articleName = "Article Name: " + data.articleName;
        const categoryName = "Category: " + data.categoryDetails.categoryName;
        const subCategoryName = "Sub Category: " + data.subCategoryDetails.subCategoryName;
        const customerName = "Customer Name: " + data.customerDetails.name;
        const brandName = "Brand Name: " + data.brandDetails.brandName
        const uomDetails = "Customer Code: " + data.uomDetails.unitName
        const cartonSize = `Carton Size: ${data.cartonSize}`
        const packing = `Packing: ${data.packing}`
        const weight = `Weight: ${data.weight}`
        const composition = `Composition: ${data.composition}`
        const hSCode = `HS Code: ${data.hSCode}`
        const description = `Description: ${data.description}`
        const catlogNumber = `Catalogue Number: ${data.catlogNumber}`
        const customerCatalogueNumber = `Customer Catalogue Number: ${data.customerCatalogueNumber}`
        const exportCost = `Export Cost: ${data.exportCost}`
        const currency = `Currency: ${data.currency}`
        const conversionToPKR = `Conversion To PKR: ${data.conversionToPKR}`
        const exportPrice = `Export Price: ${data.exportPrice}`
        const profit = `Profit: ${data.profit}`
        const articleTotalCost = `Article Total Cost: ${data.articleTotalCost}`


        const headers = [[
            "Sr",
            "Code",
            "Manual Code",
            "Colour",
            "Size",
        ]];
        const prodactData = _.isEmpty(data.variation) ? '' : data.variation.map((elt, i) => [
            i + 1,
            elt.variationSequenceNumber,
            elt.manualVariationCode,
            elt.colourLabel,
            elt.sizeLabel,
        ]);
        //   if (!_.isEmpty(prodactData)) {
        //     prodactData.push(["", "", "", Math.round(_.sumBy(prodactData, '3') * 100) / 100])
        //   }
        let ProductContent = {
            startY: 350,
            startX: 50,
            head: headers,
            styles: { fontSize: 11 },
            body: prodactData,
            theme: "grid",
            headStyles: { fillColor: "#2980ba" },
        };
        doc.autoTable(ProductContent);


        doc.addImage(logo, "PNG", 40, 15, 80, 80);
        doc.text(companyName, 150, 40, 0, 20);
        doc.setFontSize(12);
        doc.setFont("times", "normal");
        doc.text(companyAddress, 150, 58, 0, 20);
        doc.text(companyNtn, 150, 80, 0, 20);
        doc.setFontSize(12);
        doc.text(title, 240, 120);
        doc.text(sequenceNumber, 50, 150, 0, 20);
        doc.text(articleName, 330, 150, 0, 20);
        doc.text(categoryName, 50, 170, 0, 20);
        doc.text(subCategoryName, 330, 170, 0, 20);
        doc.text(customerName, 50, 190, 0, 20);
        doc.text(brandName, 330, 190, 0, 20);
        doc.text(uomDetails, 50, 210, 0, 20);
        doc.text(cartonSize, 330, 210, 0, 20);
        doc.text(packing, 50, 230, 0, 20);
        doc.text(weight, 330, 230, 0, 20);
        doc.text(composition, 50, 250, 0, 20);
        doc.text(hSCode, 330, 250, 0, 20);
        doc.text(description, 50, 270, 0, 20);
        doc.text(catlogNumber, 330, 270, 0, 20);
        doc.text(customerCatalogueNumber, 50, 290, 0, 20);
        doc.text(exportCost, 330, 290, 0, 20);
        doc.text(currency, 50, 310, 0, 20);
        doc.text(conversionToPKR, 330, 310, 0, 20);
        doc.text(exportPrice, 50, 330, 0, 20);
        doc.text(profit, 330, 330, 0, 20);


        doc.setProperties({ title: `Order PDF` });
        var blob = doc.output("blob");
        window.open(URL.createObjectURL(blob));
    };
};