const initialState = {
    incrementList: [],
    isFetchingIncrement: false,
    isAddingIncrement: false,
};

const IncrementReducer = (state = initialState, action) => {
    switch (action.type) {

        case "REQUEST_ADD_INCREMENT":
            return { ...state, isAddingIncrement: true };
        case "SUCCESS_ADD_INCREMENT":
            return { ...state, incrementList: action.payload, isAddingIncrement: false };
        case "ERROR_ADD_INCREMENT":
            return { ...state, isAddingIncrement: false };


        case "REQUEST_GET_INCREMENT":
            return { ...state, isFetchingIncrement: true };
        case "SUCCESS_GET_INCREMENT":
            return { ...state, incrementList: action.payload.data, isFetchingIncrement: false };
        case "ERROR_GET_INCREMENT":
            return { ...state };


        default:
            return state;
    }
};
export default IncrementReducer;