const initialState = {
    isFetchingMaterialRequirement: false,
    materialRequiredList: [],
    isFetchingProcessRequirement: false,
    processRequiredList: [],
    isFetchingAdminCostRequirement: false,
    adminCostRequiredList: [],
};

const CostControlReducer = (state = initialState, action) => {
    switch (action.type) {

        case "REQUEST_GET_MATERIAL_REQUIRED":
            return { ...state, isFetchingMaterialRequirement: true };
        case "SUCCESS_GET_MATERIAL_REQUIRED":
            return { ...state, materialRequiredList: action.payload, isFetchingMaterialRequirement: false };
        case "ERROR_GET_MATERIAL_REQUIRED":
            return { ...state, isFetchingMaterialRequirement: false };


        case "REQUEST_GET_PROCESS_REQUIRED":
            return { ...state, isFetchingProcessRequirement: true };
        case "SUCCESS_GET_PROCESS_REQUIRED":
            return { ...state, processRequiredList: action.payload, isFetchingProcessRequirement: false };
        case "ERROR_GET_PROCESS_REQUIRED":
            return { ...state, isFetchingProcessRequirement: false };


        case "REQUEST_GET_ADMINCOST_REQUIRED":
            return { ...state, isFetchingAdminCostRequirement: true };
        case "SUCCESS_GET_ADMINCOST_REQUIRED":
            return { ...state, adminCostRequiredList: action.payload, isFetchingAdminCostRequirement: false };
        case "ERROR_GET_ADMINCOST_REQUIRED":
            return { ...state, isFetchingAdminCostRequirement: false };


        default:
            return state;
    }
};

export default CostControlReducer;