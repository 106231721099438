import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Image } from "react-bootstrap";
import { connect } from "react-redux";
import _ from "lodash";
import Spinner from 'react-bootstrap/Spinner';
import profileImage from "../../../../../Images/profileImage.jpg";
import Select from 'react-select';
import ImageUploader from 'react-images-upload';
import { AddEmployeeApi } from "./employeeAction";
import { GetDepartmentApi } from "../department/departmentAction";
import { GetEmployeeTypeApi } from "../employeeType/employeeTypeAction";
import { GetDesignationByDepartmentApi } from "../designation/designationAction";
import Swal from 'sweetalert';
import moment from "moment";
import { setHeading } from '../../../accounts/account.actions';
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';



const AddEmployee = (props) => {
    const [uploadProfileImage, setUploadProfileImage] = useState("");
    const [image, setImage] = useState(profileImage);
    const [employeId, setEmployeId] = useState("");
    const [employeeName, setemployeeName] = useState("");
    const [appointmentType, setAppointmentType] = useState("");
    const [employeFatherName, setEmployeFatherName] = useState("");
    // const [employeMotherName, setEmployeMotherName] = useState();
    const [dob, setDob] = useState("");
    const [gender, setGender] = useState("");
    const [mobile, setMobile] = useState("");
    const [address, setAddress] = useState("");
    const [emergencyContact, setEmergencyContact] = useState("");
    const [maritalStatus, setMaritalStatus] = useState("");
    const [cnic, setCnic] = useState("");
    const [joiningDate, setJoiningDate] = useState("");
    const [religion, setReligion] = useState("");
    // const [machineID, setMachineID] = useState();
    const [allocation, setAllocation] = useState("");
    const [employeeType, setEmployeeType] = useState("");
    const [ntn, setNtn] = useState("");
    const [eobi, setEobi] = useState("");
    const [socialSecurity, setSocialSecurity] = useState("");
    const [bloodGroup, setBloodGroup] = useState("");
    const [probationPeriod, setProbationPeriod] = useState("");
    const [probationEndDate, setProbationEndDate] = useState("");
    // const [temporaryBlock, setTemporaryBlock] = useState();
    // const [reasonForBlock, setReasonForBlock] = useState();
    const [reportTo, setReportTo] = useState("");
    const [uploadCnicImage, setUploadCnicImage] = useState("");
    const [uploadDocument, setUploadDocument] = useState("");
    const [activeStatus, setActiveStatus] = useState(true);
    const [visibleAlert, setAlertVisible] = useState(false);
    const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
    const [department, setDepartment] = useState(0);
    const [designation, setDesignation] = useState(0);


    const SaveEmployee = () => {
        props.AddEmployeeApi({
            address: _.upperCase(address),
            appointment_type: appointmentType,
            name: _.upperCase(employeeName),
            father_name: _.upperCase(employeFatherName),
            // mother_name: employeMotherName,
            dob: dob,
            gender: gender,
            phone: mobile,
            emergency_contact: emergencyContact,
            marital_status: maritalStatus,
            cnic: cnic,
            uploadImage: uploadProfileImage,
            joining_date: joiningDate,
            religion: religion,
            // machine_id: machineID,
            report_to: _.upperCase(reportTo),
            uploadCnicImage: uploadCnicImage,
            uploadDocument: uploadDocument,
            // active_status: activeStatus,
            allocation: _.upperCase(allocation),
            employee_type: employeeType,
            ntn: ntn,
            eobi: eobi,
            social_security: socialSecurity,
            blood_group: bloodGroup,
            probation_period: _.upperCase(probationPeriod),
            probation_period_end_date: probationEndDate,
            // temporary_block: temporaryBlock,
            // reasonForBlock: reasonForBlock,
            department_id: department,
            designation_id: designation,
        }, handleVisible, handleDangerVisible);
    };
    const clearStates = () => {
        setUploadProfileImage("");
        setImage("");
        setEmployeId("");
        setemployeeName("");
        setAppointmentType("");
        setEmployeFatherName("");
        setDob("");
        setGender("");
        setMobile("");
        setAddress('');
        setEmergencyContact("");
        setMaritalStatus("");
        setCnic("");
        setJoiningDate("");
        setReligion("");
        setAllocation("");
        setEmployeeType(0);
        setNtn("");
        setEobi("");
        setSocialSecurity("");
        setBloodGroup("");
        setProbationPeriod("");
        setProbationEndDate("");
        setReportTo("");
        setUploadCnicImage("");
        setUploadDocument("");
        setActiveStatus(true);
        setAlertVisible();
        setDangerVisibleAlert();
        setDepartment(0);
        setDesignation(0);
    }
    const handleVisible = (message) => {
        setAlertVisible(true);
        clearStates();
        setTimeout(() => {
            setAlertVisible(false)
        }, 9000);
        Swal({
            title: "Success",
            text: message,
            icon: "success",
            button: "Ok",
        });
    }
    const handleDangerVisible = (error) => {
        setDangerVisibleAlert(true);
        setTimeout(() => {
            setDangerVisibleAlert(false)
        }, 9000);
        Swal({
            title: "Error",
            text: error,
            icon: "error",
            button: "Ok",
        });
    }


    const getAppointmentList = [
        { value: "new", label: "New" },
        { value: "replacement", label: "Replacement" },
    ];
    const handleChangeAppointment = (selectedOption) => {
        setAppointmentType(selectedOption.value);
    };

    const getReligionList = [
        { value: "Islam", label: "Islam" },
        { value: "Christianity", label: "Christianity" },
        { value: "Judaism", label: "Judaism" },
        { value: "Hinduism", label: "Hinduism" },
        { value: "Buddhism", label: "Buddhism" },
        { value: "Sikhism", label: "Sikhism" },
        { value: "Other", label: "Other" },
    ];
    const handleChangeReligion = (selectedOption) => {
        setReligion(selectedOption.value);
    };

    const getMaritalStatusList = [
        { value: "Single", label: "Single" },
        { value: "Married", label: "Married" },
        // { value: "Divorced", label: "Divorced" },
        // { value: "Separated", label: "Separated" },
    ];
    const handleChangeMaritalStatus = (selectedOption) => {
        setMaritalStatus(selectedOption.value);
    };

    const getGenderList = [
        { value: "Male", label: "Male" },
        { value: "Female", label: "Female" },
        { value: "Other", label: "Other" },
    ];
    const handleChangeGender = (selectedOption) => {
        setGender(selectedOption.value);
    };

    const getStatusList = [
        { value: true, label: "Active" },
        { value: false, label: "Non Active" },
    ];
    const handleChangeStatus = (selectedOption) => {
        setActiveStatus(selectedOption.value);
    };

    const getDepartmentNameList = !_.isEmpty(props.departmentList) && props.departmentList.map((x) => {
        let data = { value: x._id, label: x.department };
        return data
    });
    const handleChangeDepartment = (selectedOption) => {
        setDepartment(selectedOption.value);
        props.GetDesignationByDepartmentApi(selectedOption.value);
    };

    const getDesignationNameList = !_.isEmpty(props.designationListByDepartmentId) && props.designationListByDepartmentId.map((x) => {
        let data = { value: x._id, label: x.designation };
        return data
    });
    const handleChangeDesignation = (selectedOption) => {
        setDesignation(selectedOption.value);
    };

    const getEmployeeTypeList = _.isEmpty(props.employeeTypeList) ? [] : props.employeeTypeList.map((x) => {
        let data = { value: x._id, label: x.employeeType };
        return data
    });
    const handleChangeEmployeeType = (selectedOption) => {
        setEmployeeType(selectedOption.value);
    };


    const imageHandler = (e) => {
        setUploadProfileImage(e.target.files[0]);
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState == 2) {
                setImage(reader.result)
                // setUploadProfileImage(reader.result);
            }
        }
        reader.readAsDataURL(e.target.files[0]);
    };
    const onDrop = (pictureFiles, pictureDataURLs) => {
        setUploadCnicImage(pictureFiles);
    }
    const onDropDocs = (pictureFiles, pictureDataURLs) => {
        setUploadDocument(pictureFiles);
    }

    useEffect(() => {
        props.GetDepartmentApi();
        props.GetEmployeeTypeApi();
        props.setHeading("Employee Add")
    }, []);
    return (
        <>
            <Container fluid> {props.isAddingEmployee ? <div className="loader-div"> {(image !== profileImage) && setImage(profileImage)}
                <Button variant="info" disabled>
                    <Spinner
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        variant="info"
                    />
                    {props.isFetchingSectorsSummary ? 'Adding Employee' : 'Adding Employee'}
                </Button>
            </div> :
                <div className="mainHr">
                    <Row>
                        <Col lg="4" md="4" xs="12"></Col>
                        <Col lg="3" md="3" xs="12">
                            <Image
                                src={image}
                                rounded={false}
                                width="100%"
                                style={{ textAlign: "center" }} />
                            <input className="form-control" type="file" onChange={imageHandler} />
                        </Col>
                        <Col lg="4" md="4" xs="12"></Col>
                    </Row>
                    <br /><br />
                    <Form>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Department *</b></Form.Label>
                                    <Select
                                        isDisabled={_.isEmpty(getDepartmentNameList)}
                                        placeholder="Department"
                                        onChange={handleChangeDepartment}
                                        options={getDepartmentNameList}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Designation *</b></Form.Label>
                                    <Select
                                        isDisabled={_.isEmpty(getDesignationNameList)}
                                        placeholder="Designation"
                                        onChange={handleChangeDesignation}
                                        options={getDesignationNameList}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="4" md="4" xs="12">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Appointment Type *</b></Form.Label>
                                    <Select
                                        placeholder="Appointment Type"
                                        onChange={handleChangeAppointment}
                                        options={getAppointmentList}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Employee Name *</b></Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Employee Name"
                                        onBlur={(e) => setemployeeName(e.target.value)} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Father / Husband Name *</b></Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Father / Husband Name"
                                        onBlur={(e) => setEmployeFatherName(e.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Gender *</b></Form.Label>
                                    <Select
                                        placeholder="Gender"
                                        onChange={handleChangeGender}
                                        options={getGenderList}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg="4" md="4" xs="12">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Employee Type *</b></Form.Label>
                                    <Select
                                        placeholder="Employee Type"
                                        onChange={handleChangeEmployeeType}
                                        options={getEmployeeTypeList}
                                        isDisabled={_.isEmpty(getEmployeeTypeList) || _.isEmpty(props.employeeTypeList)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>CNIC *</b></Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="CNIC"
                                        onBlur={(e) => setCnic(e.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="4" md="4" xs="12">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Marital Status</b></Form.Label>
                                    <Select
                                        placeholder="Marital Status"
                                        onChange={handleChangeMaritalStatus}
                                        options={getMaritalStatusList}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg="4" md="4" xs="12">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Religion</b></Form.Label>
                                    <Select
                                        placeholder="Religion"
                                        onChange={handleChangeReligion}
                                        options={getReligionList}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg="4" md="4" xs="12">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Joining Date</b></Form.Label>
                                    <Form.Control
                                        type="date"
                                        placeholder="Joining Date"
                                        // max={moment().format("YYYY-MM-DD")}
                                        // min={min_date}
                                        // defaultValue ={joiningDate}
                                        // min={moment(new Date()).format("YYYY-MM")}
                                        onBlur={(e) => setJoiningDate(e.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Mobile Number</b></Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Mobile Number"
                                        onBlur={(e) => setMobile(e.target.value)} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Emergency Contact Number</b></Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Emergency Contact Number"
                                        onBlur={(e) => setEmergencyContact(e.target.value)} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Address</b></Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Address"
                                        onBlur={(e) => setAddress(e.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="4" md="4" xs="12">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Date of Birth </b></Form.Label>
                                    <Form.Control
                                        type="date"
                                        placeholder="Date of Birth"
                                        max={moment().format("YYYY-MM-DD")}
                                        onBlur={(e) => setDob(e.target.value)} />
                                </Form.Group>
                            </Col>
                            <Col lg="4" md="4" xs="12">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>NTN</b></Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="NTN"
                                        onBlur={(e) => setNtn(e.target.value)} />
                                </Form.Group>
                            </Col>
                            <Col lg="4" md="4" xs="12">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>EOBI</b></Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="EOBI"
                                        onBlur={(e) => setEobi(e.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Report To</b></Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Report To"
                                        onBlur={(e) => setReportTo(e.target.value)} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Social Security</b></Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Social Security"
                                        onBlur={(e) => setSocialSecurity(e.target.value)} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Blood Group</b></Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Blood Group"
                                        onBlur={(e) => setBloodGroup(e.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Asset Allocation</b></Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        placeholder="Asset Allocation"
                                        onBlur={(e) => setAllocation(e.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Probation Start Date</b></Form.Label>
                                    <Form.Control
                                        type="date"
                                        placeholder="Probation Start Date"
                                        onBlur={(e) => setProbationPeriod(e.target.value)} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Probation End Date</b></Form.Label>
                                    <Form.Control
                                        type="date"
                                        placeholder="Probation End Date"
                                        onBlur={(e) => setProbationEndDate(e.target.value)} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Upload Document</b></Form.Label>
                                    <input className="form-control" type="file" onBlur={(e) => setUploadDocument(e.target.files[0])} />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col xl="12" lg="12" md="12" sm="12" xs="12">
                                <Form.Group className="mb-3">
                                    {/* <Form.Label>
                                            <b style={{ fontSize: "large" }}>Upload CNIC Image</b>
                                        </Form.Label> */}
                                    <ImageUploader
                                        withIcon={false}
                                        buttonText='Upload CNIC Image'
                                        onChange={onDrop}
                                        // onChange = {(e) => setUploadCnicImage(e.target.files[0])}
                                        imgExtension={['.jpg', '.gif', '.png', '.gif']}
                                        maxFileSize={999999999999999999999}
                                        singleImage={true}
                                        withPreview={true}
                                    />
                                </Form.Group>
                            </Col>
                            {/* <Col xl="6" lg="6" md="6" sm="6" xs="6">
                                <Form.Group className="mb-3">
                                    <Form.Label>
                                        <b style={{ fontSize: "large" }}>Upload your Educational Documents</b>
                                    </Form.Label>
                                    <ImageUploader
                                        withIcon={false}
                                        buttonText='Upload Documents'
                                        onChange={onDropDocs}
                                        // onChange = {(e) => setUploadDocument(e.target.files[0])}
                                        imgExtension={['.jpg', '.gif', '.png', '.gif']}
                                        maxFileSize={999999999999999999999}
                                        singleImage={false}
                                        withPreview={true}
                                    />
                                </Form.Group>
                            </Col> */}
                        </Row>

                        <div className="sendDiv">
                            {_.isEmpty(appointmentType) || department === 0 || designation === 0 || (employeeType == "") || _.isEmpty(employeeName) || _.isEmpty(employeFatherName) || _.isEmpty(cnic) || _.isEmpty(gender) ? (
                                <Button variant="contained" endIcon={<SendIcon />} size="large" disabled> SAVE </Button>
                            ) : (
                                <Button variant="contained" endIcon={<SendIcon />} size="large" onClick={() => { SaveEmployee() }}>SAVE </Button>
                            )}
                        </div>

                    </Form>
                </div>
            }
            </Container>
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        AddEmployeeApi: (data, handleVisible, handleDangerVisible) => dispatch(AddEmployeeApi(data, handleVisible, handleDangerVisible)),
        GetDepartmentApi: () => dispatch(GetDepartmentApi()),
        GetEmployeeTypeApi: () => dispatch(GetEmployeeTypeApi()),
        GetDesignationByDepartmentApi: (id) => dispatch(GetDesignationByDepartmentApi(id)),
        setHeading: (data) => dispatch(setHeading(data)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    departmentList: state.DepartmentReducer.departmentList,
    designationListByDepartmentId: state.DesignationReducer.designationListByDepartmentId,
    isAddingEmployee: state.EmployeeReducer.isAddingEmployee,
    employeeTypeList: state.EmployeeTypeReducer.employeeTypeList,
    isFetchingEmployeeTypeData: state.EmployeeTypeReducer.isFetchingEmployeeTypeData,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEmployee);