import React, { useState } from 'react'
import { Container, Form, Row, Col, } from 'react-bootstrap';
import { connect } from "react-redux";
import Spinner from 'react-bootstrap/Spinner';
import { useEffect } from 'react';
import Swal from 'sweetalert';
import _ from "lodash";
import { AddEmployeeTypeApi } from './employeeTypeAction'
import { setHeading } from '../../../accounts/account.actions';
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';


const EmployeeType = (props) => {
    const [employeeTypeName, setEmployeeTypeName] = useState();
    const [employeeTypeDescription, setEmployeeTypeDescription] = useState();
    const [submit, setSubmit] = useState(false);
    const [visibleAlert, setAlertVisible] = useState(false);
    const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);


    function uploadEmployeeType() {
        props.AddEmployeeTypeApi({
            employeeType: _.upperCase(employeeTypeName),
            description: _.upperCase(employeeTypeDescription),
        }, handleVisible, handleDangerVisible);
    }
    const clearStates = () => {
        setEmployeeTypeName();
        setEmployeeTypeDescription();
        setSubmit(false);
    }
    const handleVisible = (message) => {
        setAlertVisible(true)
        clearStates();
        setTimeout(() => {
            setAlertVisible(false)
        }, 9000);
        Swal({
            title: "Success",
            text: message,
            icon: "success",
            button: "Ok",
        });
    }
    const handleDangerVisible = (error) => {
        setDangerVisibleAlert(true)
        setTimeout(() => {
            setDangerVisibleAlert(false)
        }, 9000);
        Swal({
            title: "Error",
            text: error,
            icon: "error",
            button: "Ok",
        });
    }

    useEffect(() => {
        props.setHeading("Employee Type Add")
        // props.GetHRDesignation();
    }, []);

    return (
        <>
            {(props.isAddingEmployeeTypeData) ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />
                    </Button>
                </div>
            ) : (
                <Container fluid>
                    <Form>
                        <Row>
                            <Col lg="12" md="12" xs="12">
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Employee Type *</b></Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Employee Type"
                                        onChange={(e) => setEmployeeTypeName(e.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl="12" lg="12" md="12" sm="12" xs="12">
                                <Form.Group className="mb-3">
                                    <Form.Label> <b>Employee Type Description</b></Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        placeholder="Employee Type Description"
                                        onBlur={(e) => setEmployeeTypeDescription(e.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row>

                        <div className="sendDiv">
                            {_.isEmpty(employeeTypeName) ? (
                                <Button variant="contained" endIcon={<SendIcon />} size="large" disabled> SAVE </Button>
                            ) : (
                                <Button variant="contained" endIcon={<SendIcon />} size="large" onClick={() => { uploadEmployeeType(); }}> SAVE </Button>
                            )}
                        </div>
                    </Form>
                </Container>
            )}
        </>
    );
}
const mapDispatchToProps = (dispatch) => {
    return {
        AddEmployeeTypeApi: (data, handleVisible, handleDangerVisible) => dispatch(AddEmployeeTypeApi(data, handleVisible, handleDangerVisible)),
        setHeading: (data) => dispatch(setHeading(data)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    isAddingEmployeeTypeData: state.EmployeeTypeReducer.isAddingEmployeeTypeData,
});


export default connect(mapStateToProps, mapDispatchToProps)(EmployeeType);