import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { setHeading } from "../../../accounts/account.actions";
import { addCategoryName, resetReducerCategory, } from "./Catagory.action";
import { getRestaurantData } from "../AddResturant/Resturant.action";
import _ from "lodash";
import Spinner from "react-bootstrap/Spinner";
import Select from "react-select";
import Swal from "sweetalert";
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
// import { getWarehouseApi } from "../Warehouse/Warehouse.action";


const AddCategory = (props) => {
  const [category, setCategoryName] = useState();
  // const [wareHouseId, setWarehouseId] = useState();
  // const [warehouseName, setWarehouseName] = useState("");
  const [retaurantId, setRestaurantId] = useState();
  const [restaurantName, setRestaurantName] = useState();
  const [categoryType, setCategoryType] = useState();
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  const handleVisible = (msg) => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  };
  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };
  function uploadCategory() {
    props.addCategoryName(
      {
        categoryType: categoryType,
        name: category,
        retaurantId: retaurantId,
      },
      handleVisible,
      handleDangerVisible
    );
    setCategoryName("");
    props.reset();
  }

  // const handleChangeWarehouse = (selectedOption) => {
  //   setWarehouseId(selectedOption.value);
  //   setWarehouseName(selectedOption.label);
  // };
  // const getWarehouseList = !_.isEmpty(props.warehouseList.data) && props.warehouseList.data.map((x) => {
  //   let data = { value: x._id, label: x.businessName };
  //   return data;
  // });
  const handleChangeRestaurant = (selectedOption) => {
    setRestaurantId(selectedOption.value);
    setRestaurantName(selectedOption.label);
  };
  const getRetaurantList = !_.isEmpty(props.restaurantDataList) && props.restaurantDataList.map((x) => {
    let data = { value: x._id, label: x.restaurantName };
    return data;
  });


  let categoryTypeData = [{
    id: "Store",
    type: "Store",
  },
  {
    id: "Production",
    type: "Production",
  }];
  const handleChangeCategoryType = (selectedOption) => {
    setCategoryType(selectedOption.label);
  };
  const GetCategoryTypeList = categoryTypeData.map((x) => {
    let data = { value: x.id, label: x.type };
    return data;
  });

  useEffect(() => {
    props.setHeading("New Category");
    props.getRestaurantData();
    // props.getWarehouseApi();
  }, []);

  return (
    <>
      {props.isAddingCategory ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Row>
              <Col>
                <Form.Group className="mb-1 mt-1" >
                  <Form.Label><b>Select Company *</b></Form.Label>
                  <Select
                    value={{ label: restaurantName }}
                    placeholder="Select Company"
                    onChange={handleChangeRestaurant}
                    options={getRetaurantList}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group >
                  <Form.Label><b>Category Name *</b></Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Category Name"
                    onMouseLeave={(e) => setCategoryName(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label><b>Select Category Type </b></Form.Label>
                  <Select
                    placeholder="Select Category Type"
                    onChange={handleChangeCategoryType}
                    options={GetCategoryTypeList}
                  />
                </Form.Group>
              </Col>
              <Col></Col>
            </Row>

            <div className="sendDiv">
              {_.isEmpty(category) || _.isEmpty(categoryType) ? (
                <Button variant="contained" endIcon={<SendIcon />} size="large" disabled>
                  SAVE
                </Button>
              ) : (
                <Button variant="contained" endIcon={<SendIcon />} size="large" onClick={uploadCategory}>
                  SAVE
                </Button>
              )}
            </div>

          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    reset: () => dispatch(resetReducerCategory),
    // getWarehouseApi: () => dispatch(getWarehouseApi()),
    getRestaurantData: () => dispatch(getRestaurantData()),
    addCategoryName: (data, handleVisible, handleDangerVisible) => dispatch(addCategoryName(data, handleVisible, handleDangerVisible)),
    setHeading: (data) => dispatch(setHeading(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  dataAddedCategory: state.CategoryReducer.dataAddedCategory,
  dataNotAddedCategory: state.CategoryReducer.dataNotAddedCategory,
  isAddingCategory: state.CategoryReducer.isAddingCategory,
  // warehouseList: state.WarehouseReducer.warehouseList,
  restaurantDataList: state.ResturantReducer.restaurantDataList,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddCategory);
