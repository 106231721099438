import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import { connect } from "react-redux";
import _ from "lodash";
import { AddCRMCustomerApi } from "./CustomerActionCRM";
import { setHeading } from "../../../accounts/account.actions";
import { GetCRMProductApi } from "../CRMProducts/CRMProductAction";
import Spinner from "react-bootstrap/Spinner";
import Swal from "sweetalert";
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import Select from "react-select";


const AddCustomerCRM = (props) => {
  const [productId, setProductId] = useState("");
  const [productName, setProductName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [ownerName, setOwnerName] = useState();
  const [contactPerson, setContactPerson] = useState();
  const [designation, setDesignation] = useState("");
  const [phoneNumberOne, setPhoneNumberOne] = useState("");
  const [phoneNumberTwo, setPhoneNumberTwo] = useState("");
  const [officeNumber, setOfficeNumber] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [cr, setCR] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [status, setStatus] = useState("");
  const [date, setDate] = useState("");
  const [description, setDescription] = useState("");
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);


  function SaveCRMCustomer() {
    props.AddCRMCustomerApi({
      productId: productId,
      productName: productName,
      companyName: companyName,
      ownerName: ownerName,
      status: status,
      contactPerson: contactPerson,
      designation: designation,
      phoneNumberOne: phoneNumberOne,
      phoneNumberTwo: phoneNumberTwo,
      officeNumber: officeNumber,
      city: city,
      address: address,
      cr: cr,
      email: email,
      website: website,
      date: date,
      description: description,
    },
      handleVisible,
      handleDangerVisible
    );
    setProductId("")
    setCompanyName("")
    setOwnerName("")
    setContactPerson("")
    setDesignation("")
    setPhoneNumberOne("")
    setPhoneNumberTwo("")
    setOfficeNumber("")
    setCity("")
    setAddress("")
    setCR("")
    setEmail("")
    setWebsite("")
    setDate("")
    setStatus("")
    setDescription("")
  }
  const handleVisible = (msg) => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  };
  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };

  const handleChangeProduct = (selectedOption) => {
    setProductId(selectedOption.value);
    setProductName(selectedOption.label);
  };
  const getProductList = !_.isEmpty(props.CRMProductList) && props.CRMProductList.map((x) => {
    let data = { value: x._id, label: x.productName };
    return data;
  });

  let statusData = [{
    id: "Meeting",
    type: "Meeting",
  },
  {
    id: "Positive Response",
    type: "Positive Response",
  },
  {
    id: "Call Again",
    type: "Call Again",
  },
  {
    id: "No Response",
    type: "No Response",
  },
  {
    id: "Dead",
    type: "Dead",
  },]
  const handleChangeStatus = (selectedOption) => {
    setStatus(selectedOption.label);
  };
  const GetStatusList = statusData.map((x) => {
    let data = { value: x.id, label: x.type };
    return data;
  });

  useEffect(() => {
    props.setHeading("Add Customer");
    props.GetCRMProductApi()
  }, []);

  return (
    <>
      {props.isAddingCRMCustomer ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="border"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <Form>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label><b>Select Product *</b></Form.Label>
                  <Select
                    placeholder="Select Product"
                    onChange={handleChangeProduct}
                    options={getProductList}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label><b>Status *</b></Form.Label>
                  <Select
                    placeholder="Status"
                    onChange={handleChangeStatus}
                    options={GetStatusList}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label><b>Company Name *</b></Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Company Name"
                    onChange={(e) => setCompanyName(e.target.value)}
                  />
                </Form.Group>
              </Col>

            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label><b>Owner Name </b></Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Owner Name"
                    onChange={(e) => setOwnerName(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label><b>Contact Person </b></Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Contact Person"
                    onChange={(e) => setContactPerson(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label><b>Phone Number 1 </b></Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Phone Number 1"
                    onChange={(e) => setPhoneNumberOne(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label><b>Phone Number 2 </b></Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Phone Number 2"
                    onChange={(e) => setPhoneNumberTwo(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label><b>Office Number </b></Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Office Number"
                    onChange={(e) => setOfficeNumber(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label><b>City </b></Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="City"
                    onChange={(e) => setCity(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label><b>Address </b></Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Address"
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label><b>CR </b></Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="CR"
                    onChange={(e) => setCR(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label><b>Email </b></Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label><b>Website </b></Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Website"
                    onChange={(e) => setWebsite(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label><b>Designation </b></Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Designation"
                    onChange={(e) => setDesignation(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label><b>Date </b></Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="Date"
                    onChange={(e) => setDate(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label><b>Description </b></Form.Label>
                  <Form.Control
                    type="text"
                    as="textarea"
                    rows={3}
                    placeholder="Description"
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>

            <div className="sendDiv">
              {_.isEmpty(productId) || _.isEmpty(status) || _.isEmpty(companyName) ? (
                <Button variant="contained" endIcon={<SendIcon />} size="large" disabled> SAVE </Button>
              ) : (
                <Button variant="contained" endIcon={<SendIcon />} size="large" onClick={SaveCRMCustomer}> SAVE</Button>
              )}
            </div>

          </Form>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    AddCRMCustomerApi: (data, handleVisible, handleDangerVisible) => dispatch(AddCRMCustomerApi(data, handleVisible, handleDangerVisible)),
    GetCRMProductApi: () => dispatch(GetCRMProductApi()),
    setHeading: (data) => dispatch(setHeading(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  isAddingCRMCustomer: state.CustomerReducerCRM.isAddingCRMCustomer,
  isFetchingCRMProduct: state.CRMProductReducer.isFetchingCRMProduct,
  CRMProductList: state.CRMProductReducer.CRMProductList,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddCustomerCRM);