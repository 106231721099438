import API from "../../../../../global/api";
import jsPDF from "jspdf";
import "jspdf-autotable";
import _ from "lodash";
import logo from "../../../../../Images/logo.png";


export const requestGetMaterialRequired = () => {
    return {
        type: "REQUEST_GET_MATERIAL_REQUIRED",
    };
};
export const successGetMaterialRequired = (data) => {
    return {
        type: "SUCCESS_GET_MATERIAL_REQUIRED",
        payload: data,
    };
};
export const errorGetMaterialRequired = () => {
    return {
        type: "ERROR_GET_MATERIAL_REQUIRED",
    };
};
export const GetMetrialRequiredApi = (orderId) => {
    return (dispatch) => {
        dispatch(requestGetMaterialRequired());
        API.post(`/costControl/material_requirement`, orderId).then((res) => {
            let getData = res.data;
            dispatch(successGetMaterialRequired(getData));
        }).catch((error) => {
            dispatch(errorGetMaterialRequired());
        });
    };
};
export const MaterialCostRequirementPDF = (materialRequiredList) => {
    return (dispatch) => {
        const data = materialRequiredList.data[0]

        const unit = "pt";
        const size = "A4";
        const orientation = "potrait";
        const doc = new jsPDF(orientation, unit, size);
        const title = "Material Requirement";
        const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
        const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
        const companyNtn = `${process.env.REACT_APP_COMPANY_NTN}`;
        const sequenceNumber = "Order No: " + data.sequenceNumber;
        const orderDate = "Order Date: " + data.orderDate;
        const customerName = "Customer Name: " + data.customerDetails.name;
        const brandName = "Brand Name: " + data.brandDetails.brandName;
        const orderDeliveryDate = "Order Delivery Date: " + data.orderDeliveryDate;
        const orderShippingDate = "Order Shipping Date: " + data.orderShippingDate;
        const orderDeadLineDate = "Order DeadLine Date: " + data.orderDeadLineDate;

        const headers = [[
            "Sr",
            "Product Name",
            "Quantity",
            "Purchase Price",
            "Total Cost",
        ]];
        const prodactData = _.isEmpty(materialRequiredList.requiredMaterialList) ? '' : materialRequiredList.requiredMaterialList.map((elt, i) => [
            i + 1,
            elt.productName,
            elt.totalQuantity,
            elt.purchasePrice,
            elt.totalCost,
        ]);
        if (!_.isEmpty(prodactData)) {
            prodactData.push(["", "Total", "", "", Math.round(_.sumBy(prodactData, '4') * 100) / 100])
        }
        let ProductContent = {
            startY: 230,
            startX: 50,
            head: headers,
            styles: { fontSize: 11 },
            body: prodactData,
            theme: "grid",
            headStyles: { fillColor: "#2980ba" },
        };
        doc.autoTable(ProductContent);

        doc.addImage(logo, "PNG", 40, 15, 80, 80);
        doc.text(companyName, 150, 40, 0, 20);
        doc.setFontSize(12);
        doc.setFont("times", "normal");
        doc.text(companyAddress, 150, 58, 0, 20);
        doc.text(companyNtn, 150, 80, 0, 20);
        doc.setFontSize(12);
        doc.text(title, 240, 120);
        doc.text(sequenceNumber, 50, 150, 0, 20);
        doc.text(orderDate, 330, 150, 0, 20);
        doc.text(brandName, 50, 170, 0, 20);
        doc.text(orderDeliveryDate, 330, 170, 0, 20);
        doc.text(orderShippingDate, 50, 190, 0, 20);
        doc.text(orderDeadLineDate, 330, 190, 0, 20);
        doc.text(customerName, 50, 210, 0, 20);


        doc.setProperties({ title: `Material Cost Requirement PDF` });
        var blob = doc.output("blob");
        window.open(URL.createObjectURL(blob));
    };
};


export const requestGetProcessRequired = () => {
    return {
        type: "REQUEST_GET_PROCESS_REQUIRED",
    };
};
export const successGetProcessRequired = (data) => {
    return {
        type: "SUCCESS_GET_PROCESS_REQUIRED",
        payload: data,
    };
};
export const errorGetProcessRequired = () => {
    return {
        type: "ERROR_GET_PROCESS_REQUIRED",
    };
};
export const GetProcessRequiredApi = (orderId) => {
    return (dispatch) => {
        dispatch(requestGetProcessRequired());
        API.post(`/costControl/process_requirement`, orderId).then((res) => {
            let getData = res.data;
            dispatch(successGetProcessRequired(getData));
        }).catch((error) => {
            dispatch(errorGetProcessRequired());
        });
    };
};
export const ProcessCostRequirementPDF = (materialRequiredList) => {
    return (dispatch) => {
        const data = materialRequiredList.data[0]

        const unit = "pt";
        const size = "A4";
        const orientation = "potrait";
        const doc = new jsPDF(orientation, unit, size);
        const title = "Process Requirement";
        const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
        const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
        const companyNtn = `${process.env.REACT_APP_COMPANY_NTN}`;
        const sequenceNumber = "Order No: " + data.sequenceNumber;
        const orderDate = "Order Date: " + data.orderDate;
        const customerName = "Customer Name: " + data.customerDetails.name;
        const brandName = "Brand Name: " + data.brandDetails.brandName;
        const orderDeliveryDate = "Order Delivery Date: " + data.orderDeliveryDate;
        const orderShippingDate = "Order Shipping Date: " + data.orderShippingDate;
        const orderDeadLineDate = "Order DeadLine Date: " + data.orderDeadLineDate;

        const headers = [[
            "Sr",
            "Process Name",
            "Total Process Cost",
        ]];
        const prodactData = _.isEmpty(materialRequiredList.requiredProcessList) ? '' : materialRequiredList.requiredProcessList.map((elt, i) => [
            i + 1,
            elt.processName,
            elt.processRate,
        ]);
        if (!_.isEmpty(prodactData)) {
            prodactData.push(["", "", Math.round(_.sumBy(prodactData, '2') * 100) / 100])
        }
        let ProductContent = {
            startY: 230,
            startX: 50,
            head: headers,
            styles: { fontSize: 11 },
            body: prodactData,
            theme: "grid",
            headStyles: { fillColor: "#2980ba" },
        };
        doc.autoTable(ProductContent);

        doc.addImage(logo, "PNG", 40, 15, 80, 80);
        doc.text(companyName, 150, 40, 0, 20);
        doc.setFontSize(12);
        doc.setFont("times", "normal");
        doc.text(companyAddress, 150, 58, 0, 20);
        doc.text(companyNtn, 150, 80, 0, 20);
        doc.setFontSize(12);
        doc.text(title, 240, 120);
        doc.text(sequenceNumber, 50, 150, 0, 20);
        doc.text(orderDate, 330, 150, 0, 20);
        doc.text(brandName, 50, 170, 0, 20);
        doc.text(orderDeliveryDate, 330, 170, 0, 20);
        doc.text(orderShippingDate, 50, 190, 0, 20);
        doc.text(orderDeadLineDate, 330, 190, 0, 20);
        doc.text(customerName, 50, 210, 0, 20);


        doc.setProperties({ title: `Process Cost Requirement PDF` });
        var blob = doc.output("blob");
        window.open(URL.createObjectURL(blob));
    };
};


export const requestGetAdminCostRequired = () => {
    return {
        type: "REQUEST_GET_ADMINCOST_REQUIRED",
    };
};
export const successGetAdminCostRequired = (data) => {
    return {
        type: "SUCCESS_GET_ADMINCOST_REQUIRED",
        payload: data,
    };
};
export const errorGetAdminCostRequired = () => {
    return {
        type: "ERROR_GET_ADMINCOST_REQUIRED",
    };
};
export const GetAdminCostRequiredApi = (orderId) => {
    return (dispatch) => {
        dispatch(requestGetAdminCostRequired());
        API.post(`/costControl/admincost_requirement`, orderId).then((res) => {
            let getData = res.data;
            dispatch(successGetAdminCostRequired(getData));
        }).catch((error) => {
            dispatch(errorGetAdminCostRequired());
        });
    };
};
export const AdminCostRequirementPDF = (materialRequiredList) => {
    return (dispatch) => {
        const data = materialRequiredList.data[0]

        const unit = "pt";
        const size = "A4";
        const orientation = "potrait";
        const doc = new jsPDF(orientation, unit, size);
        const title = "Admin Cost Requirement";
        const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
        const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
        const companyNtn = `${process.env.REACT_APP_COMPANY_NTN}`;
        const sequenceNumber = "Order No: " + data.sequenceNumber;
        const orderDate = "Order Date: " + data.orderDate;
        const customerName = "Customer Name: " + data.customerDetails.name;
        const brandName = "Brand Name: " + data.brandDetails.brandName;
        const orderDeliveryDate = "Order Delivery Date: " + data.orderDeliveryDate;
        const orderShippingDate = "Order Shipping Date: " + data.orderShippingDate;
        const orderDeadLineDate = "Order DeadLine Date: " + data.orderDeadLineDate;

        const headers = [[
            "Sr",
            "Name",
            "Quantity",
            "Price",
            "Total Admin Cost",
        ]];
        const prodactData = _.isEmpty(materialRequiredList.requiredAdminCostList) ? '' : materialRequiredList.requiredAdminCostList.map((elt, i) => [
            i + 1,
            elt.description,
            elt.quantity,
            elt.price,
            elt.totalPrice,
        ]);
        if (!_.isEmpty(prodactData)) {
            prodactData.push(["", "", "", "", Math.round(_.sumBy(prodactData, '4') * 100) / 100])
        }
        let ProductContent = {
            startY: 230,
            startX: 50,
            head: headers,
            styles: { fontSize: 11 },
            body: prodactData,
            theme: "grid",
            headStyles: { fillColor: "#2980ba" },
        };
        doc.autoTable(ProductContent);

        doc.addImage(logo, "PNG", 40, 15, 80, 80);
        doc.text(companyName, 150, 40, 0, 20);
        doc.setFontSize(12);
        doc.setFont("times", "normal");
        doc.text(companyAddress, 150, 58, 0, 20);
        doc.text(companyNtn, 150, 80, 0, 20);
        doc.setFontSize(12);
        doc.text(title, 240, 120);
        doc.text(sequenceNumber, 50, 150, 0, 20);
        doc.text(orderDate, 330, 150, 0, 20);
        doc.text(brandName, 50, 170, 0, 20);
        doc.text(orderDeliveryDate, 330, 170, 0, 20);
        doc.text(orderShippingDate, 50, 190, 0, 20);
        doc.text(orderDeadLineDate, 330, 190, 0, 20);
        doc.text(customerName, 50, 210, 0, 20);


        doc.setProperties({ title: `Admin Cost Requirement PDF` });
        var blob = doc.output("blob");
        window.open(URL.createObjectURL(blob));
    };
};