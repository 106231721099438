const initialState = {
    productionEmployeeList: [],
    isFetchingProductionEmployee: false,
    isAddingProductionEmployee: false,
};

const ProductionEmployeeReducer = (state = initialState, action) => {
    switch (action.type) {
        case "REQUEST_ADD_PRODUCTION_EMPLOYEE":
            return { ...state, isAddingProductionEmployee: true };
        case "SUCCESS_ADD_PRODUCTION_EMPLOYEE":
            return { ...state, productionEmployeeList: action.payload.data, isAddingProductionEmployee: false };
        case "ERROR_ADD_PRODUCTION_EMPLOYEE":
            return { ...state };


        case "REQUEST_GET_PRODUCTION_EMPLOYEE":
            return { ...state, isFetchingProductionEmployee: true };
        case "SUCCESS_GET_PRODUCTION_EMPLOYEE":
            return { ...state, productionEmployeeList: action.payload, isFetchingProductionEmployee: false };
        case "ERROR_GET_PRODUCTION_EMPLOYEE":
            return { ...state, isFetchingProductionEmployee: false };


        default:
            return state;
    }
};
export default ProductionEmployeeReducer;