import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { AddProcessApi } from "./ProcessAction";
import { setHeading } from "../../../accounts/account.actions";
// import { getVendorData } from "../../AddDetails/Vendor/Vendor.action";
// import { GetProductionEmployeeApi } from "../productionEmployee/ProductionEmployeeAction";
import _ from "lodash";
import Spinner from "react-bootstrap/Spinner";
import Swal from "sweetalert";
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import Select from "react-select";


const AddProcess = (props) => {
    const [vendorId, setVendorId] = useState();
    const [vendorName, setVendorName] = useState();
    const [employeeId, setEmployeeId] = useState();
    const [employeeName, setEmployeeName] = useState();
    const [processName, setProcessName] = useState();
    const [description, setDescription] = useState();
    const [rate, setRate] = useState();
    const [processType, setProcessType] = useState();
    const [visibleAlert, setAlertVisible] = useState(false);
    const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);


    function uploadProcess() {
        props.AddProcessApi({
            // vendorId: vendorId,
            // vendorName: vendorName,
            // employeeId: employeeId,
            // employeeName: employeeName,
            processName: processName,
            description: description,
            // rate: rate,
            processType: processType,
        },
            handleVisible,
            handleDangerVisible
        );
        // setVendorId("")
        // setEmployeeId("")
        setProcessName("")
        setDescription("")
        setRate("")
        setProcessType("")
    }
    const handleVisible = (msg) => {
        setAlertVisible(true);
        setTimeout(() => {
            setAlertVisible(false);
        }, 9000);
        Swal({
            title: "Success",
            text: msg,
            icon: "success",
            button: "Ok",
        });
    };
    const handleDangerVisible = (err) => {
        setDangerVisibleAlert(true);
        setTimeout(() => {
            setDangerVisibleAlert(false);
        }, 9000);
        Swal({
            title: "Error",
            text: err,
            icon: "error",
            button: "Ok",
        });
    };


    let processTypeData = [{
        id: "Standrad Process",
        type: "Standrad Process",
    },
    {
        id: "Specific Article Process",
        type: "Specific Article Process",
    }];
    const handleChangeProcessType = (selectedOption) => {
        setProcessType(selectedOption.label);
    };
    const GetProcessTypeList = processTypeData.map((x) => {
        let data = { value: x.id, label: x.type };
        return data;
    });

    const handleChangeVendor = (selectedOption) => {
        setVendorId(selectedOption.value);
        setVendorName(selectedOption.label);
    };
    const GetVendorList = !_.isEmpty(props.vendorDataList) && props.vendorDataList.map((x) => {
        let data = { value: x._id, label: x.vendorName };
        return data;
    });

    const handleChangeEmployee = (selectedOption) => {
        setEmployeeId(selectedOption.value);
        setEmployeeName(selectedOption.label);
    };
    const GetEmployeeList = !_.isEmpty(props.productionEmployeeList) && props.productionEmployeeList.map((x) => {
        let data = { value: x._id, label: x.employeeName };
        return data;
    });

    useEffect(() => {
        props.setHeading("Add Process");
        // props.getVendorData()
        // props.GetProductionEmployeeApi()
    }, []);

    return (
        <>
            {props.isAddingProcess || props.isFetchingVendor ? (
                <div className="loader-div">
                    <Spinner
                        variant="primary"
                        animation="border"
                        size="xl"
                        role="status"
                        aria-hidden="true"
                    />
                </div>
            ) : (
                <Container fluid>
                    <div className="main">
                        <Form>
                            {/* <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="garana">
                                        <Form.Label><b>Select Vendor *</b></Form.Label>
                                        <Select
                                            isDisabled={_.isEmpty(GetVendorList)}
                                            placeholder="Select Vendor"
                                            onChange={handleChangeVendor}
                                            options={GetVendorList}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="garana">
                                        <Form.Label><b>Select Employee *</b></Form.Label>
                                        <Select
                                            isDisabled={_.isEmpty(GetEmployeeList)}
                                            placeholder="Select Employee"
                                            onChange={handleChangeEmployee}
                                            options={GetEmployeeList}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row> */}
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="garana">
                                        <Form.Label><b>Process Name *</b></Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Process Name"
                                            onChange={(e) => setProcessName(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                                {/* <Col>
                                    <Form.Group className="mb-3" controlId="garana">
                                        <Form.Label><b>Rate *</b></Form.Label>
                                        <Form.Control
                                            type="number"
                                            placeholder="Rate"
                                            onChange={(e) => setRate(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col> */}
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="garana">
                                        <Form.Label><b>Description</b></Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={description}
                                            placeholder="Description"
                                            onChange={(e) => setDescription(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="garana">
                                        <Form.Label><b>Select Process Type </b></Form.Label>
                                        <Select
                                            placeholder="Select Process Type"
                                            onChange={handleChangeProcessType}
                                            options={GetProcessTypeList}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <div className="sendDiv">
                                {_.isEmpty(processName) ? (
                                    <Button variant="contained" endIcon={<SendIcon />} size="large" disabled> SAVE </Button>
                                ) : (
                                    <Button variant="contained" endIcon={<SendIcon />} size="large" onClick={uploadProcess}> SAVE </Button>
                                )}
                            </div>

                        </Form>
                    </div>
                </Container>
            )}
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        AddProcessApi: (data, handleVisible, handleDangerVisible) => dispatch(AddProcessApi(data, handleVisible, handleDangerVisible)),
        setHeading: (data) => dispatch(setHeading(data)),
        // getVendorData: () => dispatch(getVendorData()),
        // GetProductionEmployeeApi: () => dispatch(GetProductionEmployeeApi()),
    };
};
const mapStateToProps = (state, ownProps) => ({
    isAddingProcess: state.ProcessReducer.isAddingProcess,
    // vendorDataList: state.VendorReducer.vendorDataList,
    // isFetchingVendor: state.VendorReducer.isFetchingVendor,
    // productionEmployeeList: state.ProductionEmployeeReducer.productionEmployeeList,
    // isFetchingProductionEmployee: state.ProductionEmployeeReducer.isFetchingProductionEmployee,
});


export default connect(mapStateToProps, mapDispatchToProps)(AddProcess);