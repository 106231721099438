import React, { useEffect, useState } from "react";
import { Navbar, Container, } from "react-bootstrap";
import "../forms/AddDetails/add-details.css";
import { connect } from "react-redux";
import _ from "lodash";
import { Link } from "react-router-dom";
import { setHeading, setModuleKey } from "./account.actions";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import IconMenu from "../drawer/IconMenu";
import CardMedia from "@mui/material/CardMedia";
import InventoryPic from "../../../../src/Images/Inventory.jpg";
// import backupLogo from "../../../../src/Images/backupLogo.jpg";
import AccountsPic from "../../../../src/Images/Accounts.jpg";
// import TablePic from "../../../../src/Images/Table Management.jpg";
import OrderPic from "../../../../src/Images/view order.jpg";
// import kdsPic from "../../../../src/Images/kds.jpg";
import PosPic from "../../../../src/Images/posLogo.jpg"
import MasterControlPic from "../../../Images/Master Control.jpg"
import ReportPic from "../../../../src/Images/Reports.jpg"
import SettingPic from "../../../../src/Images/settings.jpg";
import productionImage from "../../../../src/Images/production.jpg";
import HRImage from "../../../../src/Images/HR.jpg";
import CRMImage from "../../../../src/Images/crm.png";
// import NoImage from "../../../../src/Images/NoImage.png";


const UserMainPage = (props) => {
  const permissions = JSON.parse(window.localStorage.getItem("permissions"))

  useEffect(() => { }, []);

  return (
    <>
      <div >
        <Navbar style={{ backgroundColor: '#81abd3' }} >
          <Container>
            <Navbar.Brand href="" style={{ color: 'white' }}><b>Home Page</b></Navbar.Brand>
            <IconMenu />
          </Container>
        </Navbar>
      </div>
      {/* <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Home Page
            </Typography>
          
            <IconMenu/>
          </Toolbar>
        </AppBar>
      </Box> */}
      <div style={{ width: "100%", }}>
        <Box sx={{ flexGrow: 1, mt: 30, mx: "auto", ml: 30 }}>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 5, sm: 6, md: 6 }}
          >

            {permissions.some(p => p['permissionsName'] === "Store") &&
              <Link to="/store">
                <Card
                  sx={{ maxWidth: 250, maxHeight: 250, mr: 5, mb: 5 }}
                  onClick={() => {
                    props.setModuleKey("storeList");
                    window.localStorage.setItem("module_key", "storeList");
                    props.setHeading("Store")
                  }}
                >
                  <CardMedia
                    component="img"
                    alt="UserInventory"
                    height="180"
                    width="180"
                    image={InventoryPic}
                  />
                </Card>
              </Link>
            }

            {permissions.some(p => p['permissionsName'] === "POS") &&
              <Link to="/pos">
                {/* <LightTooltip title="POS"> */}
                <Card
                  sx={{ maxWidth: 250, maxHeight: 250, mr: 5, mb: 5 }}
                  onClick={() => {
                    props.setModuleKey("UserPOS");
                    window.localStorage.setItem("module_key", "pos");
                  }}
                >
                  <CardMedia
                    component="img"
                    alt="UserAccounts"
                    height="180"
                    width="180"
                    image={PosPic}
                  />
                </Card>
                {/* </LightTooltip> */}
              </Link>}

            {permissions.some(p => p['permissionsName'] === "Order View") &&
              <Link to="/order">
                {/* <LightTooltip title="View Order"> */}
                <Card
                  sx={{ maxWidth: 250, maxHeight: 250, mr: 5, mb: 5 }}
                  onClick={() => {
                    props.setModuleKey("Userorder");
                    window.localStorage.setItem("module_key", "order");
                  }}
                >
                  <CardMedia
                    component="img"
                    alt="UserAccounts"
                    height="180"
                    width="180"
                    image={OrderPic}
                  />
                </Card>
                {/* </LightTooltip> */}
              </Link>}

            {permissions.some(p => p['permissionsName'] === "Accounts") && <Link to="/accounts">
              {/* <LightTooltip title="Accounts"> */}
              <Card
                sx={{ maxWidth: 250, maxHeight: 250, mr: 5, mb: 5 }}
                onClick={() => {
                  props.setModuleKey("UserAccounts");
                  window.localStorage.setItem("module_key", "user_accounts");
                  props.setHeading("Accounts")
                }}
              >
                <CardMedia
                  component="img"
                  alt="UserAccounts"
                  height="180"
                  width="180"
                  image={AccountsPic}
                />
              </Card>
              {/* </LightTooltip> */}
            </Link>}

            {permissions.some(p => p['permissionsName'] === "Reports") &&
              <Link to="/report">
                {/* <LightTooltip title="Report"> */}
                <Card
                  sx={{ maxWidth: 250, maxHeight: 250, mr: 5, mb: 5 }}
                  onClick={() => {
                    props.setModuleKey("UserReport");
                    window.localStorage.setItem("module_key", "Report");
                  }}
                >
                  <CardMedia
                    component="img"
                    alt="UserAccounts"
                    height="180"
                    width="180"
                    image={ReportPic}
                  />
                </Card>
                {/* </LightTooltip> */}
              </Link>}

            {permissions.some(p => p['permissionsName'] === "Master Control") &&
              <Link to="/inventory">
                {/* <LightTooltip title="Inventory"> */}
                <Card
                  sx={{ maxWidth: 250, maxHeight: 250, mr: 5, mb: 5 }}
                  onClick={() => {
                    props.setModuleKey("UserInventory");
                    window.localStorage.setItem("module_key", "user_inventory");
                    props.setHeading("Master Control")
                  }}
                >
                  <CardMedia
                    component="img"
                    alt="UserInventory"
                    height="180"
                    width="180"
                    image={MasterControlPic}
                  />
                </Card>
                {/* </LightTooltip> */}
              </Link>}

            {permissions.some(p => p['permissionsName'] === "Setting") &&
              <Link to="/setting">
                {/* <LightTooltip title="Setting"> */}
                <Card
                  sx={{ maxWidth: 250, maxHeight: 250, mr: 5, mb: 5 }}
                  onClick={() => {
                    props.setModuleKey("setting");
                    window.localStorage.setItem(
                      "module_key",
                      "setting"
                    );
                    props.setHeading("Setting")
                  }}
                >
                  <CardMedia
                    component="img"
                    alt="Setting"
                    height="180"
                    width="180"
                    image={SettingPic}
                  />
                </Card>
                {/* </LightTooltip> */}
              </Link>}

            {permissions.some(p => p['permissionsName'] === "Production") &&
              <Link to="/production">
                <Card
                  sx={{ maxWidth: 250, maxHeight: 250, mr: 5 }}
                  onClick={() => {
                    props.setModuleKey("production");
                    window.localStorage.setItem("module_key", "production");
                    props.setHeading("Production")
                  }}
                >
                  <CardMedia
                    component="img"
                    alt="production"
                    height="180"
                    width="180"
                    image={productionImage}
                  />
                </Card>
              </Link>}

            {/* {permissions.some(p => p['permissionsName'] === "Human Resources") &&
              <Link to="/hr">
                <Card
                  sx={{ maxWidth: 250, maxHeight: 250, mr: 5 }}
                  onClick={() => {
                    props.setModuleKey("hr");
                    window.localStorage.setItem("module_key", "hr");
                    props.setHeading("HUMAN RESOURCES")
                  }}
                >
                  <CardMedia
                    component="img"
                    alt="HR"
                    height="180"
                    width="180"
                    image={HRImage}
                  />
                </Card>
              </Link>} */}

            {/* {permissions.some(p => p['permissionsName'] === "Kitchen Display System (KDS)") &&
              <Link to="/kds">
                <Card
                  sx={{ maxWidth: 250, maxHeight: 250, mr: 5, mb: 5 }}
                  onClick={() => {
                    props.setModuleKey("UserKDS");
                    window.localStorage.setItem("module_key", "kds");
                  }}
                >
                  <CardMedia
                    component="img"
                    alt="UserAccounts"
                    height="180"
                    width="180"
                    image={kdsPic}
                  />
                </Card>
              </Link>} */}

            {/* {permissions.some(p => p['permissionsName'] === "Table Order") &&
              <Link to="/table">
                <Card
                  sx={{ maxWidth: 250, maxHeight: 250, mr: 5, mb: 5 }}
                  onClick={() => {
                    props.setModuleKey("UserTable");
                    window.localStorage.setItem("module_key", "Table");
                  }}
                >
                  <CardMedia
                    component="img"
                    alt="UserAccounts"
                    height="180"
                    width="180"
                    image={TablePic}
                  />
                </Card>
              </Link>} */}

            {permissions.some(p => p['permissionsName'] === "Production") &&
              <Link to="/crm">
                <Card
                  sx={{ maxWidth: 250, maxHeight: 250, mr: 5 }}
                  onClick={() => {
                    props.setModuleKey("crm");
                    window.localStorage.setItem("module_key", "crm");
                    props.setHeading("CRM")
                  }}
                >
                  <CardMedia
                    component="img"
                    alt="CRMImage"
                    height="180"
                    width="180"
                    image={CRMImage}
                  />
                </Card>
              </Link>}


          </Grid>
        </Box>
      </div>

    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    setModuleKey: (data) => dispatch(setModuleKey(data)),
    setHeading: (data) => dispatch(setHeading(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(UserMainPage);
