import React, { useState, useEffect } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { } from "./salaryAction";
import Spinner from "react-bootstrap/Spinner";
import Select from "react-select";
import { Link } from "react-router-dom";
import { GetSalaryWithEmpIDApi } from "./salaryAction";
import { GetEmployeeApi } from "../employee/employeeAction";
import { appendNonSignificantZeros } from "../../../../../global/globalFunctions";
import { setHeading } from "../../../accounts/account.actions";
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';



const SalaryEditByEmpId = (props) => {
    const [employeeId, setEmployeeId] = useState("");
    const [employeeName, setEmployeeName] = useState();
    const [employeeDataList, setEmployeeDataList] = React.useState("");


    const getEmployeeList = !isEmpty(props.employeeList) && props.employeeList.map((x) => {
        let data = {
            value: x._id,
            label: `${appendNonSignificantZeros(x.employeeName)}-${x.fatherName}-${x.cnic}`,
        };
        return data;
    });
    const handleChangeEmployee = (selectedOption) => {
        setEmployeeId(selectedOption.value);
        setEmployeeName(selectedOption.label);
        props.GetSalaryWithEmpIDApi(selectedOption.value);
    };

    useEffect(() => {
        props.GetEmployeeApi();
        props.setHeading("Salary Edit")
    }, []);

    return (
        <>
            {props.isFetchingEmployeeData ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />
                    </Button>
                </div>
            ) : (
                <Container fluid>
                    <div className="main">
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label><b>Select Employee *</b></Form.Label>
                                    <Select
                                        //value={name}
                                        placeholder="Select Employee"
                                        onChange={handleChangeEmployee}
                                        options={getEmployeeList}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>

                    <div className="sendDiv">
                        {employeeId === "" ? (
                            <Button variant="contained" endIcon={<SendIcon />} size="large" disabled> EDIT SALARY </Button>
                        ) : (
                            <Link to={{ pathname: "/hr/salary/EditSalary" }}>
                                <Button variant="contained" endIcon={<SendIcon />} size="large"> EDIT SALARY  </Button>
                            </Link>
                        )}
                    </div>

                </Container>
            )}
        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
        GetEmployeeApi: () => dispatch(GetEmployeeApi()),
        GetSalaryWithEmpIDApi: (id) => dispatch(GetSalaryWithEmpIDApi(id)),
        setHeading: (data) => dispatch(setHeading(data)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    employeeList: state.EmployeeReducer.employeeList,
    isFetchingEmployeeData: state.EmployeeReducer.isFetchingEmployeeData
});

export default connect(mapStateToProps, mapDispatchToProps)(SalaryEditByEmpId);
