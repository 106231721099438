import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { appendNonSignificantZeros } from "../../../../../global/globalFunctions";
import { setHeading } from "../../../accounts/account.actions";
import { AddSalaryApi } from "./salaryAction";
import { GetActiveEmployeeApi } from "../employee/employeeAction";
import { GetshiftControlApi } from "../shiftControl/shiftControlAction";
import _ from "lodash";
import Spinner from 'react-bootstrap/Spinner';
import Select from 'react-select';
import Swal from 'sweetalert';
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';



const AddSalary = (props) => {
    const [employeeId, setEmployeeId] = useState('');
    const [machineId, setMachineId] = useState('');
    const [basicSalary, setBasicSalary] = useState(0);
    const [medicalAllowance, setMedicalAllowance] = useState(0);
    const [petrolAllowance, setPetrolAllowance] = useState(0);
    const [specialAllowance, setSpecialAllowance] = useState(0);
    const [otherAllowance, setOtherAllowance] = useState(0);
    const [accomodationAllowance, setAccomodationAllowance] = useState(0);
    const [bonus, setBonus] = useState('');
    const [overTime, setOverTime] = useState('');
    const [overTimeRate, setOverTimeRate] = useState('');
    const [salaryTransfer, setSalaryTranfer] = useState('');
    const [bankAccount, setBankAccount] = useState();
    const [lateTimeDeduct_1, setLateTimeDeduct_1] = useState();
    const [lateTimeDeduct_2, setLateTimeDeduct_2] = useState();
    const [exemptionAttendance, setExemptionAttendance] = useState();
    const [bankName, setBankName] = useState();
    const [leaveAllowed, setLeaveAllowed] = useState();
    const [activeStatus, setActiveStatus] = useState();
    // const [temporaryBlockSalary, setTemporaryBlockSalary] = useState();
    // const [reasonForBlockSalary, setReasonForBlockSalary] = useState();
    const [salaryCreationBasis, setSalaryCreationBasis] = useState();
    const [salaryCreation, setSalaryCreation] = useState();
    const [shiftSelection, setShiftSelection] = useState(null);
    const [visibleAlert, setAlertVisible] = useState(false);
    const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);


    const saveSalary = () => {
        props.AddSalaryApi({
            employeeId: employeeId,
            machineId: Number(machineId),
            basicSalary: Number(basicSalary),
            medicialAllowance: Number(medicalAllowance),
            accomodationAllowance: Number(accomodationAllowance),
            petrolAllowance: Number(petrolAllowance),
            otherAllowance: Number(otherAllowance),
            specialAllowance: Number(specialAllowance),
            totalAllowances: (Number(medicalAllowance) + Number(petrolAllowance) + Number(specialAllowance) + Number(accomodationAllowance) + Number(otherAllowance)),
            bonus: Number(bonus),
            totalSalary: (Number(medicalAllowance) + Number(petrolAllowance) + Number(specialAllowance) + Number(accomodationAllowance) + Number(otherAllowance) + Number(basicSalary)),
            overTime: Number(overTime),
            overTimeRate: overTimeRate,
            lateTimeDeduct: Number(lateTimeDeduct_1),
            lateTimeDeductPercentage: lateTimeDeduct_2,
            exemptionAttendance: exemptionAttendance,
            bankName: bankName,
            leaveAllowed: leaveAllowed,
            // active_status: activeStatus,
            salaryTransfer: salaryTransfer,
            bankAccount: bankAccount,
            salaryCreationBased: salaryCreationBasis,
            salaryCreation: salaryCreation,
            shiftId: shiftSelection,
        }, employeeId, handleVisible, handleDangerVisible);
    };
    const clearStates = () => {
        setEmployeeId('');
        setMachineId('');
        setBasicSalary(0);
        setMedicalAllowance(0);
        setPetrolAllowance(0);
        setSpecialAllowance(0);
        setOtherAllowance(0);
        setAccomodationAllowance(0);
        setBonus('');
        setOverTime('');
        setOverTimeRate('');
        setSalaryTranfer('');
        setBankAccount();
        setLateTimeDeduct_1();
        setLateTimeDeduct_2();
        setExemptionAttendance();
        setBankName();
        setLeaveAllowed();
        setActiveStatus();
        setSalaryCreationBasis();
        setSalaryCreation();
        setShiftSelection(null);
    }
    const handleVisible = (message) => {
        setAlertVisible(true)
        clearStates();
        setTimeout(() => {
            setAlertVisible(false)
        }, 9000);
        Swal({
            title: "Success",
            text: message,
            icon: "success",
            button: "Ok",
        });
    }
    const handleDangerVisible = (error) => {
        setDangerVisibleAlert(true)
        setTimeout(() => {
            setDangerVisibleAlert(false)
        }, 9000);
        Swal({
            title: "Error",
            text: error,
            icon: "error",
            button: "Ok",
        });
    }

    const getOverTimeList = [
        { value: 1, label: "Allowed" },
        { value: 0, label: "Not Allowed" },
    ];
    const handleOverTime = (selectedOption) => {
        setOverTime(selectedOption.value);
        selectedOption.value === 0 && setOverTimeRate(0);
    };

    const getSalaryTransferList = [
        { value: "Cash", label: "Cash" },
        { value: "Bank", label: "Bank" },
    ];
    const handleChangeSalaryTransfer = (selectedOption) => {
        setSalaryTranfer(selectedOption.value);
        selectedOption.value === 'Cash' && setBankName();
        selectedOption.value === 'Cash' && setBankAccount();
    };

    const getBonusList = [
        { value: 1, label: "Yes" },
        { value: 0, label: "No" },
    ];
    const handleChangeBonus = (selectedOption) => {
        setBonus(selectedOption.value);
    };

    const getExemptionAttendanceList = [
        { value: 1, label: "Allowed" },
        { value: 0, label: "Not Allowed" },
    ];
    const handleChangeexemptionAttendance = (selectedOption) => {
        setExemptionAttendance(selectedOption.value);
    };

    const getSalaryCreationList = [
        { value: 1, label: "Allowed" },
        { value: 0, label: "Not Allowed" },
    ];
    const handleSalaryCreation = (selectedOption) => {
        setSalaryCreation(selectedOption.value);
    };

    const getDeductionList = [
        { value: 1, label: "Deduct" },
        { value: 0, label: "Do not Deduct" }
    ];
    const handleLateDeduction = (selectedOption) => {
        setLateTimeDeduct_1(selectedOption.value);
        selectedOption.value === 0 && setLateTimeDeduct_2(0);
    };

    const getLeaveAllowedList = [
        { value: 1, label: "Yes" },
        { value: 0, label: "No" },
    ];

    const handleLeaveAllowed = (selectedOption) => {
        setLeaveAllowed(selectedOption.value);
    };

    const getSalaryCreationBasisList = [
        { value: "Shift", label: "Shift" },
        { value: "Master", label: "Master" },
    ];
    const handleSalaryCreationBasis = (selectedOption) => {
        setSalaryCreationBasis(selectedOption.value);
    };

    const getSelectableShifts = !_.isEmpty(props.shiftControl) && props.shiftControl.map((x) => {
        let data = { value: x._id, label: x.shiftName };
        return data;
    });
    const handleShiftSelection = (selectedOption) => {
        setShiftSelection(selectedOption.value);
    };

    const getEmployeeList = !_.isEmpty(props.activeEmployeeList) && props.activeEmployeeList.map((x) => {
        let data = { value: x._id, label: `${appendNonSignificantZeros(x.employeeName)}-${x.fatherName}-${x.cnic}` };
        return data
    })
    const handleChangeEmployee = (selectedOption) => {
        setEmployeeId(selectedOption.value);
    };

    // const getActiveStatusList = [
    //     { value: true, label: "Active" },
    //     { value: false, label: "Non Active" },
    // ];
    // const handleChangeActiveStatus = (selectedOption) => {
    //     setActiveStatus(selectedOption.value);
    // };
    // const getLateTimeList = [
    //     { value: 1, label: "Deduct" },
    //     { value: 0, label: "Not Deduct" },
    // ];
    // const handleChangeLateTime = (selectedOption) => {
    //     setLateTime(selectedOption.value);
    // };

    useEffect(() => {
        props.GetActiveEmployeeApi();
        props.GetshiftControlApi();
        props.setHeading("Salary Add")
    }, []);

    return (
        <>
            <Container fluid>
                {(props.isFetchingActiveEmployeeData) || (props.isAddingSalary) ?
                    <div className="loader-div">
                        <Button variant="info" disabled>
                            <Spinner
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                variant="info"
                            />
                            {props.isAddingSalary ? 'Adding Salary' : 'Fetching Employee'}
                        </Button>
                    </div> :
                    <Container fluid>
                        <div className="mainHr">
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Select Employee *</b></Form.Label>
                                        <Select
                                            isDisabled={_.isEmpty(getEmployeeList)}
                                            placeholder="Select Employee"
                                            onChange={handleChangeEmployee}
                                            options={getEmployeeList}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Machine ID *</b></Form.Label>
                                        <Form.Control type="number" id="machineId" placeholder="Machine Id" onBlur={(e) => setMachineId(e.target.value)} />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Basic Salary *</b></Form.Label>
                                        <Form.Control type="number" id="basicSalary" placeholder="Basic Salary" onBlur={(e) => setBasicSalary(e.target.value)} />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Medical Allowance *</b></Form.Label>
                                        <Form.Control type="number" id="medicalAllowance" placeholder="Medical Allowance" onBlur={(e) => setMedicalAllowance(e.target.value)} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Petrol Allowance *</b></Form.Label>
                                        <Form.Control type="number" id="petrolAllowance" placeholder="Petrol Allowance" onBlur={(e) => setPetrolAllowance(e.target.value)} />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Other Allowance *</b></Form.Label>
                                        <Form.Control type="number" id="Other Allowance" placeholder="Other Allowance" onBlur={(e) => setOtherAllowance(e.target.value)} />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Accomodation Allowance *</b></Form.Label>
                                        <Form.Control type="number" id="accomodationAllowance" placeholder="Accomodation Allowance" onBlur={(e) => setAccomodationAllowance(e.target.value)} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Special Allowance *</b></Form.Label>
                                        <Form.Control type="number" id="special Allowance" placeholder="Special Allowance...." onBlur={(e) => setSpecialAllowance(e.target.value)} />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Total Allowance</b></Form.Label>
                                        <Form.Control id="totalAllowance" placeholder="Total Allowance" value={Number(medicalAllowance) + Number(petrolAllowance) + Number(specialAllowance) + Number(accomodationAllowance) + Number(otherAllowance)} disabled />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Total Salary</b></Form.Label>
                                        <Form.Control id="totalSalary" placeholder="Total Salary" value={Number(medicalAllowance) + Number(petrolAllowance) + Number(specialAllowance) + Number(accomodationAllowance) + Number(otherAllowance) + Number(basicSalary)} disabled />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Bonus *</b></Form.Label>
                                        <Select
                                            // isDisabled={_.isEmpty(getDepartmentNameList)}
                                            placeholder="Select Bonus"
                                            onChange={handleChangeBonus}
                                            options={getBonusList}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Over Time *</b></Form.Label>
                                        <Select
                                            // isDisabled={_.isEmpty(getDepartmentNameList)}
                                            placeholder="Select Over Time"
                                            onChange={handleOverTime}
                                            options={getOverTimeList}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Over Time Rate *</b></Form.Label>
                                        <Form.Control type="number" id="overTimeRate" placeholder="Over Time Rate (%)...." onBlur={(e) => setOverTimeRate(e.target.value)} disabled={overTime === 0} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Late Time Deduction *</b></Form.Label>
                                        <Select
                                            defaultValue={lateTimeDeduct_1}
                                            placeholder='Late Time Deduction'
                                            options={getDeductionList}
                                            onChange={handleLateDeduction}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Late Time Deduction Rate *</b></Form.Label>
                                        <Form.Control type="text" id="lateTimeDeduct_2" placeholder="Late Time Deduction Rate" onBlur={(e) => setLateTimeDeduct_2(e.target.value)} disabled={lateTimeDeduct_1 === 0} />
                                    </Form.Group>
                                </Col>
                                {/* <Col lg="4" md="4" xs="12">
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Late Time:</b></Form.Label>
                                        <Select
                                            //isDisabled={_.isEmpty(getDepartmentNameList)}
                                            placeholder="Select Late Time..."
                                            onChange={handleChangeLateTime}
                                            options={getLateTimeList}
                                        />
                                    </Form.Group>
                                </Col> 
                                <Col lg="4" md="4" xs="12">
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Arrival Time:</b></Form.Label>
                                        <Form.Control type="time" id="arrivalTime" placeholder="Arrival Time...." onBlur={(e) => setArrivalTime(e.target.value)} />
                                    </Form.Group>
                                </Col> */}

                                <Col>
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Exemption Attendance *</b></Form.Label>
                                        <Select
                                            placeholder="Exemption Attendance"
                                            onChange={handleChangeexemptionAttendance}
                                            options={getExemptionAttendanceList}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Leave Allowed *</b></Form.Label>
                                        <Select
                                            placeholder="Select Leave Allowed"
                                            defaultValue={leaveAllowed}
                                            onChange={handleLeaveAllowed}
                                            options={getLeaveAllowedList}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Salary Transfer *</b></Form.Label>
                                        <Select
                                            // isDisabled={_.isEmpty(getDepartmentNameList)}
                                            placeholder="Salary Transfer"
                                            onChange={handleChangeSalaryTransfer}
                                            options={getSalaryTransferList}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Bank Name *</b></Form.Label>
                                        <Form.Control type="text" id="bankName" placeholder="Bank Name" onBlur={(e) => setBankName((e.target.value).toUpperCase())} disabled={(salaryTransfer === 'Cash')} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Bank Account *</b></Form.Label>
                                        <Form.Control type="text" id="bankAccount" placeholder="Banking Account" onBlur={(e) => setBankAccount(e.target.value)} disabled={(salaryTransfer === 'Cash')} />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Salary Creation *</b></Form.Label>
                                        <Select
                                            // isDisabled={_.isEmpty(getDepartmentNameList)}
                                            placeholder="Salary Creation"
                                            onChange={handleSalaryCreation}
                                            options={getSalaryCreationList}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="garana" >
                                        <Form.Label><b>Salary Creation Basis *</b></Form.Label>
                                        <Select
                                            // isDisabled={_.isEmpty(getDepartmentNameList)}
                                            placeholder="Salary Creation Basis"
                                            onChange={handleSalaryCreationBasis}
                                            options={getSalaryCreationBasisList}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                {(salaryCreationBasis === "Shift") &&
                                    <Col lg="4" md="4" xs="12">
                                        <Form.Group className="mb-3" controlId="garana" >
                                            <Form.Label><b>Select Shift *</b></Form.Label>
                                            <Select
                                                // isDisabled={_.isEmpty(getSelectableShifts)}
                                                placeholder="Select Shift"
                                                onChange={handleShiftSelection}
                                                options={getSelectableShifts}
                                                isDisabled={(salaryCreationBasis !== "Shift") || _.isEmpty(getSelectableShifts)}
                                            />
                                        </Form.Group>
                                    </Col>}
                            </Row>

                            <div className="sendDiv">
                                {((salaryCreationBasis === "Shift") && ((shiftSelection === null)) ||
                                    (
                                        employeeId === '' ||
                                        machineId === '' ||
                                        basicSalary === 0 ||
                                        medicalAllowance === 0 ||
                                        petrolAllowance === 0 ||
                                        otherAllowance === 0 ||
                                        accomodationAllowance === 0 ||
                                        bonus === '' ||
                                        overTime === '' ||
                                        overTimeRate === '' ||
                                        salaryTransfer === '' || ((salaryTransfer === 'Bank') && (bankAccount === undefined) && (bankName === undefined)) ||
                                        lateTimeDeduct_1 === undefined || ((lateTimeDeduct_1 === 1) && (lateTimeDeduct_2 === undefined)) ||
                                        exemptionAttendance === undefined ||
                                        leaveAllowed === undefined ||
                                        salaryCreationBasis === undefined ||
                                        salaryCreation === undefined
                                    )
                                ) ? (
                                    <Button variant="contained" endIcon={<SendIcon />} size="large" disabled> SAVE </Button>
                                ) : (
                                    <Button variant="contained" endIcon={<SendIcon />} size="large" onClick={() => { saveSalary() }}>SAVE </Button>
                                )}
                            </div>

                        </div>
                    </Container>
                }
            </Container>
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        AddSalaryApi: (data, employeeId, handleVisible, handleDangerVisible) => dispatch(AddSalaryApi(data, employeeId, handleVisible, handleDangerVisible)),
        GetActiveEmployeeApi: () => dispatch(GetActiveEmployeeApi()),
        GetshiftControlApi: () => dispatch(GetshiftControlApi()),
        setHeading: (data) => dispatch(setHeading(data)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    shiftControl: state.ShiftControlReducer.shiftControl,
    isFetchingShiftControlData: state.ShiftControlReducer.isFetchingShiftControlData,
    activeEmployeeList: state.EmployeeReducer.activeEmployeeList,
    isFetchingActiveEmployeeData: state.EmployeeReducer.isFetchingActiveEmployeeData,
    isAddingSalary: state.SalaryReducer.isAddingSalary,
    successAddingSalary: state.SalaryReducer.successAddingSalary,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddSalary);