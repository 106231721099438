const initialState = {
    LTPaymentList: [],
    isAddingLTPayment: false,
    LTRecivingList: [],
    isAddingLTReciving: false,
    isFetchingLTLedger: false,
    ltLedger: [],
};

const LTReducer = (state = initialState, action) => {
    switch (action.type) {

        case "REQUEST_LT_PAYMENT":
            return { ...state, isAddingLTPayment: true };
        case "SUCCESS_LT_PAYMENT":
            return { ...state, LTPaymentList: action.payload, isAddingLTPayment: false, };
        case "ERROR_LT_PAYMENT":
            return { ...state, isAddingLTPayment: false };


        case "REQUEST_ADD_LT_RECIVING":
            return { ...state, isAddingLTReciving: true };
        case "SUCCESS_ADD_LT_RECIVING":
            return { ...state, LTRecivingList: action.payload, isAddingLTReciving: false };
        case "ERROR_ADD_LT_RECIVING":
            return { ...state, isAddingLTReciving: false };


        case "REQUEST_GET_LT_LEDGER":
            return { ...state, isFetchingLTLedger: true };
        case "SUCCESS_GET_LT_LEDGER":
            return { ...state, ltLedger: action.payload, isFetchingLTLedger: false };
        case "ERROR_GET_LT_LEDGER":
            return { ...state, isFetchingLTLedger: false };


        default:
            return state;
    }
};
export default LTReducer;