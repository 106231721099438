import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { setHeading } from "../../../accounts/account.actions";
import { AddDesignationApi } from "./designationAction";
import { GetDivisionApi } from "../division/divisionAction";
import { GetDepartmentByDivisionIdApi } from "../department/departmentAction";
import _ from "lodash";
import Spinner from 'react-bootstrap/Spinner';
import Select from 'react-select';
import Swal from 'sweetalert';
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';


const AddDesignation = (props) => {
    const [divisionId, setDivisionId] = useState(0);
    const [designationName, setDesignationName] = useState("");
    const [designationDescription, setDesignationDescription] = useState();
    const [department, setDepartment] = useState(0);
    const [visibleAlert, setAlertVisible] = useState(false);
    const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);


    function uploadDesignation() {
        props.AddDesignationApi({
            designation: _.upperCase(designationName),
            description: _.upperCase(designationDescription),
            departmentId: department,
            divisionId: divisionId,
        },
            handleVisible,
            handleDangerVisible);
    }
    const clearStates = () => {
        setDivisionId(0);
        setDesignationName();
        setDesignationDescription();
        setDepartment(0);
    }
    const handleVisible = (message) => {
        setAlertVisible(true);
        clearStates();
        setTimeout(() => {
            setAlertVisible(false)
        }, 9000);
        Swal({
            title: "Success",
            text: message,
            icon: "success",
            button: "Ok",
        });
    }
    const handleDangerVisible = (error) => {
        setDangerVisibleAlert(true);
        setTimeout(() => {
            setDangerVisibleAlert(false)
        }, 9000);
        Swal({
            title: "Error",
            text: error,
            icon: "error",
            button: "Ok",
        });
    }


    const getDivisionList = !_.isEmpty(props.divisionList) && (props.divisionList.map((x) => {
        let data = { value: x._id, label: x.name };
        return data;
    }));
    const handleChangeDivision = (selectedOption) => {
        setDivisionId(selectedOption.value);
        props.GetDepartmentByDivisionIdApi(selectedOption.value);
    }
    const getDepartmentNameList = (!_.isEmpty(props.departmentList)) ? (props.departmentList.map((x) => {
        let data = { value: x._id, label: x.department };
        return data
    })) : [];
    const handleChangeDepartment = (selectedOption) => {
        setDepartment(selectedOption.value);
    };

    useEffect(() => {
        props.setHeading("Designation Add")
        props.GetDivisionApi();
    }, []);
    return (
        <>
            {props.isFetchingDivisionData || props.isAddingDesignationData ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />
                    </Button>
                </div>
            ) : (
                <Container fluid>
                    <Form>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Select Division *</b></Form.Label>
                                    <Select
                                        placeholder="Select Division"
                                        onChange={handleChangeDivision}
                                        options={getDivisionList}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Select Department *</b></Form.Label>
                                    <Select
                                        isDisabled={props.isFetchingDepartmentData || divisionId === 0}
                                        placeholder="Select Department"
                                        onChange={handleChangeDepartment}
                                        options={getDepartmentNameList}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Designation Name *</b></Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Designation Name"
                                        onBlur={(e) => setDesignationName(e.target.value)}
                                        disabled={(department === 0)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>
                                        <b>Designation Description</b>
                                    </Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        placeholder="Designation Description"
                                        onMouseLeave={(e) => setDesignationDescription(e.target.value)}
                                        disabled={(department === 0)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <div className="sendDiv">
                            {_.isEmpty(designationName) || (department === 0) ? (
                                <Button variant="contained" endIcon={<SendIcon />} size="large" disabled> SAVE </Button>
                            ) : (
                                <Button variant="contained" endIcon={<SendIcon />} size="large" onClick={uploadDesignation}>SAVE </Button>
                            )}
                        </div>

                    </Form>
                </Container>
            )}
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        GetDivisionApi: () => dispatch(GetDivisionApi()),
        AddDesignationApi: (data, handleVisible, handleDangerVisible) => dispatch(AddDesignationApi(data, handleVisible, handleDangerVisible)),
        GetDepartmentByDivisionIdApi: (divisionId) => dispatch(GetDepartmentByDivisionIdApi(divisionId)),
        setHeading: (data) => dispatch(setHeading(data)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    isFetchingDivisionData: state.DivisionReducer.isFetchingDivisionData,
    divisionList: state.DivisionReducer.divisionList,
    isAddingDesignationData: state.DesignationReducer.isAddingDesignationData,
    departmentList: state.DepartmentReducer.departmentList,
    isFetchingDepartmentData: state.DepartmentReducer.isFetchingDepartmentData,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddDesignation);