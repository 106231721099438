import API from "../../../../../global/api";
import _ from "lodash";


export const requestAddCustomerCRM = () => {
  return {
    type: "REQUEST_ADD_CUSTOMER_CRM",
  };
};
export const successAddCustomerCRM = (data) => {
  return {
    type: "SUCCESS_ADD_CUSTOMER_CRM",
    payload: data,
  };
};
export const errorAddCustomerCRM = () => {
  return {
    type: "ERROR_ADD_CUSTOMER_CRM",
  };
};
export const AddCRMCustomerApi = (data, handleVisible, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestAddCustomerCRM());
    API.post(`/CRMCustomer`, data).then((res) => {
      dispatch(successAddCustomerCRM(data));
      handleVisible(res.data.message);
    }).catch((error) => {
      dispatch(errorAddCustomerCRM());
      handleDangerVisible(error.response.data.message);
    });
  };
};


export const requestCRMCustomerData = () => {
  return {
    type: "REQUEST_GET_CRM_CUSTOMER",
  };
};
export const successCRMCustomerGetData = (data) => {
  return {
    type: "SUCCESS_GET_CRM_CUSTOMER",
    payload: data,
  };
};
export const errorCRMCustomerGetData = () => {
  return {
    type: "ERROR_GET_CRM_CUSTOMER",
  };
};
export const GetCRMCustomerApi = () => {
  return (dispatch) => {
    dispatch(requestCRMCustomerData());
    API.get(`/CRMCustomer`).then((res) => {
      let getData = res.data;
      dispatch(successCRMCustomerGetData(getData));
    }).catch((error) => {
      dispatch(errorCRMCustomerGetData());
    });
  };
};


export const DeleteRequestCRMCustomer = () => {
  return {
    type: "DELETE_REQUEST_CRM_CUSTOMER",
  };
};
export const DeleteSuccessCRMCustomer = (user_id) => {
  return {
    type: "DELETE_SUCCESS_CRM_CUSTOMER",
    user_id: user_id,
  };
};
export const DeleteErrorCRMCustomer = () => {
  return {
    type: "DELETE_ERROR_CRM_CUSTOMER",
  };
};
export const DeleteCRMCustomer = (vendorId) => {
  return (dispatch) => {
    dispatch(DeleteRequestCRMCustomer());
    API.delete(`/CRMCustomer/${vendorId}`).then((res) => {
      dispatch(DeleteSuccessCRMCustomer(vendorId));
    }).catch((error) => {
      dispatch(DeleteErrorCRMCustomer());
    });
  };
};


export const UpdateRequestCRMCustomer = () => {
  return {
    type: "UPDATE_REQUEST_CUSTOMER",
  };
};
export const UpdateSuccessCRMCustomer = (data) => {
  return {
    type: "UPDATE_SUCCESS_CUSTOMER",
    payload: data,
  };
};
export const UpdateErrorCRMCustomer = () => {
  return {
    type: "UPDATE_ERROR_CUSTOMER",
  };
};
export const UpdateCRMCustomerAPi = (data, oldData) => {
  return (dispatch) => {
    dispatch(UpdateRequestCRMCustomer());
    API.put(`/CRMCustomer/${oldData._id}`, {
      companyName: data.companyName,
      ownerName: data.ownerName,
      contactPerson: data.contactPerson,
      designation: data.designation,
      phoneNumberOne: data.phoneNumberOne,
      phoneNumberTwo: data.phoneNumberTwo,
      officeNumber: data.officeNumber,
      city: data.city,
      address: data.address,
      cr: data.cr,
      email: data.email,
      website: data.website,
    }).then((res) => {
      dispatch(UpdateSuccessCRMCustomer([data]));
    }).catch((error) => {
      dispatch(UpdateErrorCRMCustomer())
    });
  };
};


export const requestAddCustomerCommentCRM = () => {
  return {
    type: "REQUEST_ADD_CUSTOMER_COMMENT",
  };
};
export const successAddCustomerCommentCRM = (data) => {
  return {
    type: "SUCCESS_ADD_CUSTOMER_COMMENT",
    payload: data,
  };
};
export const errorAddCustomerCommentCRM = () => {
  return {
    type: "ERROR_ADD_CUSTOMER_COMMENT",
  };
};
export const AddCRMCustomerCommentApi = (data, handleVisible, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestAddCustomerCommentCRM());
    API.post(`/CRMCustomer/add-comment`, data).then((res) => {
      dispatch(successAddCustomerCommentCRM(data));
      handleVisible(res.data.message);
    }).catch((error) => {
      dispatch(errorAddCustomerCommentCRM());
      handleDangerVisible(error.response.data.message);
    });
  };
};


export const requestUploadCustomerFile = () => {
  return {
    type: "REQUEST_UPLOAD_CUSTOMER_FILE",
  };
};
export const successUploadCustomerFile = (data) => {
  return {
    type: "SUCCESS_UPLOAD_CUSTOMER_FILE",
    payload: data,
  };
};
export const errorUploadCustomerFile = () => {
  return {
    type: "ERROR_UPLOAD_CUSTOMER_FILE",
  };
};
export const UploadCustomerFileApi = (data, handleVisible, handleDangerVisible) => {
  const formData = new FormData();
  formData.append("file", _.get(data, 'file'));
  formData.append("productId", `${data.productId}`);
  formData.append("productName", `${data.productName}`);

  return (dispatch) => {
    dispatch(requestUploadCustomerFile());
    API.post(`/CRMCustomer/upload-customer`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then((res) => {
      dispatch(successUploadCustomerFile(data));
      handleVisible(res.data.message);
    }).catch((error) => {
      dispatch(errorUploadCustomerFile());
      handleDangerVisible(error.response.data.message);
    });
  };
};


export const requestSendCustomerEmail = () => {
  return {
    type: "REQUEST_ADD_CUSTOMER_COMMENT",
  };
};
export const successSendCustomerEmail = (data) => {
  return {
    type: "SUCCESS_ADD_CUSTOMER_COMMENT",
    payload: data,
  };
};
export const errorSendCustomerEmail = () => {
  return {
    type: "ERROR_ADD_CUSTOMER_COMMENT",
  };
};
export const SendCustomerEmailApi = (data) => {
  return (dispatch) => {
    dispatch(requestSendCustomerEmail());
    API.post(`/CRMCustomer/send-email`, data).then((res) => {
      dispatch(successSendCustomerEmail(data));
    }).catch((error) => {
      dispatch(errorSendCustomerEmail());
    });
  };
};