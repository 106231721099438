import React, { useEffect } from "react";
import { Container, } from "react-bootstrap";
import { connect } from "react-redux";
import { baseURL, headers } from "../../../../../global/api";
import { deleteHall, getHallData, updateHall } from "./Hall.action";
import { setHeading } from "../../../accounts/account.actions";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";


const ViewHall = (props) => {
  const permissions = JSON.parse(window.localStorage.getItem("permissions"))
  const Checkpermissions = (permissionsType, permissionsName) => {
    return permissions.some(p => p[`${permissionsType}`] === permissionsName) ? false : true
  }

  useEffect(() => {
    props.setHeading("Hall List");
  }, []);

  return (
    <>
      {props.isFetchingHall ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <MaterialTable
              title="Hall Details"
              columns={[
                { title: 'Sequence Number', field: 'sequenceNumber', editable: 'never' },
                { title: "Hall Name", field: "hallName" },
                // { title: "Hall Location", field: "hallLocation" },
                { title: "Hall Description", field: "hallDesc" },
                { title: "Created By", field: "userDetails.userName", editable: 'never' },
              ]}
              data={(query) =>
                new Promise((resolve, reject) => {
                  let url = `${baseURL}/hall?`;
                  url += "limit=" + query.pageSize;
                  url += "&page=" + (query.page);
                  url += "&search=" + query.search;
                  fetch(url, {
                    method: 'GET',
                    headers: headers,
                  }).then((response) => response.json()).then((result) => {
                    resolve({
                      data: result.data,
                      page: result.page,
                      totalCount: result.total_results,
                    });
                  });
                })
              }
              options={{
                actionsColumnIndex: -1,
                exportButton: true,
                exportAllData: true,
                paging: true,
                pageSize: 50,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [50, 100, 150, 200],
                headerStyle: {
                  position: "sticky",
                  top: 0,
                  color: "#00BBBB",
                  fontWeight: "550",
                  onRowAdd: "none",
                },
              }}
              editable={{
                isEditHidden: rowData => Checkpermissions("permissionsSubCatName", "Hall Edit"),
                isDeleteHidden: rowData => Checkpermissions("permissionsSubCatName", "Hall Delete"),
                isDeletable: rowData => false,
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      props.updateHall(newData, oldData);
                    }, 600);
                  }),
                onRowDelete: (oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      props.deleteHall(oldData._id);
                    }, 600);
                  }),
              }}
            />
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getHallData: () => dispatch(getHallData()),
    deleteHall: (hallId) => dispatch(deleteHall(hallId)),
    updateHall: (newData, oldData) => dispatch(updateHall(newData, oldData)),
    setHeading: (data) => dispatch(setHeading(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  hallDataList: state.hallReducer.hallDataList,
  isFetchingHall: state.hallReducer.isFetchingHall,
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewHall);