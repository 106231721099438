import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { setHeading } from "../../../accounts/account.actions";
import Spinner from "react-bootstrap/Spinner";
import _ from 'lodash'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';
import moment from "moment";


const DetailViewArticle = (props) => {
    const location = useLocation();
    const fromPath = location.state?.from;

    console.log(props.articleListWithVariationID, "aaaaaaaaaaaaaaaaaaaaaaa")

    useEffect(() => {
        props.setHeading("View Article Details");
    });

    const tableStyle = {
        borderCollapse: "collapse",
        width: "90%",
        margin: '0 auto',
    };
    const cellStyle = {
        border: "1px solid black",
        padding: "8px",
        textAlign: "left",
    };

    return (
        <>
            {props.isFetchingArticleWithVariationID ? (
                <div className="loader-div">
                    <Spinner
                        variant="primary"
                        animation="border"
                        size="xl"
                        role="status"
                        aria-hidden="true"
                    />
                </div>
            ) : (
                <div>

                    <div style={{ textAlign: 'center', marginBottom: '35px' }}>
                        <Link to={fromPath}>
                            <Button variant="contained" endIcon={<ArrowBackIcon />} size="large" style={{ fontWeight: 'bold' }}> BACK TO LIST </Button>
                        </Link>
                    </div>

                    <div style={{ backgroundColor: '#aed6ae', padding: '15px', borderRadius: '25px', marginBottom: '35px' }}>
                        <Row>
                            <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Sr No: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{props.articleListWithVariationID[0].sequenceNumber}</span> </b></Col>
                            <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Customer Name: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{props.articleListWithVariationID[0].customerDetails.name}</span> </b></Col>
                            <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Brand Name: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{props.articleListWithVariationID[0].brandDetails.brandName}</span> </b></Col>
                        </Row>
                        <Row>
                            <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>UOM: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{props.articleListWithVariationID[0].uomDetails.unitName}</span> </b></Col>
                            <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Category Name: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{props.articleListWithVariationID[0].categoryDetails.categoryName}</span> </b></Col>
                            <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Sub Category Name: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{props.articleListWithVariationID[0].subCategoryDetails.subCategoryName}</span> </b></Col>
                        </Row>
                        <Row>
                            <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Article Name: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{props.articleListWithVariationID[0].articleName}</span> </b></Col>
                            <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Carton Size: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{props.articleListWithVariationID[0].cartonSize}</span> </b></Col>
                            <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Packing: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{props.articleListWithVariationID[0].packing}</span> </b></Col>
                        </Row>
                        <Row>
                            <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Weight: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{props.articleListWithVariationID[0].weight}</span> </b></Col>
                            <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Composition: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{props.articleListWithVariationID[0].composition}   </span> </b></Col>
                            <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>HS Code: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{props.articleListWithVariationID[0].hSCode}</span> </b></Col>
                        </Row>
                        <Row>
                            <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Description: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{props.articleListWithVariationID[0].description}</span> </b></Col>
                            <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Catlog Number: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{props.articleListWithVariationID[0].catlogNumber}</span> </b></Col>
                            <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Customer Catalogue Number: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{props.articleListWithVariationID[0].customerCatalogueNumber}</span> </b></Col>
                        </Row>
                        <Row>
                            <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Export Cost: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{props.articleListWithVariationID[0].exportCost}</span> </b></Col>
                            <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Currency: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{props.articleListWithVariationID[0].currency}</span> </b></Col>
                            <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Conversion To PKR: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{props.articleListWithVariationID[0].conversionToPKR}</span> </b></Col>
                        </Row>
                        <Row>
                            <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Export Price: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{props.articleListWithVariationID[0].exportPrice}</span> </b></Col>
                            <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Profit: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{props.articleListWithVariationID[0].profit}</span> </b></Col>
                            <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Article Total Cost: <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{props.articleListWithVariationID[0].articleTotalCost}</span> </b></Col>
                        </Row>
                    </div>

                    <div style={{ marginBottom: '35px' }}>
                        <Col style={{ textAlign: 'center', fontSize: '20px', fontWeight: 'bold' }}> Variation Details </Col>
                        <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Variation Sequence Number : <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{props.articleListWithVariationID[0].variation[0].variationSequenceNumber}</span> </b></Col>
                        <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Variation Manual Code : <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{props.articleListWithVariationID[0].variation[0].manualVariationCode}</span> </b></Col>
                        <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Color : <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{props.articleListWithVariationID[0].variation[0].colourLabel}</span> </b></Col>
                        <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Size : <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{props.articleListWithVariationID[0].variation[0].sizeLabel}</span> </b></Col>
                        <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Variation Cost : <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{props.articleListWithVariationID[0].variation[0].articleVariationCost}</span> </b></Col>
                    </div>

                    <div style={{ marginBottom: '35px' }}>
                        <Col style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '20px', marginBottom: '15px' }}> Material </Col>
                        <table style={tableStyle}>
                            <thead>
                                <tr style={{ backgroundColor: '#81abd3' }}>
                                    <th style={cellStyle}>Sr No</th>
                                    <th style={cellStyle}>Product Name</th>
                                    <th style={cellStyle}>Quantity</th>
                                    <th style={cellStyle}>Purchase Price</th>
                                    <th style={cellStyle}>Total Cost</th>
                                    <th style={cellStyle}>UOM</th>
                                </tr>
                            </thead>
                            <tbody>
                                {_.isUndefined(props.articleListWithVariationID[0]) ? '' : props.articleListWithVariationID[0].variation[0].productData.map((item, index) => (
                                    <tr key={index}>
                                        <td style={cellStyle}>{index + 1}</td>
                                        <td style={cellStyle}>{item.productName}</td>
                                        <td style={cellStyle}>{item.quantity}</td>
                                        <td style={cellStyle}>{item.purchasePrice}</td>
                                        <td style={cellStyle}>{item.totalCost}</td>
                                        <td style={cellStyle}>{item.uomLabel}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <div style={{ marginBottom: '35px' }}>
                        <Col style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '20px', marginBottom: '15px' }}> Process </Col>
                        <table style={tableStyle}>
                            <thead>
                                <tr style={{ backgroundColor: '#81abd3' }}>
                                    <th style={cellStyle}>Sr No</th>
                                    <th style={cellStyle}>Process Name</th>
                                    <th style={cellStyle}>Process Rate</th>
                                </tr>
                            </thead>
                            <tbody>
                                {_.isUndefined(props.articleListWithVariationID[0]) ? '' : props.articleListWithVariationID[0].variation[0].processData.map((item, index) => (
                                    <tr key={index}>
                                        <td style={cellStyle}>{index + 1}</td>
                                        <td style={cellStyle}>{item.processName}</td>
                                        <td style={cellStyle}>{item.processRate}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>


                    {/* <div style={{ marginBottom: '35px' }}>
                        <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Advance Amount : <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{props.articleListWithVariationID[0].advance}</span> </b></Col>
                        <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Expense Total : <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{props.articleListWithVariationID[0].totalExpense}</span> </b></Col>
                        <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>VAT (5%) : <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{props.articleListWithVariationID[0].vatTotal}</span> </b></Col>
                        <Col style={{ marginBottom: '7px' }}><b style={{ fontSize: '17px', fontWeight: 'bold' }}>Final Total : <span style={{ fontSize: '15px', fontWeight: 'lighter' }}>{props.articleListWithVariationID[0].finalTotal}</span> </b></Col>
                    </div> */}


                    <div style={{ marginTop: '20px' }}>
                        {props.articleListWithVariationID[0].images.map((image, index) => (
                            <div style={{ float: 'left', marginRight: '15px' }}>
                                <a href={image.file} target="blank"><img src={image.file} alt={`alt`} width={"160px"} height={"160px"} /></a>
                            </div>
                        ))}
                    </div>

                </div>
            )}
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        setHeading: (data) => dispatch(setHeading(data)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    articleListWithVariationID: state.ArticleReducer.articleListWithVariationID,
    isFetchingArticleWithVariationID: state.ArticleReducer.isFetchingArticleWithVariationID,
});

export default connect(mapStateToProps, mapDispatchToProps)(DetailViewArticle);