import API from "../../../../../global/api";


export const requestAddProcess = () => {
    return {
        type: "REQUEST_ADD_PROCESS",
    };
};
export const successAddProcess = (data) => {
    return {
        type: "SUCCESS_ADD_PROCESS",
        payload: data,
    };
};
export const errorAddProcess = () => {
    return {
        type: "ERROR_ADD_PROCESS",
    };
};
export const AddProcessApi = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddProcess());
        API.post(`/process`, data).then((res) => {
            dispatch(successAddProcess(data));
            handleVisible(res.data.message);
        }).catch((error) => {
            dispatch(errorAddProcess());
            handleDangerVisible(error.response.data.message);
        })
    };
};


export const requestGetProcess = () => {
    return {
        type: "REQUEST_GET_PROCESS",
    };
};
export const successGetProcess = (data) => {
    return {
        type: "SUCCESS_GET_PROCESS",
        payload: data,
    };
};
export const errorGetProcess = () => {
    return {
        type: "ERROR_GET_PROCESS",
    };
};
export const GetProcessApi = () => {
    return (dispatch) => {
        dispatch(requestGetProcess());
        API.get(`/process`).then((res) => {
            let getData = res.data;
            dispatch(successGetProcess(getData));
        }).catch((error) => {
            dispatch(errorGetProcess());
        })
    };
};


export const requestDeleteProcess = () => {
    return {
        type: "REQUEST_DELETE_PROCESS",
    };
};
export const successDeleteProcess = (user_id) => {
    return {
        type: "SUCCESS_DELETE_PROCESS",
        user_id: user_id,
    };
};
export const errorDeleteProcess = () => {
    return {
        type: "ERROR_DELETE_PROCESS",
    };
};
export const DeleteProcessApi = (id) => {
    return dispatch => {
        dispatch(requestDeleteProcess());
        API.delete(`/process/${id}`).then(res => {
            // dispatch(getBrandApi());
            dispatch(successDeleteProcess(id))
        }).catch((error) => {
            dispatch(errorDeleteProcess())
        });
    }
};


export const requestUpdateProcess = () => {
    return {
        type: "REQUEST_UPDATE_PROCESS",
    };
};
export const successUpdateProcess = (data) => {
    return {
        type: "SUCCESS_UPDATE_PROCESS",
        payload: data,
    };
};
export const errorUpdateProcess = () => {
    return {
        type: "ERROR_UPDATE_PROCESS",
    };
};
export const UpdateProcessApi = (data, oldData) => {
    return dispatch => {
        dispatch(requestUpdateProcess());
        API.put(`/process/${oldData._id}`, {
            processName: data.processName,
            description: data.description,
            rate: data.rate,
        }, {
        }).then(res => {
            // dispatch(getBrandApi());
            dispatch(successUpdateProcess([data]))
        }).catch((error) => {
            dispatch(errorUpdateProcess());
        })
    }
};