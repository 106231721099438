import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
import { setSalaryFields, UpdateSalaryApi } from "./salaryAction";
import { GetshiftControlApi } from "../shiftControl/shiftControlAction";
import Spinner from "react-bootstrap/Spinner";
import Select from "react-select";
import Swal from "sweetalert";
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';


const EditSalary = (props) => {
    const navigate = useNavigate();
    const oldData = !isEmpty(props.salaryListById) && props.salaryListById;
    const [over_time_flag, setOver_time_flag] = useState("");
    const [salary_transfer_flag, setSalary_transfer_flag] = useState("");
    const [late_time_deduct_1_flag, setLate_time_deduct_1_flag] = useState("");
    const [salary_creation_based_flag, setSalary_creation_based_flag] = useState("");
    const [basicSalary, setBasicSalary] = useState(!isEmpty(oldData) ? oldData.basicSalary : "");
    const [medicalAllowance, setMedicalAllowance] = useState(!isEmpty(props.salaryListById) ? props.salaryListById.medicialAllowance : "");
    const [petrolAllowance, setPetrolAllowance] = useState(!isEmpty(props.salaryListById) ? props.salaryListById.petrolAllowance : "");
    const [specialAllowance, setSpecialAllowance] = useState(!isEmpty(props.salaryListById) ? props.salaryListById.otherAllowance : "");
    const [accomodationAllowance, setAccomodationAllowance] = useState(!isEmpty(props.salaryListById) ? props.salaryListById.accomodationAllowance : "");
    const [totalAllowance, setTotalAllowance] = useState(0);
    const [totalSalary, setTotalSalary] = useState(0);
    const [visibleAlert, setAlertVisible] = useState(false);
    const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);


    const saveSalary = () => {
        props.UpdateSalaryApi({
            // machineid: isEmpty(machineId) ? props.salaryListById.machineid : machineId,
            machineId: props.salaryListById.machineId,
            basicSalary: props.salaryListById.basicSalary,
            medicialAllowance: props.salaryListById.medicialAllowance,
            petrolAllowance: props.salaryListById.petrolAllowance,
            otherAllowance: props.salaryListById.otherAllowance,
            accomodationAllowance: props.salaryListById.accomodationAllowance,
            specialAllowance: props.salaryListById.specialAllowance,
            totalAllowances: (Number(props.salaryListById.medicialAllowance) + Number(props.salaryListById.petrolAllowance) + Number(props.salaryListById.otherAllowance) + Number(props.salaryListById.specialAllowance) + Number(props.salaryListById.accomodationAllowance)),
            overTime: props.salaryListById.overTime,
            overTimeRate: props.salaryListById.overTime == 0 ? 0 : props.salaryListById.overTimeRate,
            salaryTransfer: props.salaryListById.salaryTransfer,
            bankAccount: props.salaryListById.bankAccount,
            lateTimeDeduct: props.salaryListById.lateTimeDeduct,
            lateTimeDeductPercentage: props.salaryListById.lateTimeDeduct == 0 ? 0 : props.salaryListById.lateTimeDeductPercentage,
            exemptionAttendance: props.salaryListById.exemptionAttendance,
            bankName: props.salaryListById.bankName,
            leaveAllowed: props.salaryListById.leaveAllowed,
            salaryCreation: props.salaryListById.salaryCreation,
            salaryCreationBased: props.salaryListById.salaryCreationBased,
            shiftId: isEmpty(props.salaryListById.shiftId) ? props.salaryListById.shiftDetails._id : props.salaryListById.shiftId
            // bonus: props.salaryListById.bonus,
            // total_salary: (Number(props.salaryListById.medicialAllowance) + Number(props.salaryListById.petrolAllowance) + Number(props.salaryListById.otherAllowance) + Number(props.salaryListById.specialAllowance) + Number(props.salaryListById.accomodationAllowance) + Number(props.salaryListById.basicSalary)),
            // last_time: lateTime,
            // arrived_time: arrivalTime,
            // break_in: breakIn,
            // break_out: breakOut,
            // depature_time: departureTime,
            // allowed_late_time: allowedLateTime,
            // juma_break_start_time: jummaBreakStartTime,
            // juma_break_end_time: jummaBreakEndTime,
            // normal_day_working_hours: normalDayWorkingHours,
            // juma_working_hours: jummaWorkingHours,
            // active_status: props.salaryListById.active_status,
            // temporary_block_salary: props.salaryListById.temporary_block_salary,
            // reason_for_block_salary: props.salaryListById.reason_for_block_salary,
        },
            oldData.employeeId,
            handleVisible,
            handleDangerVisible
        );
    };
    const handleVisible = (message) => {
        setAlertVisible(true);
        setTimeout(() => {
            setAlertVisible(false);
            navigate("/hr/salary/EditSalaryWithEmpID");
        }, 2000);
        Swal({
            title: "Success",
            text: message,
            icon: "success",
            button: "Ok",
        })
    };
    const handleDangerVisible = (error) => {
        setDangerVisibleAlert(true);
        setTimeout(() => {
            setDangerVisibleAlert(false);
        }, 9000);
        Swal({
            title: "Error",
            text: error,
            icon: "error",
            button: "Ok",
        });
    };


    const getOverTimeList = [
        { value: 1, label: "Allowed" },
        { value: 0, label: "Not Allowed" },
    ];
    const handleOverTime = (selectedOption) => {
        props.setSalaryFields({ key: "overTime", value: selectedOption.value }); // setOverTimeName(selectedOption.name);
        setOver_time_flag(selectedOption.value);
    };

    const getDeductionList = [
        { value: 1, label: "Deduct" },
        { value: 0, label: "Do not Deduct" },
    ];
    const handleLateDeduction = (selectedOption) => {
        props.setSalaryFields({
            key: "lateTimeDeduct",
            value: selectedOption.value,
        });
        // setLate_time_deduct_1_flag(selectedOption.value);
    };

    const getSalaryTransferList = [
        { value: "Cash", label: "Cash" },
        { value: "Bank", label: "Bank" },
    ];
    const handleChangeSalaryTransfer = (selectedOption) => {
        props.setSalaryFields({
            key: "salaryTransfer",
            value: selectedOption.value,
        });
        // setSalary_transfer_flag(selectedOption.value);
    };

    const getBonusList = [
        { value: 1, label: "Yes" },
        { value: 0, label: "No" },
    ];
    const handleChangeBonus = (selectedOption) => {
        props.setSalaryFields({ key: "bonus", value: selectedOption.value });
    };

    const getexemptionAttendanceList = [
        { value: 1, label: "Allowed" },
        { value: 0, label: "Not Allowed" },
    ];
    const handleChangeexemptionAttendance = (selectedOption) => {
        props.setSalaryFields({
            key: "exemptionAttendance",
            value: selectedOption.value,
        });
    };

    const getSalaryCreationList = [
        { value: 1, label: "Allowed" },
        { value: 0, label: "Not Allowed" },
    ];
    const handleSalaryCreation = (selectedOption) => {
        props.setSalaryFields({
            key: "salaryCreation",
            value: selectedOption.value,
        });
    };

    const getLeaveAllowedList = [
        { value: 1, label: "Yes" },
        { value: 0, label: "No" },
    ];
    const handleLeaveAllowed = (selectedOption) => {
        props.setSalaryFields({
            key: "leaveAllowed",
            value: selectedOption.value,
        });
    };

    const getSalaryCreationBasisList = [
        { value: "Shift", label: "Shift" },
        { value: "Master", label: "Master" },
    ];
    const handleSalaryCreationBasis = (selectedOption) => {
        props.setSalaryFields({
            key: "salaryCreationBased",
            value: selectedOption.value,
        });
        setSalary_creation_based_flag(selectedOption.value);
    };

    const getSelectableShifts = !isEmpty(props.shiftControl) && props.shiftControl.map((x) => {
        let data = { value: x._id, label: x.shiftName };
        return data;
    });
    const handleShiftSelection = (selectedOption) => {
        props.setSalaryFields({ key: "shiftId", value: selectedOption.value });
    };


    const setTotals = () => {
        setTotalSalary(
            Number(props.salaryListById.medicialAllowance) +
            Number(props.salaryListById.petrolAllowance) +
            Number(props.salaryListById.specialAllowance) +
            Number(props.salaryListById.otherAllowance) +
            Number(props.salaryListById.accomodationAllowance) +
            Number(props.salaryListById.basicSalary)
        );
        setTotalAllowance(
            Number(props.salaryListById.medicialAllowance) +
            Number(props.salaryListById.petrolAllowance) +
            Number(props.salaryListById.specialAllowance) +
            Number(props.salaryListById.otherAllowance) +
            Number(props.salaryListById.accomodationAllowance)
        );
    };


    useEffect(() => {
        // props.GetSalaryWithEmpIDApi(state.employeeId);
        props.GetshiftControlApi();
    }, []);
    return (
        <>
            {props.isFetchingSalaryDataById || isEmpty(props.salaryListById) || isEmpty(oldData) ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />
                        {/* {props.isFetchingSectorsSummary ? 'Loading...' : 'Loading...'} */}
                    </Button>
                </div>
            ) : (
                <Container fluid>
                    <div className="mainHr" onClick={setTotals} onMouseOver={setTotals}>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3"  >
                                    <Form.Label> <b>Employee Name </b> </Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="Employee Name"
                                        placeholder="Employee Name"
                                        defaultValue={`${props.salaryListById.employeeName}/${props.salaryListById.fatherName}/${props.salaryListById.cnic}`}
                                        disabled={true}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label> <b>Machine ID</b></Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="Machine_id"
                                        placeholder="Machine ID"
                                        defaultValue={props.salaryListById.machineId}
                                        onChange={(e) => {
                                            props.setSalaryFields({ key: "machineId", value: e.target.value });
                                            props.salaryListById.machineId = e.target.value;
                                            // setBasicSalary(e.target.value);
                                            // console.log(e.target.value);
                                            // setTotalSalary(Number(props.salaryListById.medicialAllowance) + Number(props.salaryListById.petrolAllowance) + Number(props.salaryListById.specialAllowance) + Number(props.salaryListById.otherAllowance) + Number(props.salaryListById.accomodationAllowance) + Number(props.salaryListById.basicSalary));
                                            // setTotalAllowance(Number(props.salaryListById.medicialAllowance) + Number(props.salaryListById.petrolAllowance) + Number(props.salaryListById.specialAllowance) + Number(props.salaryListById.otherAllowance) + Number(props.salaryListById.accomodationAllowance));
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col >
                                <Form.Group className="mb-3">
                                    <Form.Label> <b>Basic Salary</b></Form.Label>
                                    <Form.Control
                                        type="number"
                                        id="basicSalary"
                                        placeholder="Basic Salary"
                                        defaultValue={props.salaryListById.basicSalary}
                                        onChange={(e) => {
                                            props.setSalaryFields({ key: "basicSalary", value: e.target.value, });
                                            props.salaryListById.basicSalary = e.target.value;
                                            setBasicSalary(e.target.value);
                                            setTotalSalary(
                                                Number(props.salaryListById.medicialAllowance) +
                                                Number(props.salaryListById.petrolAllowance) +
                                                Number(props.salaryListById.specialAllowance) +
                                                Number(props.salaryListById.otherAllowance) +
                                                Number(props.salaryListById.accomodationAllowance) +
                                                Number(props.salaryListById.basicSalary)
                                            );
                                            setTotalAllowance(
                                                Number(props.salaryListById.medicialAllowance) +
                                                Number(props.salaryListById.petrolAllowance) +
                                                Number(props.salaryListById.specialAllowance) +
                                                Number(props.salaryListById.otherAllowance) +
                                                Number(props.salaryListById.accomodationAllowance)
                                            );
                                        }}
                                        disabled
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3"  >
                                    <Form.Label> <b>Medical Allowance</b></Form.Label>
                                    <Form.Control
                                        type="number"
                                        id="medicialAllowance"
                                        placeholder="Medical Allowance"
                                        defaultValue={props.salaryListById.medicialAllowance}
                                        onChange={(e) => {
                                            props.setSalaryFields({ key: "medicialAllowance", value: Number(e.target.value) });
                                            props.salaryListById.medicialAllowance = Number(e.target.value);
                                            setTotalSalary(
                                                Number(props.salaryListById.medicialAllowance) +
                                                Number(props.salaryListById.petrolAllowance) +
                                                Number(props.salaryListById.specialAllowance) +
                                                Number(props.salaryListById.otherAllowance) +
                                                Number(props.salaryListById.accomodationAllowance) +
                                                Number(props.salaryListById.basicSalary)
                                            );
                                            setTotalAllowance(
                                                Number(props.salaryListById.medicialAllowance) +
                                                Number(props.salaryListById.petrolAllowance) +
                                                Number(props.salaryListById.specialAllowance) +
                                                Number(props.salaryListById.otherAllowance) +
                                                Number(props.salaryListById.accomodationAllowance)
                                            );
                                        }}
                                    // disabled
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3"  >
                                    <Form.Label> <b>Petrol Allowance</b> </Form.Label>
                                    <Form.Control
                                        type="number"
                                        id="petrolAllowance"
                                        placeholder="Petrol Allowance"
                                        defaultValue={props.salaryListById.petrolAllowance}
                                        onChange={(e) => {
                                            props.setSalaryFields({ key: "petrolAllowance", value: Number(e.target.value), });
                                            props.salaryListById.petrolAllowance = Number(e.target.value);
                                            setTotalSalary(
                                                Number(props.salaryListById.medicialAllowance) +
                                                Number(props.salaryListById.petrolAllowance) +
                                                Number(props.salaryListById.specialAllowance) +
                                                Number(props.salaryListById.otherAllowance) +
                                                Number(props.salaryListById.accomodationAllowance) +
                                                Number(props.salaryListById.basicSalary)
                                            );
                                            setTotalAllowance(
                                                Number(props.salaryListById.medicialAllowance) +
                                                Number(props.salaryListById.petrolAllowance) +
                                                Number(props.salaryListById.specialAllowance) +
                                                Number(props.salaryListById.otherAllowance) +
                                                Number(props.salaryListById.accomodationAllowance)
                                            );
                                        }}
                                    // disabled
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3"  >
                                    <Form.Label> <b>Other Allowance</b></Form.Label>
                                    <Form.Control
                                        type="number"
                                        id="otherAllowance"
                                        placeholder="Other Allowance"
                                        defaultValue={props.salaryListById.otherAllowance}
                                        onChange={(e) => {
                                            props.setSalaryFields({ key: "otherAllowance", value: Number(e.target.value), });
                                            props.salaryListById.otherAllowance = Number(e.target.value);
                                            setTotalSalary(
                                                Number(props.salaryListById.medicialAllowance) +
                                                Number(props.salaryListById.petrolAllowance) +
                                                Number(props.salaryListById.specialAllowance) +
                                                Number(props.salaryListById.otherAllowance) +
                                                Number(props.salaryListById.accomodationAllowance) +
                                                Number(props.salaryListById.basicSalary)
                                            );
                                            setTotalAllowance(
                                                Number(props.salaryListById.medicialAllowance) +
                                                Number(props.salaryListById.petrolAllowance) +
                                                Number(props.salaryListById.specialAllowance) +
                                                Number(props.salaryListById.otherAllowance) +
                                                Number(props.salaryListById.accomodationAllowance)
                                            );
                                        }}
                                    // disabled
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3"  >
                                    <Form.Label> <b>Accomodation Allowance</b> </Form.Label>
                                    <Form.Control
                                        type="number"
                                        id="accomodationAllowance"
                                        placeholder="Accomodation Allowance"
                                        defaultValue={props.salaryListById.accomodationAllowance}
                                        onChange={(e) => {
                                            props.setSalaryFields({ key: "accomodationAllowance", value: Number(e.target.value), });
                                            props.salaryListById.accomodationAllowance = Number(e.target.value);
                                            setTotalSalary(
                                                Number(props.salaryListById.medicialAllowance) +
                                                Number(props.salaryListById.petrolAllowance) +
                                                Number(props.salaryListById.specialAllowance) +
                                                Number(props.salaryListById.otherAllowance) +
                                                Number(props.salaryListById.accomodationAllowance) +
                                                Number(props.salaryListById.basicSalary)
                                            );
                                            setTotalAllowance(
                                                Number(props.salaryListById.medicialAllowance) +
                                                Number(props.salaryListById.petrolAllowance) +
                                                Number(props.salaryListById.specialAllowance) +
                                                Number(props.salaryListById.otherAllowance) +
                                                Number(props.salaryListById.accomodationAllowance)
                                            );
                                        }}
                                    // disabled
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3"  >
                                    <Form.Label> <b>Special Allowance</b> </Form.Label>
                                    <Form.Control
                                        type="number"
                                        id="special Allowance"
                                        placeholder="Special Allowance"
                                        defaultValue={props.salaryListById.specialAllowance}
                                        onChange={(e) => {
                                            props.setSalaryFields({ key: "specialAllowance", value: Number(e.target.value), });
                                            props.salaryListById.specialAllowance = Number(e.target.value);
                                            setTotalSalary(
                                                Number(props.salaryListById.medicialAllowance) +
                                                Number(props.salaryListById.petrolAllowance) +
                                                Number(props.salaryListById.specialAllowance) +
                                                Number(props.salaryListById.otherAllowance) +
                                                Number(props.salaryListById.accomodationAllowance) +
                                                Number(props.salaryListById.basicSalary)
                                            );
                                            setTotalAllowance(
                                                Number(props.salaryListById.medicialAllowance) +
                                                Number(props.salaryListById.petrolAllowance) +
                                                Number(props.salaryListById.specialAllowance) +
                                                Number(props.salaryListById.otherAllowance) +
                                                Number(props.salaryListById.accomodationAllowance)
                                            );
                                        }}
                                    // disabled
                                    />
                                </Form.Group>
                            </Col>{" "}
                            <Col>
                                <Form.Group className="mb-3"  >
                                    <Form.Label> <b>Total Allowance</b> </Form.Label>
                                    <Form.Control
                                        id="totalAllowance"
                                        placeholder="Total Allowance"
                                        value={totalAllowance}
                                        style={{
                                            fontWeight: "bold",
                                            color: "white",
                                            backgroundColor: 'lightblue',
                                        }}
                                        disabled
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3"  >
                                    <Form.Label> <b>Total Salary = (Basic salary + Total Allowance)</b></Form.Label>
                                    <Form.Control
                                        id="totalSalary"
                                        placeholder="Total Salary"
                                        value={totalSalary}
                                        disabled
                                        style={{
                                            fontWeight: "bold",
                                            color: "white",
                                            backgroundColor: "gray",
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3"  >
                                    <Form.Label><b>Over Time</b> </Form.Label>
                                    <Select
                                        defaultValue={{
                                            label: [props.salaryListById.overTime === 1 ? "Allowed" : [props.salaryListById.overTime === 0 ? "Not Allowed" : ""]],
                                        }}
                                        placeholder="Over Time"
                                        onChange={handleOverTime}
                                        options={getOverTimeList}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg="6" md="6" xs="6">
                                <Form.Group className="mb-3"  >
                                    <Form.Label> <b>Over Time Rate</b> </Form.Label>
                                    <Form.Control
                                        type="number"
                                        id="overTimeRate"
                                        placeholder="Over Time Rate"
                                        defaultValue={props.salaryListById.overTimeRate}
                                        onChange={(e) => {
                                            props.setSalaryFields({ key: "overTimeRate", value: e.target.value });
                                            props.salaryListById.overTimeRate = e.target.value;
                                        }}
                                        disabled={props.salaryListById.overTime === "0" || props.salaryListById.overTime === 0}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label> <b>Late Time Deduction</b> </Form.Label>
                                    <Select
                                        defaultValue={{
                                            label: [props.salaryListById.lateTimeDeduct === 1 ? "Deduct" : [props.salaryListById.lateTimeDeduct === 0 ? "Do not deduct" : ""]],
                                        }}
                                        placeholder="Late Time Deduction"
                                        options={getDeductionList}
                                        onChange={handleLateDeduction}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3"  >
                                    <Form.Label><b>Late Time Deduction</b></Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="lateTimeDeduct_2"
                                        placeholder="Late Time Deduct"
                                        defaultValue={props.salaryListById.lateTimeDeductPercentage}
                                        onChange={(e) => {
                                            props.setSalaryFields({ key: "lateTimeDeductPercentage", value: e.target.value, });
                                            props.salaryListById.lateTimeDeductPercentage = e.target.value;
                                        }}
                                        disabled={props.salaryListById.lateTimeDeduct === "0" || props.salaryListById.lateTimeDeduct === 0}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3"  >
                                    <Form.Label><b>Bonus</b></Form.Label>
                                    <Select
                                        placeholder="Select Bonus"
                                        defaultValue={{ label: props.salaryListById.bonus === 1 ? "Yes" : "No" }}
                                        onChange={handleChangeBonus}
                                        options={getBonusList}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3"  >
                                    <Form.Label><b>Exemption Attendance</b> </Form.Label>
                                    <Select
                                        placeholder="Select Exemption Attendance"
                                        defaultValue={{
                                            label: [props.salaryListById.exemptionAttendance === "1" ? "Allowed" : [props.salaryListById.exemptionAttendance === "0" ? "Not Allowed" : ""]],
                                        }}
                                        onChange={handleChangeexemptionAttendance}
                                        options={getexemptionAttendanceList}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3"  >
                                    <Form.Label><b>Leave Allowed</b></Form.Label>
                                    <Select
                                        placeholder="Leave Allowed"
                                        defaultValue={{
                                            label: [props.salaryListById.leaveAllowed === "1" ? "Yes" : [props.salaryListById.leaveAllowed === "0" ? "No" : ""]],
                                        }}
                                        onChange={handleLeaveAllowed}
                                        options={getLeaveAllowedList}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3"  >
                                    <Form.Label> <b>Salary Creation</b></Form.Label>
                                    <Select
                                        // isDisabled={isEmpty(getDepartmentNameList)}+
                                        defaultValue={{
                                            label: [props.salaryListById.salaryCreation === 1 ? "Allowed" : [props.salaryListById.salaryCreation === 0 ? "Not Allowed" : ""]],
                                        }}
                                        placeholder="Salary Creation..."
                                        onChange={handleSalaryCreation}
                                        options={getSalaryCreationList}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3"  >
                                    <Form.Label><b>Salary Transfer</b></Form.Label>
                                    <Select
                                        // isDisabled={isEmpty(getDepartmentNameList)}
                                        defaultValue={{ label: props.salaryListById.salaryTransfer }}
                                        placeholder="Salary Transfer"
                                        onChange={handleChangeSalaryTransfer}
                                        options={getSalaryTransferList}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3"  >
                                    <Form.Label>
                                        <b>Bank Name</b>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="bankName"
                                        placeholder="Bank Name"
                                        defaultValue={props.salaryListById.bankName}
                                        onChange={(e) => {
                                            props.setSalaryFields({ key: "bankName", value: e.target.value.toUpperCase(), });
                                            props.salaryListById.bankName = e.target.value;
                                        }}
                                        disabled={props.salaryListById.salaryTransfer === "Cash"}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3"  >
                                    <Form.Label> <b>Bank Account</b> </Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="bankAccount"
                                        placeholder="Bank Account"
                                        defaultValue={props.salaryListById.bankAccount}
                                        onChange={(e) => {
                                            props.setSalaryFields({ key: "bankAccount", value: e.target.value, });
                                            props.salaryListById.bankAccount = e.target.value;
                                        }}
                                        disabled={salary_transfer_flag === "Cash" || props.salaryListById.salaryTransfer === "Cash"}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3"  >
                                    <Form.Label> <b>Salary Creation Basis</b> </Form.Label>
                                    <Select
                                        // isDisabled={isEmpty(getDepartmentNameList)}
                                        defaultValue={{ label: props.salaryListById.salaryCreationBased }}
                                        placeholder="Salary Creation Basis"
                                        onChange={handleSalaryCreationBasis}
                                        options={getSalaryCreationBasisList}
                                    />
                                </Form.Group>
                            </Col>

                            {props.salaryListById.salaryCreationBased === "Shift" && (
                                <Col lg="4" md="4" xs="12">
                                    <Form.Group className="mb-3"  >
                                        <Form.Label> <b>Select Shift</b> </Form.Label>
                                        <Select
                                            defaultValue={{
                                                label: [props.salaryListById.shift_control === null ? "" : props.salaryListById.shiftDetails.shiftName],
                                            }}
                                            placeholder="Select Shift"
                                            onChange={handleShiftSelection}
                                            options={getSelectableShifts}
                                            isDisabled={props.salaryListById.salaryCreationBased !== "Shift" || isEmpty(getSelectableShifts)}
                                        />
                                    </Form.Group>
                                </Col>
                            )}
                        </Row>

                        <div className="sendDiv">
                            <Button variant="contained" endIcon={<SendIcon />} size="large" onClick={() => { saveSalary(); }}> SAVE </Button>
                        </div>

                    </div>
                </Container>
            )}
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        UpdateSalaryApi: (data, id, handleVisible, handleDangerVisible) => dispatch(UpdateSalaryApi(data, id, handleVisible, handleDangerVisible)),
        // GetSalaryWithEmpIDApi: (id) => dispatch(GetSalaryWithEmpIDApi(id)),
        setSalaryFields: ({ key, value }) => dispatch(setSalaryFields({ key, value })),
        GetshiftControlApi: () => dispatch(GetshiftControlApi()),
    };
};
const mapStateToProps = (state, ownProps) => ({
    isFetchingSalaryDataById: state.SalaryReducer.isFetchingSalaryDataById,
    salaryListById: state.SalaryReducer.salaryListById,
    // isUpdatingSalary: state.HrReducer.isUpdatingSalary,
    // setFieldsConsoleVar: state.HrReducer.setFieldsConsoleVar,
    shiftControl: state.ShiftControlReducer.shiftControl,
});

export default connect(mapStateToProps, mapDispatchToProps)(EditSalary);