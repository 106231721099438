import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { AddMachineApi } from "./MachineAction";
import { setHeading } from "../../../accounts/account.actions";
import { getHallData } from "../../AddDetails/AddHall/Hall.action";
import _ from "lodash";
import Spinner from "react-bootstrap/Spinner";
import Swal from "sweetalert";
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import Select from "react-select";


const AddMachine = (props) => {
    const [hallId, setHallId] = useState();
    const [hallName, setHallName] = useState();
    const [machineName, setMachineName] = useState();
    const [description, setDescription] = useState();
    const [visibleAlert, setAlertVisible] = useState(false);
    const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);


    function SaveMachine() {
        props.AddMachineApi({
            hallId: hallId,
            hallName: hallName,
            machineName: machineName,
            description: description,
        },
            handleVisible,
            handleDangerVisible
        );
        setHallId("")
        setMachineName("")
        setDescription("")
    }
    const handleVisible = (msg) => {
        setAlertVisible(true);
        setTimeout(() => {
            setAlertVisible(false);
        }, 9000);
        Swal({
            title: "Success",
            text: msg,
            icon: "success",
            button: "Ok",
        });
    };
    const handleDangerVisible = (err) => {
        setDangerVisibleAlert(true);
        setTimeout(() => {
            setDangerVisibleAlert(false);
        }, 9000);
        Swal({
            title: "Error",
            text: err,
            icon: "error",
            button: "Ok",
        });
    };

    const handleChangeHall = (selectedOption) => {
        setHallId(selectedOption.value);
        setHallName(selectedOption.label);
    };
    const GetHallList = !_.isEmpty(props.hallDataList) && props.hallDataList.map((x) => {
        let data = { value: x._id, label: x.hallName };
        return data;
    });

    useEffect(() => {
        props.setHeading("Add Machine");
        props.getHallData()
    }, []);

    return (
        <>
            {props.isAddingMachine || props.isFetchingHall ? (
                <div className="loader-div">
                    <Spinner
                        variant="primary"
                        animation="border"
                        size="xl"
                        role="status"
                        aria-hidden="true"
                    />
                </div>
            ) : (
                <Container fluid>
                    <div className="main">
                        <Form>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="garana">
                                        <Form.Label><b>Select Hall *</b></Form.Label>
                                        <Select
                                            isDisabled={_.isEmpty(GetHallList)}
                                            placeholder="Select Hall"
                                            onChange={handleChangeHall}
                                            options={GetHallList}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="garana">
                                        <Form.Label><b>Machine Name *</b></Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Machine Name"
                                            onChange={(e) => setMachineName(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="garana">
                                        <Form.Label><b>Description</b></Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Description"
                                            onChange={(e) => setDescription(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <div className="sendDiv">
                                {_.isEmpty(hallId) || _.isEmpty(machineName) ? (
                                    <Button variant="contained" endIcon={<SendIcon />} size="large" disabled> SAVE </Button>
                                ) : (
                                    <Button variant="contained" endIcon={<SendIcon />} size="large" onClick={SaveMachine}> SAVE </Button>
                                )}
                            </div>

                        </Form>
                    </div>
                </Container>
            )}
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        AddMachineApi: (data, handleVisible, handleDangerVisible) => dispatch(AddMachineApi(data, handleVisible, handleDangerVisible)),
        setHeading: (data) => dispatch(setHeading(data)),
        getHallData: () => dispatch(getHallData()),
    };
};
const mapStateToProps = (state, ownProps) => ({
    isAddingMachine: state.MachineReducer.isAddingMachine,
    hallDataList: state.hallReducer.hallDataList,
    isFetchingHall: state.hallReducer.isFetchingHall,
});


export default connect(mapStateToProps, mapDispatchToProps)(AddMachine);