const initialState = {
    articleList: [],
    isFetchingArticle: false,
    isAddingArticle: false,
    isAddingSubArticle: false,
    subArticleList: [],
    isFetchingArticleWithID: false,
    articleListWithID: [],
    isAddingArticleRecipe: false,
    articleRecipeList: [],
    articlesWithCategory: [],
    isFetchingArticlesWithCategory: false,
    isFetchingArticleWithVariationID: false,
    articleListWithVariationID: [],
};

const ArticleReducer = (state = initialState, action) => {
    switch (action.type) {
        case "REQUEST_ADD_ARTICLE":
            return { ...state, isAddingArticle: true };
        case "SUCCESS_ADD_ARTICLE":
            return { ...state, articleList: action.payload.data, isAddingArticle: false };
        case "ERROR_ADD_ARTICLE":
            return { ...state, };


        case "REQUEST_ADD_ARTICLE_RECIPE":
            return { ...state, isAddingArticleRecipe: true };
        case "SUCCESS_ADD_ARTICLE_RECIPE":
            return { ...state, articleRecipeList: action.payload.data, isAddingArticleRecipe: false };
        case "ERROR_ADD_ARTICLE_RECIPE":
            return { ...state, };


        case "REQUEST_ADD_SUB_ARTICLE":
            return { ...state, isAddingSubArticle: true };
        case "SUCCESS_ADD_SUB_ARTICLE":
            return { ...state, subArticleList: action.payload.data, isAddingSubArticle: false };
        case "ERROR_ADD_SUB_ARTICLE":
            return { ...state, };


        case "REQUEST_GET_ARTICLE":
            return { ...state, isFetchingArticle: true };
        case "SUCCESS_GET_ARTICLE":
            return { ...state, articleList: action.payload, isFetchingArticle: false };
        case "ERROR_GET_ARTICLE":
            return { ...state, isFetchingArticle: false };


        case "REQUEST_ARTICLE_WITH_ID":
            return { ...state, isFetchingArticleWithID: true };
        case "SUCCESS_ARTICLE_WITH_ID":
            return { ...state, articleListWithID: action.payload.data, isFetchingArticleWithID: false };
        case "ERROR_ARTICLE_WITH_ID":
            return { ...state, };


        case "REQUEST_ARTICLES_WITH_CATEGORY":
            return { ...state, isFetchingArticlesWithCategory: true };
        case "SUCCESS_ARTICLES_WITH_CATEGORY":
            return { ...state, articlesWithCategory: action.payload, isFetchingArticlesWithCategory: false };
        case "ERROR_ARTICLES_WITH_CATEGORY":
            return { ...state, isFetchingArticlesWithCategory: false };


        case "REQUEST_ARTICLES_WITH_VARIATION_ID":
            return { ...state, isFetchingArticleWithVariationID: true };
        case "SUCCESS_ARTICLES_WITH_VARIATION_ID":
            return { ...state, articleListWithVariationID: action.payload.data, isFetchingArticleWithVariationID: false };
        case "ERROR_ARTICLES_WITH_VARIATION_ID":
            return { ...state, };


        default:
            return state;
    }
};
export default ArticleReducer;