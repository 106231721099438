import API from "../../../../../global/api";


export const requestAddMachine = () => {
    return {
        type: "REQUEST_ADD_MACHINE",
    };
};
export const successAddMachine = (data) => {
    return {
        type: "SUCCESS_ADD_MACHINE",
        payload: data,
    };
};
export const errorAddMachine = () => {
    return {
        type: "ERROR_ADD_MACHINE",
    };
};
export const AddMachineApi = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddMachine());
        API.post(`/machine`, data).then((res) => {
            dispatch(successAddMachine(data));
            handleVisible(res.data.message);
        }).catch((error) => {
            dispatch(errorAddMachine());
            handleDangerVisible(error.response.data.message);
        })
    };
};


export const requestGetMachine = () => {
    return {
        type: "REQUEST_GET_MACHINE",
    };
};
export const successGetMachine = (data) => {
    return {
        type: "SUCCESS_GET_MACHINE",
        payload: data,
    };
};
export const errorGetMachine = () => {
    return {
        type: "ERROR_GET_MACHINE",
    };
};
export const GetMachineApi = () => {
    return (dispatch) => {
        dispatch(requestGetMachine());
        API.get(`/machine`).then((res) => {
            let getData = res.data;
            dispatch(successGetMachine(getData));
        }).catch((error) => {
            dispatch(errorGetMachine());
        })
    };
};


export const requestDeleteMachine = () => {
    return {
        type: "REQUEST_DELETE_MACHINE",
    };
};
export const successDeleteMachine = (user_id) => {
    return {
        type: "SUCCESS_DELETE_MACHINE",
        user_id: user_id,
    };
};
export const errorDeleteMachine = () => {
    return {
        type: "ERROR_DELETE_MACHINE",
    };
};
export const DeleteMachineApi = (id) => {
    return dispatch => {
        dispatch(requestDeleteMachine());
        API.delete(`/machine/${id}`).then(res => {
            dispatch(successDeleteMachine(id))
        }).catch((error) => {
            dispatch(errorDeleteMachine())
        });
    }
};


export const requestUpdateMachine = () => {
    return {
        type: "REQUEST_UPDATE_MACHINE",
    };
};
export const successUpdateMachine = (data) => {
    return {
        type: "SUCCESS_UPDATE_MACHINE",
        payload: data,
    };
};
export const errorUpdateMachine = () => {
    return {
        type: "ERROR_UPDATE_MACHINE",
    };
};
export const UpdateMachineApi = (data, oldData) => {
    return dispatch => {
        dispatch(requestUpdateMachine());
        API.put(`/machine/${oldData._id}`, {
            machineName: data.machineName,
            description: data.description,
        }, {
        }).then(res => {
            dispatch(successUpdateMachine([data]))
        }).catch((error) => {
            dispatch(errorUpdateMachine());
        })
    }
};