const initialState = {
  CRMProductList: [],
  isFetchingCRMProduct: false,
  isAddingCRMProduct: false,
};

const CRMProductReducer = (state = initialState, action) => {
  switch (action.type) {

    case "REQUEST_ADD_PRODUCT_CRM":
      return { ...state, isAddingCRMProduct: true };
    case "SUCCESS_ADD_PRODUCT_CRM":
      return { ...state, CRMProductList: action.payload, isAddingCRMProduct: false, };
    case "ERROR_ADD_PRODUCT_CRM":
      return { ...state, isAddingCRMProduct: false };


    case "REQUEST_GET_CRM_PRODUCT":
      return { ...state, isFetchingCRMProduct: true, };
    case "SUCCESS_GET_CRM_PRODUCT":
      return { ...state, CRMProductList: action.payload.data, isFetchingCRMProduct: false };
    case "ERROR_GET_CRM_PRODUCT":
      return { ...state, };


    default:
      return state;
  }
};
export default CRMProductReducer;