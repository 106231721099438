const initialState = {
  isFetchingLedgerData: false,
  ledgerlist: [],
  ledgerSearchlist: [],
  isFetchingLevel5LedgerData: false,
  searchAccountLedgerTableList: [],
  isFetchingSearchAccountLedgerTable: false,
  level5LedgerData: [],
  isFetchingSearchAccountLedger: false,
  searchAccountLedgerList: [],
  searchAccountLedgerListReport: [],
  isFetchingSearchAccountLedgerReport: false,

};
const AccountLegderReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REQUEST_ADD_LEDGER":
      return { ...state, isFetchingLedgerData: true };
    case "SUCCESS_ADD_LEDGER":
      return {
        ...state,
        ledgerlist: action.payload.data,
        isFetchingLedgerData: false,
      };
    case "ERROR_ADD_LEDGER":
      return { ...state, isFetchingLedgerData: false };


    case "REQUEST_ADD_LEDGER_BY_DATE":
      return { ...state };
    case "SUCCESS_ADD_LEDGER_BY_DATE":
      return {
        ...state,
        ledgerSearchlist: action.payload.data,
      };
    case "ERROR_ADD_LEDGER_BY_DATE":
      return { ...state };


    case "REQUEST_LEVEL5_LEDGER_DATA":
      return { ...state, isFetchingLevel5LedgerData: true };
    case "SUCCESS_LEVEL5_LEDGER_DATA":
      return {
        ...state,
        level5LedgerData: action.payload,
        isFetchingLevel5LedgerData: false
      };
    case "ERROR_LEVEL5_LEDGER_DATA":
      return { ...state, isFetchingLevel5LedgerData: false };


    case "REQUEST_SEARCH_LEDGER":
      return { ...state, isFetchingSearchAccountLedger: true };
    case "SUCCESS_SEARCH_LEDGER":
      return {
        ...state,
        searchAccountLedgerList: action.payload.data,
        isFetchingSearchAccountLedger: false
      };
    case "ERROR_SEARCH_LEDGER":
      return { ...state, isFetchingSearchAccountLedger: false };

//ledger pdf
    case "REQUEST_SEARCH_LEDGER_REPORT":
      return { ...state, isFetchingSearchAccountLedgerReport: true };
    case "SUCCESS_SEARCH_LEDGER_REPORT":
      return {
        ...state,
        searchAccountLedgerListReport: action.payload,
        isFetchingSearchAccountLedgerReport: false
      };
    case "ERROR_SEARCH_LEDGER_REPORT":
      return { ...state, isFetchingSearchAccountLedgerReport: false };
//ledger table
case "REQUEST_SEARCH_LEDGER_TABLE":
  return { ...state, isFetchingSearchAccountLedgerTable: true };
case "SUCCESS_SEARCH_LEDGER_TABLE":
  return {
    ...state,
    searchAccountLedgerTableList: action.payload,
    isFetchingSearchAccountLedgerTable: false
  };
case "ERROR_SEARCH_LEDGER_TABLE":
  return { ...state, isFetchingSearchAccountLedgerTable: false };
      
    default:
      return state;
  }
};
export default AccountLegderReducer;
