import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { GetSTLedgerApi } from "./STAction";
import { GetEmployeeApi } from "../employee/employeeAction";
import { setHeading } from "../../../accounts/account.actions";
import { Link, Navigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import _ from "lodash";
import Select from "react-select";
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';



const SearchLedger = (props) => {
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [employeeId, setEmployeeId] = useState();
    const [employeeName, setEmployeeName] = useState();

    const getEmployeeList = !_.isEmpty(props.employeeList) && props.employeeList.map((x) => {
        let data = {
            value: x._id,
            label: `${x.employeeName} / ${x.fatherName} / ${x.cnic}`,
        };
        return data;
    });
    const handleChangeEmployee = (selectedOption) => {
        setEmployeeId(selectedOption.value);
        setEmployeeName(selectedOption.label);
    };

    useEffect(() => {
        props.GetEmployeeApi()
        props.setHeading("Short Term Ledger");
    }, []);

    function searchData() {
        props.GetSTLedgerApi({
            startDate: !_.isEmpty(startDate) ? startDate : "",
            endDate: !_.isEmpty(endDate) ? endDate : "",
            employeeId: employeeId,
        },
            startDate,
            endDate
        );
    }

    return (
        <>
            {props.isFetchingEmployeeData ? (
                <div className="loader-div">
                    <Spinner
                        animation="border"
                        size="xl"
                        role="status"
                        aria-hidden="true"
                        variant="info"
                    />
                </div>
            ) : (
                <Container fluid>
                    <div className="main">
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label><b>Start Date</b></Form.Label>
                                    <Form.Control
                                        type="date"
                                        value={startDate}
                                        onChange={(e) => setStartDate(e.target.value)}
                                    ></Form.Control>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label><b>End Date</b></Form.Label>
                                    <Form.Control
                                        type="date"
                                        value={endDate}
                                        onChange={(e) => setEndDate(e.target.value)}
                                    ></Form.Control>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label><b>Select Employee *</b></Form.Label>
                                    <Select
                                        placeholder="Select Employee"
                                        onChange={handleChangeEmployee}
                                        options={getEmployeeList}
                                        isDisabled={_.isEmpty(props.employeeList)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <div style={{ marginTop: '40px', textAlign: 'center' }}>
                            {_.isEmpty(employeeName) ? (
                                <Button variant="contained" endIcon={<SearchIcon />} size="large" disabled> SEARCH </Button>
                            ) : (
                                <Link to={{ pathname: "/hr/shortTerm/STLedgerData", state: { start: startDate, end: endDate } }}>
                                    <Button variant="contained" endIcon={<SearchIcon />} size="large" onClick={(e) => searchData()}> SEARCH </Button>
                                </Link>
                            )}
                        </div>

                    </div>
                </Container>
            )}
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        GetSTLedgerApi: (data, startDate, endDate) => dispatch(GetSTLedgerApi(data, startDate, endDate)),
        GetEmployeeApi: () => dispatch(GetEmployeeApi()),
        setHeading: (data) => dispatch(setHeading(data)),
    };
}
const mapStateToProps = (state, ownProps) => ({
    employeeList: state.EmployeeReducer.employeeList,
    isFetchingEmployeeData: state.EmployeeReducer.isFetchingEmployeeData,
    stLedger: state.STReducer.stLedger,
    isFetchingSTLedger: state.STReducer.isFetchingSTLedger,
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchLedger);