import React, { useState, useEffect } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { setHeading } from "../../../accounts/account.actions";
import { GetEmployeeApi } from "../employee/employeeAction";
import { AddLTRecivingApi } from "./LTAction";
import _ from "lodash";
import Spinner from "react-bootstrap/Spinner";
import Select from "react-select";
import Swal from 'sweetalert';
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';


const LTReciving = (props) => {
    const [employeeId, setEmployeeId] = useState();
    const [employeeName, setEmployeeName] = useState();
    const [date, setDate] = useState();
    const [accType, setAccType] = useState();
    const [amount, setAmount] = useState();
    const [deduction, setDeduction] = useState();
    const [description, setDescription] = useState();
    const [visibleAlert, setAlertVisible] = useState(false);
    const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);


    const SaveLTReceving = () => {
        props.AddLTRecivingApi({
            employeeId: employeeId,
            date: date,
            accType: accType,
            creditValue: Number(amount),
            deductionValue: Number(amount),
            description: description,
        },
            handleVisible,
            handleDangerVisible
        );
    };
    const handleVisible = (message) => {
        setAlertVisible(true)
        setEmployeeName("")
        setDate("")
        setAccType("")
        setAmount("")
        setDescription("")
        setDeduction("")
        setTimeout(() => {
            setAlertVisible(false)
        }, 9000);
        Swal({
            title: "Success",
            text: message,
            icon: "success",
            button: "Ok",
        });
    }
    const handleDangerVisible = (error) => {
        setDangerVisibleAlert(true)
        setTimeout(() => {
            setDangerVisibleAlert(false)
        }, 9000);
        Swal({
            title: "Error",
            text: error,
            icon: "error",
            button: "Ok",
        });
    }

    const getEmployeeList = !_.isEmpty(props.employeeList) && props.employeeList.map((x) => {
        let data = {
            value: x._id,
            label: `${x.employeeName} / ${x.fatherName} / ${x.cnic}`,
        };
        return data;
    });
    const handleChangeEmployee = (selectedOption) => {
        setEmployeeId(selectedOption.value);
        setEmployeeName(selectedOption.label);
    };

    useEffect(() => {
        props.GetEmployeeApi();
        props.setHeading("Receive Long Term Advance")
    }, []);

    return (
        <>
            {props.isFetchingEmployeeData || props.isAddingLTReciving ? (
                <div className="loader-div">
                    <Spinner
                        animation="grow"
                        size="xl"
                        role="status"
                        aria-hidden="true"
                        variant="info"
                    />
                </div>
            ) : (
                <Container fluid>
                    <div className="main">
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label><b>Select Employee *</b></Form.Label>
                                    <Select
                                        placeholder="Select Employee"
                                        onChange={handleChangeEmployee}
                                        options={getEmployeeList}
                                        isDisabled={_.isEmpty(props.employeeList)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label><b>Date *</b></Form.Label>
                                    <Form.Control
                                        type="date"
                                        placeholder="Date"
                                        onChange={(e) => setDate(e.target.value)}
                                    ></Form.Control>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label><b>Payment Method *</b> </Form.Label>
                                    <Select
                                        placeholder="Payment Method"
                                        onChange={(selectedOption) => { setAccType(selectedOption.label) }}
                                        options={[{ value: "Cash", label: "Cash" }, { value: "Bank", label: "Bank" }]}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label><b>Amount *</b></Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Amount"
                                        onChange={(e) => { setAmount(e.target.value); setDeduction(e.target.value) }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg="4" sm="12">
                                <Form.Group className="mb-3">
                                    <Form.Label><b>Deduction Value *</b></Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Deduction Value"
                                        defaultValue={deduction}
                                        onBlur={(e) => setDeduction(e.target.value)}
                                        disabled={true}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label><b>Description</b></Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Description"
                                        onChange={(e) => setDescription(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <div className="sendDiv">
                            {_.isEmpty(employeeName) || _.isEmpty(date) || _.isEmpty(accType) || _.isEmpty(amount) ? (
                                <Button variant="contained" endIcon={<SendIcon />} size="large" disabled> SAVE </Button>
                            ) : (
                                <Button variant="contained" endIcon={<SendIcon />} size="large" onClick={() => { SaveLTReceving(); }}> SAVE </Button>
                            )}
                        </div>

                    </div>
                </Container>
            )}
        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
        AddLTRecivingApi: (data, handleVisible, handleDangerVisible) => dispatch(AddLTRecivingApi(data, handleVisible, handleDangerVisible)),
        GetEmployeeApi: () => dispatch(GetEmployeeApi()),
        setHeading: (data) => dispatch(setHeading(data)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    isAddingLTReciving: state.LTReducer.isAddingLTReciving,
    employeeList: state.EmployeeReducer.employeeList,
    isFetchingEmployeeData: state.EmployeeReducer.isFetchingEmployeeData,
});

export default connect(mapStateToProps, mapDispatchToProps)(LTReciving);