import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { setHeading } from "../../../accounts/account.actions";
import { getCustomerData } from "../../AddDetails/AddCustomer/Customer.action";
import { GetBrandWithCustomerIDApi } from "../../AddDetails/Brand/Brand.action";
import { getSizeData } from "../../AddDetails/AddSize/Size.action";
import { getFlavorsData } from "../../AddDetails/AddColour/Colour.action";
import { getUOM } from "../../AddDetails/AddProduct/Product.action";
import { AddArticleApi } from "./ArticleAction";
import { uploadFilesApi } from "../../UploadFiles/UploadFilesAction";
import _ from "lodash";
import Spinner from "react-bootstrap/Spinner";
import Select from "react-select";
import Swal from "sweetalert";
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import ImageUploader from "react-images-upload";
import { getSubCategoryWithCategoryIdApi } from "../../AddDetails/subCategory/SubCategory.action";
import { getCategoryData } from "../../AddDetails/categories/Catagory.action";


const AddArticle = (props) => {
    const [customerId, setCustomerId] = useState();
    const [customerName, setCustomerName] = useState();
    const [brandId, setBrandId] = useState();
    const [brandName, setBrandName] = useState();
    const [uomId, setUomId] = useState();
    const [uomName, setUomName] = useState();
    const [categoryId, setCategoryId] = useState();
    const [categoryName, setCategoryName] = useState();
    const [subCategoryId, setSubCategoryId] = useState();
    const [subCategoryName, setSubCategoryName] = useState();
    const [sizeId, setSizeId] = useState("");
    const [sizeLabel, setSizeLabel] = useState("");
    const [colourId, setColorId] = useState("");
    const [colourLabel, setColourLabel] = useState("");
    const [manualVariationCode, setManualVariationCode] = useState("");
    const [articleName, setArticleName] = useState();
    const [cartonSize, setCartonSize] = useState("");
    const [packing, setPacking] = useState("");
    const [weight, setWeight] = useState("");
    const [composition, setComposition] = useState("");
    const [hSCode, setHSCode] = useState("");
    const [description, setDescription] = useState("");
    const [catlogNumber, setCatlogNumber] = useState("");
    const [customerCatalogueNumber, setCustomerCatalogueNumber] = useState("");
    const [exportCost, setExportCost] = useState("");
    const [currency, setCurrency] = useState("");
    const [conversionToPKR, setConversionToPKR] = useState(0);
    const [exportPrice, setExportPrice] = useState("");
    const [totalCost, setTotalCost] = useState(0);
    const [profit, setProfit] = useState(0);
    const [visibleAlert, setAlertVisible] = useState(false);
    const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);


    function SaveArticle() {
        props.AddArticleApi({
            customerId: customerId,
            customerName: customerName,
            brandId: brandId,
            brandName: brandName,
            uomId: uomId,
            uomName: uomName,
            categoryId: categoryId,
            categoryName: categoryName,
            subCategoryId: subCategoryId,
            subCategoryName: subCategoryName,
            articleName: articleName,
            cartonSize: cartonSize,
            packing: packing,
            weight: weight,
            composition: composition,
            hSCode: hSCode,
            description: description,
            catlogNumber: catlogNumber,
            customerCatalogueNumber: customerCatalogueNumber,
            exportCost: Number(exportCost),
            currency: currency,
            conversionToPKR: Number(conversionToPKR),
            exportPrice: Number(exportCost) * Number(conversionToPKR),
            articleTotalCost: totalCost,
            profit: profit,
            images: props.UploadFilesURL,
            variation: [{
                manualVariationCode: manualVariationCode,
                sizeId: sizeId,
                sizeLabel: sizeLabel,
                colourId: colourId,
                colourLabel: colourLabel,
            }],
        },
            handleVisible,
            handleDangerVisible
        );
        setCustomerId("")
        setBrandId("")
        setUomId("")
        setArticleName("")
        setCartonSize("")
        setPacking("")
        setWeight("")
        setComposition("")
        setHSCode("")
        setDescription("")
        setCatlogNumber("")
        setCustomerCatalogueNumber("")
        setExportCost("")
        setCurrency("")
        setConversionToPKR("")
        setExportPrice("")
        setTotalCost("")
        setProfit("")
        setSizeId("")
        setColorId("")
        setManualVariationCode("")
    }
    const handleVisible = (msg) => {
        setAlertVisible(true);
        setTimeout(() => {
            setAlertVisible(false);
        }, 9000);
        Swal({
            title: "Success",
            text: msg,
            icon: "success",
            button: "Ok",
        });
    };
    const handleDangerVisible = (err) => {
        setDangerVisibleAlert(true);
        setTimeout(() => {
            setDangerVisibleAlert(false);
        }, 9000);
        Swal({
            title: "Error",
            text: err,
            icon: "error",
            button: "Ok",
        });
    };


    const handleChangeCustomer = (selectedOption) => {
        setCustomerId(selectedOption.value);
        setCustomerName(selectedOption.label);
        props.GetBrandWithCustomerIDApi(selectedOption.value)
    };
    const getCustomerList = !_.isEmpty(props.customerDataList) && props.customerDataList.map((x) => {
        let data = { value: x._id, label: x.name };
        return data;
    });


    const handleChangeBrand = (selectedOption) => {
        setBrandId(selectedOption.value);
        setBrandName(selectedOption.label);
    };
    const getBrandList = !_.isEmpty(props.brandWithCustomerID) && props.brandWithCustomerID.map((x) => {
        let data = { value: x._id, label: x.brandName };
        return data;
    });


    const handleChangeUom = (selectedOption) => {
        setUomId(selectedOption.value);
        setUomName(selectedOption.label);
    };
    const getUomList = !_.isEmpty(props.productUomList) && props.productUomList.map((x) => {
        let data = { value: x._id, label: x.unitName };
        return data;
    });


    const handleChangeCategory = (selectedOption) => {
        setCategoryId(selectedOption.value);
        setCategoryName(selectedOption.label);
        props.getSubCategoryWithCategoryIdApi(selectedOption.value);
    };
    const getCategoryList = !_.isEmpty(props.categoryList) && props.categoryList.data.filter(catData => catData.categoryType === 'Production').map((x) => {
        let data = { value: x._id, label: x.name };
        return data;
    });


    const handleChangeSubCategory = (selectedOption) => {
        setSubCategoryId(selectedOption.value);
        setSubCategoryName(selectedOption.label);
    };
    const getSubCategoryList = !_.isEmpty(props.subCategoryListByCategoryId) && props.subCategoryListByCategoryId.map((x) => {
        let data = { value: x._id, label: x.subCategoryName };
        return data;
    });


    let currencyData = [{
        id: "USD",
        type: "USD",
    },
    {
        id: "Pound",
        type: "Pound",
    },
    {
        id: "Euro",
        type: "Euro",
    },
    {
        id: "Yen",
        type: "Yen",
    },
    {
        id: "PKR",
        type: "PKR",
    },
    {
        id: "Riyal",
        type: "Riyal",
    },
    {
        id: "Dinar",
        type: "Dinar",
    },
    {
        id: "Dirham",
        type: "Dirham",
    }];
    const handleChangeCurrency = (selectedOption) => {
        setCurrency(selectedOption.label);
    };
    const GetCurrencyList = currencyData.map((x) => {
        let data = { value: x.id, label: x.type };
        return data;
    });

    const onDrop = (pictureFiles) => {
        props.uploadFilesApi({
            image: pictureFiles[pictureFiles.length - 1],
        });
    };

    const handleChangeSize = (selectedOption) => {
        setSizeId(selectedOption.value);
        setSizeLabel(selectedOption.label);
    };
    const inventorySize = props.sizeDataList.map((x) => {
        let data = { value: x._id, label: x.sizeName };
        return data;
    });

    const handleChangeColor = (selectedOption) => {
        setColorId(selectedOption.value);
        setColourLabel(selectedOption.label);
    };
    const inventoryFlavor = props.flavorsDataList.map((x) => {
        let data = { value: x._id, label: x.colourName };
        return data;
    });


    useEffect(() => {
        props.getCustomerData()
        props.getSizeData()
        props.getFlavorsData()
        props.getUOM()
        props.getCategoryData()
        props.setHeading("New Article");
    }, []);

    return (
        <>
            {props.isFetchingCustomer || props.isFetchingBrand || props.isFetchingSize || props.isFetchingFlavors || props.isAddingArticle || props.isFetchingcategoryList ? (
                <div className="loader-div">
                    <Spinner
                        variant="primary"
                        animation="border"
                        size="xl"
                        role="status"
                        aria-hidden="true"
                    />
                </div>
            ) : (
                <Container fluid>
                    <Form>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" >
                                    <Form.Label><b>Select Category *</b></Form.Label>
                                    <Select
                                        placeholder="Select Category"
                                        isDisabled={_.isEmpty(getCategoryList)}
                                        onChange={handleChangeCategory}
                                        options={getCategoryList}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" >
                                    <Form.Label><b>Select Sub Category *</b></Form.Label>
                                    <Select
                                        placeholder="Select Sub Category"
                                        isDisabled={_.isEmpty(props.subCategoryListByCategoryId) || _.isEmpty(categoryId)}
                                        onChange={handleChangeSubCategory}
                                        options={getSubCategoryList}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label><b>Article Name *</b></Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={articleName}
                                        placeholder="Article Name"
                                        onChange={(e) => setArticleName(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label><b>Select Customer </b></Form.Label>
                                    <Select
                                        placeholder="Select Customer"
                                        onChange={handleChangeCustomer}
                                        options={getCustomerList}
                                        isDisabled={_.isEmpty(getCustomerList)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label><b>Select Brand </b></Form.Label>
                                    <Select
                                        placeholder="Select Brand"
                                        onChange={handleChangeBrand}
                                        options={getBrandList}
                                        isDisabled={_.isEmpty(customerId) || _.isEmpty(getBrandList)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label><b>Select UOM *</b></Form.Label>
                                    <Select
                                        placeholder="Select UOM"
                                        onChange={handleChangeUom}
                                        options={getUomList}
                                        isDisabled={_.isEmpty(getUomList)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label><b>Carton Size </b></Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Carton Size"
                                        onChange={(e) => setCartonSize(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label><b>Packing Quantity </b></Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Packing Quantity"
                                        onChange={(e) => setPacking(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label><b>Weight </b></Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={weight}
                                        placeholder="Weight"
                                        onChange={(e) => setWeight(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label><b>Composition </b></Form.Label>
                                    <Form.Control
                                        value={composition}
                                        type="text"
                                        placeholder="Composition"
                                        onChange={(e) => setComposition(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label><b>HS Code </b></Form.Label>
                                    <Form.Control
                                        value={hSCode}
                                        type="number"
                                        placeholder="HS Code"
                                        onChange={(e) => setHSCode(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label><b>Description</b></Form.Label>
                                    <Form.Control
                                        value={description}
                                        type="text"
                                        placeholder="Description"
                                        onChange={(e) => setDescription(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label><b>Catalogue Number </b></Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Catalogue Number"
                                        onChange={(e) => setCatlogNumber(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label><b>Customer Catalogue Number </b></Form.Label>
                                    <Form.Control
                                        // value={customerCode}
                                        type="number"
                                        placeholder="Customer Catalogue Number"
                                        onChange={(e) => setCustomerCatalogueNumber(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col></Col>
                        </Row>
                        <Row style={{ backgroundColor: '#aed6ae', padding: '10px', borderRadius: '20px' }}>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label><b>Variation Manual Code *</b> </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Variation Manual Code"
                                        onChange={(e) => setManualVariationCode(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label><b> Select Color *</b></Form.Label>
                                    <Select
                                        isDisabled={_.isEmpty(inventoryFlavor)}
                                        placeholder="Select Color"
                                        onChange={handleChangeColor}
                                        options={inventoryFlavor}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label><b> Select Size *</b></Form.Label>
                                    <Select
                                        isDisabled={_.isEmpty(inventorySize)}
                                        placeholder="Select Size"
                                        onChange={handleChangeSize}
                                        options={inventorySize}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label><b>Export Cost *</b></Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Export Cost"
                                        onChange={(e) => setExportCost(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label><b>Select Currency </b></Form.Label>
                                    <Select
                                        placeholder="Select Currency"
                                        onChange={handleChangeCurrency}
                                        options={GetCurrencyList}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label><b>Conversion To (PKR) </b></Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Conversion To PKR"
                                        onChange={(e) => setConversionToPKR(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label><b>Export Price (PKR) </b></Form.Label>
                                    <Form.Control
                                        value={exportCost * conversionToPKR}
                                        disabled
                                        type="number"
                                        placeholder="Export Price (PKR)"
                                        onChange={(e) => setExportPrice(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label><b>Total Cost </b></Form.Label>
                                    <Form.Control
                                        disabled
                                        type="number"
                                        placeholder="Total Cost"
                                        onChange={(e) => setTotalCost(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label><b>Article Profit </b></Form.Label>
                                    <Form.Control
                                        disabled
                                        type="number"
                                        placeholder="Article Profit"
                                        onChange={(e) => setProfit(e.target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col></Col>
                        </Row>
                        <Row style={{ marginTop: '20px' }}>
                            <Col xl="12" lg="12" md="12" sm="12" xs="12">
                                <Form.Group className="mb-3">
                                    <Form.Label><b style={{ fontSize: "large" }}>Upload Article Images</b></Form.Label>
                                    <ImageUploader
                                        withIcon={false}
                                        buttonText="Upload Article Images"
                                        onChange={onDrop}
                                        imgExtension={[".jpg", ".jpeg", ".png"]}
                                        maxFileSize={999999999999999999999}
                                        singleImage={false}
                                        withPreview={true}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <div className="sendDiv">
                            {_.isEmpty(categoryId) || _.isEmpty(subCategoryId) || _.isEmpty(uomId) || _.isEmpty(exportCost) || _.isEmpty(articleName) || _.isEmpty(manualVariationCode) || _.isEmpty(sizeId) || _.isEmpty(colourId) ? (
                                <Button variant="contained" endIcon={<SendIcon />} size="large" disabled> SAVE </Button>
                            ) : (
                                <Button variant="contained" endIcon={<SendIcon />} size="large" onClick={SaveArticle}> SAVE </Button>
                            )}
                        </div>

                    </Form>
                </Container>
            )}
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        AddArticleApi: (data, handleVisible, handleDangerVisible) => dispatch(AddArticleApi(data, handleVisible, handleDangerVisible)),
        getCustomerData: () => dispatch(getCustomerData()),
        GetBrandWithCustomerIDApi: (id) => dispatch(GetBrandWithCustomerIDApi(id)),
        getSizeData: () => dispatch(getSizeData()),
        getFlavorsData: () => dispatch(getFlavorsData()),
        getUOM: () => dispatch(getUOM()),
        getCategoryData: () => dispatch(getCategoryData()),
        setHeading: (data) => dispatch(setHeading(data)),
        uploadFilesApi: (data) => dispatch(uploadFilesApi(data)),
        getSubCategoryWithCategoryIdApi: (id) => dispatch(getSubCategoryWithCategoryIdApi(id)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    isAddingArticle: state.ArticleReducer.isAddingArticle,
    customerDataList: state.CustomerReducer.customerDataList,
    isFetchingCustomer: state.CustomerReducer.isFetchingCustomer,
    brandWithCustomerID: state.brandReducer.brandWithCustomerID,
    // isFetchingBrand: state.brandReducer.isFetchingBrand,
    sizeDataList: state.sizeReducer.sizeDataList,
    isFetchingSize: state.sizeReducer.isFetchingSize,
    flavorsDataList: state.ColourReducer.flavorsDataList,
    isFetchingFlavors: state.ColourReducer.isFetchingFlavors,
    productUomList: state.ProductReducer.productUomList,
    UploadFilesURL: state.UploadFilesReducer.UploadFilesURL,
    isFetchingcategoryList: state.CategoryReducer.isFetchingcategoryList,
    categoryList: state.CategoryReducer.categoryList,
    subCategoryListByCategoryId: state.SubCategoryReducer.subCategoryListByCategoryId,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddArticle);
