import * as React from 'react';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
import HomeIcon from '@mui/icons-material/Home';
import { Link } from 'react-router-dom';
import { socket } from '../../../socket';
import Button from '@mui/material/Button';


export default function IconMenu() {

  const handleLogout = () => {
    window.localStorage.clear();
    socket.disconnect();
    window.location.replace('/login');
  }

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Account settings">
          <Link to="/home">
            <Button variant="contained" endIcon={<HomeIcon />} size="small" style={{ marginRight: '10px', padding: '7px' }}> Home </Button>
          </Link>
          <Link onClick={() => handleLogout()}>
            <Button variant="contained" endIcon={<Logout />} size="small" style={{ padding: '7px' }}> LogOut </Button>
          </Link>
        </Tooltip>
      </Box>
    </React.Fragment>
  );
}