import React, { useEffect } from "react";
import { Form, Container } from "react-bootstrap";
import { connect } from "react-redux";
import MaterialTable from 'material-table';
import { baseURL, headers } from "../../../../../global/api";
import { UpdateFineApi, DeleteFineApi, GetFineApi } from "./FineAndPenaltyAction";
import { setHeading } from "../../../accounts/account.actions";


const FineList = (props) => {
    useEffect(() => {
        props.GetFineApi()
        props.setHeading("Fine & Penaltiess List");
    }, []);

    return (
        <Container fluid>
            <Form>
                <MaterialTable
                    title="Fine & Penalties"
                    columns={[
                        { title: 'Employee', field: 'employeeDetails.employeeName', editable: 'never' },
                        { title: 'Reason', field: 'reasonFine' },
                        { title: 'Amount Deducted', field: 'amountDeduct' },
                        { title: 'Status', field: 'status', editable: 'never' },
                        { title: 'Date', field: 'date' },
                    ]}
                    data={props.fineDataList.data}
                    options={{
                        actionsColumnIndex: -1,
                        exportButton: true,
                        filtering: true,
                        exportAllData: true,
                        paging: true,
                        pageSize: 50,
                        emptyRowsWhenPaging: false,
                        pageSizeOptions: [50, 100, 150, 200],
                        headerStyle: {
                            position: "sticky",
                            top: 0,
                            color: "#00BBBB",
                            fontWeight: "550",
                            onRowAdd: "none",
                        },
                    }}
                    editable={{
                        isEditable: (rowData) => rowData.status === 'Deducted' ? false : true,
                        isDeletable: (rowData) => rowData.status === 'Deducted' ? false : true,
                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve) => {
                                setTimeout(() => {
                                    resolve();
                                    props.UpdateFineApi(newData, oldData)
                                }, 600);
                            }),
                        onRowDelete: (oldData) =>
                            new Promise((resolve) => {
                                setTimeout(() => {
                                    resolve();
                                    props.DeleteFineApi(oldData._id);
                                }, 600);
                            }),
                    }}
                />
            </Form>
        </Container>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        UpdateFineApi: (newData, oldData) => dispatch(UpdateFineApi(newData, oldData)),
        DeleteFineApi: (id) => dispatch(DeleteFineApi(id)),
        GetFineApi: () => dispatch(GetFineApi()),
        setHeading: (data) => dispatch(setHeading(data)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    fineDataList: state.FineReducer.fineDataList,
    isFetchingFineData: state.FineReducer.isFetchingFineData
});

export default connect(mapStateToProps, mapDispatchToProps)(FineList);