import API from "../../../../../global/api";


export const requestAddFine = () => {
    return {
        type: "REQUEST_ADD_FINE",
    };
};
export const successAddFine = (data) => {
    return {
        type: "SUCCESS_ADD_FINE",
        payload: data,
    };
};
export const errorAddFine = () => {
    return {
        type: "ERROR_ADD_FINE",
    };
};
export const AddFineApi = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddFine());
        API.post(`/fineAndPenalties`, data).then((res) => {
            dispatch(successAddFine(data));
            handleVisible(res.data.message);
        }).catch((error) => {
            dispatch(errorAddFine());
            handleDangerVisible(error.response.data.message);
        });
    };
};


export const requestGetFine = () => {
    return {
        type: "REQUEST_GET_FINE",
    };
};
export const successGetFine = (data) => {
    return {
        type: "SUCCESS_GET_FINE",
        payload: data,
    };
};
export const errorGetFine = () => {
    return {
        type: "ERROR_GET_FINE",
    };
};
export const GetFineApi = () => {
    return (dispatch) => {
        dispatch(requestGetFine());
        API.get(`/fineAndPenalties`).then((res) => {
            let getData = res.data;
            dispatch(successGetFine(getData));
        }).catch((error) => {
            dispatch(errorGetFine());
        })
    };
};


export const requestDeleteFine = () => {
    return {
        type: "REQUEST_DELETE_FINE",
    };
};
export const successDeleteFine = (id) => {
    return {
        type: "SUCCESS_DELETE_FINE",
        fine_and_plenties_id: id,
    };
};
export const errorDeleteFine = () => {
    return {
        type: "ERROR_DELETE_FINE",
    };
};
export const DeleteFineApi = (id) => {
    return dispatch => {
        dispatch(requestDeleteFine());
        API.delete(`/fineAndPenalties/${id}`)
            .then(res => {
                dispatch(successDeleteFine(id))
            }).catch((error) => {
                dispatch(errorDeleteFine());
            })
    }
};


export const requestUpdateFine = () => {
    return {
        type: "REQUEST_UPDATE_FINE",
    };
};
export const successUpdateFine = (newData) => {
    return {
        type: "SUCCESS_UPDATE_FINE",
        payload: newData,
    };
};
export const errorUpdateFine = () => {
    return {
        type: "ERROR_UPDATE_FINE",
    };
};
export const UpdateFineApi = (newData, oldData) => {
    return (dispatch) => {
        dispatch(requestUpdateFine());
        API.put(`/fineAndPenalties/${oldData._id}`, newData).then((res) => {
            dispatch(successUpdateFine(newData));
        }).catch((error) => {
            dispatch(errorUpdateFine());
        });
    };
};