import API from "../../../../../global/api";


export const requestUpdateRestaurant = () => {
    return {
        type: "REQUEST_UPDATE_RESTAURANT",
    };
};
export const successUpdateRestaurant = (data) => {
    return {
        type: "SUCCESS_UPDATE_RESTAURANT",
        payload: data,
    };
};
export const errorUpdateRestaurant = () => {
    return {
        type: "ERROR_UPDATE_RESTAURANT",
    };
};
export const AddFBRIntegration = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestUpdateRestaurant());
        API.post(`/restaurant/add_FBR_credential`, data).then((res) => {
            //  dispatch(getRestaurantData());
            dispatch(successUpdateRestaurant(data));
            handleVisible(res.data.message);
        }).catch((error) => {
            dispatch(errorUpdateRestaurant());
            handleDangerVisible(error.response.data.message);
        })
    };
};