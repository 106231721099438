import React, { useEffect, useState } from "react";
import { Row, Col, Form, Table, Container, Navbar } from "react-bootstrap";
import "../add-details.css";
import { ModalBody } from "reactstrap";
import Modal from "react-bootstrap/Modal";
import { connect } from "react-redux";
import { isEmpty, find, isUndefined, sumBy, reverse, isNull, filter, } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import moment from "moment";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { GetOrderById, OrderCancel, OrderEdit, OrderEditRoute, filterOrder, filterOrderByTable, filterType, getCompleteOrders, getFilterOrders, getOrders, getPendingOrder, getTodayOrders, } from "./POSView.action";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import MailIcon from "@mui/icons-material/Mail";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SearchIcon from "@mui/icons-material/Search";
import PaidIcon from "@mui/icons-material/Paid";
import PrintIcon from "@mui/icons-material/Print";
import ChangeCircle from "@mui/icons-material/ChangeCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import AppBar from "@mui/material/AppBar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import SettingsIcon from "@mui/icons-material/Settings";
import IconMenu from "../../../drawer/IconMenu";
import EditIcon from "@mui/icons-material/Edit";
import { getOrderPdfReport, getOrderPrintReport, } from "../Reports/Report.action";
import { updateStatus } from "../KDS/KDSView.action";
import swal from "sweetalert";
import { Image } from "@material-ui/icons";
import { Chip } from "@mui/material";
import { filterItem } from "../Recipe/Recipe.action";
import TableView from "./TableView";
import TableShift from "./TableShift";
import { getRestaurantData } from "../AddResturant/Resturant.action";
import { getCoupons } from "../AddCoupons/Coupons.action";
import TaskIcon from '@mui/icons-material/Task';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';


const ViewOrder = (props) => {
  const { state } = useLocation();
  const [Customer, setCustomer] = useState("");
  const [Date, setDate] = useState("");
  const [data, setData] = useState([]);
  const [idEdit, setId] = useState("");
  const [Phone, setPhone] = useState("");
  const [Email, setEmail] = useState("");
  const [total, setTotal] = useState("");
  const [status, setStatus] = useState("");
  const [discount, setDiscount] = useState("");
  const [tax, setTax] = useState("");
  const [reason, setReason] = useState("");
  const [user, setUser] = useState("");
  const [search, setSearch] = useState("");
  const [searchTable, setSearchTable] = useState("");
  const [taxLabel, setTaxLabel] = useState(0);
  const [orderType, setOrderType] = useState("");
  const [orderCancelReason, setorderCancelReason] = useState("");
  const [coupon, setCoupon] = useState("");
  const [couponType, setCouponType] = useState("");
  const [couponDiscount, setCouponDiscount] = useState(0);
  const [orderId, setOrderId] = useState("");
  const [tableId, setTableId] = useState("");
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  const [list, setList] = useState([]);
  //const [isSelect, setIsSelect] = useState(0);
  const [Item, setItem] = useState([]);
  const [orderData, setOrderData] = useState();
  const [customerModal, setCustomerModal] = useState(false);
  const [shiftModal, setShiftModal] = useState(false);
  const [cash, setCash] = useState(0);
  const [card, setCard] = useState(0);
  const [taxAmount, setTaxAmount] = useState(0);
  const [afterTaxTotalAmount, setAfterTaxTotalAmount] = useState(isEmpty(props.OrderEdit) ? 0 : props.OrderEdit.SubTotalAmount);
  const [show1, setShow1] = useState(false);
  const [orderCancelModal, setOrderCancelModal] = useState(false);
  const [couponModal, setCouponModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);


  const navigate = useNavigate();
  const handleCloseCustomer = () => setCustomerModal(false);
  const handleCloseShift = () => setShiftModal(false);
  const handleShowShift = () => setShiftModal(true);
  const handleShowCoupon = () => setCouponModal(true);
  const handleCloseCoupon = () => setCouponModal(false);
  const handleCloseOrderCancel = () => setOrderCancelModal(false);
  const handleShowOrderCancel = () => setOrderCancelModal(true);
  // const handleClickCoupon = (selectedOption) => {
  //   setSubTotalAmount(totalAmount);
  //   console.log(selectedOption, "click ho");
  //   setCoupon(selectedOption.value);
  //   setCouponLabel(selectedOption.label);
  //   setAmount(selectedOption.amount);
  //   setCouponType(selectedOption.type);
  //   let dis =
  //     selectedOption.type == "fixed"
  //       ? selectedOption.amount
  //       : totalAmount * selectedOption.amount * 0.01;
  //   setCouponDiscount(
  //     selectedOption.type == "fixed"
  //       ? selectedOption.amount
  //       : totalAmount * selectedOption.amount * 0.01
  //   );
  //   // console.log(selectedOption.type,"llllllll",discount)
  //   setSubTotalAmount(totalAmount - dis);
  //   handleCloseCoupon()
  // };
  // const handleDeleteCoupon = () => {
  //   console.log("delete ho");
  //   setCoupon("");
  //   setCouponLabel("");
  //   setCouponDiscount(0)
  //   setSubTotalAmount(totalAmount);
  // };
  //   const OrderData12 = (item) => {
  //   props.searchTodayOrdersDataList.filter()
  // }
  const OrderData = (item) => {
    setOrderData(item);
    setOrderId(item._id);
    // !isNull(item.tableId) && setTableId(item.tableId._id);
    setData(item);
    setItem(item.items);
    setId(item._id);
    setDate(moment(item.createdAt).format("llll")); //moment().format('MMMM Do YYYY, h:mm:ss a');
    setCustomer(item.customer.name);
    setDiscount(item.discount);
    setTax(item.tax);
    // setUser(item.waiterId.userName);
    setOrderType(item.orderType);
    setorderCancelReason(item.orderCancelReason);
    setPhone(item.customer.phone);
    setEmail(item.customer.email);
    setStatus(item.status);
    setTotal(item.grandTotal);
    //  setIsSelect(1)
  };
  const handleVisible = () => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    swal({
      title: "Success",
      text: "Order Status update Successfully",
      icon: "success",
      button: "Ok",
    });
  };
  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    swal({
      title: "Error",
      text: "Something went Wrong!",
      icon: "error",
      button: "Ok",
    });
  };
  const checkTrue = (item) => {
    //   isEmpty(item.orderDetail)?navigate('/pos',{state:item.tableData._id}):
    //   navigate('/pos/Editpos',{state:{id:item.orderDetail._id}})
    //  // (e) => navigate('/pos',{state:item._id})
  };
  const getDiscountList = !isEmpty(props.couponsDataList) ? props.couponsDataList.map((x) => {
    let data = {
      value: x._id,
      label: x.name,
      type: x.type,
      amount: x.amount,
    };
    return data;
  }) : [];


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const editPos = (status) => {
    if (status == "Pending") {
      navigate("/pos/Editpos", { state: { id: idEdit } });
    }
    else {
      navigate("/pos/EditposCooking", { state: { id: idEdit } });
    }
  };


  const handlePaymentType = (type) => {
    if (props.restaurantList[0].taxType == "FBRTax") {
      if (type == "cash") {
        setCash(1);
        setCard(0);
        setTaxLabel(props.restaurantList[0].fbrTaxCash);
        let taxCash = (Number(orderData.grandTotal) * Number(props.restaurantList[0].fbrTaxCash)) / 100;
        setTaxAmount(taxCash);
        setAfterTaxTotalAmount(Number(taxCash) + Number(orderData.grandTotal));
      } else {
        setCard(1);
        setCash(0);
        setTaxLabel(props.restaurantList[0].fbrTaxCard);
        let taxCard = (Number(orderData.grandTotal) * Number(props.restaurantList[0].fbrTaxCard)) / 100;
        setTaxAmount(taxCard);
        setAfterTaxTotalAmount(Number(taxCard) + Number(orderData.grandTotal));
      }
    } else {
      if (type == "cash") {
        setCash(1);
        setCard(0);
        setTaxLabel(props.restaurantList[0].praTaxCash);
        let taxCash = (Number(orderData.grandTotal) * Number(props.restaurantList[0].praTaxCash)) / 100;
        setTaxAmount(taxCash);
        setAfterTaxTotalAmount(taxCash + orderData.grandTotal);
      } else {
        setCard(1);
        setCash(0);
        setTaxLabel(props.restaurantList[0].praTaxCard);
        let taxCard = (Number(orderData.grandTotal) * Number(props.restaurantList[0].praTaxCard)) / 100;
        setTaxAmount(taxCard);
        setAfterTaxTotalAmount(taxCard + orderData.grandTotal);
      }
    }
  };
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  function openPaymentMethodModal() {
    setCard(0);
    setCard(0);
    handleShow1();
  }

  useEffect(() => {
    props.getResturant();
    props.getOrder();
  }, []);

  const permissions = JSON.parse(window.localStorage.getItem("permissions"));
  const Checkpermissions = (permissionsType, permissionsName) => {
    return permissions.some((p) => p[`${permissionsType}`] === permissionsName) ? true : false;
  };

  return (
    <>
      <div>
        <Navbar style={{ backgroundColor: "#81abd3" }}>
          <Container>
            <Navbar.Brand style={{ color: "white" }}>
              <b>Order View</b>
            </Navbar.Brand>
            <IconMenu />
          </Container>
        </Navbar>
      </div>
      {props.isFetchingTodayOrdersData ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            { props.isFetchingTodayOrdersData ? "Loading..." : "Saving..."}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <Row style={{ height: "auto", overflow: "hidden" }} >

            {/* ORDER LIST SIDE */}
            <Col xl="4" lg="4" md="4" sm="4" style={{ borderRightStyle: "solid", borderRightColor: "#e9ecef", marginTop: "15px" }}>
              {/* <Row>
                <Col xl="2" lg="2" md="2" sm="5">
                  <Button style={{ backgroundColor: "#81abd3", marginBottom: "2px" }}
                    onClick={() => props.getCompleteOrder("Pending")}
                  // onClick={() => filter(props.todayOrdersDataList.data, { status: "Pending" })}
                  >
                    Pending
                  </Button>
                </Col>
                <Col xl="3" lg="3" md="3" sm="6" style={{ marginLeft: "10px" }} >
                  <Button style={{ backgroundColor: "#81abd3" }}
                    onClick={() => props.getCompleteOrder("Complete")}
                  // onClick={() => filter(props.todayOrdersDataList.data,{status:"Complete"})}
                  >
                    Complete
                  </Button>
                </Col>
                <Col xl="2" lg="2" md="2" sm="4" >
                  <Button style={{ backgroundColor: "#81abd3" }}
                    onClick={() => props.getCompleteOrder("Served")}
                  // filter(props.todayOrdersDataList.data,{status:"Complete"})
                  >
                    Served
                  </Button>
                </Col>
                <Col xl="2" lg="2" md="2" sm="4" >
                  <Button style={{ backgroundColor: "#81abd3" }}
                    onClick={() => props.getCompleteOrder("Cancelled")}
                  // filter(props.todayOrdersDataList.data,{status:"Complete"})}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col xl="2" lg="2" md="2" sm="4" >
                  <Button style={{ backgroundColor: "#81abd3" }}
                    onClick={() => props.getOrder()}
                  >
                    All
                  </Button>
                </Col>
              </Row> */}

              <Row>
                <Col xl="12" lg="12" md="12" sm="12" className="mt-2 mb-2">
                  <Form.Group>
                    <Form.Control
                      type="text"
                      placeholder="Search Order"
                      name="receivedBy"
                      value={search}
                      onChange={(e) => {
                        setSearchTable("")
                        props.searchOrder(e.target.value);
                        setSearch(e.target.value)
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              {/* <Row>
                <Col xl="12" lg="12" md="12" sm="12" className="mt-2 mb-2">
                  <Form.Group>
                    <Form.Control
                      type="text"
                      placeholder="Search Order With TableID"
                      value={searchTable}
                      name="receivedBy"
                      onChange={(e) => {
                        setSearch("")
                        props.searchOrderbyTable(e.target.value);
                        setSearchTable(e.target.value)
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row> */}

              <Row>
                <Col xl="3" lg="3" md="3" sm="3" style={{ marginLeft: "2%", fontSize: "14px" }} >
                  <b>Order Number</b>
                </Col>
                <Col xl="3" lg="3" md="3" sm="3" style={{ marginLeft: "2%" }}>
                  <b>Status</b>
                </Col>
                {/* <Col xl="2" lg="2" md="2" sm="2" style={{ marginLeft: "3%" }}>
                  <b>Type</b>
                </Col> */}
                {/* <Col xl="3" lg="3" md="3" sm="3">
                  <b>Table</b>
                </Col> */}
                <Col xl="3" lg="3" md="3" sm="3" >
                  <b>Bill Amount</b>
                </Col>
              </Row>

              <Row style={{ height: '78vh', overflow: "auto", }} >
                <Col xl="12" lg="12" md="12" sm="12">
                  {!isEmpty(props.searchTodayOrdersDataList) ? props.searchTodayOrdersDataList.map((item, i) => {
                    return (
                      <React.Fragment key={i}>
                        <Row>
                          <Col xl="12" lg="12" md="12" sm="12">
                            <Card
                              style={{ marginTop: "1%", height: "45px", backgroundColor: item._id == idEdit ? "#c1c1c1" : "#81abd3", marginLeft: '-10px', marginRight: '-10px', }}
                              onClick={(e) => OrderData(item)}
                            >
                              <CardContent>
                                <Typography gutterBottom style={{ marginTop: "-10px" }}>
                                  <Row >
                                    <Col xl="3" lg="3" md="3" sm="3" >
                                      <b><Chip label={item.number} style={{ backgroundColor: "#c1c1c1", }} />{" "} </b>
                                    </Col>
                                    <Col xl="3" lg="3" md="3" sm="3" style={{ marginLeft: '-5%' }}>
                                      <b >
                                        <Chip label={item.status}
                                          color={item.status == "Cancelled" ? "error" : item.status == "Served" ? "warning" : item.status == "Complete" ? "success" : "primary"}
                                        />
                                      </b>
                                    </Col>
                                    {/* <Col xl="3" lg="3" md="3" sm="5" >
                                      <b >
                                        {item.orderType}
                                      </b>
                                    </Col> */}
                                    {/* <Col xl="3" lg="3" md="3" sm="5" >
                                      {item && item.orderType == "Dine In" && (
                                        <Chip
                                          style={{ marginLeft: "3px", marginRight: "3px", }}
                                          label={  !isNull(item.tableId) ? item.tableId.tableName : "N/A"}
                                          color={"primary"}
                                        />
                                      )}
                                    </Col> */}
                                    <Col xl="3" lg="3" md="3" sm="3" >
                                      <b > {item.grandTotal} </b>
                                    </Col>
                                  </Row>
                                </Typography>
                              </CardContent>
                            </Card>
                          </Col></Row>
                      </React.Fragment>
                    );
                  })
                    : "No Order Found"}
                </Col>
              </Row>
            </Col>

            {/* ORDER DETAIL SIDE */}
            <Col xl="8" lg="8" md="8" sm="8" hidden={Customer == "" ? true : false} style={{ marginTop: "25px" }} >
              <Row style={{ float: 'right', marginRight: '46px' }}>
                <Col>
                  <Button style={{ backgroundColor: '#81abd3', color: 'white' }} onClick={() => { props.orderPrint(idEdit); }} >
                    <PrintIcon style={{ fontSize: "26px", cursor: "pointer" }} />
                  </Button>
                </Col>
                <Col>
                  <Button style={{ backgroundColor: '#81abd3', color: 'white' }} onClick={() => { props.orderPDF(idEdit) }} >
                    <PictureAsPdfIcon style={{ fontSize: "26px", cursor: "pointer" }} />
                  </Button>
                </Col>
                <Col>
                  <Button style={{ backgroundColor: '#81abd3', color: 'white' }} disabled={status == "Complete" || status == "Cancelled" ? true : false} onClick={() => { editPos(status); }} >
                    <EditIcon style={{ fontSize: "26px", cursor: "pointer" }} />
                  </Button>
                </Col>
                {Checkpermissions("permissionsCatName", "Order Cancel") && (
                  <Col>
                    <Button style={{ backgroundColor: '#81abd3', color: 'white' }} disabled={status == "Cancelled" ? true : false} onClick={() => { handleShowOrderCancel(); setCustomer(""); }}>
                      <CancelIcon style={{ fontSize: "26px", cursor: "pointer" }} />
                    </Button>
                  </Col>
                )}
              </Row>

              <Row>
                <Col xl="10" lg="10" md="10" sm="10">
                  {/* <Row>
                    <Col xl="3" lg="3" md="3" sm="3">
                      <Form.Label> <b>Waiter:</b></Form.Label>
                    </Col>
                    <Col>
                      <b style={{ color: "#007BFF", fontSize: "16px", }} >{user}</b>
                    </Col>
                  </Row> */}
                  <Row>
                    <Col xl="3" lg="3" md="3" sm="3">
                      <Form.Label><b>Customer:</b> </Form.Label>
                    </Col>
                    <Col >
                      <b style={{ color: "#007BFF", fontSize: "16px", }} >{Customer}</b>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="3" lg="3" md="3" sm="3">
                      <Form.Label> <b>DateTime:</b> </Form.Label>
                    </Col>
                    <Col >
                      <b style={{ color: "#007BFF", fontSize: "16px" }}> {Date} </b>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="3" lg="3" md="3" sm="3" >
                      <Form.Label> <b>Discount:</b> </Form.Label>
                    </Col>
                    <Col>
                      <b style={{ color: "#007BFF", fontSize: "16px" }}>{discount}/-</b>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="3" lg="3" md="3" sm="3">
                      <Form.Label> <b>Tax Amount:</b> </Form.Label>
                    </Col>
                    <Col >
                      <b style={{ color: "#007BFF", fontSize: "16px" }}>{tax}/-</b>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="3" lg="3" md="3" sm="3">
                      <Form.Label> <b>Total Bill:</b> </Form.Label>
                    </Col>
                    <Col>
                      <b style={{ color: "#007BFF", fontSize: "18px" }}>{total}/-</b>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col xl="1" lg="1" md="1" sm="1"></Col>
                <Col xl="6" lg="6" md="6" sm="6" >
                  <b>Product</b>
                </Col>
                <Col xl="1" lg="1" md="1" sm="1" style={{ marginLeft: "-3%" }}>
                  <b>Quantity</b>
                </Col>
                <Col xl="2" lg="2" md="2" sm="2">
                  <b>Price</b>
                </Col>
                <Col xl="2" lg="2" md="2" sm="2" style={{ marginLeft: "-2%" }}>
                  <b>Total</b>
                </Col>
              </Row>

              {Item.map((item, i) => {
                return (
                  <Row>
                    <Col xl="1" lg="1" md="1" sm="1"></Col>
                    <Col xl="11" lg="11" md="11" sm="11" style={{ marginLeft: "-5%" }} >
                      <Card style={{ marginTop: "1%", height: "45px", backgroundColor: "#c1c1c1", }}>
                        <Row>
                          {/* item image */}
                          {/* <Col xl="1" lg="1" md="1" sm="1" style={{ margin: "1%" }}>
                            <CardMedia
                              style={{ width: "80px" }}
                              component="img"
                              height="100%"
                              image={`${item.product.images}`}
                              alt="Product"
                            />
                          </Col> */}

                          <CardContent>
                            <Typography gutterBottom
                            // variant="body3"
                            // component="div"
                            >
                              {/* <CardMedia style={{width:"60px"}}
                                component="img"
                                height="60"
                                image={`${item.product.images}`}
                                alt="Product"
                              /> */}
                              <Row>
                                <Col xl="1" lg="1" md="1" sm="1">
                                  {i + 1}
                                </Col>
                                <Col xl="6" lg="6" md="6" sm="6">
                                  {/* {`${item.productLabel} - ${item.productVariationLabel}`} */}
                                  {item.productLabel == item.productVariationLabel ? item.productLabel : `${item.productLabel} - ${item.productVariationLabel}`}
                                </Col>
                                <Col xl="1" lg="1" md="1" sm="1">
                                  {item.quantity}
                                </Col>
                                <Col xl="2" lg="2" md="2" sm="2">
                                  {item.productSellingPrice}
                                </Col>
                                <Col xl="2" lg="2" md="2" sm="2">
                                  {item.productTotalAmount}
                                </Col>
                              </Row>
                            </Typography>
                          </CardContent>
                          {/* </Col> */}
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                );
              })}

              <div className="sendDiv">
                {isEmpty(idEdit) ? (
                  <Button variant="contained" size="large" disabled> Complete Order </Button>
                ) : (
                  <Button disabled={status == "Served" ? false : true} variant="contained" size="large" onClick={() => { openPaymentMethodModal(); }}> Complete Order </Button>
                )}
              </div>

            </Col>

          </Row>


          {/* MODAL FOR SHIFT TABLE  */}
          <Modal show={shiftModal} onHide={handleCloseShift} animation={false}>
            <Modal.Header closeButton>
              <Modal.Title>Shift Table</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <TableShift
                orderId={orderId}
                closeModal={handleCloseShift}
                tableId={tableId}
              />
            </Modal.Body>
          </Modal>

          {/* MODAL FOR ORDER CANCELL  */}
          <Modal show={orderCancelModal} onHide={handleCloseOrderCancel} animation={false}>
            <Modal.Header closeButton>
              <Modal.Title>Order Cancel Reason</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="garana">
                    <Form.Label>
                      <b>Reason *</b>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Reason"
                      onChange={(e) => setReason(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Row>
                  <Col>
                    <Button
                      disabled={reason == "" ? true : false}
                      variant="contained"
                      endIcon={<SendIcon />}
                      size="large"
                      onClick={() => { props.orderCancel(idEdit, { orderCancelReason: reason, }); handleCloseOrderCancel(); }}
                    >
                      Order Cancel </Button>
                  </Col>
                </Row>

              </Row>
            </Modal.Body>
          </Modal>

          {/* MODAL FOR PAYMENT CASH OR CARD  */}
          <Modal show={show1} onHide={handleClose1} animation={false}>
            <Modal.Header closeButton>
              <Modal.Title>Select Payment method</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col xl="4" lg="4" md="4" sm="4"> {" "}</Col>
                <Col xl="2" lg="2" md="2" sm="2"> {" "}
                  <Chip
                    color="primary"
                    label={"Cash"}
                    onClick={(e) => handlePaymentType("cash")}
                  ></Chip>
                </Col>
                <Col xl="2" lg="2" md="2" sm="2">{" "}
                  <Chip
                    color="primary"
                    label={"Card"}
                    onClick={(e) => handlePaymentType("card")}
                  ></Chip>
                </Col>
                <Col xl="4" lg="4" md="4" sm="4"> {" "}</Col>
              </Row>
              <Row>
                <Form.Label>
                  <span style={{ float: "left" }}>Total Amount</span>
                  <span style={{ float: "right" }}>
                    {!isEmpty(orderData) && orderData.subTotal}
                  </span>
                </Form.Label>
              </Row>
              {!isEmpty(orderData) && (
                <Row>
                  <Form.Label>
                    <span style={{ float: "left" }}>
                      {!isEmpty(orderData.coupon) && orderData.coupon.name}
                    </span>
                    <span style={{ float: "right" }}>
                      {!isEmpty(orderData.coupon) && orderData.discount}
                    </span>
                  </Form.Label>
                </Row>
              )}
              <Row>
                ----------------------------------------------------------------------------
              </Row>
              <Row>
                <Form.Label>
                  <span style={{ float: "right" }}>
                    {!isEmpty(orderData) && orderData.grandTotal}
                  </span>
                </Form.Label>
              </Row>
              <Row>
                <Form.Label>
                  <span style={{ float: "left" }}>
                    Tax({taxLabel}%) on{" "}
                    {cash == 0 && card == 0
                      ? ""
                      : cash == 1
                        ? "Cash"
                        : card == 1
                          ? "Card"
                          : ""}
                  </span>
                  <span style={{ float: "right" }}>{taxAmount}</span>
                </Form.Label>
              </Row>
              <Row>
                ----------------------------------------------------------------------------
              </Row>
              <Row>
                <Form.Label>
                  <span style={{ float: "left" }}>Total Amount After Tax</span>
                  <span style={{ float: "right" }}>{afterTaxTotalAmount}</span>
                </Form.Label>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                onClick={() => {
                  props.updateStatus(
                    orderData,
                    "Complete",
                    taxAmount,
                    afterTaxTotalAmount,
                    handleVisible,
                    handleDangerVisible
                  );
                  console.log(orderData, "clcik ico");
                  props.getOrder();
                  handleClose1();
                  setCustomer("");
                }}
                disabled={cash == 1 || card == 1 ? false : true}
              >
                Save
              </Button>
            </Modal.Footer>
          </Modal>

          {/* MODAL FOR COUPAN */}
          <Modal show={couponModal} onHide={handleCloseCoupon} animation={false}>
            <Modal.Header closeButton>
              <Modal.Title>Select Coupon</Modal.Title>
            </Modal.Header>
            {props.isFetchingCouponsData ? (
              <div className="loader-div " style={{ margin: "10%", marginLeft: "40%" }}>
                <Button variant="info" disabled>
                  <Spinner
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    variant="info"
                  />
                  Loading
                </Button>
              </div>
            ) : (
              <Modal.Body>{" "}
                {getDiscountList.map((x, i) => {
                  return (
                    <React.Fragment key={i}>
                      <Chip
                        style={{ margin: "3px" }}
                        label={x.label}
                        color="primary"
                      // onClick={(e) => handleClickCoupon(x)}
                      // onDelete={handleDelete}
                      />
                    </React.Fragment>
                  );
                })}
              </Modal.Body>
            )}
          </Modal>

        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getOrder: () => dispatch(getTodayOrders()),
    // getTable:()=>dispatch(getTableData()),
    orderCancel: (orderId, data) => dispatch(OrderCancel(orderId, data)),
    getOrderById: (orderId) => dispatch(GetOrderById(orderId)),
    orderPDF: (id) => dispatch(getOrderPdfReport(id)),
    orderPrint: (id) => dispatch(getOrderPrintReport(id)),
    getCompleteOrder: (data) => dispatch(filterType(data)),
    getPendingOrder: () => dispatch(getPendingOrder()),
    searchOrder: (data) => dispatch(filterOrder(data)),
    searchOrderbyTable: (data) => dispatch(filterOrderByTable(data)),
    updateStatus: (data, status, taxAmount, afterTaxTotalAmount, handleVisible, handleDangerVisible) => dispatch(updateStatus(data, status, taxAmount, afterTaxTotalAmount, handleVisible, handleDangerVisible)),
    getResturant: () => dispatch(getRestaurantData()),
    getCoupons: () => dispatch(getCoupons()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  couponsDataList: state.CouponReducer.couponsDataList,
  isFetchingCouponsData: state.CouponReducer.isFetchingCouponsData,
  ordersDataList: state.POSViewReducer.ordersDataList,
  todayOrdersDataList: state.POSViewReducer.todayOrdersDataList,
  searchTodayOrdersDataList: state.POSViewReducer.searchTodayOrdersDataList,
  isFetchingTodayOrdersData: state.POSViewReducer.isFetchingTodayOrdersData,
  tableDataList: state.TableReducer.tableDataList,
  isFetchingTable: state.TableReducer.isFetchingTable,
  isSelect: state.POSViewReducer.isSelect,
  isFetchingOrdersData: state.POSViewReducer.isFetchingOrdersData,
  completeOrdersList: state.POSViewReducer.completeOrdersList,
  isFetchingCompleteOrdersData: state.POSViewReducer.isFetchingCompleteOrdersData,
  pendingOrdersList: state.POSViewReducer.pendingOrdersList,
  isFetchingPendingOrdersData: state.POSViewReducer.isFetchingPendingOrdersData,
  restaurantList: state.ResturantReducer.restaurantDataList,
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewOrder);