import React, { useEffect, useState } from "react";
import { Col, Row, Form, Container, Button } from "react-bootstrap";
import { SearchLedger, SearchLedgerByDate, getinventoryData } from "./materialledger.actions";
import { connect } from "react-redux";
import Spinner from 'react-bootstrap/Spinner';
import "./style.css"
import { Link } from 'react-router-dom';
import Select from 'react-select';


const MaterialLedger = (props) => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [item, setItem] = useState('');
  const [itemCode, setItemCode] = useState('');


  const getItem = props.inventoryList.map((x) => { let data = { value: x.inventoryId, label: x.warehouse.whKey + "-" + x.category.cateKey + "-" + x.invKey + " " + x.productName }; return data });
  const handleChangeLedgerItem = (selectedOption) => {
    setItem(selectedOption.value);
  };


  useEffect(() => {
    props.getinventoryData();
  }, []);
  function searchData() {
    props.SearchLedgerByDate({
      startedDate: startDate,
      endDate: endDate,
      item: item,
      itemCode: itemCode
    });
  }
  return (
    <>
      {props.isFetchinginventoryData ? (
        <div className="loader-div">
          <Spinner
            animation="border"
            size="xl"
            role="status"
            aria-hidden="true"
            variant="info"
          />
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Form>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label><b> Start Date </b></Form.Label>
                    <Form.Control
                      type="date"
                      onChange={(e) => setStartDate(e.target.value)}
                    >
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label><b> End Date</b></Form.Label>
                    <Form.Control
                      type="date"
                      onChange={(e) => setEndDate(e.target.value)}
                    >
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label><b> Select Item </b></Form.Label>
                    <Select
                      placeholder="Select Item"
                      onChange={handleChangeLedgerItem}
                      options={getItem}
                    />
                  </Form.Group>
                </Col>
              </Row>


              <Row>
                <Col lg="12" sm="12" className="text-center" style={{ marginTop: '30px' }}>
                  <Link to="/inventory/material-ledger-details">
                    <Button className="sendButton" onClick={(e) => searchData(e.target.value)} >Search</Button>
                  </Link>
                </Col>
              </Row>


            </Form>
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getinventoryData: () => dispatch(getinventoryData()),
    SearchLedger: () => dispatch(SearchLedger()),
    SearchLedgerByDate: (data) => dispatch(SearchLedgerByDate(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  ledgerlist: state.MaterialLegderReducer.ledgerlist,
  ledgerSearchlist: state.MaterialLegderReducer.ledgerSearchlist,
  inventoryList: state.MaterialLegderReducer.inventoryData,
  isFetchinginventoryData: state.MaterialLegderReducer.isFetchinginventoryData
});

export default connect(mapStateToProps, mapDispatchToProps)(MaterialLedger);