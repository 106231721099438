const initialState = {
    showEmployeeAdd: false,
    isAddingSalary: false,
    errorAddingSalary: false,
    successAddingSalary: false,
    isFetchingSalaryDataById: false,
    salaryListById: [],
    isUpdatingSalary: false,
    salaryUpdate: [],
};

const SalaryReducer = (state = initialState, action) => {
    switch (action.type) {

        case "REQUEST_ADD_SALARY":
            return { ...state, isAddingSalary: true };
        case "SUCCESS_ADD_SALARY":
            return { ...state, salaryAdd: action.payload, isAddingSalary: false, successAddingSalary: true };
        case "ERROR_ADD_SALARY":
            return { ...state, isAddingSalary: false, errorAddingSalary: true };


        case "REQUEST_GET_SALARY_BY_ID":
            return { ...state, isFetchingSalaryDataById: true };
        case "SUCCESS_GET_SALARY_BY_ID":
            return { ...state, salaryListById: action.payload.data, isFetchingSalaryDataById: false };
        case "ERROR_GET_SALARY_BY_ID":
            return { ...state, isFetchingSalaryDataById: false };


        case "SET_EDIT_EMPLOYEE_FIELDS":
            let valueEmp = state.employeeListById;
            let keyEmp = action.payload.key;
            valueEmp[keyEmp] = action.payload.value;
            let valueEmp2 = [valueEmp];
            return { ...state, employeeListById: valueEmp };


        case "REQUEST_UPDATE_SALARY":
            return { ...state, isUpdatingSalary: true };
        case "SUCCESS_UPDATE_SALARY":
            return { ...state, salaryUpdate: action.payload, isUpdatingSalary: false, };
        case "ERROR_UPDATE_SALARY":
            return { ...state, isUpdatingSalary: false };


        default:
            return state;
    }
}

export default SalaryReducer;