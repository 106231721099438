import React, { useEffect } from "react";
import { Container, } from "react-bootstrap";
import { connect } from "react-redux";
import { DeleteProductionEmployeeApi, UpdateProductionEmployeeApi } from "./ProductionEmployeeAction";
import { baseURL, headers } from "../../../../../global/api";
import { setHeading } from "../../../accounts/account.actions";
import MaterialSize from "material-table";
import Spinner from "react-bootstrap/Spinner";
import jsPDF from "jspdf";
import logo from "../../../../../Images/logo.png";


const ViewProductionEmployee = (props) => {

    useEffect(() => {
        props.setHeading("Employee List");
    }, []);

    return (
        <>
            {props.isFetchingProductionEmployee ? (
                <div className="loader-div">
                    <Spinner
                        variant="border"
                        animation="grow"
                        size="xl"
                        role="status"
                        aria-hidden="true"
                    />
                </div>
            ) : (
                <Container fluid>
                    <div className="main">
                        <MaterialSize
                            title="Employee Details"
                            columns={[
                                { title: 'Sequence Number', field: 'sequenceNumber', editable: 'never' },
                                { title: "Employee Name", field: "employeeName" },
                                { title: "Father Name", field: "fatherName" },
                                { title: "Phone", field: "phone" },
                                { title: "CNIC", field: "cnic" },
                                { title: "Gender", field: "gender" },
                                { title: "Machine ID", field: "machineId" },
                                { title: "Department", field: "department" },
                                { title: "Designation", field: "designation" },
                                { title: "Incharge", field: "incharge" },
                                { title: "Hall", field: "hall" },
                                { title: "Created By", field: "userDetails.userName", editable: 'never' },
                            ]}
                            data={(query) =>
                                new Promise((resolve, reject) => {
                                    let url = `${baseURL}/productionEmployee?`;
                                    url += "limit=" + query.pageSize;
                                    url += "&page=" + (query.page);
                                    url += "&search=" + query.search;
                                    fetch(url, {
                                        method: "GET",
                                        headers: headers,
                                    }).then((response) => response.json()).then((result) => {
                                        resolve({
                                            data: result.data,
                                            page: result.page,
                                            totalCount: result.total_results,
                                        });
                                    });
                                })
                            }
                            options={{
                                actionsColumnIndex: -1,
                                exportButton: true,
                                exportAllData: true,
                                paging: true,
                                pageSize: 50,
                                emptyRowsWhenPaging: false,
                                pageSizeOptions: [50, 100, 150, 200],
                                headerStyle: {
                                    position: "sticky",
                                    top: 0,
                                    color: "#00BBBB",
                                    fontWeight: "550",
                                    onRowAdd: "none",
                                },
                                exportPdf: (columns, data) => {
                                    const orientation = 'landscape';
                                    const doc = new jsPDF(orientation);
                                    const columnTitles = [
                                        { title: 'Sequence Number', field: 'sequenceNumber', },
                                        { title: "Employee Name", field: "employeeName" },
                                        { title: "Father Name", field: "fatherName" },
                                        { title: "Phone", field: "phone" },
                                        { title: "CNIC", field: "cnic" },
                                        { title: "Gender", field: "gender" },
                                        { title: "Machine ID", field: "machineId" },
                                        { title: "Department", field: "department" },
                                        { title: "Designation", field: "designation" },
                                        { title: "Incharge", field: "incharge" },
                                        { title: "Hall", field: "hall" },
                                    ].map((columnDef) => columnDef.title);
                                    const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
                                    const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
                                    const companyNtn = `${process.env.REACT_APP_COMPANY_NTN}`;
                                    const pdfData = data.map((rowData) => [
                                        rowData.sequenceNumber,
                                        rowData.employeeName,
                                        rowData.fatherName,
                                        rowData.phone,
                                        rowData.cnic,
                                        rowData.gender,
                                        rowData.machineId,
                                        rowData.department,
                                        rowData.designation,
                                        rowData.incharge,
                                        rowData.hall,
                                    ]);
                                    let content = {
                                        startY: 50,
                                        startX: 10,
                                        head: [columnTitles],
                                        body: pdfData,
                                        styles: { fontSize: 11 },
                                        theme: "grid",
                                        headStyles: { fillColor: "#2980ba" },
                                    };
                                    doc.addImage(logo, "PNG", 15, 9, 28, 28);
                                    doc.setFontSize(12);
                                    doc.setFont("times", "normal");
                                    doc.setFont(undefined, "bold");
                                    doc.text(companyName, 50, 18, 0, 0);
                                    doc.setFont("times", "normal");
                                    doc.text(companyAddress, 50, 25, 0, 0);
                                    doc.text(companyNtn, 50, 32, 0, 0);
                                    doc.text(`Employees List`, 140, 45, 0, 0);
                                    doc.autoTable(content);
                                    doc.setProperties({ title: `Employee PDF` });
                                    var blob = doc.output("blob");
                                    window.open(URL.createObjectURL(blob));
                                },
                            }}
                            editable={{
                                // isEditHidden: (rowData) => Checkpermissions("permissionsSubCatName", "Colour Edit"),
                                // isDeleteHidden: (rowData) => Checkpermissions("permissionsSubCatName", "Colour Delete"),
                                isDeletable: rowData => false,
                                isEditable: rowData => true,
                                onRowUpdate: (newData, oldData) =>
                                    new Promise((resolve) => {
                                        setTimeout(() => {
                                            resolve();
                                            props.UpdateProductionEmployeeApi(newData, oldData);
                                        }, 600);
                                    }),
                                onRowDelete: (oldData) =>
                                    new Promise((resolve) => {
                                        setTimeout(() => {
                                            resolve();
                                            props.DeleteProductionEmployeeApi(oldData._id);
                                        }, 600);
                                    }),
                            }}
                        />
                    </div>
                </Container>
            )}
        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
        DeleteProductionEmployeeApi: (id) => dispatch(DeleteProductionEmployeeApi(id)),
        UpdateProductionEmployeeApi: (newData, oldData) => dispatch(UpdateProductionEmployeeApi(newData, oldData)),
        setHeading: (data) => dispatch(setHeading(data)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    productionEmployeeList: state.ProductionEmployeeReducer.productionEmployeeList,
    isFetchingProductionEmployee: state.ProductionEmployeeReducer.isFetchingProductionEmployee,
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewProductionEmployee);