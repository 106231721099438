import API from "../../../../../global/api";
import _ from "lodash";


export const requestAddEmployee = () => {
    return {
        type: "REQUEST_ADD_EMPLOYEE",
    };
};
export const successAddEmployee = (data) => {
    return {
        type: "SUCCESS_ADD_EMPLOYEE",
        payload: data,
    };
};
export const errorAddEmployee = () => {
    return {
        type: "ERROR_ADD_EMPLOYEE",
    };
};
export const AddEmployeeApi = (data, handleVisible, handleDangerVisible) => {
    const formData = new FormData();
    formData.append("uploadImage", _.get(data, 'uploadImage'));
    formData.append("uploadDocument", _.get(data, 'uploadDocument'));
    // formData.append("uploadCnicImage", get(data, 'uploadCnicImage'));
    formData.append("appointmentType", `${data.appointment_type}`);
    formData.append("employeeName", `${data.name}`);
    formData.append("fatherName", `${data.father_name}`);
    formData.append("dob", `${data.dob}`);
    formData.append("gender", `${data.gender}`);
    formData.append("phone", `${data.phone}`);
    formData.append("joiningDate", `${data.joining_date}`);
    formData.append("emergencyContact", `${data.emergency_contact}`);
    formData.append("maritalStatus", `${data.marital_status}`);
    formData.append("cnic", `${data.cnic}`);
    // formData.append("joining_date", `${data.joining_date}`);
    formData.append("religion", `${data.religion}`);
    formData.append("reportTo", `${data.report_to}`);
    formData.append("address", `${data.address}`);
    formData.append("allocation", `${data.allocation}`);
    formData.append("employeeTypeId", `${data.employee_type}`);
    formData.append("ntn", `${data.ntn}`);
    formData.append("eobi", `${data.eobi}`);
    formData.append("socialSecurity", `${data.social_security}`);
    formData.append("bloodGroup", `${data.blood_group}`);
    formData.append("departmentId", `${data.department_id}`);
    formData.append("designationId", `${data.designation_id}`);
    formData.append("probationPeriod", `${data.probation_period}`);
    formData.append("probationPeriodEndDate", `${data.probation_period_end_date}`);
    _.forEach(data.uploadCnicImage, (x, index) => {
        formData.append("uploadCnicImage", data.uploadCnicImage[index]);
    })
    return (dispatch) => {
        dispatch(requestAddEmployee());
        API.post(`/employee`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((res) => {
            let getData = res.data;
            dispatch(successAddEmployee(getData));
            handleVisible(res.data.message);
        }).catch((error) => {
            dispatch(errorAddEmployee());
            handleDangerVisible(error.response.data.message);
        })
    };
};


export const requestGetEmployee = () => {
    return {
        type: "REQUEST_GET_EMPLOYEE",
    };
};
export const successGetEmployee = (data) => {
    return {
        type: "SUCCESS_GET_EMPLOYEE",
        payload: data,
    };
};
export const errorGetEmployee = () => {
    return {
        type: "ERROR_GET_EMPLOYEE",
    };
};
export const GetEmployeeApi = () => {
    return (dispatch) => {
        dispatch(requestGetEmployee());
        API.get(`/employee`).then((res) => {
            let getData = res.data;
            dispatch(successGetEmployee(getData));
        }).catch((error) => {
            dispatch(errorGetEmployee());
        })
    };
};


export const requestGetActiveEmployee = () => {
    return {
        type: "REQUEST_GET_ACTIVE_EMPLOYEE",
    };
};
export const successGetActiveEmployee = (data) => {
    return {
        type: "SUCCESS_GET_ACTIVE_EMPLOYEE",
        payload: data,
    };
};
export const errorGetActiveEmployee = () => {
    return {
        type: "ERROR_GET_ACTIVE_EMPLOYEE",
    };
};
export const GetActiveEmployeeApi = () => {
    return (dispatch) => {
        dispatch(requestGetActiveEmployee());
        API.post(`/employee/get_active_employee`).then((res) => {
            let getData = res.data;
            dispatch(successGetActiveEmployee(getData));
        }).catch((error) => {
            dispatch(errorGetActiveEmployee());
        })
    };
};


export const UpdateRequestHR = () => {
    return {
        type: "UPDATE_REQUEST_HR",
    };
};
export const UpdateSuccessHR = (data) => {
    return {
        type: "UPDATE_SUCCESS_HR",
        payload: data,
    };
};
export const UpdateErrorHR = () => {
    return {
        type: "UPDATE_ERROR_HR",
    };
};
export const UpdateEmployeeApi = (data, oldData, handleVisible, handleDangerVisible) => {
    const formData = new FormData();
    formData.append("uploadImage", _.get(data, 'uploadImage'));
    formData.append("uploadCnicImage", _.get(data, 'uploadCnicImage'));
    formData.append("uploadDocument", _.get(data, 'uploadDocument'));
    formData.append("appointmentType", `${data.appointment_type}`);
    formData.append("employeeName", `${data.name}`);
    formData.append("fatherName", `${data.father_name}`);
    formData.append("dob", `${data.dob}`);
    formData.append("gender", `${data.gender}`);
    formData.append("phone", `${data.phone}`);
    formData.append("joiningDate", `${data.joining_date}`);
    formData.append("emergencyContact", `${data.emergency_contact}`);
    formData.append("maritalStatus", `${data.marital_status}`);
    formData.append("cnic", `${data.cnic}`);
    // formData.append("uploadImage", `${data.uploadImage}`);
    formData.append("address", `${data.address}`);
    formData.append("religion", `${data.religion}`);
    // formData.append("machine_id", `${data.machine_id}`);
    formData.append("reportTo", `${data.report_to}`);
    formData.append("allocation", `${data.allocation}`);
    formData.append("activeStatus", `${data.active_status}`);
    formData.append("employeeTypeId", `${data.employee_type}`);
    formData.append("ntn", `${data.ntn}`);
    formData.append("eobi", `${data.eobi}`);
    formData.append("socialSecurity", `${data.social_security}`);
    formData.append("bloodGroup", `${data.blood_group}`);
    formData.append("departmentId", `${data.department_id}`);
    formData.append("designationId", `${data.designation_id}`);
    formData.append("probationPeriod", `${data.probation_period}`);
    formData.append("probationPeriodEndDate", `${data.probation_period_end_date}`);
    // forEach(data.uploadCnicImage, (x, index) => {
    //     formData.append("uploadCnicImage", data.uploadCnicImage[index]);
    //   })
    //   forEach(data.uploadDocument, (x, index) => {
    //     formData.append("uploadDocument", data.uploadDocument[index]);
    //   })
    // formData.append("department", `${data.department}`);
    // formData.append("designation", `${data.designation}`);
    // formData.append("probation_period", `${data.probation_period}`);
    // formData.append("probation_period_end_date", `${data.probation_period_end_date}`);
    return (dispatch) => {
        dispatch(UpdateRequestHR());
        API.put(`/employee/${oldData._id}`, formData, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then((res) => {
            let getData = res.data;
            dispatch(GetEmployeeApi())
            dispatch(UpdateSuccessHR(getData));
            handleVisible();
        }).catch((error) => {
            dispatch(UpdateSuccessHR());
            handleDangerVisible();
        })
    };
};