import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import { connect } from "react-redux";
import _ from "lodash";
import Spinner from 'react-bootstrap/Spinner';
import Select from 'react-select';
import { AddshiftControlApi } from "./shiftControlAction";
import Swal from 'sweetalert';
import { setHeading } from "../../../accounts/account.actions";
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';


const AddShiftControl = (props) => {
    const [shiftName, setShiftName] = useState();
    const [arrivalIn, setArrivalIn] = useState();
    const [breakIn, setBreakIn] = useState();
    const [breakOut, setBreakOut] = useState();
    const [departure, setDeparture] = useState();
    const [lateDeparture, setlateDeparture] = useState();
    const [jummahIn, setJummahIn] = useState();
    const [jummahBreakOut, setJummahBreakOut] = useState();
    const [jummahBreakIn, setJummahBreakIn] = useState();
    const [departureJummah, setDepartureJummah] = useState();
    const [workHourNormalDays, setWorkHourNormalDays] = useState();
    const [workHourJummah, setWorkHourJummah] = useState();
    const [lateTimeIn, setLateTimeIn] = useState();
    const [lateTimeAllowJummah, setLateTimeInJummah] = useState();
    const [earlyLeaveOutBreak, setEarlyLeaveOutBreak] = useState();
    const [earlyLeaveInBreak, setEarlyLeaveInBreak] = useState();
    const [earlyBreakOutJummah, setEarlyLeaveOutJummah] = useState();
    const [earlyBreakInJummah, setEarlyBreakInJummah] = useState();
    const [lateDepartureJummah, setLateDepartureJummah] = useState();
    const [shiftMng, setShiftMng] = useState(1);
    const [visibleAlert, setAlertVisible] = useState(false);
    const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);


    const SaveShiftControl = () => {
        props.AddshiftControlApi({
            shiftName: shiftName,
            arrivalIn: arrivalIn,
            breakOut: breakOut,
            breakIn: breakIn,
            departure: departure,
            allowLateArrivalIn: lateTimeIn,
            allowEarlyBreakOut: earlyLeaveOutBreak,
            allowEarlyBreakIn: earlyLeaveInBreak,
            allowLateDeparture: lateDeparture,
            jummahArrivelIn: jummahIn,
            breakJummahOut: jummahBreakOut,
            breakJummahIn: jummahBreakIn,
            workingHourNormalDays: workHourNormalDays,
            workingHourJumah: workHourJummah,
            jummahDeparture: departureJummah,
            jummahAllowLateArrivalIn: lateTimeAllowJummah,
            jummahAllowEarlyBreakOut: earlyBreakOutJummah,
            jummahAllowEarlyBreakIn: earlyBreakInJummah,
            jummahAllowLateDeparture: lateDepartureJummah,
            shiftMangement: shiftMng,
        }, handleVisible, handleDangerVisible,);
    };
    const clearStates = () => {
        setShiftName();
        setArrivalIn();
        setBreakIn();
        setBreakOut();
        setDeparture();
        setlateDeparture();
        setJummahIn();
        setJummahBreakOut();
        setJummahBreakIn();
        setDepartureJummah();
        setLateTimeIn();
        setLateTimeInJummah();
        setEarlyLeaveOutBreak();
        setEarlyLeaveInBreak();
        setEarlyLeaveOutJummah();
        setEarlyBreakInJummah();
        setLateDepartureJummah();
        setShiftMng();
    }
    const handleVisible = (message) => {
        setAlertVisible(true);
        clearStates();
        setTimeout(() => {
            setAlertVisible(false)
        }, 9000);
        Swal({
            title: "Success",
            text: message,
            icon: "success",
            button: "Ok",
        });
    }
    const handleDangerVisible = (error) => {
        setDangerVisibleAlert(true)
        setTimeout(() => {
            setDangerVisibleAlert(false)
        }, 9000);
        Swal({
            title: "Error",
            text: error,
            icon: "error",
            button: "Ok",
        });
    }

    const getShiftList = [
        { value: 1, label: "Two Times" },
        { value: 2, label: "Four Times" },
    ];
    const handleChangeShift = (selectedOption) => {
        setShiftMng(selectedOption.value);
    };

    useEffect(() => {
        props.setHeading("Shift Control Add")
    }, []);

    return (
        <>
            <Container fluid>
                {props.isFetchingShiftControlData || props.isAddingShiftControl ? <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />
                    </Button>
                </div> :
                    <div className="mainHr">
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Shift Name *</b></Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="shiftName"
                                        name="ShiftName"
                                        placeholder="Shift Name"
                                        onBlur={(e) => setShiftName(e.target.value)} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" controlId="garana" >
                                    <Form.Label><b>Shift Management *</b></Form.Label>
                                    <Select
                                        placeholder="Shift Management"
                                        defaultValue={{ label: (shiftMng === 1) && "Two Times" }}
                                        onChange={handleChangeShift}
                                        options={getShiftList}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        {shiftMng === 2 ?
                            <div className="fourTimes">
                                <Row>
                                    <h4>Normal Day Timing </h4>
                                    <Col lg="3" md="3" xs="12">
                                        <Form.Group className="mb-3" controlId="garana" >
                                            <Form.Label><b>Arrival In *</b></Form.Label>
                                            <Form.Control
                                                type="time"
                                                id="arrivalIn"
                                                name="ArrivalIn"
                                                placeholder="Arrival In"
                                                onBlur={(e) => setArrivalIn(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                    <Col lg="3" md="3" xs="12">
                                        <Form.Group className="mb-3" controlId="garana" >
                                            <Form.Label><b>Break Out *</b></Form.Label>
                                            <Form.Control
                                                type="time"
                                                id="breakOut"
                                                name="BreakOut"
                                                placeholder="Break Out"
                                                onBlur={(e) => setBreakOut(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                    <Col lg="3" md="3" xs="12">
                                        <Form.Group className="mb-3" controlId="garana" >
                                            <Form.Label><b>Break In *</b></Form.Label>
                                            <Form.Control
                                                type="time"
                                                id="breakIn"
                                                name="BreakIn"
                                                placeholder="Break In"
                                                onBlur={(e) => setBreakIn(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                    <Col lg="3" md="3" xs="12">
                                        <Form.Group className="mb-3" controlId="garana" >
                                            <Form.Label><b>Departure *</b></Form.Label>
                                            <Form.Control
                                                type="time"
                                                id="departure"
                                                name="LateTimeInMinutes"
                                                placeholder="Departure..."
                                                onBlur={(e) => setDeparture(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="3" md="3" xs="12">
                                        <Form.Group className="mb-3" controlId="garana" >
                                            <Form.Label><b>Allowed Late Arrival In *</b></Form.Label>
                                            <Form.Control
                                                type="time"
                                                id="lateTimeInMinutes"
                                                name="LateTimeInMinutes"
                                                placeholder="Early Leave In"
                                                onBlur={(e) => setLateTimeIn(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                    <Col lg="3" md="3" xs="12">
                                        <Form.Group className="mb-3" controlId="garana" >
                                            <Form.Label><b>Allowed Early Break Out *</b></Form.Label>
                                            <Form.Control
                                                type="time"
                                                id="lateTimeInMinutes"
                                                name="LateTimeInMinutes"
                                                placeholder="Early Leave Out Break"
                                                onBlur={(e) => setEarlyLeaveOutBreak(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                    <Col lg="3" md="3" xs="12">
                                        <Form.Group className="mb-3" controlId="garana" >
                                            <Form.Label><b>Allowed Late Break In *</b></Form.Label>
                                            <Form.Control
                                                type="time"
                                                id="lateTimeInMinutes"
                                                name="LateTimeInMinutes"
                                                placeholder="Early Leave Out Break"
                                                onBlur={(e) => setEarlyLeaveInBreak(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                    <Col lg="3" md="3" xs="12">
                                        <Form.Group className="mb-3" controlId="garana" >
                                            <Form.Label><b>Allowed Early Departure *</b></Form.Label>
                                            <Form.Control
                                                type="time"
                                                id="lateTimeInMinutes"
                                                name="LateTimeInMinutes"
                                                placeholder="Early Leave Out Break"
                                                onBlur={(e) => setlateDeparture(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <h4>Jummah Day Timing </h4>
                                    <Col lg="3" md="3" xs="12">
                                        <Form.Group className="mb-3" controlId="garana" >
                                            <Form.Label><b>Arrival In *</b></Form.Label>
                                            <Form.Control
                                                type="time"
                                                id="arrivalIn"
                                                name="ArrivalIn"
                                                placeholder="Arrival In"
                                                onBlur={(e) => setJummahIn(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                    <Col lg="3" md="3" xs="12">
                                        <Form.Group className="mb-3" controlId="garana" >
                                            <Form.Label><b>Break Out For Jummah *</b></Form.Label>
                                            <Form.Control type="time" id="breakOut" name="BreakOut" placeholder="Break Out...." onBlur={(e) => setJummahBreakOut(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                    <Col lg="3" md="3" xs="12">
                                        <Form.Group className="mb-3" controlId="garana" >
                                            <Form.Label><b>Break In After Jummah *</b></Form.Label>
                                            <Form.Control type="time" id="breakIn" name="BreakIn" placeholder="Break In...." onBlur={(e) => setJummahBreakIn(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                    <Col lg="3" md="3" xs="12">
                                        <Form.Group className="mb-3" controlId="garana" >
                                            <Form.Label><b>Departure *</b></Form.Label>
                                            <Form.Control type="time" id="lateTimeInMinutes" name="LateTimeInMinutes" placeholder="Late Time In..." onBlur={(e) => setDepartureJummah(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="3" md="3" xs="12">
                                        <Form.Group className="mb-3" controlId="garana" >
                                            <Form.Label><b>Allowed Late Arrival In *</b></Form.Label>
                                            <Form.Control type="time" id="lateTimeInMinutes" name="LateTimeInMinutes" placeholder="Early Leave In..." onBlur={(e) => setLateTimeInJummah(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                    <Col lg="3" md="3" xs="12">
                                        <Form.Group className="mb-3" controlId="garana" >
                                            <Form.Label><b>Allowed Early Break Out For Jummah *</b></Form.Label>
                                            <Form.Control type="time" id="lateTimeInMinutes" name="LateTimeInMinutes" placeholder="Early Leave Out Break..." onBlur={(e) => setEarlyLeaveOutJummah(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                    <Col lg="3" md="3" xs="12">
                                        <Form.Group className="mb-3" controlId="garana" >
                                            <Form.Label><b>Allowed Late Break In After Jummah *</b></Form.Label>
                                            <Form.Control type="time" id="lateTimeInMinutes" name="LateTimeInMinutes" placeholder="Early Leave Out Break..." onBlur={(e) => setEarlyBreakInJummah(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                    <Col lg="3" md="3" xs="12">
                                        <Form.Group className="mb-3" controlId="garana" >
                                            <Form.Label><b>Allowed Early Departure *</b></Form.Label>
                                            <Form.Control type="time" id="lateTimeInMinutes" name="LateTimeInMinutes" placeholder="Early Leave Out Break..." onBlur={(e) => setLateDepartureJummah(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div> :
                            <div className="twoTimes">
                                <h4>Normal Day Timing </h4>
                                <Row>
                                    <Col lg="3" md="3" xs="12">
                                        <Form.Group className="mb-3" controlId="garana" >
                                            <Form.Label><b>Arrival In *</b></Form.Label>
                                            <Form.Control type="time" id="arrivalIn" name="ArrivalIn" placeholder="Arrival In...." onBlur={(e) => setArrivalIn(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                    <Col lg="3" md="3" xs="12">
                                        <Form.Group className="mb-3" controlId="garana" >
                                            <Form.Label><b>Departure *</b></Form.Label>
                                            <Form.Control type="time" id="departure" name="LateTimeInMinutes" placeholder="Departure..." onBlur={(e) => setDeparture(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="3" md="3" xs="12">
                                        <Form.Group className="mb-3" controlId="garana" >
                                            <Form.Label><b>Allowed Late Arrival In *</b></Form.Label>
                                            <Form.Control type="time" id="lateTimeInMinutes" name="LateTimeInMinutes" placeholder="Early Leave In..." onBlur={(e) => setLateTimeIn(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                    <Col lg="3" md="3" xs="12">
                                        <Form.Group className="mb-3" controlId="garana" >
                                            <Form.Label><b>Allowed Early Departure *</b></Form.Label>
                                            <Form.Control type="time" id="lateTimeInMinutes" name="LateTimeInMinutes" placeholder="Early Leave Out Break..." onBlur={(e) => setlateDeparture(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <h4>Friday Day Timing </h4>
                                    <Col lg="3" md="3" xs="12">
                                        <Form.Group className="mb-3" controlId="garana" >
                                            <Form.Label><b>Arrival In *</b></Form.Label>
                                            <Form.Control type="time" id="arrivalIn" name="ArrivalIn" placeholder="Arrival In...." onBlur={(e) => setJummahIn(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                    <Col lg="3" md="3" xs="12">
                                        <Form.Group className="mb-3" controlId="garana" >
                                            <Form.Label><b>Departure *</b></Form.Label>
                                            <Form.Control type="time" id="lateTimeInMinutes" name="LateTimeInMinutes" placeholder="Late Time In..." onBlur={(e) => setDepartureJummah(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="3" md="3" xs="12">
                                        <Form.Group className="mb-3" controlId="garana" >
                                            <Form.Label><b>Allowed Late Arrival In *</b></Form.Label>
                                            <Form.Control type="time" id="lateTimeInMinutes" name="LateTimeInMinutes" placeholder="Early Leave In..." onBlur={(e) => setLateTimeInJummah(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                    <Col lg="3" md="3" xs="12">
                                        <Form.Group className="mb-3" controlId="garana" >
                                            <Form.Label><b>Allowed Early Departure *</b></Form.Label>
                                            <Form.Control type="time" id="lateTimeInMinutes" name="LateTimeInMinutes" placeholder="Early Leave Out Break..." onBlur={(e) => setLateDepartureJummah(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>
                        }

                        <div className="sendDiv">
                            {(
                                (shiftMng === 1) & (
                                    (arrivalIn === undefined) || (departure === undefined) || (lateTimeIn === undefined) ||
                                    (lateDeparture === undefined) || (jummahIn === undefined) || (departureJummah === undefined) ||
                                    (lateTimeAllowJummah === undefined) || (lateDepartureJummah === undefined)
                                )) || (
                                    (shiftMng === 2) & (
                                        (arrivalIn === undefined) || (breakOut === undefined) || (breakIn === undefined) ||
                                        (departure === undefined) || (lateTimeIn === undefined) || (earlyLeaveOutBreak === undefined) ||
                                        (earlyLeaveInBreak === undefined) || (lateDeparture === undefined) || (jummahIn === undefined) ||
                                        (jummahBreakOut === undefined) || (jummahBreakIn === undefined) || (departureJummah === undefined) ||
                                        (lateTimeAllowJummah === undefined) || (earlyBreakOutJummah === undefined) || (earlyBreakInJummah === undefined) || (lateDepartureJummah === undefined)
                                    )) ? (
                                <Button variant="contained" endIcon={<SendIcon />} size="large" disabled> SAVE </Button>
                            ) : (
                                <Button variant="contained" endIcon={<SendIcon />} size="large" onClick={SaveShiftControl}>SAVE </Button>
                            )}
                        </div>
                    </div>
                }
            </Container>
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        AddshiftControlApi: (data, handleVisible, handleDangerVisible) => dispatch(AddshiftControlApi(data, handleVisible, handleDangerVisible)),
        setHeading: (data) => dispatch(setHeading(data)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    shiftControl: state.ShiftControlReducer.shiftControl,
    isAddingShiftControl: state.ShiftControlReducer.isAddingShiftControl,
    isFetchingShiftControlData: state.ShiftControlReducer.isFetchingShiftControlData,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddShiftControl);