import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import { connect } from "react-redux";
import _ from "lodash";
import { UploadCustomerFileApi } from "./CustomerActionCRM";
import { setHeading } from "../../../accounts/account.actions";
import { GetCRMProductApi } from "../CRMProducts/CRMProductAction";
import Spinner from "react-bootstrap/Spinner";
import Swal from "sweetalert";
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import Select from "react-select";


const UploadCustomerCRM = (props) => {
    const [productId, setProductId] = useState("");
    const [productName, setProductName] = useState("");
    const [customerUploadFile, setCustomerUploadFile] = useState("");
    const [visibleAlert, setAlertVisible] = useState(false);
    const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

    function SaveCRMCustomer() {
        props.UploadCustomerFileApi({
            file: customerUploadFile,
            productId: productId,
            productName: productName,
        },
            handleVisible,
            handleDangerVisible
        );
        setProductId("")
    }
    const handleVisible = (msg) => {
        setAlertVisible(true);
        setTimeout(() => {
            setAlertVisible(false);
        }, 9000);
        Swal({
            title: "Success",
            text: msg,
            icon: "success",
            button: "Ok",
        });
    };
    const handleDangerVisible = (err) => {
        setDangerVisibleAlert(true);
        setTimeout(() => {
            setDangerVisibleAlert(false);
        }, 9000);
        Swal({
            title: "Error",
            text: err,
            icon: "error",
            button: "Ok",
        });
    };

    const handleChangeProduct = (selectedOption) => {
        setProductId(selectedOption.value);
        setProductName(selectedOption.label);
    };
    const getProductList = !_.isEmpty(props.CRMProductList) && props.CRMProductList.map((x) => {
        let data = { value: x._id, label: x.productName };
        return data;
    });

    useEffect(() => {
        props.setHeading("Upload Customer");
        props.GetCRMProductApi()
    }, []);

    return (
        <>
            {props.isUploadingCustomer ? (
                <div className="loader-div">
                    <Spinner
                        variant="primary"
                        animation="border"
                        size="xl"
                        role="status"
                        aria-hidden="true"
                    />
                </div>
            ) : (
                <Container fluid>
                    <Form>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" controlId="garana">
                                    <Form.Label><b>Select Product *</b></Form.Label>
                                    <Select
                                        placeholder="Select Product"
                                        onChange={handleChangeProduct}
                                        options={getProductList}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="formFile" className="mb-3">
                                    <Form.Label><b>Upload Customer File *</b></Form.Label>
                                    <Form.Control
                                        placeholder="Upload Customer File"
                                        type="file"
                                        accept=".xls, .xlsx"
                                        onChange={(e) => setCustomerUploadFile(e.target.files[0])}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <div className="sendDiv">
                            {_.isEmpty(productId) ? (
                                <Button variant="contained" endIcon={<SendIcon />} size="large" disabled> SAVE </Button>
                            ) : (
                                <Button variant="contained" endIcon={<SendIcon />} size="large" onClick={SaveCRMCustomer}> SAVE</Button>
                            )}
                        </div>

                    </Form>
                </Container>
            )}
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        UploadCustomerFileApi: (data, handleVisible, handleDangerVisible) => dispatch(UploadCustomerFileApi(data, handleVisible, handleDangerVisible)),
        GetCRMProductApi: () => dispatch(GetCRMProductApi()),
        setHeading: (data) => dispatch(setHeading(data)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    isUploadingCustomer: state.CustomerReducerCRM.isUploadingCustomer,
    isFetchingCRMProduct: state.CRMProductReducer.isFetchingCRMProduct,
    CRMProductList: state.CRMProductReducer.CRMProductList,
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadCustomerCRM);