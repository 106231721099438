// import _ from "lodash";

const initialState = {
    isAddingEmployeeTypeData: false,
    addEmployeeType: [],
    employeeTypeList: [],
    isFetchingEmployeeTypeData: false,
};

const EmployeeTypeReducer = (state = initialState, action) => {
    switch (action.type) {

        case "REQUEST_ADD_EMPLOYEE_TYPE":
            return { ...state, isAddingEmployeeTypeData: true };
        case "SUCCESS_ADD_EMPLOYEE_TYPE":
            return { ...state, addEmployeeType: action.payload, isAddingEmployeeTypeData: false };
        case "ERROR_ADD_EMPLOYEE_TYPE":
            return { ...state, isAddingEmployeeTypeData: false };


        case "REQUEST_GET_EMPLOYEE_TYPE":
            return { ...state, isFetchingEmployeeTypeData: true };
        case "SUCCESS_GET_EMPLOYEE_TYPE":
            return { ...state, employeeTypeList: action.payload.data, isFetchingEmployeeTypeData: false };
        case "ERROR_GET_EMPLOYEE_TYPE":
            return { ...state, isFetchingEmployeeTypeData: false, };


        default:
            return state;
    }
}

export default EmployeeTypeReducer;