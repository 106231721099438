import API from "../../../../../global/api";


export const requestAddProductionReceiving = () => {
    return {
        type: "REQUEST_ADD_PRODUCTION_RECEIVING",
    };
};
export const successAddProductionReceiving = (data) => {
    return {
        type: "SUCCESS_ADD_PRODUCTION_RECEIVING",
        payload: data,
    };
};
export const errorAddProductionReceiving = () => {
    return {
        type: "ERROR_ADD_PRODUCTION_RECEIVING",
    };
};
export const AddProductionReceivingApi = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddProductionReceiving());
        API.post(`/productionReceiving`, data).then((res) => {
            dispatch(successAddProductionReceiving(data));
            handleVisible(res.data.message);
        }).catch((error) => {
            dispatch(errorAddProductionReceiving());
            handleDangerVisible(error.response.data.message);
        })
    };
};
