import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import { baseURL, headers } from "../../../../../global/api";
import { setHeading } from "../../../accounts/account.actions";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import jsPDF from "jspdf";
import logo from "../../../../../Images/logo.png";


const ViewUom = (props) => {
  useEffect(() => {
    props.setHeading("Uom List");
  }, []);

  return (
    <>
      {props.isFetchingCouponsData ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="border"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <MaterialTable
              title="UOM Details"
              columns={[
                { title: "_id", field: "_id" },
                { title: "Sequence Number", field: "sequenceNumber" },
                { title: "Uom Name", field: "unitName", },
                { title: "Created By", field: "userDetails.userName", editable: 'never' },
              ]}
              data={(query) =>
                new Promise((resolve, reject) => {
                  let url = `${baseURL}/uom?`;
                  url += "limit=" + query.pageSize;
                  url += "&page=" + (query.page);
                  url += "&search=" + query.search;
                  fetch(url, {
                    method: 'GET',
                    headers: headers,
                  }).then((response) => response.json()).then((result) => {
                    resolve({
                      data: result.data,
                      page: result.page,
                      totalCount: result.total_results,
                    });
                  });
                })
              }
              options={{
                actionsColumnIndex: -1,
                exportButton: true,
                exportAllData: true,
                paging: true,
                pageSize: 50,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [50, 100, 150, 200],
                headerStyle: {
                  position: "sticky",
                  top: 0,
                  color: "#00BBBB",
                  fontWeight: "550",
                  onRowAdd: "none",
                },
                exportPdf: (columns, data) => {
                  const doc = new jsPDF();
                  const columnTitles = [
                    { title: "Sequence Number", field: "sequenceNumber", render: (rowData) => rowData.sequenceNumber },
                    { title: "Unit Name", field: "unitName", render: (rowData) => rowData.unitName },
                  ].map((columnDef) => columnDef.title);
                  const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
                  const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
                  const companyNtn = `${process.env.REACT_APP_COMPANY_NTN}`;
                  const pdfData = data.map((rowData) => [
                    rowData.sequenceNumber,
                    rowData.unitName,
                  ]);
                  let content = {
                    startY: 50,
                    startX: 10,
                    head: [columnTitles],
                    body: pdfData,
                    styles: { fontSize: 11 },
                    // theme: "grid",
                    headStyles: { fillColor: "#2980ba" },
                  };
                  doc.addImage(logo, "PNG", 15, 9, 28, 28);
                  doc.setFontSize(12);
                  doc.setFont("times", "normal");
                  doc.setFont(undefined, "bold");
                  doc.text(companyName, 50, 18, 0, 0);
                  doc.setFont("times", "normal");
                  doc.text(companyAddress, 50, 25, 0, 0);
                  doc.text(companyNtn, 50, 32, 0, 0);
                  doc.text(`Unit Of Measures List`, 90, 45, 0, 0);
                  doc.autoTable(content);
                  doc.setProperties({ title: `UOM PDF` });
                  var blob = doc.output("blob");
                  window.open(URL.createObjectURL(blob));
                },
              }}
            />
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setHeading: (data) => dispatch(setHeading(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  isFetchingProduct: state.ProductReducer.isFetchingProduct,
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewUom);