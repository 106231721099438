import API from "../../../../../global/api";


export const requestLTPayment = () => {
    return {
        type: "REQUEST_LT_PAYMENT",
    };
};
export const successLTPayment = (data) => {
    return {
        type: "SUCCESS_LT_PAYMENT",
        payload: data,
    };
};
export const errorLTPayment = () => {
    return {
        type: "ERROR_LT_PAYMENT",
    };
};
export const AddLTPaymentApi = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestLTPayment());
        API.post(`/longTermAdv`, data).then((res) => {
            dispatch(successLTPayment(data));
            handleVisible(res.data.message);
        }).catch((error) => {
            dispatch(errorLTPayment());
            handleDangerVisible(error.response.data.message);
        });
    };
};


export const requestAddLTRecivings = () => {
    return {
        type: "REQUEST_ADD_LT_RECIVING",
    };
};
export const successAddLTRecivings = (data) => {
    return {
        type: "SUCCESS_ADD_LT_RECIVING",
        payload: data,
    };
};
export const errorAddLTRecivings = () => {
    return {
        type: "ERROR_ADD_LT_RECIVING",
    };
};
export const AddLTRecivingApi = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddLTRecivings());
        API.post(`/longTermAdv/long_term_receiving`, data).then((res) => {
            dispatch(successAddLTRecivings(data));
            handleVisible(res.data.message);
        }).catch((error) => {
            dispatch(errorAddLTRecivings());
            handleDangerVisible(error.response.data.message);
        });
    };
};


export const requestGetLTLedger = () => {
    return {
        type: "REQUEST_GET_LT_LEDGER",
    };
};
export const successGetLTLedger = (data) => {
    return {
        type: "SUCCESS_GET_LT_LEDGER",
        payload: data,
    };
};
export const errorGetLTLedger = () => {
    return {
        type: "ERROR_GET_LT_LEDGER",
    };
};
export const GetLTLedgerApi = (data, startDate, endDate) => {
    return (dispatch) => {
        dispatch(requestGetLTLedger());
        API.post(`/longTermAdv/ledger`, data).then((res) => {
            let getData = res.data;
            dispatch(successGetLTLedger(getData));
        }).catch((error) => {
            dispatch(errorGetLTLedger());
        })
    };
};