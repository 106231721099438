const initialState = {
    levelFourList: [],
    isFetchingLevelFourAccounts: false,
    isAddingMasterControlHR: false,
    isFetchingSelectedMasterAccounts: false,
    selectedMasterData: [],
    masterControlHR: [],
};

const MasterControlHRReducer = (state = initialState, action) => {
    switch (action.type) {

        case "REQUEST_GET_LEVELFOUR":
            return { ...state, isFetchingLevelFourAccounts: true };
        case "SUCCESS_GET_LEVELFOUR":
            return { ...state, levelFourList: action.payload.allData, isFetchingLevelFourAccounts: false };
        case "ERROR_GET_LEVELFOUR":
            return { ...state, isFetchingLevelFourAccounts: false };


        case "REQUEST_ADD_MASTER_ACCOUNTS":
            return { ...state, isAddingMasterControlHR: true };
        case "SUCCESS_ADD_MASTER_ACCOUNTS":
            return { ...state, masterControlHR: action.payload, isAddingMasterControlHR: false };
        case "ERROR_ADD_MASTER_ACCOUNTS":
            return { ...state, isAddingMasterControlHR: false };


        case "REQUEST_GET_SELECTED_MASTERCONTROL_HR_ACCOUNTS":
            return { ...state, isFetchingSelectedMasterAccounts: true };
        case "SUCCESS_GET_SELECTED_MASTERCONTROL_HR_ACCOUNTS":
            return { ...state, selectedMasterData: action.payload.data, isFetchingSelectedMasterAccounts: false, };
        case "ERROR_GET_SELECTED_MASTERCONTROL_HR_ACCOUNTS":
            return { ...state, isFetchingSelectedMasterAccounts: false };


        case "RESET_MASTER_DATA":
            return { ...state, addAccounts: '' }

        default:
            return state;
    }
};

export default MasterControlHRReducer;