import API from "../../../../../global/api";
// import _ from "lodash";


export const requestAddDivision = () => {
    return {
        type: "REQUEST_ADD_DIVISION",
    };
};
export const successAddDivision = (data) => {
    return {
        type: "SUCCESS_ADD_DIVISION",
        payload: data,
    };
};
export const errorAddDivision = () => {
    return {
        type: "ERROR_ADD_DIVISION",
    };
};
export const AddDivisionApi = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddDivision());
        API.post(`/division`, data).then((res) => {
            handleVisible(res.data.message);
            let getData = res.data;
            dispatch(successAddDivision(getData));
        }).catch((error) => {
            handleDangerVisible(error.response.data.message);
            dispatch(errorAddDivision());
        })
    };
};

export const requestGetDivision = () => {
    return {
        type: "REQUEST_GET_DIVISION",
    };
};
export const successGetDivision = (data) => {
    return {
        type: "SUCCESS_GET_DIVISION",
        payload: data,
    };
};
export const errorGetDivision = () => {
    return {
        type: "ERROR_GET_DIVISION",
    };
};
export const GetDivisionApi = () => {
    return (dispatch) => {
        dispatch(requestGetDivision());
        API.get(`/division`).then((res) => {
            let getData = res.data;
            dispatch(successGetDivision(getData));
        }).catch((error) => {
            dispatch(errorGetDivision());
        })
    };
};

export const requestUpdateDivision = () => {
    return {
        type: "REQUEST_UPDATE_DIVISION",
    };
};
export const successUpdateDivision = (data) => {
    return {
        type: "SUCCESS_UPDATE_DIVISION",
        payload: data,
    };
};
export const errorUpdateDivision = () => {
    return {
        type: "ERROR_UPDATE_DIVISION",
    };
};
export const UpdateDivisionApi = (newData, oldData,updateTableQuery) => {
    return (dispatch) => {
        dispatch(requestUpdateDivision());
        API.put(`/division/${oldData._id}`, newData).then((res) => {
            dispatch(successUpdateDivision([newData]));
            // dispatch(getHRDivision());
            updateTableQuery()
        }).catch((error) => {
            dispatch(errorUpdateDivision());
        })
    };
};

export const requestDeleteDivision = () => {
    return {
        type: "REQUEST_DELETE_DIVISION",
    };
};
export const successDeleteDivision = (data) => {
    return {
        type: "SUCCESS_DELETE_DIVISION",
        payload: data,
    };
};
export const errorDeleteDivision = () => {
    return {
        type: "ERROR_DELETE_DIVISION",
    };
};
export const DeleteDivisionApi = (id,updateTableQuery) => {
    return (dispatch) => {
        dispatch(requestDeleteDivision());
        API.delete(`/division/${id}`).then((res) => {
            dispatch(successDeleteDivision());
            updateTableQuery()
            // dispatch(getHRDivision());
        }).catch((error) => {
            dispatch(errorDeleteDivision());
        })
    };
};