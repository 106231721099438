const initialState = {
    addFine: [],
    fineDataList: [],
    isFetchingFineData: false,
    isAddingFine: false,
};

const FineReducer = (state = initialState, action) => {
    switch (action.type) {

        case "REQUEST_ADD_FINE":
            return { ...state, isAddingFine: true };
        case "SUCCESS_ADD_FINE":
            return { ...state, addFine: action.payload, isAddingFine: false };
        case "ERROR_ADD_FINE":
            return { ...state, isAddingFine: false };


        case "REQUEST_GET_FINE":
            return { ...state, isFetchingFineData: true };
        case "SUCCESS_GET_FINE":
            return { ...state, fineDataList: action.payload, isFetchingFineData: false };
        case "ERROR_GET_FINE":
            return { ...state, isFetchingFineData: false };

        default:
            return state;
    }
};
export default FineReducer;