import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { DeleteArticleApi, UpdateArticleApi, GetArticleWithIDApi, DuplicateArticleApi, PrintFullArticlePDF, GetArticlesWithVariationIdApi } from "./ArticleAction";
import { getSizeData } from "../../AddDetails/AddSize/Size.action";
import { getFlavorsData } from "../../AddDetails/AddColour/Colour.action";
import { baseURL, headers } from "../../../../../global/api";
import { setHeading } from "../../../accounts/account.actions";
import MaterialSize from "material-table";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import _ from "lodash";
import PrintIcon from '@mui/icons-material/Print';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Select from "react-select";
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import Swal from "sweetalert";
import { useNavigate, useLocation } from "react-router-dom";
const tableRef = React.createRef()


const ViewArticle = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [openPDF, setOpenPDF] = React.useState(false);
    const handleOpenPDF = () => { setOpenPDF(true) };
    const handleClosePDF = () => setOpenPDF(false);
    const [articleVariationId, setArticleVariationId] = useState();
    const [colorId, setColorId] = useState();
    const [colorName, setColorName] = useState();
    const [sizeId, setSizeId] = useState();
    const [sizeName, setSizeName] = useState();
    const [manualVariationCode, setManualVariationCode] = useState();
    const [visibleAlert, setAlertVisible] = useState(false);
    const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
    const stylePDF = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '40%',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 5,
    };


    function DuplicateArticleVariation() {
        props.DuplicateArticleApi({
            articleId: props.articleListWithID[0]._id,
            manualVariationCode: manualVariationCode,
            colorId: colorId,
            colorName: colorName,
            sizeId: sizeId,
            sizeName: sizeName,
            articleRecipe: {
                articleVariationCost: articleVariationId.articleVariationCost,
                adminCostData: articleVariationId.adminCostData,
                processData: articleVariationId.processData,
                productData: articleVariationId.productData,
            }
        },
            handleVisible,
            handleDangerVisible
        );
        handleClosePDF()
        tableRef.current && tableRef.current.onQueryChange();
        setColorId("")
        setSizeId("")
    }
    const handleVisible = (msg) => {
        setAlertVisible(true);
        setTimeout(() => {
            setAlertVisible(false);
        }, 9000);
        Swal({
            title: "Success",
            text: msg,
            icon: "success",
            button: "Ok",
        });
    };
    const handleDangerVisible = (err) => {
        setDangerVisibleAlert(true);
        setTimeout(() => {
            setDangerVisibleAlert(false);
        }, 9000);
        Swal({
            title: "Error",
            text: err,
            icon: "error",
            button: "Ok",
        });
    };

    const handleChangeSize = (selectedOption) => {
        setSizeId(selectedOption.value);
        setSizeName(selectedOption.label);
    };
    const inventorySize = props.sizeDataList.map((x) => {
        let data = { value: x._id, label: x.sizeName };
        return data;
    });

    const handleChangeColor = (selectedOption) => {
        setColorId(selectedOption.value);
        setColorName(selectedOption.label);
    };
    const inventoryFlavor = props.flavorsDataList.map((x) => {
        let data = { value: x._id, label: x.colourName };
        return data;
    });

    useEffect(() => {
        props.setHeading("Article List");
        props.getSizeData()
        props.getFlavorsData()
    }, []);

    return (
        <>
            {props.isFetchingArticle ? (
                <div className="loader-div">
                    <Spinner
                        variant="border"
                        animation="grow"
                        size="xl"
                        role="status"
                        aria-hidden="true"
                    />
                </div>
            ) : (
                <Container fluid>
                    <div className="main">
                        <MaterialSize
                            tableRef={tableRef}
                            title="Article List"
                            columns={[
                                { title: 'Article Code', field: 'sequenceNumber', editable: 'never' },
                                { title: "Category", field: "categoryDetails.categoryName", editable: 'never' },
                                { title: "Sub Category", field: "subCategoryDetails.subCategoryName", editable: 'never' },
                                { title: "Customer Name", field: "customerDetails.name", editable: 'never' },
                                { title: "Brand Name", field: "brandDetails.brandName", editable: 'never' },
                                { title: "UOM", field: "uomDetails.unitName", editable: 'never' },
                                { title: "Article Name", field: "articleName" },
                                // { title: "Carton Size", field: "cartonSize" },
                                // { title: "Packing", field: "packing" },
                                // { title: "Weight", field: "weight" },
                                // { title: "Composition", field: "composition" },
                                // { title: "HSCode", field: "hSCode" },
                                // { title: "Description", field: "description" },
                                // { title: "Catalogue Number", field: "catlogNumber" },
                                // { title: "Customer Catalogue Number", field: "customerCatalogueNumber" },
                                { title: "Export Cost", field: "exportCost" },
                                { title: "Currency", field: "currency", editable: 'never' },
                                { title: "Conversion To PKR", field: "conversionToPKR" },
                                { title: "Export Price", field: "exportPrice" },
                                { title: "Profit", field: "profit" },
                                { title: "Article Total Cost", field: "articleTotalCost" },
                                { title: "Created By", field: "userDetails.userName", editable: 'never' },
                            ]}
                            data={(query) =>
                                new Promise((resolve, reject) => {
                                    let url = `${baseURL}/article?`;
                                    url += "limit=" + query.pageSize;
                                    url += "&page=" + (query.page);
                                    url += "&search=" + query.search;
                                    fetch(url, {
                                        method: "GET",
                                        headers: headers,
                                    }).then((response) => response.json()).then((result) => {
                                        resolve({
                                            data: result.data.sort((a, b) => b.sequenceNumber - a.sequenceNumber),
                                            page: result.page,
                                            totalCount: result.total_results,
                                        });
                                    });
                                })
                            }
                            detailPanel={rowData => {
                                return (
                                    <MaterialTable
                                        columns={[
                                            { title: 'Code', field: 'variationSequenceNumber' },
                                            { title: 'Manual Code', field: 'manualVariationCode' },
                                            { title: 'Color', field: 'colourLabel' },
                                            { title: 'Size', field: 'sizeLabel' },
                                            { title: 'Article Variation Cost', field: 'articleVariationCost' },
                                        ]}
                                        data={rowData.variation.map(variation => ({
                                            ...variation,
                                            articleId: rowData._id,
                                        }))}
                                        options={{
                                            actionsColumnIndex: -1,
                                            toolbar: false,
                                            exportButton: false,
                                            sorting: false,
                                            paging: false,
                                            pageSize: 50,
                                            emptyRowsWhenPaging: false,
                                            pageSizeOptions: [50, 100, 150, 200],
                                            headerStyle: {
                                                position: 'sticky',
                                                top: 0,
                                                color: '#00BBBB',
                                                fontWeight: '550',
                                                onRowAdd: 'none',
                                            }
                                        }}
                                        actions={[
                                            {
                                                icon: 'refresh',
                                                tooltip: 'Refresh Data',
                                                isFreeAction: true,
                                                onClick: () => tableRef.current && tableRef.current.onQueryChange(),
                                            },
                                            (rowData) => ({
                                                icon: () => <ContentCopyIcon />,
                                                tooltip: "Duplicate Article",
                                                disabled: rowData.isRecipe === true ? false : true,
                                                onClick: (event, rowData) => {
                                                    handleOpenPDF()
                                                    setArticleVariationId(rowData)
                                                    props.GetArticleWithIDApi(rowData.articleId)
                                                },
                                            }),
                                            // (rowData) => ({
                                            //     icon: () => <PrintIcon />,
                                            //     tooltip: "Print Article",
                                            //     onClick: (event, rowData) => {

                                            //     },
                                            // }),
                                            (rowData) => ({
                                                icon: () => <VisibilityIcon />,
                                                tooltip: "Preview Article Variation",
                                                onClick: (event, rowData) => {
                                                    navigate("/production/Article/DetailViewArticle", { state: { from: location.pathname } })
                                                    props.GetArticlesWithVariationIdApi({ articleId: rowData.articleId, variationId: rowData._id })
                                                },
                                            }),
                                        ]}
                                        editable={{
                                            isDeletable: rowData => false,
                                            onRowDelete: (oldData) =>
                                                new Promise((resolve) => {
                                                    setTimeout(() => {
                                                        resolve();
                                                        props.DeleteArticleApi(oldData._id);
                                                    }, 600);
                                                }),
                                        }}
                                    />
                                )
                            }}
                            options={{
                                actionsColumnIndex: -1,
                                exportButton: true,
                                exportAllData: true,
                                paging: true,
                                pageSize: 50,
                                emptyRowsWhenPaging: false,
                                pageSizeOptions: [50, 100, 150, 200],
                                headerStyle: {
                                    position: "sticky",
                                    top: 0,
                                    color: "#00BBBB",
                                    fontWeight: "550",
                                    onRowAdd: "none",
                                },
                            }}
                            editable={{
                                // isEditHidden: (rowData) => Checkpermissions("permissionsSubCatName", "Colour Edit"),
                                // isDeleteHidden: (rowData) => Checkpermissions("permissionsSubCatName", "Colour Delete"),
                                isDeletable: rowData => false,
                                isEditable: rowData => true,
                                onRowUpdate: (newData, oldData) =>
                                    new Promise((resolve) => {
                                        setTimeout(() => {
                                            resolve();
                                            props.UpdateArticleApi(newData, oldData);
                                        }, 600);
                                    }),
                                onRowDelete: (oldData) =>
                                    new Promise((resolve) => {
                                        setTimeout(() => {
                                            resolve();
                                            props.DeleteArticleApi(oldData._id);
                                        }, 600);
                                    }),
                            }}
                            actions={[
                                (rowData) => ({
                                    icon: () => <PrintIcon />,
                                    tooltip: "Print Full Article",
                                    onClick: (event, rowData) => {
                                        props.PrintFullArticlePDF(rowData)
                                    },
                                }),
                            ]}
                        />
                    </div>

                    <Modal
                        open={openPDF}
                        onClose={handleClosePDF}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={stylePDF}>
                            <Col style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: '15px' }}> Please Select Variation </Col>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" controlId="garana">
                                        <Form.Label><b>Select Color *</b> </Form.Label>
                                        <Select
                                            // isDisabled={_.isEmpty(articleId)}
                                            style={{ zIndex: 1 }}
                                            // value={{ label: item.colourLabel }}
                                            placeholder="Select Color"
                                            name="colour"
                                            onChange={handleChangeColor}
                                            options={inventoryFlavor}
                                            isOptionDisabled={(option) => !_.isEmpty(_.find(props.articleListWithID[0].variation, (x) => x.colourLabel === option.label))}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" controlId="garana">
                                        <Form.Label><b>Select Size *</b> </Form.Label>
                                        <Select
                                            // isDisabled={_.isEmpty(articleId)}
                                            style={{ zIndex: 1 }}
                                            // value={{ label: item.sizeLabel }}
                                            placeholder="Select Size"
                                            name="size"
                                            onChange={handleChangeSize}
                                            options={inventorySize}
                                        // isOptionDisabled={(option) => !_.isEmpty(_.find(getArticleWithID[0].variation, (x) => x.sizeLabel === option.label)) || !_.isEmpty(_.find(inputField, (x) => x.sizeLabel === option.label))}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label><b>Manual Code *</b> </Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Manual Code"
                                            onChange={(e) => setManualVariationCode(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <div className="sendDiv">
                                {_.isEmpty(colorId) || _.isEmpty(sizeId) || _.isEmpty(manualVariationCode) ? (
                                    <Button variant="contained" endIcon={<SendIcon />} size="large" disabled> SAVE </Button>
                                ) : (
                                    <Button variant="contained" endIcon={<SendIcon />} size="large" onClick={DuplicateArticleVariation}> SAVE </Button>
                                )}
                            </div>

                        </Box>
                    </Modal>
                </Container>
            )}
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        DuplicateArticleApi: (data, handleVisible, handleDangerVisible) => dispatch(DuplicateArticleApi(data, handleVisible, handleDangerVisible)),
        DeleteArticleApi: (id) => dispatch(DeleteArticleApi(id)),
        UpdateArticleApi: (newData, oldData) => dispatch(UpdateArticleApi(newData, oldData)),
        GetArticleWithIDApi: (id) => dispatch(GetArticleWithIDApi(id)),
        getSizeData: () => dispatch(getSizeData()),
        getFlavorsData: () => dispatch(getFlavorsData()),
        PrintFullArticlePDF: (data) => dispatch(PrintFullArticlePDF(data)),
        GetArticlesWithVariationIdApi: (data) => dispatch(GetArticlesWithVariationIdApi(data)),
        setHeading: (data) => dispatch(setHeading(data)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    isFetchingArticle: state.ArticleReducer.isFetchingArticle,
    isFetchingArticleWithID: state.ArticleReducer.isFetchingArticleWithID,
    articleListWithID: state.ArticleReducer.articleListWithID,
    sizeDataList: state.sizeReducer.sizeDataList,
    isFetchingSize: state.sizeReducer.isFetchingSize,
    flavorsDataList: state.ColourReducer.flavorsDataList,
    isFetchingFlavors: state.ColourReducer.isFetchingFlavors,
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewArticle);