// import _ from "lodash";

const initialState = {
    isAddingEmployeeTypeData: false,
    addEmployeeType: [],
    employeeTypeList: [],
    isFetchingEmployeeTypeData: false,
    isUpdatingEmployeeTypeData: false,
    isDeletingEmployeeTypeData: false,
    employeeAdd: [],
    isAddingEmployee: false,
    employeeList: [],
    isFetchingEmployeeData: false,
    isAddingDepartmentData: false,
    showEmployeeAdd: false,
    activeEmployeeList: [],
    isFetchingActiveEmployeeData: false,
    isFetchingEmployeeLeavesData: false,
    employeeLeaveData: [],
    isFetchingEmployeeDataById: false,
    employeeListById: [],
};

const EmployeeReducer = (state = initialState, action) => {
    switch (action.type) {

        case "REQUEST_ADD_EMPLOYEE":
            return { ...state, isAddingEmployee: true, showEmployeeAdd: false };
        case "SUCCESS_ADD_EMPLOYEE":
            return { ...state, employeeAdd: action.payload, isAddingEmployee: false, showEmployeeAdd: true };
        case "ERROR_ADD_EMPLOYEE":
            return { ...state, isAddingEmployee: false };


        case "REQUEST_GET_EMPLOYEE":
            return { ...state, isFetchingEmployeeData: true };
        case "SUCCESS_GET_EMPLOYEE":
            return { ...state, employeeList: action.payload.data, employeeLeaveData: [], isFetchingEmployeeData: false };
        case "ERROR_GET_EMPLOYEE":
            return { ...state, isFetchingEmployeeData: false };


        case "REQUEST_GET_ACTIVE_EMPLOYEE":
            return { ...state, isFetchingActiveEmployeeData: true };
        case "SUCCESS_GET_ACTIVE_EMPLOYEE":
            return { ...state, activeEmployeeList: action.payload.data, employeeLeaveData: [], isFetchingActiveEmployeeData: false };
        case "ERROR_GET_ACTIVE_EMPLOYEE":
            return { ...state, isFetchingActiveEmployeeData: false };


        case "SET_EDIT_EMPLOYEE_FIELDS":
            let valueEmp = state.employeeListById;
            let keyEmp = action.payload.key;
            valueEmp[keyEmp] = action.payload.value;
            return { ...state, employeeListById: valueEmp };


        default:
            return state;
    }
}

export default EmployeeReducer;