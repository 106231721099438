const initialState = {
    STPaymentList: [],
    isAddingSTPayment: false,
    STRecivingList: [],
    isAddingSTReciving: false,
    isFetchingSTLedger: false,
    stLedger: [],
};

const STReducer = (state = initialState, action) => {
    switch (action.type) {

        case "REQUEST_ST_PAYMENT":
            return { ...state, isAddingSTPayment: true };
        case "SUCCESS_ST_PAYMENT":
            return { ...state, STPaymentList: action.payload, isAddingSTPayment: false, };
        case "ERROR_ST_PAYMENT":
            return { ...state, isAddingSTPayment: false };


        case "REQUEST_ADD_ST_RECIVING":
            return { ...state, isAddingSTReciving: true };
        case "SUCCESS_ADD_ST_RECIVING":
            return { ...state, STRecivingList: action.payload, isAddingSTReciving: false };
        case "ERROR_ADD_ST_RECIVING":
            return { ...state, isAddingSTReciving: false };


        case "REQUEST_GET_ST_LEDGER":
            return { ...state, isFetchingSTLedger: true };
        case "SUCCESS_GET_ST_LEDGER":
            return { ...state, stLedger: action.payload, isFetchingSTLedger: false };
        case "ERROR_GET_ST_LEDGER":
            return { ...state, isFetchingSTLedger: false };


        default:
            return state;
    }
};
export default STReducer;