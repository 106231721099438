import 'jspdf-autotable';
import jsPDF from 'jspdf';
import logo from "../../src/Images/logo.png";
import { camelCase, mapKeys } from 'lodash';
import swal from 'sweetalert';


export const appendNonSignificantZeros = (id) => {
    if (Number(id) < 10 && Number(id) > 0) {
        return (id = `000${id}`);
    }
    if (Number(id) < 100 && Number(id) >= 10) {
        return (id = `00${id}`);
    }
    if (Number(id) < 1000 && Number(id) <= 100) {
        return (id = `0${id}`);
    }
    if (Number(id) >= 1000) {
        return (id);
    }
    return (id);
}

export const handleNoDataVisible = () => {
    swal({
        title: "Warning",
        text: "No Data Found!",
        icon: "info",
        button: "Ok",
    });
};

export const startFinancialDate = "2024-07-01";
export const endFinancialDate = "2025-06-30";

export function debugBase64(base64URL) {
    var win = window.open()
    if (win) win.document.write('<iframe src="' + base64URL + "_blank", '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
}

export function getVoucherName(id) {
    if (id == '1') return "Cash Payment Voucher"
    else if (id == '2') return "Cash Reciept Voucher"
    else if (id == '3') return "Bank Payment Voucher"
    else if (id == '4') return "Bank Reciept Voucher"
    else return "Journal Voucher"
}
export const previewVoucher = (data, status, vo_number) => {
    const unit = "pt";
    const size = "A4";
    const orientation = "potrait";
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(11);
    doc.addImage(logo, 'PNG', 40, 15, 80, 80);
    doc.setFontSize(16);
    const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
    const companyLocation = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
    const companyntn = `${process.env.REACT_APP_COMPANY_NTN}`;
    doc.setFont("Times New Roman", "bold")
    doc.text(companyName, 160, 40, 0, 20);
    doc.setFontSize(14)
    doc.setFont("Times New Roman", "Normal")
    doc.text(companyLocation, 160, 60, 0, 20)
    doc.text(companyntn, 160, 80, 0, 20)
    doc.setFontSize(14);
    doc.text(getVoucherName(data.voucherTypeId), 230, 140, 0, 20);
    doc.text(`Voucher: ${data.voucherManualId}`, 40, 160, 0, 20);
    doc.text(`Voucher Status: ${status}`, 40, 180, 0, 20);
    doc.text(`Date: ${data.vocDate}`, 40, 200, 0, 20);
    const headers = [["Account Name", "Bill", "Description", "Debit", "Credit"]];
    const headersSigned = [["Prepared By", "Approved By", "Audited By", "Recieved By"]];
    const tableData = status == "Pending" ?
        data.voucherData.map((elt) => [
            elt.accountTittle,
            elt.billNumber,
            elt.voucherDescription,
            elt.accountType == 2 ? elt.voucherAmount : "",
            elt.accountType == 1 ? elt.voucherAmount : "",]) :
        data.voucherData.map((elt) => [
            elt.accountTittle,
            elt.billNumber,
            elt.voucherDescription,
            elt.accountType == 2 ? elt.voucherAmount : "",
            elt.accountType == 1 ? elt.voucherAmount : "",])
    let content = {
        startY: 220,
        startX: 5,
        head: headers,
        body: tableData,
        theme: 'grid',
        columnStyles: {
            0: { cellWidth: 160 },
            1: { cellWidth: 60 },
            2: { cellWidth: 180 },
            3: { cellWidth: 60 },
            4: { cellWidth: 60 },
        },
        headStyles: { fillColor: '#2980ba' },
    };
    doc.autoTable(content);
    let contentSign = {
        startY: doc.lastAutoTable.finalY + 10,
        startX: 5,
        head: headersSigned,
        0: { cellWidth: 130 },
        1: { cellWidth: 130 },
        2: { cellWidth: 130 },
        3: { cellWidth: 130 },
        body: [[{ content: '', rowSpan: 5 }, { content: '', rowSpan: 5 }, { content: '', rowSpan: 5 }, { content: '', rowSpan: 5 }]],
        theme: 'grid',
        headStyles: { fillColor: '#2980ba' },
        bodyStyles: { minCellHeight: 300 }
    };
    doc.autoTable(contentSign);
    doc.setProperties({ title: 'Voucher' })
    doc.autoPrint();
    doc.output('dataurlnewwindow')
}

export const camelizeKeys = (arr) => {
    let result = arr.map(el => mapKeys(el, (val, key) => camelCase(key)));
    return result;
};

export function mergeNames(array, obj) {
    let output = [];
    for (let item of array) {
        output.push(`[${item[obj]}]`);
    }
    return output.toString()
}