import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import "jspdf-autotable";
import Swal from "sweetalert";
import { addRestaurant } from "./Resturant.action";
import { setHeading } from "../../../accounts/account.actions";
import ImageUploader from "react-images-upload";
import { addProductImage } from "../AddProduct/Product.action";
import { Switch } from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import Select from "react-select";


const AddResturant = (props) => {
  const [restaurant, setRestaurant] = useState("");
  const [restaurantDesc, setRestaurantDesc] = useState("");
  const [tax, setTax] = useState(0);
  const [ntnNumber, setNTNNumber] = useState(0);
  const [praOnCard, setPRAOnCard] = useState(0);
  const [praOnCash, setPRAOnCash] = useState(0);
  const [fbrOnCash, setFBROnCash] = useState(0);
  const [fbrOnCard, setFBROnCard] = useState(0);
  const [restaurantLocation, setRestaurantLocation] = useState("");
  const [s1, setS1] = useState("PRATax");
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [strn, setSTRN] = useState("");


  const handleVisible = (msg) => {
    setAlertVisible(true)
    setTimeout(() => {
      setAlertVisible(false)
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  }
  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true)
    setTimeout(() => {
      setDangerVisibleAlert(false)
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  }
  const handleSwitch = () => {
    s1 == "FBRTax" ? setS1("PRATax") : setS1("FBRTax")
  }
  const onDrop = (pictureFiles, pictureDataURLs) => {
    props.ProductImage({
      image: pictureFiles[0],
    })
    //setUploadImage(pictureFiles);
  };
  function uploadRestaurant() {
    props.addRestaurant({
      restaurantName: restaurant,
      restaurantLocation: restaurantLocation,
      restaurantDesc: restaurantDesc,
      tax: tax,
      image: props.ProductImageURL.file,
      ntn: ntnNumber,
      praTaxCash: praOnCash,
      praTaxCard: praOnCard,
      fbrTaxCard: fbrOnCard,
      fbrTaxCash: fbrOnCash,
      taxType: s1,
      phone: phone,
      email: email,
      strn: strn,
    },
      handleVisible,
      handleDangerVisible
    );
    setRestaurantDesc("");
    setS1("PRATax")
  }

  useEffect(() => {
    props.setHeading("Add Company");
  }, []);

  return (
    <>
      {props.isAddingRes ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <div>
              <Form>
                <Row>
                  <Col lg="6" md="6" xs="6">
                    <Form.Group className="mb-3" controlId="garana" >
                      <Form.Label><b>Company Name *</b></Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Company Name"
                        onChange={(e) => setRestaurant(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="6" md="6" xs="6">
                    <Form.Group className="mb-3" controlId="garana" >
                      <Form.Label><b>Company Location *</b></Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Company Location"
                        onChange={(e) => { setRestaurantLocation(e.target.value) }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="garana" >
                      <Form.Label><b>Phone / Tell *</b></Form.Label>
                      <Form.Control
                        type="Number"
                        placeholder="Phone"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="garana" >
                      <Form.Label><b>Email *</b></Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Email"
                        // max={100}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="garana" >
                      <Form.Label><b>STRN *</b></Form.Label>
                      <Form.Control
                        type="Number"
                        placeholder="STRN"
                        // max={100}
                        value={strn}
                        onChange={(e) => setSTRN(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="garana" >
                      <Form.Label><b>Description </b></Form.Label>
                      <Form.Control
                        type="text"
                        value={restaurantDesc}
                        placeholder="Description"
                        onChange={(e) => setRestaurantDesc(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="garana" >
                      <Form.Label><b>Tax Amount </b></Form.Label>
                      <Form.Control
                        type="Number"
                        max={100}
                        value={tax}
                        placeholder="Tax Amount" onChange={(e) => setTax(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="garana" >
                      <Form.Label><b>NTN Number </b></Form.Label>
                      <Form.Control
                        type="Number"
                        value={ntnNumber}
                        placeholder="NTN Number"
                        onChange={(e) => setNTNNumber(e.target.value)} />
                    </Form.Group>
                  </Col>
                  <Col lg="1" md="1" xs="1"></Col>
                  <Col lg="2" md="2" xs="2" style={{ marginTop: '31px' }}>
                    <Form.Group >
                      <Form.Label><b>PRA Tax</b></Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg="1" md="1" xs="1" className="mt-4" style={{ marginRight: '2%', marginLeft: '-5%' }}>
                    <Switch onClick={handleSwitch} />
                  </Col>
                  <Col lg="2" md="2" xs="2" style={{ marginTop: '31px' }}>
                    <Form.Group  >
                      <Form.Label><b>FBR Tax</b></Form.Label>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6" md="6" xs="6">
                    <Form.Group className="mb-3" controlId="garana" >
                      <Form.Label><b>PRA Tax on Cash </b></Form.Label>
                      <Form.Control
                        type="Number"
                        disabled={s1 == "FBRTax" ? true : false}
                        value={praOnCash}
                        max={100}
                        placeholder="PRA Tax on Cash"
                        onChange={(e) => setPRAOnCash(e.target.value)} />
                    </Form.Group>
                  </Col>
                  <Col lg="6" md="6" xs="6">
                    <Form.Group className="mb-3" controlId="garana" >
                      <Form.Label><b>PRA Tax on Card :</b></Form.Label>
                      <Form.Control
                        type="Number"
                        value={praOnCard}
                        disabled={s1 == "FBRTax" ? true : false}
                        max={100}
                        placeholder="PRA Tax on Card"
                        onChange={(e) => setPRAOnCard(e.target.value)} />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6" md="6" xs="6">
                    <Form.Group className="mb-3" controlId="garana" >
                      <Form.Label><b>FBR tax on cash:</b></Form.Label>
                      <Form.Control
                        type="Number"
                        value={fbrOnCash}
                        disabled={s1 == "PRATax" ? true : false}
                        max={100}
                        placeholder="FPR tax on cash"
                        onChange={(e) => setFBROnCash(e.target.value)} />
                    </Form.Group>
                  </Col>
                  <Col lg="6" md="6" xs="6">
                    <Form.Group className="mb-3" controlId="garana" >
                      <Form.Label><b>FBR tax on card :</b></Form.Label>
                      <Form.Control
                        type="Number"
                        value={fbrOnCard}
                        disabled={s1 == "PRATax" ? true : false}
                        max={100}
                        placeholder="FPR tax on card"
                        onChange={(e) => setFBROnCard(e.target.value)} />
                    </Form.Group>
                    {/* <FormControlLabel control={<Switch defaultChecked />} label="Label" /> */}
                  </Col>

                </Row>

                <Row>
                  <Col xl="12" lg="12" md="12" sm="12" xs="12">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <b style={{ fontSize: "large" }}>
                          Upload Company Logo *
                        </b>
                      </Form.Label>
                      <ImageUploader
                        withIcon={false}
                        buttonText="Upload Company Logo"
                        onChange={onDrop}
                        // onChange = {(e) => setUploadCnicImage(e.target.files[0])}
                        imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                        maxFileSize={999999999999999999999}
                        singleImage={true}
                        withPreview={true}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <div className="sendDiv">
                  {isEmpty(restaurant) || isEmpty(restaurantLocation) || isEmpty(props.ProductImageURL.file) ? (
                    <Button variant="contained" endIcon={<SendIcon />} size="large" disabled> SAVE </Button>
                  ) : (
                    <Button variant="contained" endIcon={<SendIcon />} size="large" onClick={uploadRestaurant}> SAVE </Button>
                  )}
                </div>

              </Form>
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addRestaurant: (data, handleVisible, handleDangerVisible) => dispatch(addRestaurant(data, handleVisible, handleDangerVisible)),
    setHeading: (data) => dispatch(setHeading(data)),
    ProductImage: (data) => dispatch(addProductImage(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  restaurantList: state.ResturantReducer.restaurantList,
  isAddingRestaurant: state.ResturantReducer.isAddingRestaurant,
  ProductImageURL: state.ProductReducer.ProductImageURL,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddResturant);
