import API from "../../../../../global/api";


export const requestAddProductCRM = () => {
    return {
        type: "REQUEST_ADD_PRODUCT_CRM",
    };
};
export const successAddProductCRM = (data) => {
    return {
        type: "SUCCESS_ADD_PRODUCT_CRM",
        payload: data,
    };
};
export const errorAddProductCRM = () => {
    return {
        type: "ERROR_ADD_PRODUCT_CRM",
    };
};
export const AddProductCRMApi = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddProductCRM());
        API.post(`/CRMProduct`, data).then((res) => {
            dispatch(successAddProductCRM(data));
            handleVisible(res.data.message);
        }).catch((error) => {
            dispatch(errorAddProductCRM());
            handleDangerVisible(error.response.data.message);
        })
    };
};


export const requestGetCRMProduct = () => {
    return {
        type: "REQUEST_GET_CRM_PRODUCT",
    };
};
export const successGetCRMProduct = (data) => {
    return {
        type: "SUCCESS_GET_CRM_PRODUCT",
        payload: data,
    };
};
export const errorGetCRMProduct = () => {
    return {
        type: "ERROR_GET_CRM_PRODUCT",
    };
};
export const GetCRMProductApi = () => {
    return (dispatch) => {
        dispatch(requestGetCRMProduct());
        API.get(`/CRMProduct`).then((res) => {
            let getData = res.data;
            dispatch(successGetCRMProduct(getData));
        }).catch((error) => {
            dispatch(errorGetCRMProduct());
        })
    };
};


export const DeleteRequestSize = () => {
    return {
        type: "DELETE_REQUEST_SIZE",
    };
};
export const DeleteSuccessSize = (user_id) => {
    return {
        type: "DELETE_SUCCESS_SIZE",
        user_id: user_id,
    };
};
export const DeleteErrorSize = () => {
    return {
        type: "DELETE_ERROR_SIZE",
    };
};
export const deleteSize = (vendorId) => {
    return dispatch => {
        dispatch(DeleteRequestSize());
        API.delete(`/size/${vendorId}`).then(res => {
            dispatch(DeleteSuccessSize(vendorId))
        }).catch((error) => {
            dispatch(DeleteErrorSize())
        });
    }
};


export const UpdateRequestSize = () => {
    return {
        type: "UPDATE_REQUEST_SIZE",
    };
};
export const UpdateSuccessSize = (data) => {
    return {
        type: "UPDATE_SUCCESS_SIZE",
        payload: data,
    };
};
export const UpdateErrorSize = () => {
    return {
        type: "UPDATE_ERROR_SIZE",
    };
};
export const updateSize = (data, oldData) => {
    return dispatch => {
        dispatch(UpdateRequestSize());
        API.put(`/size/${oldData._id}`,
            {
                sizeName: data.sizeName,
                sizeDesc: data.sizeDesc
            }, {
        }).then(res => {
            dispatch(UpdateSuccessSize([data]))
        }).catch((error) =>
            dispatch(UpdateErrorSize()));
    }
};