import API from "../../../../../global/api";
import jsPDF from "jspdf";
import logo from "../../../../../../src/Images/logo.png";
import moment from "moment";
import _ from 'lodash'
import { handleNoDataVisible } from "../../../../../global/globalFunctions";


export const requestAddProduct = () => {
    return {
        type: "REQUEST_ADD_PRODUCT",
    };
};
export const successAddProduct = (data) => {
    return {
        type: "SUCCESS_ADD_PRODUCT",
        payload: data,
    };
};
export const errorAddProduct = () => {
    return {
        type: "ERROR_ADD_PRODUCT",
    };
};
export const addProduct = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddProduct());
        API.post(`/products`, data).then((res) => {
            // dispatch(getProductData());
            dispatch(successAddProduct(data));
            handleVisible(res.data.message);
        }).catch((error) => {
            dispatch(errorAddProduct());
            handleDangerVisible(error.response.data.message);
        })
    };
};


export const requestProductData = () => {
    return {
        type: "REQUEST_PRODUCT_GET_DATA",
    };
};
export const successProductGetData = (data) => {
    return {
        type: "SUCCESS_PRODUCT_GET_DATA",
        payload: data,
    };
};
export const errorProductGetData = () => {
    return {
        type: "ERROR_PRODUCT_GET_DATA",
    };
};
export const getProductData = () => {
    return (dispatch) => {
        dispatch(requestProductData());
        API.get(`products`).then((res) => {
            let getData = res.data;
            dispatch(successProductGetData(getData));
        }).catch((error) => {
            dispatch(errorProductGetData());
        })
    };
};


export const UpdateRequestProduct = () => {
    return {
        type: "UPDATE_REQUEST_PRODUCT",
    };
};
export const UpdateSuccessProduct = (data) => {
    return {
        type: "UPDATE_SUCCESS_PRODUCT",
        payload: data,
    };
};
export const UpdateErrorProduct = () => {
    return {
        type: "UPDATE_ERROR_PRODUCT",
    };
};
export const updateProduct = (data, oldData) => {
    return dispatch => {
        dispatch(UpdateRequestProduct());
        API.put(`/products/${oldData._id}`,
            {
                name: data.name,
                discount: data.discount,
                desc: data.desc,
                greenColorTime: data.greenColorTime,
                yellowColorTime: data.yellowColorTime,
                redColorTime: data.redColorTime,
                productBalance: data.productBalance,
                purchasePrice: data.purchasePrice,
                salePrice: data.salePrice,
                //category:data.category,    
            }, {
        }).then(res => {
            dispatch(getProductData());
            dispatch(UpdateSuccessProduct([data]))
        }).catch((error) =>
            dispatch(UpdateErrorProduct()));
    }
};


export const DeleteRequestProduct = () => {
    return {
        type: "DELETE_REQUEST_PRODUCT",
    };
};
export const DeleteSuccessProduct = (user_id) => {
    return {
        type: "DELETE_SUCCESS_PRODUCT",
        user_id: user_id,
    };
};
export const DeleteErrorProduct = () => {
    return {
        type: "DELETE_ERROR_PRODUCT",
    };
};
export const deleteProduct = (vendorId) => {
    return dispatch => {
        dispatch(DeleteRequestProduct());
        API.delete(`/products/${vendorId}`).then(res => {
            dispatch(getProductData());
            dispatch(DeleteSuccessProduct(vendorId))
        }).catch((error) => {
            dispatch(DeleteErrorProduct())
        });
    }
};


export const requestUOM = () => {
    return {
        type: "REQUEST_UOM",
    };
};
export const successUOM = (data) => {
    return {
        type: "SUCCESS_UOM",
        payload: data,
    };
};
export const errorUOM = () => {
    return {
        type: "ERROR_UOM",
    };
};
export const getUOM = () => {
    return (dispatch) => {
        dispatch(requestUOM());
        API.get(`/uom?limit=1000&page=0&search`).then((res) => {
            let getData = res.data;
            dispatch(successUOM(getData));
        }).catch((error) => {
            dispatch(errorUOM());
        })
    };
};


export const requestAddProductImage = () => {
    return {
        type: "REQUEST_ADD_PRODUCT_IMAGE",
    };
};
export const successAddProductImage = (data) => {
    return {
        type: "SUCCESS_ADD_PRODUCT_IMAGE",
        payload: data,
    };
};
export const errorAddProductImage = () => {
    return {
        type: "ERROR_ADD_PRODUCT_IMAGE",
    };
};
export const addProductImage = (data) => {
    return (dispatch) => {
        const formData = new FormData();
        // formData.append("image", get(data, 'image'));
        formData.append("image", data.image)
        dispatch(requestAddProductImage());
        API.post(`/files/image`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((res) => {
            let getData = res.data;
            dispatch(successAddProductImage(getData));
        }).catch((error) => {
            dispatch(errorAddProductImage());
        })
    }
};


export const requestProductType = () => {
    return {
        type: "REQUEST_PRODUCT_TYPE",
    };
};
export const successProductType = (data) => {
    return {
        type: "SUCCESS_PRODUCT_TYPE",
        payload: data,
    };
};
export const errorProductType = () => {
    return {
        type: "ERROR_PRODUCT_TYPE",
    };
};
export const getProductType = () => {
    return (dispatch) => {
        dispatch(requestProductType);
        API.get(`/products/productTypes`).then((res) => {
            let getData = res.data;
            dispatch(successProductType(getData));
        }).catch((error) => {
            dispatch(errorProductType());
        })
    };
};


export const requestProductBalance = () => {
    return {
        type: "REQUEST_PRODUCT_GET_BALANCE",
    };
};
export const successProductGetBalance = (data) => {
    return {
        type: "SUCCESS_PRODUCT_GET_BALANCE",
        payload: data,
    };
};
export const errorProductGetBalance = () => {
    return {
        type: "ERROR_PRODUCT_GET_BALANCE",
    };
};
export const getProductBalance = () => {
    return (dispatch) => {
        dispatch(requestProductBalance());
        API.get(`/products/update_ledgerBlnc_to_productBlnc`).then((res) => {
            let getData = res.data;
            dispatch(successProductGetBalance(getData));
        }).catch((error) => {
            dispatch(errorProductGetBalance());
        })
    };
};


export const requestGetSimpleCompleteProduct = () => {
    return {
        type: "REQUEST_GET_SIMPLE_COMPLETE_PRODUCT",
    };
};
export const successGetSimpleCompleteProduct = (data) => {
    return {
        type: "SUCCESS_GET_SIMPLE_COMPLETE_PRODUCT",
        payload: data,
    };
};
export const errorGetSimpleCompleteProduct = () => {
    return {
        type: "ERROR_GET_SIMPLE_COMPLETE_PRODUCT",
    };
};
export const getSimpleCompleteProduct = () => {
    return (dispatch) => {
        dispatch(requestGetSimpleCompleteProduct());
        API.get(`products/complete_product_simple`).then((res) => {
            let getData = res.data;
            if (_.isEmpty(getData)) {
                handleNoDataVisible()
            } else {
                const unit = "pt";
                const size = "A4"; // Use A1, A2, A3 or A4
                const orientation = "potrait"; // portrait or landscape

                const marginLeft = 40;
                const doc = new jsPDF(orientation, unit, size);
                const title = "Store Product List";
                const companyName = `${getData.resImage.restaurantName}`;
                const companyAddress = `${getData.resImage.restaurantLocation}`;
                const companyNtn = `${getData.resImage.ntn}`;
                const logo1 = getData.restaurantImage;
                // const Date1 = getData.orderDate;
                // // const Date2 = getData.endDate;
                // // const restaurant = getData.data[0].restaurant.restaurantName;
                // const DisName = isNull(getData.reprintOrderResult.coupon)
                //   ? "Null"
                //   : getData.reprintOrderResult.coupon.name;
                // const DiscountAmount = getData.reprintOrderResult.discount;
                // //  const closecash = getData.totalClosingCash;
                // const totalOrderAmount = getData.reprintOrderResult.grandTotal;
                // const Customer = getData.reprintOrderResult.customer.name;
                const headers = [
                    [
                        "Code",
                        "Product Name",
                        "UOM",
                        "Product Balance",
                        "Product Purchase Price",
                        "Amount",
                    ],
                ];
                const data = getData.completeProductsResults.map((elt, index) => [
                    elt.code,
                    elt.name,
                    elt.uomLabel,
                    elt.productBalance,
                    elt.purchasePrice,
                    elt.productBalance * elt.purchasePrice,
                ]);
                const total = _.sumBy(data, "5").toFixed(1)
                let content = {
                    startY: 140,
                    startX: 10,
                    head: headers,
                    body: data,
                };
                doc.addImage(logo1, "PNG", 40, 15, 100, 100);
                doc.setFontSize(20);
                doc.setFont(undefined, "bold");
                doc.text(companyName, 150, 50, 0, 20);
                doc.setFontSize(13);
                doc.setFont("TimesNewRoman", "Normal");
                doc.text(companyAddress, 150, 70, 0, 20);
                doc.text(companyNtn, 150, 90, 0, 20);
                // doc.text(`Day Open Name: ${dayopenname}`, marginLeft, 150, 0, 20);
                //doc.text(`Status: ${status}`, 350, 150, 0, 20);
                // doc.text(`Customer Name: ${Customer}`, marginLeft, 170, 0, 20);
                // doc.text(`Coupon: ${DisName}`, 350, 170, 0, 20);
                // doc.text(`Discount Amount: ${DiscountAmount}`, marginLeft, 190, 0, 20);
                // doc.text(`Total Order Amount: ${totalOrderAmount}`, 350, 190, 0, 20);
                // // doc.text(`Total Order Length: ${todaysOrderLength}`, 350, 210, 0, 20);
                doc.setFontSize(18);
                doc.setFont("TimesNewRoman", "bold");
                doc.text(title, 215, 120);
                doc.setFontSize(13);
                doc.setFont("TimesNewRoman", "Normal");
                // doc.text(`Date :${moment(Date1).format("YYYY-MM-DD")}`, 210, 140);
                doc.setFontSize(11);
                doc.autoTable(content);
                doc.text(`Total : ${total}`, 440, doc.lastAutoTable.finalY + 20)
                doc.setProperties({
                    title: `Store Product List ${moment(new Date()).format("YYYY-MM-DD")}.pdf`,
                });
                //   doc.output('dataurlnewwindow')
                doc.save(
                    `Store Product List ${moment(new Date()).format("YYYY-MM-DD")}.pdf`
                );
            }
            dispatch(successGetSimpleCompleteProduct(getData));
        }).catch((error) => {
            dispatch(errorGetSimpleCompleteProduct());
        })
    };
};


export const resetReducer = () => {
    return {
        type: "RESET_PRODUCT_URL",
    };
};
export const filterItem = (data) => {
    return {
        type: "FILTER_PRODUCT",
        payload: data
    };
};


export const requestAddProductFile = () => {
    return {
        type: "REQUEST_ADD_PRODUCT_FILE"
    };
};
export const successAddProductFile = (data) => {
    return {
        type: "SUCCESS_ADD_PRODUCT_FILE",
        // payload: data
    }
};
export const errorAddProductFile = () => {
    return {
        type: "ERROR_ADD_PRODUCT_FILE"
    }
};
export const addProductFile = (file, retaurantId, CategoryId, subCategoryId, warehouseId, brandId, handleVisible, handleDangerVisible) => {
    const formData = new FormData();
    formData.append("file", file.file);
    formData.append("wareHouseId", `${warehouseId}`);
    formData.append("retaurantId", `${retaurantId}`);
    formData.append("category", `${CategoryId}`);
    formData.append("subCategoryId", `${subCategoryId}`);
    formData.append("brandId", `${brandId}`);
    return (dispatch) => {
        dispatch(requestAddProductFile());
        API.post(`/products/upload_products`, formData).then((res) => {
            let getData = res.data;
            dispatch(successAddProductFile(getData));
            handleVisible(res.data.message);
        }).catch((error) => {
            dispatch(errorAddProductFile());
            handleDangerVisible(error.response.data.message);
        })
    };
};


export const requestAllProductPDF = () => {
    return {
        type: "REQUEST_ALL_PRODUCT_PDF",
    };
};
export const successAllProductPDF = (data) => {
    return {
        type: "SUCCESS_ALL_PRODUCT_PDF",
        payload: data,
    };
};
export const errorAllProductPDF = () => {
    return {
        type: "ERROR_ALL_PRODUCT_PDF",
    };
};
export const getAllProductPDFApi = (handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAllProductPDF());
        API.get(`products`).then((res) => {
            let data = res.data
            const doc = new jsPDF({ orientation: 'l', });
            const columnTitles = [
                { title: 'Product Code' },
                { title: "Product Name" },
                { title: "Opening Balance" },
                { title: "Closing Balance" },
                { title: "Purchase Price", },
                { title: "Total Purchase Price" },
                { title: "Sale Price" },
                { title: "Total Sale Price" },
                { title: "Business Location" },
                { title: "Status" },
            ];
            const pdfData = data.data.map((columnDef) => [
                columnDef.code,
                columnDef.name,
                columnDef.openingBalance,
                columnDef.productBalance,
                columnDef.desc,
                columnDef.purchasePrice,
                columnDef.purchasePrice * columnDef.productBalance,
                columnDef.salePrice,
                columnDef.salePrice * columnDef.productBalance,
                columnDef.retaurantId.restaurantName,
                columnDef.wareHouseId.businessName,
                columnDef.status == true ? 'Active' : 'Non-Active',
                columnDef.productDate,
            ]);
            let content = {
                startY: 50,
                startX: 5,
                head: [columnTitles],
                body: pdfData,
            };
            const companyName = `${data.resImage.restaurantName}`;
            const companyAddress = `${data.resImage.restaurantLocation}`;
            const companyNTN = `NTN # ${data.resImage.ntn}`;
            // doc.addImage(logo, "PNG", 10, 11, 30, 30);
            doc.setFont("Times New Roman", "bolditalic");
            doc.text(companyName, 20, 15, 0, 20);
            doc.setFontSize(11);
            doc.setFont("Times New Roman", "normal");
            doc.text(companyAddress, 20, 20, 0, 20);
            doc.text(companyNTN, 20, 25, 0, 20);
            doc.setFontSize(14);
            doc.setFont("Times New Roman", "normal");
            doc.text("Product Report", 20, 40, 0, 20);
            doc.autoTable(content);
            doc.setFontSize(12);
            let finalY = doc.lastAutoTable.finalY;
            // doc.rect(13, finalY + 10, 270, 13);
            // doc.text(`Total`, 16, finalY + 18, 0, 20);
            doc.text(`Opening Balance Total : ${_.sumBy(pdfData, "2")}`, 16, finalY + 10)
            doc.text(`Closing Balance Total : ${_.sumBy(pdfData, "3")}`, 16, finalY + 17)
            doc.text(`Total Purchase Value : ${_.sumBy(pdfData, "6")}`, 16, finalY + 24)
            doc.text(`Total Sale Value: ${_.sumBy(pdfData, "8")}`, 16, finalY + 31)
            // doc.text(`${_.sumBy(pdfData, "2")}`, 53, finalY + 18);
            // doc.text(`${_.sumBy(pdfData, "3")}`, 73, finalY + 18);
            // doc.text(`${_.sumBy(pdfData, "5")}`, 120, finalY + 18);
            // doc.text(`${_.sumBy(pdfData, "6")}`, 140, finalY + 18);
            // doc.text(`${_.sumBy(pdfData, "7")}`, 165, finalY + 18);
            // doc.text(`${_.sumBy(pdfData, "8")}`, 180, finalY + 18);
            // doc.save(`Store Product.pdf`);
            doc.setProperties({ title: `Product Report_${moment().format("YYYY-MM-DD")}` });
            var blob = doc.output("blob");
            window.open(URL.createObjectURL(blob));

            dispatch(successAllProductPDF(data));
        }).catch((error) => {
            console.log(error, "error")
            dispatch(errorAllProductPDF());
            handleDangerVisible(error);
        });
    };
};


export const UpdateProductStatusRequest = () => {
    return {
        type: "UPDATE_REQUEST_PRODUCT_STATUS",
    };
};
export const UpdateProductStatusSuccess = (data) => {
    return {
        type: "UPDATE_SUCCESS_PRODUCT_STATUS",
        payload: data,
    };
};
export const UpdateProductStatusError = () => {
    return {
        type: "UPDATE_ERROR_PRODUCT_STATUS",
    };
};
export const updateProductStatusApi = (data, oldData) => {
    return dispatch => {
        dispatch(UpdateProductStatusRequest());
        API.put(`/products/update_product_status/${oldData}`,
            {
                status: data,
            }, {
        }).then(res => {
            dispatch(getProductData());
            dispatch(UpdateProductStatusSuccess([data]))
        }).catch((error) =>
            dispatch(UpdateProductStatusError()));
    }
};


// export const requestProductDataWithID = () => {
//     return {
//         type: "REQUEST_PRODUCT_GET_DATA_WITH_ID",
//     };
// };
// export const successProductGetDataWithID = (data) => {
//     return {
//         type: "SUCCESS_PRODUCT_GET_DATA_WITH_ID",
//         payload: data,
//     };
// };
// export const errorProductGetDataWithID = () => {
//     return {
//         type: "ERROR_PRODUCT_GET_DATA_WITH_ID",
//     };
// };
export const GetProductWithIDApi = (id) => {
    API.get(`products/get_product_with_id/${id}`).then((res) => {
        return res.data.data
    })
    // return (dispatch) => {
    //     dispatch(requestProductDataWithID());
    //     API.get(`products/get_product_with_id/${id}`).then((res) => {
    //         let getData = res.data;
    //         dispatch(successProductGetDataWithID(getData));
    //     }).catch((error) => {
    //         dispatch(errorProductGetDataWithID());
    //     })
    // };
};